define("additive-mc/mirage/scenarios/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.partnerMember = _exports.partnerManager = _exports.partnerAdmin = void 0;
  const partnerMember = _exports.partnerMember = function partnerMember(server) {
    let orgSlug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'test-org';
    server.get(`/me`, schema => {
      return schema.users.findBy({
        isPartner: true,
        role: 'member'
      });
    });
  };
  const partnerManager = _exports.partnerManager = function partnerManager(server) {
    let orgSlug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'test-org';
    server.get(`/me`, schema => {
      return schema.users.findBy({
        isPartner: true,
        role: 'manager'
      });
    });
  };
  const partnerAdmin = _exports.partnerAdmin = function partnerAdmin(server) {
    let orgSlug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'test-org';
    server.get(`/me`, schema => {
      return schema.users.findBy({
        isPartner: true,
        role: 'admin'
      });
    });
  };
});