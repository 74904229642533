define("additive-mc/components/amc-campaign/ads/detail-wrapper", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex flex-column pb3">
    <span class="secondary font-sm pb05">
      {{@label}}
    </span>
    <div class="font-md">
      {{#if @content}}
        {{@content}}
      {{else}}
        {{yield}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "vVoc8/3c",
    "block": "[[[10,0],[14,0,\"flex flex-column pb3\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"secondary font-sm pb05\"],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"font-md\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[1,[30,2]],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,3,null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@label\",\"@content\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "additive-mc/components/amc-campaign/ads/detail-wrapper.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});