define("additive-mc/components/amc-campaign/configuration-item/yeardate", ["exports", "@ember/component", "@ember/object", "@ember/service", "ember-concurrency", "ember-changeset"], function (_exports, _component, _object, _service, _emberConcurrency, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which handles campaigns configuration-item for `yeardate`.
   *
   * `yeardate` is a string with MONTH-DATE: `08-11` format MM-TT
   *
   * @class amc-campaigns/configuration-item/yeardate
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    tagName: '',
    /**
     * The configuration-item
     *
     * @property item
     * @default null
     */
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * Internal changeset
     *
     * @property _dayOptions
     * @default null
     * @private
     */
    _changeset: null,
    /**
     * Select options for day
     *
     * @property _dayOptions
     * @default null
     * @private
     */
    _dayOptions: null,
    /**
     * Select options for month
     *
     * @property _dayOptions
     * @default null
     * @private
     */
    _monthOptions: null,
    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {},
    init() {
      this._super(...arguments);
      let resource = {};
      if (this.item && this.item.value) {
        const [month, day] = this.item.value.split('-');
        resource = {
          month,
          day
        };
      } else {
        resource = {
          month: 1,
          day: 1
        };
      }
      const changeset = new _emberChangeset.default(resource);
      (0, _object.setProperties)(this, {
        _dayOptions: new Array(31).fill(null).map((_, index) => {
          return {
            value: index + 1
          };
        }),
        _monthOptions: new Array(12).fill(null).map((_, index) => {
          return {
            value: index + 1
          };
        }),
        _changeset: changeset
      });
    },
    /**
     * Build yeardate string, sets it to parents changeset and trigger save
     *
     * @function _save
     * @type {Task}
     * @param {Changeset} parentChangeset The changeset of the `with-modal` hoc
     * @private
     */
    _save: (0, _emberConcurrency.task)(function* (parentChangeset) {
      const tasks = [];
      const snaphsot = this.item.value;
      try {
        yield this._changeset.validate();
        this._changeset.execute();
        let month = this._changeset.get('month');
        let day = this._changeset.get('day');

        /* ensure it uses 2 digits (1 -> 01) */
        month = ('0' + month).slice(-2);
        day = ('0' + day).slice(-2);
        parentChangeset.set('value', `${month}-${day}`);
        parentChangeset.execute();
        tasks.push(this.onSave());
        tasks.push((0, _emberConcurrency.timeout)(250));
        yield (0, _emberConcurrency.all)(tasks);
      } catch (error) {
        // reset
        (0, _object.set)(this, 'item.value', snaphsot);
      }
    })
  });
});