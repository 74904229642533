define("additive-mc/helpers/mm-medium-url-options", ["exports", "@additive-apps/media/helpers/mm-medium-url-options"], function (_exports, _mmMediumUrlOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mmMediumUrlOptions.default;
    }
  });
  Object.defineProperty(_exports, "mmMediumUrlOptions", {
    enumerable: true,
    get: function get() {
      return _mmMediumUrlOptions.mmMediumUrlOptions;
    }
  });
});