define("additive-mc/mirage/factories/trigger-email-campaign", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const allowedValues = [{
    label: 'option1',
    value: 'option1-value'
  }, {
    label: 'option2',
    value: 'option2-value'
  }, {
    label: 'option3',
    value: 'option3-value'
  }];
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    title: () => _faker.default.name.findName(),
    name() {
      return this.title;
    },
    active: () => _faker.default.random.boolean(),
    code: () => _faker.default.random.arrayElement(['trigger-email', 'lead-email']),
    type: () => _faker.default.random.arrayElement(['trigger-campaign', 'lead-campaign']),
    isStartsAtEnabled() {
      if (this.active) {
        return true;
      }
      return false;
    },
    startsAt() {
      return _faker.default.date.past();
    },
    isEndsAtEnabled() {
      if (this.active) {
        return true;
      }
      return false;
    },
    endsAt() {
      return _faker.default.date.future();
    },
    configurations() {
      return [{
        key: 'campaign_postfix',
        value: 'postfix'
      }, {
        key: 'disable_after_booking',
        value: true
      }];
    },
    availableLanguages: ['de', 'en', 'it'],
    publishedLanguages: ['de', 'en'],
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'Campaign Summary Text (from API) ...',
      details: [{
        title: 'Campaign Objective',
        description: 'Campaign objective description...',
        icon: 'info'
      }, {
        title: 'Primary Goal',
        description: 'Goal description...',
        icon: 'compass'
      }, {
        title: 'Technical Requirements',
        description: 'Technical requirements description...',
        icon: 'settings'
      }]
    },
    isNewStructure: false,
    afterCreate(campaign, server) {
      let voucher = null;
      if (campaign.type === 'trigger-campaign') {
        campaign.update({
          isNewStructure: true
        });
      }
      voucher = server.create('email-campaign-voucher');
      campaign.isNewStructure ? campaign.update({
        contribution: voucher.toJSON()
      }) : campaign.update({
        voucher: voucher.toJSON()
      });
      if (campaign.isNewStructure) {
        const trigger = server.create('trigger');
        campaign.update({
          trigger: trigger.toJSON()
        });
      }
      const emails = server.createList('trigger-email', 3, Object.assign({
        campaign
      }, voucher.active ? {
        ctaType: 'voucher',
        ctaText: null
      } : {}));
      const emailSeries = server.createList('trigger-email-series', 3, {
        campaign
      });
      campaign.update({
        emails,
        emailSeries
      });
    }
  });
});