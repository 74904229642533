define("additive-mc/utils/currency-serialization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.centsToAmount = _exports.amountToCents = void 0;
  /**
   * format an amount to cents
   *
   * @param {Number} amount
   */
  const amountToCents = amount => {
    if (!amount || isNaN(parseFloat(amount))) {
      return amount;
    }
    const floatAmount = typeof amount === 'string' ? parseFloat(amount.replace(',', '.')) : amount;
    return Math.floor(floatAmount * 100);
  };
  /**
   * transform an amount in cents into an float
   *
   * @param {Number} cents
   */
  _exports.amountToCents = amountToCents;
  const centsToAmount = cents => {
    if (!cents || isNaN(parseFloat(cents))) {
      return cents;
    }
    return cents / 100;
  };
  _exports.centsToAmount = centsToAmount;
});