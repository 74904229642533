define("additive-mc/components/amc-campaign/trigger-email-campaign/steps/configuration", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <this.wizard.step
    @id="configuration"
    @title={{t "emailCampaigns.steps.configurations.title"}}
    @state="success" as |step|
  >
    <step.contentBody>
      {{#if this._isLoading}}
        <div class="mt2">
          <UiSkeleton @items={{3}} @itemsPerRow={{1}} @cardHeight={{108}} />
        </div>
      {{else}}
        {{#each this.configurationItems as |configuration|}}
          <div class="mt2">
            <div class="font-md font-medium primary">
              {{configuration.label}}
            </div>
  
            {{#if configuration.description}}
              <div class="primary font-md pt1 pb2">
                {{configuration.description}}
              </div>
            {{/if}}
  
            {{component
              (concat "amc-campaign/email-campaign/configuration-item/" configuration.type)
              item=(hash
                type=configuration.type
                key=configuration.key
                label=configuration.label
                value=(get (array-find configuration.key this.model.configurations "key") "value")
              )
              onChange=(fn this.model.transactionConfigurationChange configuration.key)
            }}
          </div>
        {{/each}}
      {{/if}}
    </step.contentBody>
  </this.wizard.step>
  */
  {
    "id": "VVZ3lczs",
    "block": "[[[8,[30,0,[\"wizard\",\"step\"]],null,[[\"@id\",\"@title\",\"@state\"],[\"configuration\",[28,[37,0],[\"emailCampaigns.steps.configurations.title\"],null],\"success\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"contentBody\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"_isLoading\"]],[[[1,\"      \"],[10,0],[14,0,\"mt2\"],[12],[1,\"\\n        \"],[8,[39,2],null,[[\"@items\",\"@itemsPerRow\",\"@cardHeight\"],[3,1,108]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,0,[\"configurationItems\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"mt2\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"font-md font-medium primary\"],[12],[1,\"\\n            \"],[1,[30,2,[\"label\"]]],[1,\"\\n          \"],[13],[1,\"\\n\\n\"],[41,[30,2,[\"description\"]],[[[1,\"            \"],[10,0],[14,0,\"primary font-md pt1 pb2\"],[12],[1,\"\\n              \"],[1,[30,2,[\"description\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n          \"],[46,[28,[37,6],[\"amc-campaign/email-campaign/configuration-item/\",[30,2,[\"type\"]]],null],null,[[\"item\",\"onChange\"],[[28,[37,7],null,[[\"type\",\"key\",\"label\",\"value\"],[[30,2,[\"type\"]],[30,2,[\"key\"]],[30,2,[\"label\"]],[28,[37,8],[[28,[37,9],[[30,2,[\"key\"]],[30,0,[\"model\",\"configurations\"]],\"key\"],null],\"value\"],null]]]],[28,[37,10],[[30,0,[\"model\",\"transactionConfigurationChange\"]],[30,2,[\"key\"]]],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"step\",\"configuration\"],false,[\"t\",\"if\",\"ui-skeleton\",\"each\",\"-track-array\",\"component\",\"concat\",\"hash\",\"get\",\"array-find\",\"fn\"]]",
    "moduleName": "additive-mc/components/amc-campaign/trigger-email-campaign/steps/configuration.hbs",
    "isStrictMode": false
  });
  let AmcCampaignTriggerEmailCampaignStepsConfiguration = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _class = class AmcCampaignTriggerEmailCampaignStepsConfiguration extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor, this);
      _initializerDefineProperty(this, "uiTransactionManager", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "wizard", _descriptor4, this);
      _initializerDefineProperty(this, "model", _descriptor5, this);
      _initializerDefineProperty(this, "isLoading", _descriptor6, this);
    }
    get _isLoading() {
      return this.isLoading;
    }
    get defaultLanguage() {
      return this.uiAppSettings.languages.defaultLanguage;
    }
    get configurationItems() {
      var _this$model;
      if (((_this$model = this.model) === null || _this$model === void 0 || (_this$model = _this$model.meta) === null || _this$model === void 0 || (_this$model = _this$model.configurations) === null || _this$model === void 0 ? void 0 : _this$model.length) <= 0) {
        return [];
      }
      const key = Object.keys(this.model.meta).sort()[0];
      const configurationMeta = this.model.meta[key].configurations;
      configurationMeta.forEach(configuration => {
        var _this$model2, _this$model3;
        if (!((_this$model2 = this.model) !== null && _this$model2 !== void 0 && _this$model2.configurations) || ((_this$model3 = this.model) === null || _this$model3 === void 0 || (_this$model3 = _this$model3.configurations) === null || _this$model3 === void 0 ? void 0 : _this$model3.length) <= 0) {
          return;
        }
        const item = this.model.configurations.find(config => config.key === configuration.key);
        configuration.value = (item === null || item === void 0 ? void 0 : item.value) || null;
      });
      return configurationMeta;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiTransactionManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "wizard", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignTriggerEmailCampaignStepsConfiguration);
});