define("additive-mc/mirage/factories/past-marketing-action", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    title() {
      return _faker.default.random.arrayElement(['Beitragsimpressionen', 'Virale Impressionen', 'Verursachte Stories', 'Likes', 'Beitrags-Klicks', 'Link-Klicks', 'Shares', 'Kommentare', 'Postings', 'Reservierungen', 'Andere']);
    },
    type() {
      return _faker.default.random.arrayElement(['Zimmer', 'Angebot', 'Restplatz']);
    },
    publishedDate() {
      return _faker.default.date.past(3);
    },
    impressions() {
      return _faker.default.random.number();
    },
    clicks() {
      return _faker.default.random.number();
    },
    conversions() {
      return _faker.default.random.number();
    },
    reservations() {
      return _faker.default.random.number();
    }
  });
});