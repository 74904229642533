define("additive-mc/mirage/routes/campaigns/email", ["exports", "additive-mc/config/environment", "additive-mc/mirage/routes/helpers/response", "ember-cli-mirage", "faker", "additive-mc/mirage/routes/transactions", "additive-mc/mirage/utils/constants"], function (_exports, _environment, _response, _emberCliMirage, _faker, _transactions, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmailRoutes = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const EMAIL_CAMPAIGNS = ['usecase1', 'usecase2a', 'usecase2b', 'usecase3a', 'usecase3b', 'usecase4'];
  const EmailRoutes = self => {
    /* Email campaigns */
    self.get('/email', schema => {
      const campaigns = schema.emailCampaigns.all();
      return {
        emailCampaigns: campaigns.models,
        meta: {
          total: campaigns.models.length
        }
      };
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/guest-lifecycle-mail-campaigns`, schema => {
      const campaigns = schema.emailCampaigns.all();
      return {
        campaigns: campaigns.models,
        meta: {
          total: campaigns.models.length
        }
      };
    });
    self.get('/organisation-campaigns/email/available', () => {
      return EMAIL_CAMPAIGNS;
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/guest-lifecycle-mail-campaigns/campaign-types`, schema => {
      const existingCampaigns = schema.emailCampaigns.all().models;
      const allCampaignTypes = _constants.EMAIL_CAMPAIGN_TYPES.map(campaignType => {
        const existingCampaign = existingCampaigns.find(ct => ct.type === campaignType.type);
        return _objectSpread(_objectSpread({}, campaignType), {}, {
          creatableChecks: existingCampaign ? [{
            key: 'campaign_type_already_created'
          }] : []
        });
      });
      return {
        campaignTypes: allCampaignTypes
      };
    });
    self.post('/organisation-campaigns/email', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      const {
        title,
        campaignCode
      } = body;
      return schema.emailCampaigns.create({
        title,
        campaignCode
      });
    });
    /* Email campaign */
    self.get('/organisation-campaigns/email/:id', (schema, request) => {
      try {
        return emailCampaignOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/guest-lifecycle-mail-campaigns/:id`, (schema, request) => {
      try {
        return emailCampaignOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/email/:id');
    self.put('/organisation-campaigns/email/:id', (schema, request) => {
      /* Randomly fail with 422 to be able to test task. Can be removed after done (https://app.clubhouse.io/additivapps/story/11050/beim-speichern-und-aktivieren-einer-kampagnen-konfiguration-sollte-die-fehlermeldung-der-api-angezeigt-werden) */
      if (_faker.default.random.boolean()) {
        return new _emberCliMirage.Response(422, {}, {
          errors: [{
            custom: 'bla bla'
          }],
          message: 'The given data was invalid'
        });
      }
      try {
        const model = emailCampaignOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return model.update(attrs);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get('/organisation-campaigns/email/:id/meta', (schema, request) => {
      return {
        meta: {
          surveys: [{
            label: 'Deutsche Umfrage 🇩🇪',
            value: '42e5b312-1060-4d18-b873-d4b408b2f37d'
          }],
          ctaTypes: [{
            label: 'Kein Button',
            value: null
          }, {
            label: 'Click-to-action(CTA)',
            value: 'cta'
          }, {
            label: 'Landing Page',
            value: 'landingpage'
          }, {
            label: 'Umfrage',
            value: 'survey'
          }]
        }
      };
    });
    self.get('/guest-lifecycle-mail-campaigns/meta', (schema, request) => {
      return {
        meta: {
          ctaTypes: [{
            value: null,
            label: 'No button'
          }, {
            value: 'voucher',
            label: 'voucher'
          }, {
            value: 'cta',
            label: 'Call-to-action (CTA)'
          }, {
            value: 'landingpage',
            label: 'Landing page'
          }, {
            value: 'referral',
            label: 'Referral campaign'
          }, {
            value: 'survey',
            label: 'Survey'
          }],
          contribution: {
            greeting: {
              syncedValue: 'Synced Voucher greeting text'
            }
          },
          landingPage: {
            availableLanguages: []
          }
        }
      };
    });
    self.put('/organisation-campaigns/email/:id/activate', (schema, request) => {
      try {
        const model = emailCampaignOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/email/:id/deactivate', (schema, request) => {
      try {
        const model = emailCampaignOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    /* Email campaign emails */
    self.get('/organisation-campaigns/email/:id/emails', (schema, request) => {
      try {
        const campaign = emailCampaignOrFail(schema, request);
        return campaign.emails;
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.post('/organisation-campaigns/email/:id/emails', (schema, request) => {
      try {
        const campaign = emailCampaignOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return schema.emails.create(Object.assign(attrs, {
          campaign
        }));
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    /* Email campaign email */
    self.get('/organisation-campaigns/email/:id/emails/:email_id', (schema, request) => {
      try {
        return emailOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/guest-lifecycle-mail-campaigns/:id/emails/:email_id`, (schema, request) => {
      try {
        return emailOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/email/:id/emails/:email_id', (schema, request) => {
      try {
        const model = emailOrFail(schema, request);
        return model.destroy();
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/email/:id/emails/:email_id', (schema, request) => {
      try {
        const model = emailOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return model.update(attrs);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/email/:id/emails/:email_id/:language', (schema, request) => {
      try {
        const {
          language
        } = request.params;
        if (['de', 'en', 'it'].indexOf(language) === -1) {
          throw (0, _response.notFoundResponse)(`'${language}' not supported`);
        }
        const model = emailOrFail(schema, request);
        if (model.availableLanguages.indexOf(language) === -1) {
          return (0, _emberCliMirage.Response)(400, {}, {
            message: `'${language}' does not exist`
          });
        }
        model.availableLanguages.splice(model.availableLanguages.indexOf(language), 1);
        return model;
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/email/:id/emails/:email_id/activate', (schema, request) => {
      try {
        const model = emailOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/email/:id/emails/:email_id/deactivate', (schema, request) => {
      try {
        const model = emailOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    /* Voucher */
    self.get('/organisation-campaigns/email/:id/vouchers/:voucher_id', (schema, request) => {
      try {
        return voucherOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/guest-lifecycle-mail-campaigns/:id/contributions/:voucher_id`, (schema, request) => {
      try {
        return voucherOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/email/:id/vouchers/:voucher_id', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return model.update(Object.assign({
          image: null
        }, attrs));
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/email/:id/vouchers/:voucher_id/:language', (schema, request) => {
      try {
        const {
          language
        } = request.params;
        if (['de', 'en', 'it'].indexOf(language) === -1) {
          throw (0, _response.notFoundResponse)(`'${language}' not supported`);
        }
        const model = voucherOrFail(schema, request);
        if (model.availableLanguages.indexOf(language) === -1) {
          return (0, _emberCliMirage.Response)(400, {}, {
            message: `'${language}' does not exist`
          });
        }
        model.availableLanguages.splice(model.availableLanguages.indexOf(language), 1);
        return model;
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/email/:id/vouchers/:voucher_id/activate', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/email/:id/vouchers/:voucher_id/deactivate', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    /* Email-series */
    self.get('/organisation-campaigns/email/:id/email-series', (schema, request) => {
      try {
        return emailSeriesOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get('/organisation-campaigns/email/:id/landing-page/:landing_page_id', (schema, request) => {
      try {
        return landingPageOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/emails/:id/preview`, schema => {
      return _transactions.HTML_PREVIEW;
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/contributions/:id/preview`, schema => {
      return _transactions.HTML_PREVIEW;
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/pre-stay-campaign/:id/landing-page-preview`, schema => {
      return _transactions.HTML_PREVIEW;
    });
  };
  _exports.EmailRoutes = EmailRoutes;
  const emailCampaignOrFail = (schema, request) => {
    const {
      id
    } = request.params;
    const model = schema.emailCampaigns.find(id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
  const emailOrFail = (schema, request) => {
    const campaign = emailCampaignOrFail(schema, request);
    const {
      email_id
    } = request.params;
    const model = schema.emails.find(email_id);
    // if (!model || model.campaignId !== campaign.id) {
    //   throw new Error('not_found');
    // }

    return model;
  };
  const voucherOrFail = (schema, request) => {
    const {
      voucher_id
    } = request.params;
    const model = schema.emailCampaignVouchers.find(voucher_id);
    model.policies = `<p>${_faker.default.lorem.lines(5)}</p>`;
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
  const landingPageOrFail = (schema, request) => {
    const {
      landing_page_id
    } = request.params;
    const model = schema.emailCampaignLandingpages.find(landing_page_id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
  const emailSeriesOrFail = (schema, request) => {
    const campaign = emailCampaignOrFail(schema, request);
    const {
      id
    } = request.params;
    const model = schema.emailSeries.findBy({
      campaignId: id
    });
    if (!model || model.campaignId !== campaign.id) {
      throw new Error('not_found');
    }
    return model;
  };
});