define("additive-mc/utils/iso-duration", ["exports", "additive-mc/utils/iso-duration/parse", "additive-mc/utils/iso-duration/to-string"], function (_exports, _parse, _toString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.objectMap = void 0;
  Object.defineProperty(_exports, "parse", {
    enumerable: true,
    get: function get() {
      return _parse.default;
    }
  });
  Object.defineProperty(_exports, "toString", {
    enumerable: true,
    get: function get() {
      return _toString.default;
    }
  });
  /* TODO: implement 'weeks' if needed, currently not implemented as it has special syntax*/
  const objectMap = _exports.objectMap = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];
});