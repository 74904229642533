define("additive-mc/utils/helpers", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDefaultFilterDates = void 0;
  /**
   * returns the default date range for the filter
   *  { start: first day of this year, end: today }
   *
   * @function getDefaultFilterDates
   */
  const getDefaultFilterDates = () => {
    const start = (0, _dateFns.format)(new Date(new Date().getFullYear(), 0, 1), 'yyyy-MM-dd');
    const end = (0, _dateFns.format)(new Date(), 'yyyy-MM-dd');
    return {
      start,
      end
    };
  };
  _exports.getDefaultFilterDates = getDefaultFilterDates;
});