define("additive-mc/utils/iso-duration/to-string", ["exports", "additive-mc/utils/iso-duration"], function (_exports, _isoDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const stringMapDate = {
    years: 'Y',
    months: 'M',
    weeks: 'W',
    days: 'D'
  };
  const stringMapTime = {
    hours: 'H',
    minutes: 'M',
    seconds: 'S'
  };

  /**
   * Builds an ios8601-duration string out of the given input.
   *
   *``js
    input: { years: 10, months: 20, days: 30, hours: 9, minutes: 50 }
    result: 'stringMapTime[durationPart]'
   ``
   * @param {Object} input - With a property for each part of the pattern
   * @return {string} - iso8601-duration
   */
  const toString = input => {
    if (!input || typeof input !== 'object' || Object.keys(input).length === 0) {
      throw new Error('[iso-duration] toString error: wrong input type');
    }
    let resultDate = '';
    let resultTime = '';
    _isoDuration.objectMap.forEach(durationPart => {
      const value = input[durationPart];
      if (!value) {
        /* We treat 0 as valid value but do not add it to ISO duration string*/
        if (value !== 0 && (!stringMapDate[durationPart] || !stringMapTime[durationPart])) {
          throw new Error('[iso-duration] toString error: invalid key found');
        }
        return;
      }
      if (stringMapDate[durationPart]) {
        resultDate += `${value}${stringMapDate[durationPart]}`;
      } else if (stringMapTime[durationPart]) {
        resultTime += `${value}${stringMapTime[durationPart]}`;
      }
    });
    if (resultDate !== '' && resultTime !== '') {
      return `P${resultDate}T${resultTime}`;
    } else if (resultDate) {
      return `P${resultDate}`;
    } else if (resultTime) {
      return `PT${resultTime}`;
    }
    return '';
  };
  var _default = _exports.default = toString;
});