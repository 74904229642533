define("additive-mc/mirage-models/voucher", ["exports", "@additive-apps/ui/mirage-models/voucher"], function (_exports, _voucher) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _voucher.default;
    }
  });
});