define("additive-mc/helpers/amc-state-to-color-class", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.amcStateToColorClass = void 0;
  const STATE_COLOR_CLASS_MAPPING = {
    error: 'red',
    warning: 'yellow'
  };

  /**
   * Helper what translates states ('error', 'warning', 'info' etc.) into ui-css-color-classes ('red', 'yellow', etc)
   *
   * @param {String} param0 The state
   * @param {Object} hash
   * @param {String} hash.fallback The fallback css class if state not found
   */
  const amcStateToColorClass = (_ref, hash) => {
    let [state] = _ref;
    if (!state || !STATE_COLOR_CLASS_MAPPING[state]) {
      if (hash.fallback) {
        return hash.fallback;
      }
      return '';
    }
    return STATE_COLOR_CLASS_MAPPING[state];
  };
  _exports.amcStateToColorClass = amcStateToColorClass;
  var _default = _exports.default = (0, _helper.helper)(amcStateToColorClass);
});