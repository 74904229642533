define("additive-mc/serializers/trigger-email-campaign-email-series", ["exports", "additive-mc/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      emails: {
        embedded: 'always'
      }
    }
  });
});