define("additive-mc/components/amc-campaign/referral/create-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-arg-types", "prop-types", "@additive-apps/ui/constants", "@additive-apps/utils/utils/plans"], function (_exports, _component, _templateFactory, _component2, _object, _service, _tracking, _emberArgTypes, _propTypes, _constants, _plans) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal
    @modalClassNames="referral-create-dialog h-auto"
    @title={{this.title}}
    @size="md"
    @onClose={{this.close}} as |m|
  >
    <m.header />
    <m.content as |c|>
      <c.body>
        <UiItemGroup @class="w-100 pv0" as |group|>
          <group.item
            @icon="target"
            @onClick={{action "showNameDialog" "ReferralLeadCampaign"}} as |item|
          >
            <item.intro
              @title={{t "referralCampaigns.create.types.leadCampaign.title"}}
              @description={{t "referralCampaigns.create.types.leadCampaign.description"}}
            />
          </group.item>
          <group.item
            @icon="gift"
            @onClick={{action "showNameDialog" "ReferralGiveAwayCampaign"}} as |item|
          >
            <item.intro
              @title={{t "referralCampaigns.create.types.incentiveCampaign.title"}}
              @description={{t "referralCampaigns.create.types.incentiveCampaign.description"}}
            />
          </group.item>
        </UiItemGroup>
      </c.body>
    </m.content>
  </UiModal>
  
  {{#if this._isSetName}}
    <AmcCampaign::Referral::SetCampaignNameDialog
      @onClose={{action "close"}}
      @filterName={{this.filterName}}
      @title={{this.createDialogTitle}}
      @model={{this.model}}
    />
  {{/if}}
  */
  {
    "id": "hLPPC+mU",
    "block": "[[[8,[39,0],null,[[\"@modalClassNames\",\"@title\",\"@size\",\"@onClose\"],[\"referral-create-dialog h-auto\",[30,0,[\"title\"]],\"md\",[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@class\"],[\"w-100 pv0\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,3,[\"item\"]],null,[[\"@icon\",\"@onClick\"],[\"target\",[28,[37,2],[[30,0],\"showNameDialog\",\"ReferralLeadCampaign\"],null]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,4,[\"intro\"]],null,[[\"@title\",\"@description\"],[[28,[37,3],[\"referralCampaigns.create.types.leadCampaign.title\"],null],[28,[37,3],[\"referralCampaigns.create.types.leadCampaign.description\"],null]]],null],[1,\"\\n        \"]],[4]]]]],[1,\"\\n        \"],[8,[30,3,[\"item\"]],null,[[\"@icon\",\"@onClick\"],[\"gift\",[28,[37,2],[[30,0],\"showNameDialog\",\"ReferralGiveAwayCampaign\"],null]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[30,5,[\"intro\"]],null,[[\"@title\",\"@description\"],[[28,[37,3],[\"referralCampaigns.create.types.incentiveCampaign.title\"],null],[28,[37,3],[\"referralCampaigns.create.types.incentiveCampaign.description\"],null]]],null],[1,\"\\n        \"]],[5]]]]],[1,\"\\n      \"]],[3]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"_isSetName\"]],[[[1,\"  \"],[8,[39,5],null,[[\"@onClose\",\"@filterName\",\"@title\",\"@model\"],[[28,[37,2],[[30,0],\"close\"],null],[30,0,[\"filterName\"]],[30,0,[\"createDialogTitle\"]],[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],null]],[\"m\",\"c\",\"group\",\"item\",\"item\"],false,[\"ui-modal\",\"ui-item-group\",\"action\",\"t\",\"if\",\"amc-campaign/referral/set-campaign-name-dialog\"]]",
    "moduleName": "additive-mc/components/amc-campaign/referral/create-dialog.hbs",
    "isStrictMode": false
  });
  let AmcCampaignReferralCreateDialogComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignReferralCreateDialogComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor4, this);
      /**
       * the name of the filter
       *
       * @argument filterName
       * @type {String}
       */
      _initializerDefineProperty(this, "filterName", _descriptor5, this);
      /**
       * the adapteroptions to save the model
       *
       * @argument adapterOptions
       * @type {Object}
       */
      _initializerDefineProperty(this, "adapterOptions", _descriptor6, this);
      /**
       * the title of the dialog
       *
       * @argument nameProperty
       * @type {String}
       */
      _initializerDefineProperty(this, "title", _descriptor7, this);
      /**
       * whether the set name dialog is open
       *
       * @property _isSetName
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "_isSetName", _descriptor8, this);
      /**
       * the created model
       *
       * @argument model
       * @type {DS.Model}
       */
      _initializerDefineProperty(this, "model", _descriptor9, this);
      /**
       * callback function to close the dialog
       *
       * @argument onClose
       * @type {Function}
       */
      _initializerDefineProperty(this, "onClose", _descriptor10, this);
    }
    get createDialogTitle() {
      var _this$model;
      if (((_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.type) === 'ReferralLeadCampaign') {
        return this.intl.t('referralCampaigns.create.types.leadCampaign.createTitle');
      }
      return this.intl.t('referralCampaigns.create.types.incentiveCampaign.createTitle');
    }
    get hasIncentiveCampaigns() {
      var _this$uiAppSettings, _this$currentUser, _this$currentUser2;
      const currentPlan = (_this$uiAppSettings = this.uiAppSettings) === null || _this$uiAppSettings === void 0 ? void 0 : _this$uiAppSettings.planName;
      if (currentPlan && (0, _plans.is)(currentPlan, _plans.PLAN_PROFESSIONAL)) {
        return false;
      }
      if (!((_this$currentUser = this.currentUser) !== null && _this$currentUser !== void 0 && (_this$currentUser = _this$currentUser.currentOrganization) !== null && _this$currentUser !== void 0 && _this$currentUser.accessibleApps)) {
        return false;
      }
      const voucherApp = (_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 || (_this$currentUser2 = _this$currentUser2.currentOrganization) === null || _this$currentUser2 === void 0 || (_this$currentUser2 = _this$currentUser2.accessibleApps) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.filter(app => app.id === _constants.APP_ID_VOUCHER);
      return !!voucherApp.length;
    }
    close() {
      this.onClose();
    }
    showNameDialog(type) {
      this.model = this.store.createRecord('referral-campaign', {
        group: 'referral-campaigns',
        type: type
      });
      this._isSetName = true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filterName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "adapterOptions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_isSetName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showNameDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showNameDialog"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignReferralCreateDialogComponent);
});