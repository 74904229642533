define("additive-mc/components/ui-info-item", ["exports", "@additive-apps/ui/components/ui-info-item"], function (_exports, _uiInfoItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiInfoItem.default;
    }
  });
});