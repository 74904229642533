define("additive-mc/components/amc-reports/email-metrics", ["exports", "additive-mc/config/environment", "@additive-apps/ui/components/ui-property-watcher", "@ember/object", "ember-concurrency", "@ember/service", "@additive-apps/ui/utils/query-param-util"], function (_exports, _environment, _uiPropertyWatcher, _object, _emberConcurrency, _service, _queryParamUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_TYPE = 'email';

  /**
   * @class amc-reports/email-metrics
   * @module amc-reports
   */
  var _default = _exports.default = _uiPropertyWatcher.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    /**
     * AbortController for the current fetch
     *
     * @property abortController
     * @type {AbortController}
     * @default null
     */
    abortController: null,
    /**
     * the date range object
     *
     * @property _dateRange
     * @type {Object}
     */
    dateRange: null,
    /**
     * the current organization slug
     *
     * @property organizationSlug
     * @type {String}
     * @default null
     */
    organizationSlug: null,
    /**
     * the campaign type
     *
     * @property type
     * @type {String}
     * @default email
     */
    type: DEFAULT_TYPE,
    /**
     * Whether the campaigns table is empty
     *
     * @computed activeTableDataIsEmpty
     * @type {Boolean}
     */
    get isTableEmpty() {
      var _this$campaigns;
      return !((_this$campaigns = this.campaigns) !== null && _this$campaigns !== void 0 && _this$campaigns.length) && !this.emailMetrics.isRunning;
    },
    init() {
      this._super(...arguments);
      (0, _object.setProperties)(this, {
        _tableHeaderOptions: [{
          i18n: 'reports.emailMetrics.columns.campaign',
          minWidth: '80px'
        }, {
          i18n: 'reports.emailMetrics.columns.emails',
          minWidth: '80px'
        }, {
          i18n: 'reports.emailMetrics.columns.uniqueOpenings',
          minWidth: '80px'
        }, {
          i18n: 'reports.emailMetrics.columns.uniqueClicks',
          minWidth: '80px'
        }, {
          i18n: 'reports.emailMetrics.columns.reservations',
          minWidth: '80px'
        }, {
          i18n: 'reports.emailMetrics.columns.conversions',
          minWidth: '80px'
        }, {
          i18n: 'reports.emailMetrics.columns.vouchers',
          minWidth: '80px'
        }],
        // property watcher properties
        _watchedProperties: ['dateRange', 'organizationSlug'],
        _onPropertyChange: () => this.emailMetrics.perform()
      });
    },
    /**
     * fetches sent emails for the emails report
     *
     * @type {Task}
     * @function emailMetrics
     */
    emailMetrics: (0, _emberConcurrency.task)(function* () {
      const tasks = [];
      const baseUrl = _environment.default.APP.apiBaseHost;
      const type = this.type || 'email';
      try {
        const qps = this.dateRange ? (0, _queryParamUtil.getQueryParams)(this.dateRange) : '';
        const chartsUrl = `${baseUrl}/${this.organizationSlug}/reports/${type}/metrics${qps}`;
        tasks.push(this.authenticatedFetch.fetch(chartsUrl, {
          signal: this.abortController && this.abortController.signal
        }));
        tasks.push((0, _emberConcurrency.timeout)(250));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        const {
          metrics,
          campaigns
        } = yield response.json();
        (0, _object.setProperties)(this, {
          metrics,
          campaigns: campaigns.models
        });
      } catch (error) {
        /* When AbortControllers signal gets aborted, this is intended, so do not show a toast */
        if (error && error.name === 'AbortError') {
          return;
        }
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }).restartable().on('init')
  });
});