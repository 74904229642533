define("additive-mc/components/amc-campaign/voucher-preview-iframe", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ember-arg-types", "prop-types", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _emberArgTypes, _propTypes, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="voucher-preview-iframe relative mw-100 flex items-start justify-start">
    <div class={{this._widthWrapperClassName}} style={{this._maxWidthStyle}}>
      <div class="voucher-template-preview-wrapper relative">
        <iframe
          id="voucher-preview-image"
          title="voucher-preview"
          class={{this._wrapperClassNames}}
          src={{this.url}}
          frameborder="0"
          scrolling="no"
          {{did-insert this.onInsert}}
          {{did-update this.onUpdate this.url}}
        ></iframe>
      </div>
    </div>
  </div>
  */
  {
    "id": "PIERQVrN",
    "block": "[[[10,0],[14,0,\"voucher-preview-iframe relative mw-100 flex items-start justify-start\"],[12],[1,\"\\n  \"],[10,0],[15,0,[30,0,[\"_widthWrapperClassName\"]]],[15,5,[30,0,[\"_maxWidthStyle\"]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"voucher-template-preview-wrapper relative\"],[12],[1,\"\\n      \"],[11,\"iframe\"],[24,1,\"voucher-preview-image\"],[24,\"title\",\"voucher-preview\"],[16,0,[30,0,[\"_wrapperClassNames\"]]],[16,\"src\",[30,0,[\"url\"]]],[24,\"frameborder\",\"0\"],[24,\"scrolling\",\"no\"],[4,[38,0],[[30,0,[\"onInsert\"]]],null],[4,[38,1],[[30,0,[\"onUpdate\"]],[30,0,[\"url\"]]],null],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "additive-mc/components/amc-campaign/voucher-preview-iframe.hbs",
    "isStrictMode": false
  });
  let AmcCampaignVoucherPreviewIframeComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.bool), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class AmcCampaignVoucherPreviewIframeComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * Boolean indicating if the iframe is interactive or not
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor, this);
      /**
       * The url of the voucher preview
       *
       * @argument url
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "url", _descriptor2, this);
      /**
       * Boolean indicating if the content of the iframe is loaded or not
       *
       * @property _isLoading
       * @type {Boolean}
       * @default true
       * @private
       */
      _initializerDefineProperty(this, "_isLoading", _descriptor3, this);
    }
    /**
     * Get the specified layout type (portrait/landscape)
     *
     * @computed layoutType
     * @type {String}
     * @private
     */
    get layoutType() {
      // Get layout query param
      let params = new URL(this.url).searchParams;
      let layout = params.get('layout');
      return layout;
    }

    /**
     * The classnames of the iframe wrapper
     *
     * @computed _wrapperClassNames
     * @type {String}
     * @private
     */
    get _wrapperClassNames() {
      return `absolute top-0 left-0 db w-100 h-100 voucher-preview-iframe ${this._isLoading ? 'voucher-preview-iframe__is-loading' : ''} ${this.isReadOnly ? 'voucher-preview-iframe__is-read-only' : ''}`;
    }

    /**
     * The max-width style
     *
     * @computed _maxWidthStyle
     * @type {String}
     * @private
     */
    get _widthWrapperClassName() {
      return `voucher-template-preview-width voucher-template-preview-width__${this.layoutType} relative`;
    }
    onInsert() {
      // adds event listener for iframe onload
      if (document) {
        const iframe = document.getElementById('voucher-preview-image');
        iframe && iframe.addEventListener('load', () => {
          this._isLoading = false;
        });
      }
    }
    onUpdate() {
      this._isLoading = true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignVoucherPreviewIframeComponent);
});