define("additive-mc/components/amc-route-card", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _object, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="amc-route-card flex items-center pa2 br1 shadow-1 cpointer"
    role="button"
    ...attributes
    {{on "click" this._onClick}}
  >
    {{#if this.isEmpty}}
      {{svg-jar "thumb-up" class="white"}}
      <div class="font-sm white ph2">
        {{this.title}}
      </div>
    {{else}}
      {{svg-jar this.svg class="blue"}}
      <div class="flex flex-column flex-grow-1 ph2">
        <div class="font-sm blue">
          {{this.title}}
        </div>
      </div>
      {{svg-jar "arrow-right" class="blue"}}
    {{/if}}
  </div>
  */
  {
    "id": "0jk6+4Di",
    "block": "[[[11,0],[24,0,\"amc-route-card flex items-center pa2 br1 shadow-1 cpointer\"],[24,\"role\",\"button\"],[17,1],[4,[38,0],[\"click\",[30,0,[\"_onClick\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isEmpty\"]],[[[1,\"    \"],[1,[28,[35,2],[\"thumb-up\"],[[\"class\"],[\"white\"]]]],[1,\"\\n    \"],[10,0],[14,0,\"font-sm white ph2\"],[12],[1,\"\\n      \"],[1,[30,0,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],[[30,0,[\"svg\"]]],[[\"class\"],[\"blue\"]]]],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-column flex-grow-1 ph2\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"font-sm blue\"],[12],[1,\"\\n        \"],[1,[30,0,[\"title\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,2],[\"arrow-right\"],[[\"class\"],[\"blue\"]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\"],false,[\"on\",\"if\",\"svg-jar\"]]",
    "moduleName": "additive-mc/components/amc-route-card.hbs",
    "isStrictMode": false
  });
  let AmcRouteCard = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class AmcRouteCard extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "route", _descriptor2, this);
      _initializerDefineProperty(this, "svg", _descriptor3, this);
      _initializerDefineProperty(this, "title", _descriptor4, this);
      _initializerDefineProperty(this, "start", _descriptor5, this);
      _initializerDefineProperty(this, "end", _descriptor6, this);
    }
    _onClick() {
      if (!this.route) {
        return;
      }
      this.router.transitionTo(this.route, {
        queryParams: {
          start: this.start,
          end: this.end
        }
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "route", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "svg", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "start", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "end", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcRouteCard);
});