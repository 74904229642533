define("additive-mc/helpers/amc-campaign-configuration-value", ["exports", "@ember/component/helper", "@ember/service", "@ember/string", "@ember/utils", "date-fns", "date-fns/locale/de", "@additive-apps/utils/utils/currency-util", "additive-mc/utils/iso-duration", "additive-mc/utils/currency-serialization"], function (_exports, _helper, _service, _string, _utils, _dateFns, _de, _currencyUtil, _isoDuration, _currencySerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Helper to control the output of the existing campaign configuration types
   */
  var _default = _exports.default = _helper.default.extend({
    uiAppSettings: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    compute(_ref) {
      let [item, options = {}] = _ref;
      if (!item || (0, _utils.isEmpty)(item.value) || Array.isArray(item.value) && item.value.length === 0) {
        return this.intl.t('components.amc-campaign-configuration-value.empty');
      }

      /* Boolean uses switch to indicate value, so no need for additional value displaying */
      if (item.type === 'boolean') {
        return '';
      }
      try {
        switch (item.type) {
          case 'array':
            return item.value.join(', ');
          case 'money':
            return `${(0, _currencyUtil.getCurrencySymbol)((options === null || options === void 0 ? void 0 : options.currency) || 'EUR')} ${(0, _currencySerialization.centsToAmount)(item.value)}`;
          case 'datetime':
            return (0, _dateFns.format)((0, _dateFns.parseISO)(item.value), 'dd.MM.yyyy HH:mm', {
              locale: _de.default
            });
          case 'yeardate':
            return (0, _dateFns.format)((0, _dateFns.parse)(item.value, 'MM-dd', new Date()), 'dd. MMM', {
              locale: _de.default
            });
          case 'select':
            return item.value && item.value.label;
          case 'multiselect':
            if (Array.isArray(item.value)) {
              if (item.value.length === 1) {
                return item.value[0].label;
              }
              return [item.value[0].label, `, +${item.value.length - 1}`].join(' ');
            }
            break;
          case 'timespan':
            {
              // P1Y5M --> 1 Jahr 5 Monate
              const duration = (0, _isoDuration.parse)(item.value);
              let output = Object.keys(duration).reduce((accumulator, key) => {
                const value = duration[key];
                if (!value || value < 1) {
                  return accumulator;
                }
                const newPart = this.intl.t(`global.dates.${key}`, {
                  value
                });
                return `${accumulator} ${value} ${newPart}`;
              }, '');
              return output;
            }
          case 'multimedia':
            return this.intl.t('components.amc-campaign-configuration-value.multimedia.selected');
          case 'percentage-distribution':
            {
              const result = item.value.reduce((accumulator, _, index, source) => {
                const arrayElement = source[index];
                if (!arrayElement || !arrayElement.value || arrayElement.value < 1) {
                  return accumulator;
                }
                const newPart = `${arrayElement.label}: ${arrayElement.value}`;
                return `${accumulator} ${newPart},`;
              }, '');

              /* Remove trailing `,` */
              return result.slice(0, result.length - 1);
            }
          case 'multilingual-text':
          case 'multilingual-link':
            {
              const languages = this.uiAppSettings && this.uiAppSettings.languages && this.uiAppSettings.languages.contentLanguages || [];
              return (0, _string.htmlSafe)(languages.map(language => {
                const color = item.value && item.value[language] ? 'green' : 'red';
                return `<span class="font-sm pr1 ${color}">${language.toUpperCase()}</span>`;
              }).join(''));
            }
          case 'multilingual-texts':
            {
              return this.intl.t('components.amc-campaign-configuration-value.multilingual-texts.selected', {
                count: item.value && item.value.length
              });
            }
          case 'widget-display-variant':
            {
              return `${this.intl.t(`components.amc-campaign-configuration.widget-display-variant.positionOptions.${item.value.position}`)}, ${this.intl.t(`components.amc-campaign-configuration.widget-display-variant.sizeOptions.${item.value.size}`)}`;
            }
          case 'widget-trigger-event':
            {
              if (!(item.value.trigger === 'on-exit')) {
                return `${this.intl.t(`components.amc-campaign-configuration.widget-trigger-event.options.${item.value.trigger}`)}: ${item.value.value}${this.intl.t(`components.amc-campaign-configuration.widget-trigger-event.unit.${item.value.trigger}`)}`;
              } else {
                return this.intl.t(`components.amc-campaign-configuration.widget-trigger-event.options.${item.value.trigger}`);
              }
            }
          case 'utm-parameter':
            {
              if (item.value.type && item.value.type !== 'none') {
                return `${this.intl.t(`components.amc-campaign-configuration.utm-parameter.options.${item.value.type}`)}: ${item.value.value}`;
              } else {
                return '';
              }
            }
          case 'delivery-time':
            {
              if (item.value) {
                var _item$value, _item$value2, _item$value3;
                const time = (_item$value = item.value) === null || _item$value === void 0 ? void 0 : _item$value.daytime;
                const day = (_item$value2 = item.value) === null || _item$value2 === void 0 ? void 0 : _item$value2.offsetInDays;
                const type = (_item$value3 = item.value) === null || _item$value3 === void 0 ? void 0 : _item$value3.deliveryType;
                if (type === 'immediately') {
                  return this.intl.t('components.amc-campaign-configuration.deliveryTime.options.immediately');
                }
                if (time && day === 0 || time && day === 1) {
                  return this.intl.t(`components.amc-campaign-configuration.deliveryTime.${day === 0 ? 'deliveryToday' : 'deliveryTomorrow'}`, {
                    time: time
                  });
                } else if (time && day >= 2 && day <= 7) {
                  return this.intl.t('components.amc-campaign-configuration.deliveryTime.deliveryDays', {
                    time: time,
                    count: day
                  });
                }
                return '';
              }
              break;
            }
          case 'master-details-multiselect':
            {
              if (Object.keys(item.value).length > 0) {
                const selectedTriggerKey = Object.keys(item.value)[0];

                // Get selected trigger
                const selectedTrigger = item.value[selectedTriggerKey].label;

                // Get selected interests, channel, addressbook etc.
                let selectedChannels = item.value[selectedTriggerKey].values;
                let channels = '';
                if (typeof selectedChannels === 'object' && !Array.isArray(selectedChannels)) {
                  const index = Object.keys(selectedChannels).sort()[0];
                  selectedChannels = selectedChannels[index];
                }
                selectedChannels.forEach((channel, index) => {
                  if (selectedChannels.length - 1 === index) {
                    channels += channel.label;
                  } else {
                    channels += `${channel.label}, `;
                  }
                });
                return `${selectedTrigger}: ${channels}`;
              } else {
                return '';
              }
            }
        }
        return item.value;
      } catch (error) {
        /* Return whatever the value is, when we get a parse/format error */
        return item.value;
      }
    }
  });
});