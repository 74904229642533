define("additive-mc/helpers/amc-html-safe", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.amcHtmlSafe = amcHtmlSafe;
  _exports.default = void 0;
  /**
   * Returns html-safe version of the passed value
   */
  function amcHtmlSafe(_ref) {
    let [value] = _ref;
    return (0, _template.htmlSafe)(value);
  }
  var _default = _exports.default = (0, _helper.helper)(amcHtmlSafe);
});