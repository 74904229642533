define("additive-mc/services/multimedia", ["exports", "@additive-apps/media/services/multimedia"], function (_exports, _multimedia) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _multimedia.default;
    }
  });
});