define("additive-mc/mirage/routes/reports/referral", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ReferralReportsPastMarketingActions = _exports.ReferralReportsKeyIndicators = _exports.ReferralReportsCampaigns = void 0;
  const ReferralReportsKeyIndicators = self => {
    self.get('/reports/referral/key-indicators', () => {
      return {
        impressions: {
          type: 'compare',
          change: parseInt(_faker.default.random.number({
            min: -100,
            max: 100
          })),
          current: {
            value: parseInt(_faker.default.random.number()),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: parseInt(_faker.default.random.number()),
            type: 'yearly',
            date: 2018
          }
        },
        conversions: {
          type: 'compare',
          change: parseInt(_faker.default.random.number({
            min: -100,
            max: 100
          })),
          current: {
            value: parseInt(_faker.default.random.number()),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: parseInt(_faker.default.random.number()),
            type: 'yearly',
            date: 2018
          }
        },
        leads: {
          type: 'compare',
          change: parseInt(_faker.default.random.number({
            min: -100,
            max: 100
          })),
          current: {
            value: parseInt(_faker.default.random.number()),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: parseInt(_faker.default.random.number()),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.ReferralReportsKeyIndicators = ReferralReportsKeyIndicators;
  const ReferralReportsPastMarketingActions = self => {
    self.get('/reports/referral/past-marketing-actions', schema => {
      return schema.referralPastMarketingActions.all();
    });
  };
  _exports.ReferralReportsPastMarketingActions = ReferralReportsPastMarketingActions;
  const ReferralReportsCampaigns = self => {
    self.get('/reports/referral/campaigns', () => {
      const data = [];
      data.push({
        rowData: [_faker.default.random.word(), _faker.default.random.arrayElement(['ReferralLeadCampaign', 'ReferralIncentiveCampaign']), {
          value: parseInt(_faker.default.random.number()),
          format: 'number'
        }, {
          value: parseInt(_faker.default.random.number()),
          format: 'number'
        }, {
          value: parseInt(_faker.default.random.number()),
          format: 'number'
        }, {
          value: parseInt(_faker.default.random.number()),
          format: 'number'
        }]
      });
      return {
        labels: [{
          key: 'campaign',
          value: 'Kampagne'
        }, {
          key: 'campaign-type',
          value: 'Kampagnen Typ'
        }, {
          key: 'impressions',
          value: 'Impressionen'
        }, {
          key: 'conversions',
          value: 'Konversionen'
        }, {
          key: 'leads',
          value: 'Leads'
        }, {
          key: 'voucher',
          value: 'Voucher'
        }],
        data
      };
    });
  };
  _exports.ReferralReportsCampaigns = ReferralReportsCampaigns;
});