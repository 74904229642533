define("additive-mc/utils/widget-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getColorOptions = _exports.StyleValues = _exports.FONT_FALLBACKS = _exports.DEFAULT_STYLES_TYPOGRAPHY = _exports.DEFAULT_STYLES_COLORS = _exports.DEFAULT_STYLES = _exports.DEFAULT_FONTS_CONFIG = void 0;
  const FONT_FALLBACKS = _exports.FONT_FALLBACKS = {
    sansSerif: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
    serif: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif'
  };
  const DEFAULT_STYLES_COLORS = _exports.DEFAULT_STYLES_COLORS = {
    /* Main Colors */
    main: {
      color: '#3D3D3D',
      contrastColor: '#FFFFFF'
    },
    accent: {
      color: '#967E6E',
      contrastColor: '#FFFFFF'
    },
    ambient: {
      color: '#FFFFFF',
      contrastColor: '#3D3D3D'
    }
  };
  const DEFAULT_STYLES_TYPOGRAPHY = _exports.DEFAULT_STYLES_TYPOGRAPHY = {
    h2: {
      fontFamily: 'primary',
      fontWeight: 'bold',
      fontSize: '32px',
      uppercase: false,
      letterSpacing: 0,
      lineHeight: '40px'
    },
    h3: {
      fontFamily: 'primary',
      fontWeight: 'bold',
      fontSize: '24px',
      uppercase: false,
      letterSpacing: 0,
      lineHeight: '28px'
    },
    p: {
      fontFamily: 'secondary',
      fontWeight: 'normal',
      fontSize: '18px',
      uppercase: false,
      letterSpacing: 0,
      lineHeight: '24px'
    },
    label: {
      fontFamily: 'secondary',
      fontWeight: 'normal',
      fontSize: '11px',
      uppercase: false,
      letterSpacing: 0,
      lineHeight: '16px'
    },
    button: {
      fontFamily: 'secondary',
      fontWeight: 'normal',
      fontSize: '14px',
      uppercase: false,
      letterSpacing: 0,
      lineHeight: '20px'
    },
    small: {
      fontFamily: 'secondary',
      fontWeight: 'normal',
      fontSize: '14px',
      uppercase: false,
      letterSpacing: 0,
      lineHeight: '20px'
    }
  };
  const DEFAULT_STYLES = _exports.DEFAULT_STYLES = {
    layout: 'layout1',
    compactness: 'normal',
    backgroundImage: null,
    borderRadius: '2px',
    boxShadowSpreadRadius: '1px',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)'
  };
  const DEFAULT_FONTS_CONFIG = _exports.DEFAULT_FONTS_CONFIG = {
    embedFonts: false,
    names: {
      primary: {
        normal: '',
        light: '',
        bold: ''
      },
      secondary: {
        normal: '',
        light: '',
        bold: ''
      }
    }
  };
  const StyleValues = _exports.StyleValues = {
    globals: DEFAULT_STYLES,
    colors: DEFAULT_STYLES_COLORS,
    typography: DEFAULT_STYLES_TYPOGRAPHY,
    fonts: {
      primary: FONT_FALLBACKS.sansSerif,
      secondary: FONT_FALLBACKS.serif,
      fontWeightBold: 'bold'
    }
  };

  /**
   * Util that transforms given colors into an array which are supported by our select-component
   *
   *
   * The input param should always have the correct format as defined by the api.
   *
   * ```js
   * colors: {
   *  key: {
   *    main: {
   *      color: #000,
   *      contrastColor: #ff
   *    }
   *  }
   * }
   * ```
   * Available colors keys are `main`, `accent` and `ambient`.
   * Each one is again an object which consists of a color and a contrast color.
   *
   * @function getColorOptions
   * @param {Object} colors
   * @return {Array} colorOptions
   */
  const getColorOptions = colors => {
    const colorOptions = Object.keys(colors).map(key => {
      return {
        key,
        value: colors[key]
      };
    });
    return colorOptions;
  };
  _exports.getColorOptions = getColorOptions;
});