define("additive-mc/controllers/instance/dashboard", ["exports", "additive-mc/config/environment", "@ember/controller", "@ember/service", "@ember/object", "ember-concurrency", "date-fns"], function (_exports, _environment, _controller, _service, _object, _emberConcurrency, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    organizationSlug: _object.computed.alias('currentUser.currentOrganization.id'),
    getSelectedDateRange: (0, _object.computed)('dateRange.{end,start}', 'dateRanges', {
      get() {
        if (!this.dateRanges || !this.dateRange) {
          return;
        }
        const selected = this.dateRanges.filter(dateRange => dateRange.start === this.dateRange.start && dateRange.end === this.dateRange.end)[0];
        return selected;
      }
    }),
    salutation: (0, _object.computed)('currentUser.user.fullName', {
      get() {
        var _this$currentUser;
        const time = (0, _dateFns.format)(new Date(), 'HH:mm');
        let salutation;
        let name = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.user) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.fullName) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.split(' ')[0];
        name == undefined ? name = '' : name = `, ${name}`;
        time > '00:00' && time <= '12:00' ? salutation = this.intl.t(`dashboard.salutations.morning`) : time > '12:00' && time < '17:00' ? salutation = this.intl.t(`dashboard.salutations.afternoon`) : salutation = this.intl.t(`dashboard.salutations.evening`);
        return `${salutation}${name}!`;
      }
    }),
    getDateRange: (0, _emberConcurrency.task)(function* () {
      const tasks = [];
      const baseUrl = _environment.default.APP.apiBaseHost;
      const url = `${baseUrl}/${this.organizationSlug}/dashboard/filters`;
      try {
        tasks.push(this.authenticatedFetch.fetch(url));
        tasks.push((0, _emberConcurrency.timeout)(250));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        if (response && response.ok) {
          const {
            dateranges
          } = yield response.json();
          (0, _object.set)(this, 'dateRanges', dateranges);
          (0, _object.set)(this, 'dateRange', {
            start: dateranges[0].start,
            end: dateranges[0].end
          });
        } else {
          const {
            errors
          } = yield response.json();
          throw new Error(`${errors}`);
        }
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }),
    actions: {
      onDateRangeChange(value) {
        const dateRange = this.dateRanges.filter(dateRange => dateRange.value === value)[0];
        (0, _object.set)(this, 'dateRange', {
          start: dateRange.start,
          end: dateRange.end
        });
      }
    }
  });
});