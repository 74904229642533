define("additive-mc/components/ui-selectable", ["exports", "@additive-apps/ui/components/ui-selectable"], function (_exports, _uiSelectable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiSelectable.default;
    }
  });
});