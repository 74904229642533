define("additive-mc/mirage/routes/dashboard/ad-campaigns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DashboardAdCampaigns = void 0;
  const DashboardAdCampaigns = self => {
    /*
     * Zugestellte Email mit % vergl. zum Vorjahr
     *
     * eindeutige Öffnungen mit % vergl. zum Vorjahr
     *
     * eindeutig Klicks mit % vergl. zum Vorjahr
     *
     * Online Konversionen mit % vergl. zum Vorjahr
     */
    self.get('/dashboard/ad-campaigns', () => {
      return {
        count: 0,
        maxCount: 5,
        ads: {
          type: 'compare',
          change: 0,
          current: {
            value: 0,
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: 0,
            type: 'yearly',
            date: 2018
          }
        },
        impressions: {
          type: 'compare',
          change: 0,
          current: {
            value: 0,
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: 0,
            type: 'yearly',
            date: 2018
          }
        },
        clicks: {
          type: 'compare',
          change: 0,
          current: {
            value: 0,
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: 0,
            type: 'yearly',
            date: 2018
          }
        },
        conversions: {
          type: 'compare',
          change: 0,
          current: {
            value: 0,
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: 0,
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.DashboardAdCampaigns = DashboardAdCampaigns;
});