define("additive-mc/controllers/instance/widgets", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "@additive-apps/auth/utils/roles-map"], function (_exports, _controller, _object, _tracking, _service, _rolesMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceWidgetsController = _exports.default = (_class = class InstanceWidgetsController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor2, this);
      _initializerDefineProperty(this, "uiToast", _descriptor3, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor4, this);
      _initializerDefineProperty(this, "currentUser", _descriptor5, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor6, this);
      _initializerDefineProperty(this, "isWidgetDialog", _descriptor7, this);
      _initializerDefineProperty(this, "activeModel", _descriptor8, this);
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get hasManagerAccess() {
      var _this$currentUser$use;
      return (0, _rolesMap.hasAccess)(_rolesMap.ROLE_MANAGER, (_this$currentUser$use = this.currentUser.user) === null || _this$currentUser$use === void 0 ? void 0 : _this$currentUser$use.role);
    }
    onEdit(model) {
      this.activeModel = model;
      this.isWidgetDialog = true;
    }
    onCloseWidgetDialog() {
      this.activeModel = null;
      this.isWidgetDialog = false;
    }
    onDelete(model) {
      if (model.default) {
        this.uiDialog.showError(this.intl.t('widgets.isDefaultError.title'), this.intl.t('widgets.isDefaultError.description'));
        return;
      }
      this.uiDialog.showConfirm(this.intl.t('widgets.deleteDialog.title'), this.intl.t('widgets.deleteDialog.description', {
        htmlSafe: true
      }), () => this.deleteWidget(model), this.intl.t('global.actions.delete'), true, true);
    }
    async onDefault(model) {
      try {
        model.set('default', true);
        await model.save();
        this.uiFilter.reset('widgets');
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        model.rollbackAttributes();
        this.uiToast.showToast({
          title: this.intl.t('toast.widgetInUse'),
          type: 'error'
        });
      }
    }
    async deleteWidget(model) {
      try {
        await model.destroyRecord();
        this.uiFilter.reset('widgets');
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        model.rollbackAttributes();
        this.uiToast.showToast({
          title: this.intl.t('toast.widgetInUse'),
          type: 'error'
        });
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isWidgetDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "activeModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseWidgetDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseWidgetDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDelete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDefault", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDefault"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteWidget", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteWidget"), _class.prototype), _class);
});