define("additive-mc/validations/on-page-campaign", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.onPageContentValidation = void 0;
  const onPageContentValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    return {
      headline: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.onPageContentValidation = onPageContentValidation;
});