define("additive-mc/mirage/routes/preview/email-preview-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmailPreview = void 0;
  const EmailPreview = _exports.EmailPreview = `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN" "http://www.w3.org/TR/REC-html40/loose.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
  style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; margin: 0 auto !important; padding: 0 !important; height: 100% !important; width: 100% !important; font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;">

  <head style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
    <meta charset="utf-8" style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
    <!-- utf-8 works for most cases -->
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"
      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
    <meta http-equiv="X-UA-Compatible" content="IE=edge"
      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
    <!-- Use the latest (edge) version of IE rendering engine -->
    <meta name="x-apple-disable-message-reformatting"
      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
    <!-- Disable auto-scale in iOS 10 Mail entirely -->
    <meta name="format-detection" content="telephone=no"
      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
    <meta name="format-detection" content="date=no" style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
    <meta name="format-detection" content="address=no"
      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
    <meta name="format-detection" content="email=no"
      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
    <title style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;"></title>
    <style style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
      /* What it does: Remove spaces around the email design added by some email clients. */
      /* Beware: It can remove the padding / margin and add a background color to the compose a reply window. */
      html,
      body {
        margin: 0 auto !important;
        padding: 0 !important;
        height: 100% !important;
        width: 100% !important;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      }

      h1 {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        font-size: 32px;
        line-height: 40px;
        color: #575757;
        font-weight: 600;
      }

      h2,
      p,
      .table .table-entry {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        font-size: 18px;
        line-height: 24px;
      }

      h2,
      p {
        color: #575757;
      }

      h2 {
        font-weight: 600;
      }

      .label {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        font-size: 11px;
        line-height: 16px;
        color: #575757;
      }

      .light {
        color: #ababab;
      }

      .semi-light {
        color: #8a8a8a;
      }


      .button a {
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
      }

      /* What it does: Stops email clients resizing small text. */
      * {
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
      }

      /* What it does: Centers email on Android 4.4 */
      div[style*="margin: 16px 0"] {
        margin: 0 !important;
      }

      /* What it does: Stops Outlook from adding extra spacing to tables. */
      table,
      td {
        mso-table-lspace: 0pt !important;
        mso-table-rspace: 0pt !important;
        border-collapse: collapse !important;
      }

      /* What it does: Fixes webkit padding issue. Fix for Yahoo mail table alignment bug. Applies table-layout to the first 2 tables then removes for anything nested deeper. */
      table {
        border-spacing: 0 !important;
        border-collapse: collapse !important;
        table-layout: fixed !important;
        margin: 0 auto !important;
      }

      table table table {
        table-layout: auto;
      }

      /* What it does: Uses a better rendering method when resizing images in IE. */
      img {
        -ms-interpolation-mode: bicubic;
      }

      /* What it does: A work-around for iOS meddling in triggered links. */
      *[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
      }

      /* What it does: A work-around for Gmail meddling in triggered links. */
      .x-gmail-data-detectors,
      .x-gmail-data-detectors *,
      .aBn {
        border-bottom: 0 !important;
        cursor: default !important;
      }

      /* What it does: Prevents Gmail from displaying an download button on large, non-linked images. */
      .a6S {
        display: none !important;
        opacity: 0.01 !important;
      }

      /* If the above doesn't work, add a .g-img class to any image in question. */
      img.g-img+div {
        display: none !important;
      }

      ol,
      ul {
        margin-top: 0;
        margin-bottom: 10px;
      }

      p[style] a {
        color: #9E8D7E;
        text-decoration: underline;
      }

      .button:hover,
      p a:hover {
        opacity: 0.8;
      }

      /* What it does: Removes right gutter in Gmail iOS app: https://github.com/TedGoas/Cerberus/issues/89  */
      /* Create one of these media queries for each additional viewport size you'd like to fix */
      /* Thanks to Eric Lepetit @ericlepetitsf) for help troubleshooting */
      @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {

        /* iPhone 6 and 6+ */
        .wrapper {
          min-width: 375px !important;
        }
      }

      @media only screen and (max-width: 600px) {
        .content {
          width: 100%;
          max-width: 600px;
        }

        .content-cell {
          padding: 0 24px !important;
        }

        .space-md td {
          height: 32px !important;
        }

        .space-xl td {
          height: 48px !important;
        }

        h1 {
          font-size: 24px !important;
          line-height: 28px !important;
        }

        h2 {
          font-size: 16px !important;
          line-height: 22px !important;
        }

        .body p {
          font-size: 16px !important;
          line-height: 22px !important;
        }

        .body img {
          width: 100% !important;
          height: auto !important;
        }

        .action+p {
          padding-top: 32px !important;
        }

        .button {
          padding: 14px 18px !important;
        }
      }
    </style>
    <!--[if gte mso 9]>
    <style>
        @-ms-viewport{
            width: 600px;
            width: 600px !important;
        }
    </style>
    <![endif]-->
    <!--[if gte mso 9]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
  </head>

  <body width="100%"
    style="-ms-text-size-adjust: 100%; margin: 0 auto !important; padding: 0 !important; height: 100% !important; font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; hyphens: auto; font-size: 18px; line-height: 24px; -moz-hyphens: auto; -ms-word-break: break-all; width: 100% !important; -webkit-hyphens: auto; -webkit-text-size-adjust: none; word-break: break-word; mso-line-height-rule: exactly; background-color: #FFFFFF; color: #575757;">
    <!-- work around for Windows Mail -->
    <!--[if gte mso 9]>
    <table width="100%" align="center" bgcolor="#FFFFFF" role="presentation" aria-hidden="true" cellspacing="0" cellpadding="0" border="0" style="background-color:#FFFFFF;">
        <tr>
            <td align="center" valign="top">
    <![endif]-->
    <table class="wrapper" width="100%" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0"
      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0; width: 100%;">
      <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
        <td width="100%" align="center"
          style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
          <table class="content" width="600" align="center" cellpadding="0" cellspacing="0"
            style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0;">
            <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
              <td class="full-width" width="100%" align="center"
                style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; width: 100%; max-width: 600px;">
                <table class="space space-md full-width" align="left" border="0" cellpadding="0" cellspacing="0"
                  style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0; width: 100%; max-width: 600px;">
                  <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                    <td height="40" bgcolor=""
                      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; height: 40px; font-size: 1px; line-height: 1px;">
                       </td>
                  </tr>
                </table>
                <table class="header full-width" width="600" align="left" cellpadding="0" cellspacing="0"
                  style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; width: 100%; max-width: 600px;">
                  <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                    <td height="8"
                      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; height: 8px;">
                    </td>
                  </tr>
                  <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                    <td class="content-cell" align="left"
                      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; width: 100%; padding: 0 32px; max-width: 600px; box-sizing: border-box;">
                      <img
                        src="https://additive-account.herokuapp.com/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LnMzLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tL29yZ2FuaXphdGlvbnMvNjgyMi9sYXlvdXQtbG9nby0yMjB4MTEwP3RzPTE1NzQ3ODU5NDY=&amp;ts=1574785946"
                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; -ms-interpolation-mode: bicubic; max-width: 220px; max-height: 88px;">
                    </td>
                  </tr>
                  <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                    <td height="8"
                      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; height: 8px;">
                    </td>
                  </tr>
                </table>
                <table class="space space-xl full-width" align="left" border="0" cellpadding="0" cellspacing="0"
                  style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0; width: 100%; max-width: 600px;">
                  <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                    <td height="56" bgcolor=""
                      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; height: 56px; font-size: 1px; line-height: 1px;">
                       </td>
                  </tr>
                </table>
                <!-- Email Body -->
                <table class="full-width" width="100%" align="center" cellpadding="0" cellspacing="0"
                  style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; width: 100%; max-width: 600px;">
                  <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                    <td class="body full-width" width="600" align="center"
                      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; width: 100%; max-width: 600px; margin: 0; padding: 0;">
                      <table class="inner-body full-width" width="600" align="center" cellpadding="0" cellspacing="0"
                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; width: 100%; max-width: 600px;">
                        <!-- Body content -->
                        <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                          <td align="center"
                            style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                            <table width="100%" align="center" cellpadding="0" cellspacing="0"
                              style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important;">
                              <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                <td class="content-cell"
                                  style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; width: 100%; padding: 0 32px; max-width: 600px; box-sizing: border-box;">
                                  <h1
                                    style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; font-size: 32px; line-height: 40px; color: #575757; font-weight: 600; margin: 0;">
                                    Beispieltext</h1>
                                  <table class="space space-md full-width" align="left" border="0" cellpadding="0"
                                    cellspacing="0"
                                    style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0; width: 100%; max-width: 600px;">
                                    <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                      <td height="40" bgcolor=""
                                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; height: 40px; font-size: 1px; line-height: 1px;">
                                         </td>
                                    </tr>
                                  </table>
                                  <p
                                    style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; font-size: 18px; line-height: 24px; color: #575757; margin: 0 !important; padding: 0 !important;">
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                                    euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.<br
                                      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;"> Duis autem
                                    vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel
                                    illum dolore eu feugiat nulla facilisis at vero et accumsan et iusto.</p>
                                  <table class="space space-md full-width" align="left" border="0" cellpadding="0"
                                    cellspacing="0"
                                    style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0; width: 100%; max-width: 600px;">
                                    <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                      <td height="40" bgcolor=""
                                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; height: 40px; font-size: 1px; line-height: 1px;">
                                         </td>
                                    </tr>
                                  </table>
                                  <table class="action" width="100%" cellpadding="0" cellspacing="0"
                                    style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0; width: 100%;">
                                    <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                      <td align="left"
                                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                                        <table align="left" border="0" cellpadding="0" cellspacing="0"
                                          style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important;">
                                          <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                            <td
                                              style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                                              <a class="button" href="http://www.testhotel-post.com" target="_blank"
                                                style="-ms-text-size-adjust: 100%; display: inline-block; padding: 18px 22px; text-decoration: none; -webkit-text-size-adjust: none; border-radius: 2px; box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2); box-sizing: border-box; white-space: nowrap; transition: 0.3s; -webkit-transition: 0.3s; font-size: 14px; line-height: 20px; color: #ffffff; background: #967E6E;">
                                                <!--[if mso]>&nbsp;&nbsp;<![endif]-->Zur Internetseite
                                                <!--[if mso]>&nbsp;&nbsp;<![endif]-->
                                              </a>
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      <table class="space space-xl full-width" align="left" border="0" cellpadding="0" cellspacing="0"
                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0; width: 100%; max-width: 600px;">
                        <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                          <td height="56" bgcolor=""
                            style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; height: 56px; font-size: 1px; line-height: 1px;">
                             </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <table class="footer full-width" width="600" align="left" cellpadding="0" cellspacing="0"
                  style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; width: 100%; max-width: 600px;">
                  <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                    <td class="content-cell" align="left"
                      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; width: 100%; padding: 0 32px; max-width: 600px; box-sizing: border-box;">
                      <table class="space space-line full-width" align="left" border="0" cellpadding="0" cellspacing="0"
                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0; width: 100%; max-width: 600px;">
                        <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                          <td height="1" bgcolor="#efefef"
                            style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; height: 1px; font-size: 1px; line-height: 1px;">
                             </td>
                        </tr>
                      </table>
                      <table class="space space-md full-width" align="left" border="0" cellpadding="0" cellspacing="0"
                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0; width: 100%; max-width: 600px;">
                        <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                          <td height="40" bgcolor=""
                            style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; height: 40px; font-size: 1px; line-height: 1px;">
                             </td>
                        </tr>
                      </table>
                      <table class="organization-data" width="100%" align="left" border="0" cellpadding="0"
                        cellspacing="0"
                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important;">
                        <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                          <td
                            style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                            <table align="left" border="0" cellpadding="0" cellspacing="0"
                              style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important;">
                              <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                <td
                                  style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                                  <table class="label" border="0" cellpadding="0" cellspacing="0"
                                    style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; font-size: 11px; line-height: 16px; color: #575757;">
                                    <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                      <td
                                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                                        Testhotel Post</td>
                                    </tr>
                                    <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                      <td
                                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                                        0473 538800</td>
                                    </tr>
                                    <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                      <td
                                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                                        dev@additive-apps.eu</td>
                                    </tr>
                                  </table>
                                </td>
                                <td width="56"
                                  style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; width: 56px;">
                                </td>
                                <td
                                  style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                                  <table class="label light" border="0" cellpadding="0" cellspacing="0"
                                    style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; font-size: 11px; line-height: 16px; color: #ababab;">
                                    <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                      <td
                                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                                        Industriezone 1/5</td>
                                    </tr>
                                    <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                      <td
                                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                                        39011 Lana</td>
                                    </tr>
                                    <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                                      <td
                                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                                        Südtirol / IT</td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                      <table class="space space-sm full-width" align="left" border="0" cellpadding="0" cellspacing="0"
                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0; width: 100%; max-width: 600px;">
                        <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                          <td height="24" bgcolor=""
                            style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; height: 24px; font-size: 1px; line-height: 1px;">
                             </td>
                        </tr>
                      </table>
                      <table class="conclusion full-width" width="600" align="left" border="0" cellpadding="0"
                        cellspacing="0"
                        style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; width: 100%; max-width: 600px;">
                        <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                          <td
                            style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important;">
                            <div class="label light"
                              style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; font-size: 11px; line-height: 16px; color: #ababab;">
                              powered by</div>
                            <img src="https://additive-account.herokuapp.com/assets/images/additive-apps-logo.png"
                              height="15"
                              style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; max-width: 100%; -ms-interpolation-mode: bicubic; width: auto; height: 15px;">
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <table class="space space-md full-width" align="left" border="0" cellpadding="0" cellspacing="0"
                  style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-spacing: 0 !important; border-collapse: collapse !important; table-layout: fixed !important; margin: 0 auto !important; padding: 0; width: 100%; max-width: 600px;">
                  <tr style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                    <td height="40" bgcolor=""
                      style="-ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; mso-table-lspace: 0pt !important; mso-table-rspace: 0pt !important; border-collapse: collapse !important; height: 40px; font-size: 1px; line-height: 1px;">
                       </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <!--[if gte mso 9]>
            </td>
        </tr>
    </table>
    <![endif]-->
  </body>
</html>`;
});