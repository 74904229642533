define("additive-mc/mirage/factories/search-ad", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    type: 'GoogleSearchAd',
    name: i => `Anzeige ${i}`,
    headlines() {
      return [{
        id: 1,
        text: _faker.default.lorem.sentence(3),
        position: 1
      }, {
        id: 2,
        text: _faker.default.lorem.sentence(3),
        position: 2
      }, {
        id: 3,
        text: _faker.default.lorem.sentence(3),
        position: 3
      }];
    },
    descriptions() {
      return [{
        id: 1,
        text: _faker.default.lorem.sentence(10),
        position: 1
      }, {
        id: 2,
        text: _faker.default.lorem.sentence(10),
        position: 2
      }, {
        id: 3,
        text: _faker.default.lorem.sentence(10),
        position: null
      }];
    },
    language: () => _faker.default.random.arrayElement(['de', 'en', 'it']),
    state: _faker.default.random.arrayElement(['enabled', 'paused']),
    url: _faker.default.internet.url()
  });
});