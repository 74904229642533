define("additive-mc/controllers/instance/reports/trigger-email/index", ["exports", "@ember/controller", "@ember/service", "@ember/object/computed", "@ember/object", "@glimmer/tracking", "additive-mc/utils/constants"], function (_exports, _controller, _service, _computed, _object, _tracking, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceReportsTriggerEmailController = _exports.default = (_dec = (0, _computed.alias)('currentUser.currentOrganization.id'), _class = class InstanceReportsTriggerEmailController extends _controller.default {
    constructor() {
      var _this$_activeFilters, _this$_activeFilters2, _this$_activeFilters7, _this$_activeFilters8, _this$_activeFilters9, _this$_activeFilters10, _this$_activeFilters11;
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _defineProperty(this, "queryParams", [{
        queryStart: 'start',
        queryEnd: 'end'
      }, 'dateFilter']);
      _initializerDefineProperty(this, "organizationSlug", _descriptor3, this);
      /**
       * AbortController for the current fetch
       *
       * @property abortController
       * @type {AbortController}
       * @default null
       */
      _initializerDefineProperty(this, "abortController", _descriptor4, this);
      _initializerDefineProperty(this, "start", _descriptor5, this);
      _initializerDefineProperty(this, "end", _descriptor6, this);
      _initializerDefineProperty(this, "queryStart", _descriptor7, this);
      _initializerDefineProperty(this, "queryEnd", _descriptor8, this);
      _initializerDefineProperty(this, "dateFilter", _descriptor9, this);
      _initializerDefineProperty(this, "_defaultFilter", _descriptor10, this);
      _initializerDefineProperty(this, "_activeFilters", _descriptor11, this);
      _initializerDefineProperty(this, "initialRange", _descriptor12, this);
      this.abortController = new AbortController();
      if (!localStorage.getItem(_constants.LOCAL_STORAGE_FILTER_KEY)) localStorage.setItem(_constants.LOCAL_STORAGE_FILTER_KEY, JSON.stringify({}));
      this._activeFilters = JSON.parse(localStorage.getItem(_constants.LOCAL_STORAGE_FILTER_KEY));

      // get date filter from query params
      const urlParams = new URLSearchParams(window.location.search);
      const dateFilter = urlParams.get('dateFilter');
      const start = urlParams.get('start');
      const end = urlParams.get('end');
      if ((_this$_activeFilters = this._activeFilters) !== null && _this$_activeFilters !== void 0 && _this$_activeFilters.start || (_this$_activeFilters2 = this._activeFilters) !== null && _this$_activeFilters2 !== void 0 && _this$_activeFilters2.end || start && end) {
        var _this$_activeFilters3, _this$_activeFilters4, _this$_activeFilters5, _this$_activeFilters6;
        const _dateRange = {
          start: start || this._activeFilters.start,
          end: end || this._activeFilters.end
        };
        if (start && end) this._activeFilters.dateFilter = 'custom_date';
        if ((_this$_activeFilters3 = this._activeFilters) !== null && _this$_activeFilters3 !== void 0 && _this$_activeFilters3.dateFilter && typeof ((_this$_activeFilters4 = this._activeFilters) === null || _this$_activeFilters4 === void 0 ? void 0 : _this$_activeFilters4.dateFilter) === 'object') this._activeFilters.dateFilter = (_this$_activeFilters5 = this._activeFilters) === null || _this$_activeFilters5 === void 0 ? void 0 : _this$_activeFilters5.dateFilter.value;
        localStorage.setItem(_constants.LOCAL_STORAGE_FILTER_KEY, JSON.stringify({
          start: _dateRange.start,
          end: _dateRange.end,
          dateFilter: (_this$_activeFilters6 = this._activeFilters) === null || _this$_activeFilters6 === void 0 ? void 0 : _this$_activeFilters6.dateFilter
        }));
        this.initialRange = _objectSpread({}, _dateRange);
        this.start = _dateRange.start;
        this.end = _dateRange.end;
      }
      if ((_this$_activeFilters7 = this._activeFilters) !== null && _this$_activeFilters7 !== void 0 && _this$_activeFilters7.dateFilter && typeof ((_this$_activeFilters8 = this._activeFilters) === null || _this$_activeFilters8 === void 0 ? void 0 : _this$_activeFilters8.dateFilter) === 'object') this._activeFilters.dateFilter = (_this$_activeFilters9 = this._activeFilters) === null || _this$_activeFilters9 === void 0 ? void 0 : _this$_activeFilters9.dateFilter.value;
      dateFilter && !start && !end ? this._defaultFilter = dateFilter : (_this$_activeFilters10 = this._activeFilters) !== null && _this$_activeFilters10 !== void 0 && _this$_activeFilters10.dateFilter ? this._defaultFilter = (_this$_activeFilters11 = this._activeFilters) === null || _this$_activeFilters11 === void 0 ? void 0 : _this$_activeFilters11.dateFilter : this._defaultFilter = 'last_month';
      this._activeFilters = JSON.parse(localStorage.getItem(_constants.LOCAL_STORAGE_FILTER_KEY));
    }
    onFilterChange(newRange) {
      this.abortController && this.abortController.abort();
      this.abortController = new AbortController();

      // Update query params
      this.start = newRange.start;
      this.end = newRange.end;
      this._defaultFilter = newRange.dateFilter;
      if (typeof this._defaultFilter === 'object') this._defaultFilter = this._defaultFilter.value;
      localStorage.setItem(_constants.LOCAL_STORAGE_FILTER_KEY, JSON.stringify({
        start: this.start,
        end: this.end,
        dateFilter: this._defaultFilter
      }));
      if (this._defaultFilter === 'custom_date') {
        this.queryStart = this.start;
        this.queryEnd = this.end;
        this.dateFilter = null;
      } else {
        this.queryStart = null;
        this.queryEnd = null;
        this.dateFilter = this._defaultFilter;
      }
      this._activeFilters = JSON.parse(localStorage.getItem(_constants.LOCAL_STORAGE_FILTER_KEY));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "organizationSlug", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "abortController", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "start", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "end", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "queryStart", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "queryEnd", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "dateFilter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_defaultFilter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_activeFilters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "initialRange", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onFilterChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onFilterChange"), _class.prototype), _class);
});