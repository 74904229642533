define("additive-mc/mirage/config", ["exports", "additive-mc/config/environment", "additive-mc/mirage/routes/campaigns", "additive-mc/mirage/routes/dashboard", "additive-mc/mirage/routes/reports", "additive-mc/mirage/routes/reports/onPage", "additive-mc/mirage/routes/preview", "additive-mc/mirage/routes/settings", "additive-mc/mirage/routes/services", "additive-mc/mirage/routes/transactions", "additive-mc/mirage/routes/widgets/widgets", "additive-mc/mirage/routes/widgets/corporate-design", "additive-mc/mirage/routes/campaigns/on-page", "additive-mc/mirage/routes/campaigns/referral"], function (_exports, _environment, _campaigns, _dashboard, _reports, _onPage, _preview, _settings, _services, _transactions, _widgets, _corporateDesign, _onPage2, _referral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.urlPrefix = _environment.default.APP.apiBaseHost;
    this.namespace = '**';

    // dashboard specific requests
    (0, _dashboard.DashboardCharts)(this);
    (0, _dashboard.DashboardKeyIndicators)(this);
    (0, _dashboard.DashboardOverview)(this);
    (0, _dashboard.DashboardEmailCampaigns)(this);
    (0, _dashboard.DashboardAdCampaigns)(this);
    (0, _dashboard.DashboardSocialCampaigns)(this);

    // campaign specific routes
    (0, _campaigns.AdRoutes)(this);
    (0, _campaigns.CatalogAdRoutes)(this);
    (0, _campaigns.FacebookAdRoutes)(this);
    (0, _campaigns.TikTokLeadAdsRoutes)(this);
    (0, _campaigns.LeadAdRoutes)(this);
    (0, _campaigns.TriggerEmailRoutes)(this);
    (0, _campaigns.EmailRoutes)(this);
    (0, _campaigns.SocialRoutes)(this);
    (0, _onPage2.OnPageRoutes)(this);
    (0, _referral.ReferralRoutes)(this);
    (0, _campaigns.SearchAdRoutes)(this);

    // report specific routes
    (0, _reports.ReportsOverview)(this);
    (0, _reports.ReportsKeyIndicators)(this);
    (0, _reports.ReportsReservations)(this);
    (0, _reports.ReportsConversions)(this);
    (0, _reports.ReportsValueAdded)(this);
    (0, _reports.ReportsReservationTable)(this);
    (0, _reports.ReportsLeadsTable)(this);

    // reports/email
    (0, _reports.EmailReportsKeyIndicators)(this);
    (0, _reports.EmailReportsReservations)(this);
    (0, _reports.EmailReportsConversions)(this);
    (0, _reports.EmailReportsMetrics)(this);
    (0, _reports.EmailReportsPastMarketingActions)(this);
    (0, _reports.EmailReportsReservationTable)(this);

    // reports/ad
    (0, _reports.AdReportsLeadAdMetrics)(this);
    (0, _reports.AdReportsKeyIndicators)(this);
    (0, _reports.AdReportsTiktokLeadAdMetrics)(this);
    (0, _reports.AdReportsReservations)(this);
    (0, _reports.AdReportsConversions)(this);
    (0, _reports.AdReportsMetrics)(this);
    (0, _reports.AdReportsFacebookAdMetrics)(this);
    (0, _reports.AdReportsPastMarketingActions)(this);
    (0, _reports.AdReportsReservationTable)(this);
    (0, _reports.AdReportsOverall)(this);

    //reports/onPage
    (0, _onPage.OnPageReportKeyIndicators)(this);

    // reports/social
    (0, _reports.SocialReportsKeyIndicators)(this);
    (0, _reports.SocialReportsReservations)(this);
    (0, _reports.SocialReportsConversions)(this);
    (0, _reports.SocialReportsMetrics)(this);
    (0, _reports.SocialReportsPastMarketingActions)(this);
    (0, _reports.SocialReportsReservationTable)(this);

    // reports/referral
    (0, _reports.ReferralReportsKeyIndicators)(this);
    (0, _reports.ReferralReportsCampaigns)(this);
    (0, _reports.ReferralReportsPastMarketingActions)(this);

    //reports/leads
    (0, _reports.LeadPerformanceMetrics)(this);
    (0, _reports.LeadPerformanceCampaignsInsights)(this);
    (0, _reports.LeadPerformanceChart)(this);

    // preview specific routes
    (0, _preview.OverviewRequests)(this);
    (0, _preview.EmailPreviewRequests)(this);

    // widgets
    (0, _widgets.WidgetRoutes)(this);
    (0, _corporateDesign.CorporateDesignRoutes)(this);

    // services
    (0, _services.ServiceRoutes)(this);

    // settings
    (0, _settings.SettingRoutes)(this);
    (0, _settings.LegalsRoutes)(this);
    (0, _settings.FacebookRoutes)(this);

    // transactions
    (0, _transactions.TransactionsRoutes)(this);
  }
});