define("additive-mc/controllers/instance/campaigns/ad/catalog-ads/depictions", ["exports", "@ember/controller", "@ember/service", "@glimmer/tracking", "@ember/object/computed", "ember-concurrency", "@ember/object"], function (_exports, _controller, _service, _tracking, _computed, _emberConcurrency, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsAdCatalogAdsDepictionsController = _exports.default = (_dec = (0, _computed.equal)(`data.length`, 0), _dec2 = (0, _computed.alias)('fetchDepictions.isRunning'), _dec3 = (0, _emberConcurrency.task)(function* () {
    try {
      let peekedDepictions = this.store.peekAll('depiction', {
        adapterOptions: {
          campaignId: this.modelId,
          type: 'catalog-ads'
        }
      });
      let fetchDepictionsTask = null;
      if (!peekedDepictions.length) {
        fetchDepictionsTask = this.store.findAll('depiction', {
          adapterOptions: {
            campaignId: this.modelId,
            type: 'catalog-ads'
          }
        });
      }
      const fetchDepictionsMetaTask = this.getDepictionMeta.perform();
      let tasks = [];
      tasks.push(fetchDepictionsTask);
      tasks.push(fetchDepictionsMetaTask);
      tasks.push((0, _emberConcurrency.timeout)(400));
      const [depictions, depictionsMeta] = yield (0, _emberConcurrency.all)(tasks);
      this.depictions = depictions || peekedDepictions;
      this.meta = depictionsMeta;
    } catch (e) {
      this.uiDialog.showError();
    }
  }), _dec4 = (0, _emberConcurrency.task)(function* () {}), _class = class InstanceCampaignsAdCatalogAdsDepictionsController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor4, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor5, this);
      _initializerDefineProperty(this, "uiToast", _descriptor6, this);
      /**
       * the id of the active depiction
       *
       * @property activeDepictionId
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "activeDepictionId", _descriptor7, this);
      /**
       * whether the detail dialog is open
       *
       * @property isDetailDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDetailDialog", _descriptor8, this);
      /**
       * whether an error has occured
       *
       * @property isError
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isError", _descriptor9, this);
      /**
       * the depictions meta
       *
       * @property meta
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "meta", _descriptor10, this);
      /**
       * the id of the catalog ads
       *
       * @property modelId
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "modelId", _descriptor11, this);
      /**
       * fetched depictions
       *
       * @property depictions
       * @type Array
       */
      _initializerDefineProperty(this, "depictions", _descriptor12, this);
      /**
       * whether the table is empty
       *
       * @computed isEmpty
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isEmpty", _descriptor13, this);
      /**
       * Property to know if we are still
       * downloading data for the tables
       *
       * @computed isLoading
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isLoading", _descriptor14, this);
      /**
       * Fetches the depictions from the api
       *
       * @function fetchDepictions
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchDepictions", _descriptor15, this);
      /**
       * Fetches and returns the depictions meta
       * Is replaced in setupController by task of parent controller
       *
       * @function getDepictionMeta
       * @type {Task}
       */
      _initializerDefineProperty(this, "getDepictionMeta", _descriptor16, this);
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }

    /**
     * Formats the arrays to strings to display them
     *
     * @computed formattedDepictions
     * @type {Object[]}
     */
    get formattedDepictions() {
      var _this$depictions;
      return (_this$depictions = this.depictions) === null || _this$depictions === void 0 ? void 0 : _this$depictions.filter(depiction => !depiction.isNew).map(depiction => {
        var _this$meta;
        const formattedDepiction = {
          id: depiction.id,
          text: depiction.text
        };
        let selectedProductTypes = [];
        (_this$meta = this.meta) === null || _this$meta === void 0 || (_this$meta = _this$meta.productTypes) === null || _this$meta === void 0 || _this$meta.forEach(productType => {
          var _depiction$productTyp;
          const selectedProductType = (_depiction$productTyp = depiction.productTypes) === null || _depiction$productTyp === void 0 ? void 0 : _depiction$productTyp.find(selectedProductType => selectedProductType.value === productType.value);
          if (selectedProductType) {
            if (selectedProductType.subTypes) {
              selectedProductType.subTypes.forEach(selectedSubType => {
                const productTypeSubType = productType.subTypes.find(productTypeSubType => productTypeSubType.value === selectedSubType);
                if (productTypeSubType) {
                  selectedProductTypes.push(productTypeSubType.label);
                }
              });
            } else {
              selectedProductTypes.push(productType.label);
            }
          }
        });
        formattedDepiction.productTypes = selectedProductTypes.join(', ');
        return formattedDepiction;
      });
    }
    async onDelete(id) {
      try {
        const depictionModel = this.store.peekRecord('depiction', id, {
          adapterOptions: {
            campaignId: this.modelId,
            type: 'catalog-ads'
          }
        });
        await depictionModel.destroyRecord({
          adapterOptions: {
            campaignId: this.modelId,
            type: 'catalog-ads'
          }
        });
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
    delete(id) {
      this.uiDialog.showConfirm(this.intl.t('components.amc-delete-model.confirm.title'), this.intl.t('components.amc-delete-model.confirm.description', {
        htmlSafe: true
      }), () => this.onDelete(id), this.intl.t('global.actions.delete'), true, true);
    }
    openDetail(id) {
      this.activeDepictionId = id;
      this.isDetailDialog = true;
    }
    closeDetail() {
      this.activeDepictionId = null;
      this.isDetailDialog = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "activeDepictionId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isDetailDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "meta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "modelId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "depictions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isEmpty", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "fetchDepictions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "getDepictionMeta", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openDetail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openDetail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeDetail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeDetail"), _class.prototype), _class);
});