define("additive-mc/components/ui-integrity-check-dialog", ["exports", "additive-mc/config/environment", "@glimmer/component", "@glimmer/tracking", "ember-arg-types", "prop-types", "@ember/service", "@ember/object/computed", "date-fns/format", "@additive-apps/ui/utils/date-util", "date-fns/locale", "@ember/string", "ember-svg-jar/utils/make-svg", "ember-concurrency"], function (_exports, _environment, _component, _tracking, _emberArgTypes, _propTypes, _service, _computed, _format, _dateUtil, _locale, _string, _makeSvg, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AmcUiIntegrityCheckDialog = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _computed.alias)('currentUser.currentOrganization.id'), _dec5 = (0, _emberConcurrency.task)(function* (params) {
    try {
      const qps = params;
      const url = `${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/organisation-campaigns/ad/alerts?${qps}`;
      const response = yield this.authenticatedFetch.fetch(url, {
        method: 'GET'
      });
      const json = yield response.json();
      this.data = json;
    } catch (e) {
      this.isError = true;
      this.uiDialog.showError();
    }
  }), _dec6 = (0, _emberConcurrency.task)(function* (link, method) {
    try {
      const url = link;
      const response = yield this.authenticatedFetch.fetch(url, {
        method: method
      });
      if (!response || !response.ok) {
        throw new Error();
      }
      yield this.fetchIndividualAlerts.perform(this.queryParams);
      this.onFetch();
      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (e) {
      this.uiDialog.showError();
    }
  }), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), _dec8 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcUiIntegrityCheckDialog extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiDialog", _descriptor, this);
      _initializerDefineProperty(this, "uiToast", _descriptor2, this);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "currentUser", _descriptor5, this);
      // function that renders an svg with link
      _defineProperty(this, "renderLink", content => {
        const div = document.createElement('div');
        div.className = 'flex';
        div.style = 'vertical-align: top';
        const anchor = document.createElement('a');
        anchor.href = content.link;
        anchor.target = '_blank';
        anchor.ref = 'noreferrer noopener';
        anchor.className = 'flex blue ml05';
        anchor.innerHTML = (0, _makeSvg.default)('new-window', {
          width: '20px',
          height: '20px'
        },
        // eslint-disable-next-line
        id => require(`ember-svg-jar/inlined/${id}`).default);
        div.innerHTML = `${content.value}${anchor.outerHTML}`;
        return (0, _string.htmlSafe)(div.outerHTML);
      });
      /**
       * whether an error has occured during the fetch
       *
       * @property isError
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isError", _descriptor6, this);
      /**
       * the date filter object
       *
       * @argument filters
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "filters", _descriptor7, this);
      /**
       * the query parameters that get passed to this component
       *
       * @argument queryParams
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "queryParams", _descriptor8, this);
      /**
       * the data that should be displayed in the table
       *
       * @argument data
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "data", _descriptor9, this);
      _initializerDefineProperty(this, "organizationSlug", _descriptor10, this);
      /**
       * fetches the individual alerts based on the recieved query parameters
       *
       * @function fetchIndividualAlerts
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchIndividualAlerts", _descriptor11, this);
      /**
       * triggers the archive request depending on the given link and method
       *
       * @function archiveAlert
       * @type {Task}
       */
      _initializerDefineProperty(this, "archiveAlert", _descriptor12, this);
      _initializerDefineProperty(this, "onClose", _descriptor13, this);
      _initializerDefineProperty(this, "onFetch", _descriptor14, this);
    }
    // sorting options for the table
    get columnOptions() {
      return [{
        sort: 'String',
        classNames: 'ws-nowrap'
      }, {
        sort: 'String',
        classNames: 'ws-nowrap'
      }, {
        sort: 'String',
        classNames: 'ws-nowrap'
      }, {
        sort: false,
        classNames: 'ws-nowrap'
      }, {
        sort: false,
        classNames: 'ws-nowrap'
      }];
    }

    // formatting options for the table
    get formats() {
      return {
        custom: {
          name: this.renderLink,
          campaign: this.renderLink,
          alert: content => {
            const div = document.createElement('div');
            div.className = 'flex';
            div.style = 'vertical-align: top';
            const image = document.createElement('div');
            image.className = `flex mh1 ${content.severity === 'error' ? 'red' : content.severity === 'warning' ? 'yellow' : 'blue'}`;
            image.style = 'vertical-align: top';
            image.innerHTML = (0, _makeSvg.default)(`${content.severity}`, {
              width: '20px',
              height: '20px'
            },
            // eslint-disable-next-line
            id => require(`ember-svg-jar/inlined/${id}`).default);
            div.innerHTML = `${image.outerHTML}${content.value}`;
            return (0, _string.htmlSafe)(div.outerHTML);
          },
          action: content => {
            return {
              link: content.link,
              method: content.method
            };
          },
          time: _ref => {
            let {
              value
            } = _ref;
            const div = document.createElement('div');
            div.className = 'flex';
            div.style = 'vertical-align: top';
            const dateFormat = 'dd.MM.yyyy HH:mm';
            const date = (0, _format.default)((0, _dateUtil.parseDate)(value), dateFormat, {
              locale: _locale.de
            });
            div.innerHTML = `${date}`;
            return (0, _string.htmlSafe)(div.outerHTML);
          }
        }
      };
    }

    /**
     * Whether the alerts table is empty
     *
     * @computed isTableEmpty
     * @type {Boolean}
     */
    get isTableEmpty() {
      var _this$rows;
      return !((_this$rows = this.rows) !== null && _this$rows !== void 0 && _this$rows.length) && !this.data;
    }

    /**
     * the table columns
     *
     * @property columns
     * @type {Array}
     */
    get columns() {
      return this.data.labels.map((name, index) => {
        return Object.assign({
          name,
          key: index
        }, this.columnOptions[index]);
      });
    }

    /**
     * the table rows
     *
     * @property rows
     * @type {Array}
     */
    get rows() {
      return this.data.data;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isError", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "organizationSlug", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "fetchIndividualAlerts", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "archiveAlert", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "onFetch", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
});