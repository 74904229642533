define("additive-mc/components/amc-campaign/ads/advertisements/depiction-variants", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _object, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="amc-campaign-depiction-variant flex">
    {{#each this.variants as |variant index|}}
      <div
        class={{concat
          "variant-item flex items-center cpointer br1 ba mr1 "
          (if
            (equal this.activeVariant index) "bg-blue--10 selected blue" "b--secondary-10 secondary"
          )
        }}
        role="button"
        {{action "selectVariant" index bubbles=false}}
      >
        <div class="font-sm mr1">
          {{t "components.amcCampaign.ads.advertisements.depictionVariants.name" count=variant}}
        </div>
        {{#unless this.hideRemoveAction}}
          {{! template-lint-disable }}
          <div role="button" {{action "onRemoveVariant" index bubbles=false}}>
            {{svg-jar "close" width="16" height="16"}}
          </div>
          {{! template-lint-enable  }}
        {{/unless}}
      </div>
    {{/each}}
  
    {{#unless @isReadOnly}}
      <div
        class="variant-item selected flex items-center pv05 ph1 cpointer br1 bg-blue--10 ba mr1"
        role="button"
        {{action "onAddVariant"}}
      >
        <div class="font-sm blue mr1">
          {{t "components.amcCampaign.ads.advertisements.depictionVariants.addVariant"}}
        </div>
        {{svg-jar "plus" width="16" height="16" class="blue"}}
      </div>
    {{/unless}}
  </div>
  */
  {
    "id": "xaRrLFbv",
    "block": "[[[10,0],[14,0,\"amc-campaign-depiction-variant flex\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"variants\"]]],null]],null],null,[[[1,\"    \"],[11,0],[16,0,[28,[37,2],[\"variant-item flex items-center cpointer br1 ba mr1 \",[52,[28,[37,4],[[30,0,[\"activeVariant\"]],[30,2]],null],\"bg-blue--10 selected blue\",\"b--secondary-10 secondary\"]],null]],[24,\"role\",\"button\"],[4,[38,5],[[30,0],\"selectVariant\",[30,2]],[[\"bubbles\"],[false]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"font-sm mr1\"],[12],[1,\"\\n        \"],[1,[28,[35,6],[\"components.amcCampaign.ads.advertisements.depictionVariants.name\"],[[\"count\"],[[30,1]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"hideRemoveAction\"]]],[[[1,\"        \"],[11,0],[24,\"role\",\"button\"],[4,[38,5],[[30,0],\"onRemoveVariant\",[30,2]],[[\"bubbles\"],[false]]],[12],[1,\"\\n          \"],[1,[28,[35,8],[\"close\"],[[\"width\",\"height\"],[\"16\",\"16\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"\\n\"],[41,[51,[30,3]],[[[1,\"    \"],[11,0],[24,0,\"variant-item selected flex items-center pv05 ph1 cpointer br1 bg-blue--10 ba mr1\"],[24,\"role\",\"button\"],[4,[38,5],[[30,0],\"onAddVariant\"],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"font-sm blue mr1\"],[12],[1,\"\\n        \"],[1,[28,[35,6],[\"components.amcCampaign.ads.advertisements.depictionVariants.addVariant\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[1,[28,[35,8],[\"plus\"],[[\"width\",\"height\",\"class\"],[\"16\",\"16\",\"blue\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"variant\",\"index\",\"@isReadOnly\"],false,[\"each\",\"-track-array\",\"concat\",\"if\",\"equal\",\"action\",\"t\",\"unless\",\"svg-jar\"]]",
    "moduleName": "additive-mc/components/amc-campaign/ads/advertisements/depiction-variants.hbs",
    "isStrictMode": false
  });
  let AmcCampaignAdsAdvertisementsDepictionVariantsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.number), _dec2 = (0, _emberArgTypes.arg)(_propTypes.number), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignAdsAdvertisementsDepictionVariantsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "numberOfVariants", _descriptor, this);
      _initializerDefineProperty(this, "activeVariant", _descriptor2, this);
      _initializerDefineProperty(this, "isReadOnly", _descriptor3, this);
      /**
       * callback function to add a new variant
       *
       * @argument onAddVariant
       * @type {Function}
       */
      _initializerDefineProperty(this, "onAddVariant", _descriptor4, this);
      /**
       * callback function to remove a variant
       *
       * @argument onRemoveVariant
       * @type {Function}
       */
      _initializerDefineProperty(this, "onRemoveVariant", _descriptor5, this);
      /**
       * callback function to select a variant
       *
       * @argument onSelectVariant
       * @type {Function}
       */
      _initializerDefineProperty(this, "onSelectVariant", _descriptor6, this);
    }
    get variants() {
      return Array.from({
        length: this.numberOfVariants
      }, (_, i) => i + 1);
    }
    get hideRemoveAction() {
      return this.numberOfVariants <= 1 || this.isReadOnly;
    }
    selectVariant(variant) {
      if (this.activeVariant === variant) {
        return;
      }
      this.onSelectVariant(variant);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "numberOfVariants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeVariant", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "onAddVariant", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onRemoveVariant", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onSelectVariant", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectVariant", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectVariant"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignAdsAdvertisementsDepictionVariantsComponent);
});