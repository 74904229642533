define("additive-mc/components/amc-campaign/configuration-item/percentage-distribution", ["exports", "@ember/component", "@ember/object", "additive-mc/validations/campaign-configurations", "ember-changeset", "ember-changeset-validations", "ember-concurrency", "@ember/service", "rsvp"], function (_exports, _component, _object, _campaignConfigurations, _emberChangeset, _emberChangesetValidations, _emberConcurrency, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which handles campaigns configuration-item for `percentage-distribution`.
   *
   * `percentage-distribution` is a combination of array elements object where each has a value.
   * The sum of those values must be 100. It can not be lower nor greater than 100.
   * But the value can be 0.
   *
   * The array could look like so:
   *
   *``js
    [
      {
        key: 'key-1',
        label: 'Key 1',
        value: 20
      },
      {
        key: 'key-2',
        label: 'Key 2',
        value: 0
      },
      {
        key: 'key-3',
        label: 'Key 3',
        value: 80
      }
    ]
  ]
   ``
   *
   * @class amc-campaigns/configuration-item/percentage-distribution
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    tagName: '',
    /**
     * The configuration-item
     *
     * @property item
     * @default null
     */
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * The internal changeset of the item
     *
     * @property _changeset
     * @default null
     * @private
     */
    _changeset: null,
    /**
     * The initial value
     *
     * @property _initialValue
     * @private
     */
    _initialValue: null,
    /**
     * Changesets underlying object;
     *
     * @property _resource
     * @default null
     * @private
     */
    _resource: null,
    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {},
    /**
     * Close event
     *
     * @function onClose
     * @return {Promise} Returns a promise
     * @public
     */
    onClose() {},
    init() {
      this._super(...arguments);
      let resource = {
        value: [],
        /* Dummy property to set error on sum */
        sum: 0
      };
      if (this.item && this.item.value && Array.isArray(this.item.value)) {
        (0, _object.set)(resource, 'value', this.item.value.slice(0));
      }
      const _validation = (0, _campaignConfigurations.percentageDistributionValidation)(this.intl);
      const changeset = new _emberChangeset.default(resource, (0, _emberChangesetValidations.default)(_validation), _validation);
      (0, _object.setProperties)(this, {
        _changeset: changeset,
        _resource: resource,
        _initialValue: (this.item.value || []).map(element => Object.assign({}, element))
      });
    },
    /**
     * Build duration string, sets it to parents changeset and trigger save
     *
     * @function _save
     * @type {Task}
     * @param {Changeset} parentChangeset The changeset of the `with-modal` hoc
     * @private
     */
    _save: (0, _emberConcurrency.task)(function* (parentChangeset) {
      const tasks = [];
      try {
        yield this._changeset.validate();
        if (this._changeset.get('isInvalid')) {
          return;
        }

        // Will update the underlying object, which in this case is `this._resource`
        this._changeset.execute();
        parentChangeset.set('value', this._resource.value);
        parentChangeset.execute();
        tasks.push(this.onSave());
        tasks.push((0, _emberConcurrency.timeout)(250));
        yield (0, _emberConcurrency.all)(tasks);
      } catch (error) {
        // reset
        (0, _object.set)(this, 'item.value', this._initialValue);
      }
    }),
    /**
     * Sum values and set to changesets `sum` property
     *
     * @function _setSum
     * @type {Task}
     * @private
     */
    _setSum: (0, _emberConcurrency.task)(function* () {
      try {
        const value = this._changeset.get('value');
        const sum = value.reduce((accumulator, _ref) => {
          let {
            value
          } = _ref;
          return accumulator + parseInt(value) || 0;
        }, 0);
        yield this._changeset.set('sum', sum);
        return (0, _rsvp.resolve)(true);
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    })
  });
});