define("additive-mc/mirage/scenarios/app/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * This is the default scenario for normal app-use (development, staging)
   */
  function _default(server) {
    // campaign lists
    server.createList('social-campaign', 3);
    server.createList('on-page-campaign', 3);
    server.createList('referral-campaign', 3);
    server.createList('search-ad-campaign', 3);
    server.createList('trigger-email-campaign', 3);

    // email-campaigns
    // server.create('email-campaign', { code: 'usecase11' });
    server.createList('email-campaign', Math.floor(Math.random() * 4) + 4);
    server.createList('ad-campaign', Math.floor(Math.random() * 5) + 4);
    server.createList('ad-catalog', Math.floor(Math.random() * 5) + 4);
    server.createList('ad-facebook', Math.floor(Math.random() * 5) + 4);

    // reports data
    server.createList('value-added', 10);
    server.createList('advertisement', 10);
    server.createList('advertisement', 10, 'leadAd');
    server.createList('advertisement', 10, 'catalogAd');
    server.createList('advertisement', 10, 'facebookContentAd');
    server.createList('advertisement', 10, 'tiktokLeadAd');

    // Catalog-ad depictions
    // server.createList('depiction', 10);
    // content-ad depictions
    server.createList('depiction', 10, 'facebookContentAd');
    server.createList('extension', 5);
    server.createList('target-group', 5);
    server.createList('search-ad-template', 5);
    server.createList('search-ad', 5);

    // campaign lists
    server.createList('social-preview', 3);
    server.createList('email-preview', 10);
    server.createList('ad-preview', 5);
    server.createList('subscriber', 100);
    server.createList('reports-social-posting', 4);
    server.createList('reports-email', 4);
    server.createList('reports-ad', 4);

    // past marketing action lists
    server.createList('social-past-marketing-action', 20);
    server.createList('email-past-marketing-action', 20);
    server.createList('ad-past-marketing-action', 20);
    const services = server.createList('service', 10);
    services.forEach(service => {
      var _service$status;
      if (((_service$status = service.status) === null || _service$status === void 0 ? void 0 : _service$status.state) === 'pending') {
        setTimeout(() => {
          const pendingStatus = Object.assign({}, service.status, {
            state: 'success'
          });
          service.update({
            status: pendingStatus
          });
        }, 10000);
      }
    });
    server.createList('widget', 10);
    server.create('setting');
    server.create('legal');
    server.passthrough('https://staging.account.additive-apps.tech/**');
    server.passthrough('https://api.staging.multimedia.additive-apps.tech/**');
    server.passthrough('https://api.staging.marketing-automation.additive-apps.tech/**');

    /**
     * Some role/plan mocked organizations:
     *
     * marsinator-big:
     *  - plan: starter_yearly
     *  - user-role: member
     * stefaner-com:
     *  - plan: starter_yearly
     *  - user-role: admin
     * marsinator2-big:
     *  - plan: professional_yearly
     *  - user-role: manager
     * testhotel-post-com:
     *  - plan: enterprise_yearly
     *  - user-role: admin
     * profi-marsinator-big-2:
     *  - plan: professional_yearly
     *  - user-role: admin
     *  - but not partner
     */
    server.get(`${server.urlPrefix}/marsinator-big`, () => {
      let json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'de',
          planName: 'starter_yearly',
          currency: 'CHF'
        }
      };
      return json;
    });
    server.get(`${server.urlPrefix}/marsinator-big/me`, () => {
      let json = {
        id: 'ec156759-fcdd-41a3-9b7b-201fe6bd2e3e',
        email: 'member@additive.eu',
        fullName: 'Member',
        role: 'member',
        language: 'de',
        isPartner: true,
        isAdditive: true
      };
      return json;
    });
    server.get(`${server.urlPrefix}/stefaner-com`, () => {
      let json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'de',
          planName: 'starter_yearly',
          currency: 'EUR'
        }
      };
      return json;
    });
    server.get(`${server.urlPrefix}/stefaner-com/me`, () => {
      let json = {
        id: 'ec156759-fcdd-41a3-9b7b-201fe6bd2e3e',
        email: 'admin@additive.eu',
        fullName: 'Admin',
        role: 'admin',
        language: 'en',
        isPartner: true,
        isAdditive: true
      };
      return json;
    });
    server.get(`${server.urlPrefix}/marsinator2-big`, () => {
      let json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'de',
          planName: 'professional_yearly',
          currency: 'CHF'
        }
      };
      return json;
    });
    server.get(`${server.urlPrefix}/marsinator2-big/me`, () => {
      let json = {
        id: 'ec156759-fcdd-41a3-9b7b-201fe6bd2e3e',
        email: 'manager@additive.eu',
        fullName: 'Manager',
        role: 'manager',
        language: 'de',
        isPartner: true,
        isAdditive: true
      };
      return json;
    });
    server.get(`${server.urlPrefix}/testhotel-post-com`, () => {
      let json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'en',
          planName: 'enterprise_yearly',
          currency: 'EUR'
        }
      };
      return json;
    });
    server.get(`${server.urlPrefix}/belvedere-hotel-it`, () => {
      let json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'de',
          planName: 'enterprise_yearly',
          currency: 'EUR'
        }
      };
      return json;
    });
    server.get(`${server.urlPrefix}/testhotel-post-com/me`, () => {
      let json = {
        id: 'ec156759-fcdd-41a3-9b7b-201fe6bd2e3e',
        email: 'admin@additive.eu',
        fullName: 'Admin',
        role: 'admin',
        language: 'en',
        isPartner: true,
        isAdditive: true
      };
      return json;
    });
    server.get(`${server.urlPrefix}/profi-marsinator-big-2`, () => {
      let json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'de',
          planName: 'professional_yearly',
          currency: 'CHF'
        }
      };
      return json;
    });
    server.get(`${server.urlPrefix}/profi-marsinator-big-2/me`, () => {
      let json = {
        id: 'ec156759-fcdd-41a3-9b7b-201fe6bd2e3e',
        email: 'admin@additive.eu',
        fullName: 'non-partner-Admin',
        role: 'admin',
        language: 'de',
        isPartner: false,
        isAdditive: false
      };
      return json;
    });
    server.passthrough(`${server.urlPrefix}/${server.namespace}`);
  }
});