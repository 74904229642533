define("additive-mc/controllers/application", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@additive-apps/auth/utils/roles-map", "additive-mc/utils/constants"], function (_exports, _controller, _object, _service, _rolesMap, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // add instance Routes here
  const instanceRoutes = [{
    path: 'instance.dashboard',
    intlKey: 'routes.dashboard.title',
    svg: 'home'
  }, {
    path: 'instance.campaigns',
    intlKey: 'routes.campaigns.title',
    svg: 'advertising'
  }, {
    path: 'instance.reports',
    intlKey: 'routes.reports.title',
    svg: 'arrow-chart'
  }, {
    path: 'instance.preview',
    intlKey: 'routes.preview.title',
    svg: 'pending'
  }, {
    path: 'instance.auditlog',
    intlKey: 'routes.auditlog.title',
    svg: 'audit-log'
  }, {
    path: 'instance.widgets',
    intlKey: 'routes.widgets.title',
    svg: 'style'
  }, {
    path: 'instance.services',
    intlKey: 'routes.services.title',
    svg: 'plug'
  }, {
    path: 'instance.settings',
    intlKey: 'routes.settings.title',
    svg: 'settings'
  }];
  let ApplicationController = _exports.default = (_class = class ApplicationController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor6, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor7, this);
      _initializerDefineProperty(this, "uiState", _descriptor8, this);
      _defineProperty(this, "queryParams", ['onboarding']);
      _defineProperty(this, "onboarding", null);
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get instanceRoutes() {
      var _this$currentUser;
      const userRole = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.user) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.role;
      return instanceRoutes.filter(route => {
        if (route.requiredRole && !(0, _rolesMap.hasAccess)(route.requiredRole, userRole)) {
          return false;
        }
        if (this.isReadOnly && route.path === 'instance.auditlog') {
          return false;
        }
        return true;
      });
    }
    get helpUrl() {
      var _this$uiLocale;
      const locale = ((_this$uiLocale = this.uiLocale) === null || _this$uiLocale === void 0 ? void 0 : _this$uiLocale.locale) || 'de';
      return `https://knowledge.additive.eu/${locale}/knowledge/additive-marketing-automation`;
    }
    get _onboardingOptions() {
      return (0, _constants.onboardingData)(this.intl);
    }
    transitionToOrganizationRoute(organization) {
      var _this$currentUser2;
      const currentOrganization = (_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.currentOrganization;
      if (currentOrganization === organization) {
        return;
      }
      this.currentUser.currentOrganization = organization;
      this.currentUser.user = null;
      this.store.unloadAll('ad-campaign');
      this.store.unloadAll('email-campaign');
      this.store.unloadAll('email-preview');
      this.store.unloadAll('email');
      this.store.unloadAll('social-campaign');
      this.store.unloadAll('subscriber');
      this.store.unloadAll('searchAdTemplate');
      this.store.unloadAll('targetGroup');
      this.store.unloadAll('extension');

      // mm related models
      this.store.unloadAll('folder');
      this.store.unloadAll('medium');
      this.uiAppSettings.reset();
      /* pass id instead of object to ensure model-hook gets triggered */
      this.router.transitionTo('instance', organization.id);
    }
    finishOnboarding() {
      this.onboarding = null;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToOrganizationRoute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToOrganizationRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "finishOnboarding", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "finishOnboarding"), _class.prototype), _class);
});