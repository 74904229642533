define("additive-mc/components/amc-campaign/configuration-item/delivery-time", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "ember-concurrency", "ember-arg-types", "prop-types", "@glimmer/tracking", "@ember/service", "rsvp", "additive-mc/validations/campaign-configurations"], function (_exports, _component, _templateFactory, _object, _component2, _emberConcurrency, _emberArgTypes, _propTypes, _tracking, _service, _rsvp, _campaignConfigurations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AmcCampaign::ConfigurationItem::WithModal
    @onSave={{perform this.save}}
    @onClose={{this.onClose}}
    @item={{this.item}}
    @validation={{this.validation}}
    @beforeSave={{this.beforeSave}}
    @isReadOnly={{this.isReadOnly}} as |modal|
  >
    <modal.content>
      <UiSelect
        @classNames="w-100"
        @label={{t "components.amc-campaign-configuration.deliveryTime.day"}}
        @selected={{get this.selectedDay "label"}}
        @options={{this.selectOptions}}
        @isReadOnly={{this.isReadOnly}}
        @errorMessage={{modal.changeset.error.value.deliveryType.validation.firstObject}}
        @isTouched={{this.isTouched}}
        @onChange={{fn this.onSelectChange modal.changeset}} as |optionComponent option|
      >
        <optionComponent.item @value={{option.value}}>
          {{option.label}}
        </optionComponent.item>
      </UiSelect>
  
      {{#unless this.isTimePickerDisabled}}
        <UiTimepicker
          @allowEmptyValue={{false}}
          @useChangeset={{true}}
          @label={{t "components.amc-campaign-configuration.deliveryTime.time"}}
          @minTime="00:00"
          @maxTime="23:59"
          @initialValue={{this.item.value.daytime}}
          @onChange={{fn this.onTimeChange modal.changeset}}
          @isTouched={{this.isTouched}}
          @isReadOnly={{this.isTimePickerDisabled}}
        />
      {{/unless}}
    </modal.content>
  </AmcCampaign::ConfigurationItem::WithModal>
  */
  {
    "id": "cIJvj1Ve",
    "block": "[[[8,[39,0],null,[[\"@onSave\",\"@onClose\",\"@item\",\"@validation\",\"@beforeSave\",\"@isReadOnly\"],[[28,[37,1],[[30,0,[\"save\"]]],null],[30,0,[\"onClose\"]],[30,0,[\"item\"]],[30,0,[\"validation\"]],[30,0,[\"beforeSave\"]],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@classNames\",\"@label\",\"@selected\",\"@options\",\"@isReadOnly\",\"@errorMessage\",\"@isTouched\",\"@onChange\"],[\"w-100\",[28,[37,3],[\"components.amc-campaign-configuration.deliveryTime.day\"],null],[28,[37,4],[[30,0,[\"selectedDay\"]],\"label\"],null],[30,0,[\"selectOptions\"]],[30,0,[\"isReadOnly\"]],[30,1,[\"changeset\",\"error\",\"value\",\"deliveryType\",\"validation\",\"firstObject\"]],[30,0,[\"isTouched\"]],[28,[37,5],[[30,0,[\"onSelectChange\"]],[30,1,[\"changeset\"]]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"item\"]],null,[[\"@value\"],[[30,3,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"label\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n\\n\"],[41,[51,[30,0,[\"isTimePickerDisabled\"]]],[[[1,\"      \"],[8,[39,7],null,[[\"@allowEmptyValue\",\"@useChangeset\",\"@label\",\"@minTime\",\"@maxTime\",\"@initialValue\",\"@onChange\",\"@isTouched\",\"@isReadOnly\"],[false,true,[28,[37,3],[\"components.amc-campaign-configuration.deliveryTime.time\"],null],\"00:00\",\"23:59\",[30,0,[\"item\",\"value\",\"daytime\"]],[28,[37,5],[[30,0,[\"onTimeChange\"]],[30,1,[\"changeset\"]]],null],[30,0,[\"isTouched\"]],[30,0,[\"isTimePickerDisabled\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"optionComponent\",\"option\"],false,[\"amc-campaign/configuration-item/with-modal\",\"perform\",\"ui-select\",\"t\",\"get\",\"fn\",\"unless\",\"ui-timepicker\"]]",
    "moduleName": "additive-mc/components/amc-campaign/configuration-item/delivery-time.hbs",
    "isStrictMode": false
  });
  /**
   * Component which handles the delivery time of an email campaign.
   *
   * @class amc-campaigns/configuration-item/delivery-time
   * @module amc-campaigns
   */
  let AmcCampaignConfigurationItemWidgetDeliveryTimeComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _dec5 = (0, _object.computed)('item.value.{deliveryType,offsetInDays}', 'selectOptions'), _dec6 = (0, _object.computed)('item.value.deliveryType'), _dec7 = (0, _emberConcurrency.task)(function* () {
    let copy = Object.assign({}, this.item);
    const tasks = [];
    try {
      tasks.push(this.onSave());
      tasks.push((0, _emberConcurrency.timeout)(400));
      yield (0, _emberConcurrency.all)(tasks);
    } catch (error) {
      // reset
      this.item = copy;
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _class = class AmcCampaignConfigurationItemWidgetDeliveryTimeComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiToast", _descriptor2, this);
      /**
       * the configuration-item
       *
       * @argument item
       * @type {Object}
       */
      _initializerDefineProperty(this, "item", _descriptor3, this);
      /**
       * whether its read only
       *
       * @argument isReadOnly
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor4, this);
      /**
       * whether the modal is touched
       *
       * @property isTouched
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isTouched", _descriptor5, this);
      /**
       * the function that is executed when the dialog is closed
       *
       * @argument onSave
       * @type {Function}
       */
      _initializerDefineProperty(this, "onSave", _descriptor6, this);
      /**
       * the function that is executed when the dialog is closed
       *
       * @argument onClose
       * @type {Function}
       */
      _initializerDefineProperty(this, "onClose", _descriptor7, this);
      /**
       * checks if the changeset is valid and saves the model
       *
       * @type {Task}
       * @function save
       * @private
       */
      _initializerDefineProperty(this, "save", _descriptor8, this);
      this.validation = _campaignConfigurations.deliveryTimeValidation;

      // Initialize select options
      let options = [{
        label: this.intl.t('components.amc-campaign-configuration.deliveryTime.options.immediately'),
        value: 'immediately'
      }, {
        label: this.intl.t('components.amc-campaign-configuration.deliveryTime.options.today'),
        value: 'today'
      }, {
        label: this.intl.t('components.amc-campaign-configuration.deliveryTime.options.tomorrow'),
        value: 1
      }];
      for (let day = 2; day <= 7; day++) {
        options.push({
          label: this.intl.t('components.amc-campaign-configuration.deliveryTime.options.days', {
            count: day
          }),
          value: day
        });
      }
      (0, _object.set)(this, 'selectOptions', options);
    }

    /**
     * Compute the selected option of the "day" select
     *
     * @computed selectedDay
     * @type {Object}
     */
    get selectedDay() {
      if (!this.item.value) {
        return null;
      }
      let {
        deliveryType,
        offsetInDays
      } = this.item.value;
      if (offsetInDays === 0) {
        offsetInDays = 'today';
      }
      const selected = this.selectOptions.find(option => option.value === deliveryType || option.value === offsetInDays);
      return selected;
    }

    /**
     * Compute the selected option of the "day" select
     *
     * @computed selectedDay
     * @type {Object}
     */
    get isTimePickerDisabled() {
      var _this$item;
      if (((_this$item = this.item) === null || _this$item === void 0 || (_this$item = _this$item.value) === null || _this$item === void 0 ? void 0 : _this$item.deliveryType) === 'immediately') {
        return true;
      }
      return false;
    }
    /**
     * the function that is executed before save
     *
     * @function beforeSave
     */
    beforeSave(changeset) {
      this.isTouched = true;
      const type = changeset.get('value.deliveryType');
      const time = changeset.get('value.daytime');
      if (type === 'delayed' && !time) {
        (0, _rsvp.resolve)(false);
      }
    }

    /**
     * the function is executed on "select" change
     *
     * @function onSelectChange
     * @param changeset
     * @param value
     */
    onSelectChange(changeset, value) {
      if (value === 'immediately') {
        // Reset "value" if delivery type is immediately
        changeset.set('value', {});
        changeset.execute();

        // Set delivery type "immediately"
        changeset.set('value.deliveryType', 'immediately');
        changeset.execute();
        return;
      }

      // Set delivery type
      changeset.set('value.deliveryType', 'delayed');
      changeset.execute();

      // Set days
      if (value === 'today') {
        changeset.set('value.offsetInDays', 0);
      } else {
        changeset.set('value.offsetInDays', value);
      }
      changeset.execute();
    }

    /**
     * the function is executed on "time" change
     *
     * @function onTimeChange
     * @param changeset
     * @param value
     */
    onTimeChange(changeset, date) {
      if (!(date instanceof Date)) {
        /* Update changeset */
        changeset.set('value.daytime', null);
        changeset.execute();
        return;
      }
      let [hours, minutes] = [date.getHours(), date.getMinutes()];
      /* ensure it uses 2 digits (1 -> 01) */
      hours = ('0' + hours).slice(-2);
      minutes = ('0' + minutes).slice(-2);

      /* Update changeset */
      changeset.set('value.daytime', `${hours}:${minutes}`);
      changeset.execute();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onSave", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectedDay", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "selectedDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isTimePickerDisabled", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isTimePickerDisabled"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "save", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "beforeSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "beforeSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelectChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTimeChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTimeChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignConfigurationItemWidgetDeliveryTimeComponent);
});