define("additive-mc/mirage/routes/reports/leads", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LeadPerformanceMetrics = _exports.LeadPerformanceChart = _exports.LeadPerformanceCampaignsInsights = void 0;
  const LeadPerformanceMetrics = self => {
    self.get('/reports/leads/key-indicators', schema => {
      return {
        metrics: {
          totalLeads: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          reservations: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(1000),
              type: 'yearly',
              date: 2019,
              rate: _faker.default.random.number({
                min: 10,
                max: 100
              })
            },
            compare: {
              value: _faker.default.random.number(1000),
              type: 'yearly',
              date: 2018
            }
          },
          revenue: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019,
              rate: _faker.default.random.number({
                min: 10,
                max: 100
              })
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          valueAdded: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019,
              rate: _faker.default.random.number({
                min: 10,
                max: 100
              })
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          totalValueAdded: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019,
              rate: _faker.default.random.number({
                min: 10,
                max: 100
              })
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          leadsReservationsRatio: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(50),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(50),
              type: 'yearly',
              date: 2018
            }
          }
        }
      };
    });
  };
  _exports.LeadPerformanceMetrics = LeadPerformanceMetrics;
  const LeadPerformanceChart = self => {
    self.get('/reports/leads/chart', (schema, req) => {
      const {
        from,
        to
      } = req.queryParams;
      if (from && to) {
        return {
          datasets: [{
            from: 'ads',
            to: 'mails',
            flow: 1073,
            clickable: false
          }, {
            from: 'onPage',
            to: 'mails',
            flow: 456,
            clickable: false
          }, {
            from: 'referral',
            to: 'mails',
            flow: 105,
            clickable: false
          }, {
            from: 'mails',
            to: 'opens',
            flow: 880,
            clickable: false
          }, {
            from: 'opens',
            to: 'conversions',
            flow: 700,
            clickable: false
          }, {
            from: 'conversions',
            to: 'reservations',
            flow: 100,
            clickable: true
          }, {
            from: 'reservations',
            to: 'direct',
            flow: 33,
            clickable: false
          }, {
            from: 'reservations',
            to: 'additiveNewsletter',
            flow: 33,
            clickable: false
          }, {
            from: 'reservations',
            to: 'additiveMa',
            flow: 33,
            clickable: false
          }],
          clickable: ['ads', 'reservations'],
          labels: {
            ads: 'Ads (1073)',
            onPage: 'On-Page (456)',
            referral: 'Referral (105)',
            mails: 'Mails (1634)',
            opens: 'Opens (880)',
            conversions: 'Conversions (700)',
            reservations: 'Reservations (100)',
            direct: 'Direct (100)',
            additiveNewsletter: 'additive-newsletter (100)',
            additiveMa: 'additive-ma (100)'
          },
          colors: {
            ads: 'red',
            mails: 'yellow',
            onPage: 'black',
            referral: 'blue',
            opens: 'slategray',
            conversions: '#33FF8A',
            reservations: 'green',
            direct: 'red',
            additiveNewsletter: 'yellow',
            additiveMa: 'purple'
          }
        };
      }
      return {
        datasets: [{
          from: 'ads',
          to: 'mails',
          flow: 1073,
          clickable: true
        }, {
          from: 'onPage',
          to: 'mails',
          flow: 456,
          clickable: false
        }, {
          from: 'referral',
          to: 'mails',
          flow: 105,
          clickable: false
        }, {
          from: 'mails',
          to: 'opens',
          flow: 880,
          clickable: false
        }, {
          from: 'opens',
          to: 'conversions',
          flow: 700,
          clickable: false
        }, {
          from: 'conversions',
          to: 'reservations',
          flow: 100,
          clickable: true
        }],
        labels: {
          ads: 'Ads (1073)',
          onPage: 'On-Page (456)',
          referral: 'Referral (105)',
          mails: 'Mails (1634)',
          opens: 'Opens (880)',
          conversions: 'Conversions (700)',
          reservations: 'Reservations (100)'
        },
        colors: {
          ads: 'red',
          mails: 'yellow',
          onPage: 'black',
          referral: 'blue',
          opens: 'slategray',
          conversions: '#33FF8A',
          reservations: 'green'
        }
      };
    });
  };
  _exports.LeadPerformanceChart = LeadPerformanceChart;
  const LeadPerformanceCampaignsInsights = self => {
    self.get('/reports/leads/campaigns-insights', schema => {
      return {
        campaignInsights: {
          labels: [{
            key: 'campaign-type',
            name: 'Campaign Type',
            description: 'Description'
          }, {
            key: 'leads',
            name: 'Leads',
            description: 'Description'
          }, {
            key: 'uniqueMails',
            name: 'Unique Mails',
            description: 'Description'
          }, {
            key: 'uniqueOpens',
            name: 'Unique Opens',
            description: 'Description'
          }, {
            key: 'generatedVouchers',
            name: 'Generated Vouchers',
            description: 'Description'
          }, {
            key: 'conversions',
            name: 'Conversions (R/B)*',
            description: 'Description'
          }, {
            key: 'reservations',
            name: 'Reservations',
            description: 'Description'
          }, {
            key: 'leadsReservationsRatio',
            name: 'Lead : Reservation',
            description: 'Description'
          }, {
            key: 'avgTime',
            name: 'Avg. time from Lead to Reservation',
            description: 'Description'
          }, {
            key: 'revenue',
            name: 'Revenue',
            description: 'Description'
          }, {
            key: 'filter',
            name: 'Filter'
          }],
          data: [{
            rowData: ['PopUps', {
              value: 600,
              format: 'number'
            }, {
              value: 400,
              format: 'number'
            }, {
              value: 240,
              format: 'number'
            }, {
              value: 120,
              format: 'number'
            }, {
              value: '80/7'
            }, {
              value: 300,
              format: 'number'
            }, {
              value: '16:1'
            }, {
              value: 'xyz'
            }, {
              value: 1234,
              format: 'number'
            }, 'campaignGroup=PopUps']
          }, {
            rowData: ['test', {
              value: 600,
              format: 'number'
            }, {
              value: 400,
              format: 'number'
            }, {
              value: 240,
              format: 'number'
            }, {
              value: 120,
              format: 'number'
            }, {
              value: '80/7'
            }, {
              value: 300,
              format: 'number'
            }, {
              value: '16:1'
            }, {
              value: 'xyz'
            }, {
              value: 1234,
              format: 'number'
            }, 'campaignGroup=test'],
            subData: [{
              rowData: ['subdata', {
                value: 600,
                format: 'number'
              }, {
                value: 400,
                format: 'number'
              }, {
                value: 240,
                format: 'number'
              }, {
                value: 120,
                format: 'number'
              }, {
                value: '80/7'
              }, {
                value: 300,
                format: 'number'
              }, {
                value: '16:1'
              }, {
                value: 'xyz'
              }, {
                value: 1234,
                format: 'number'
              }, 'campaignGroup=test&campaignId=1'],
              subData: [{
                rowData: ['sub-sub-data', {
                  value: 600,
                  format: 'number'
                }, {
                  value: 400,
                  format: 'number'
                }, {
                  value: 240,
                  format: 'number'
                }, {
                  value: 120,
                  format: 'number'
                }, {
                  value: '80/7'
                }, {
                  value: 300,
                  format: 'number'
                }, {
                  value: '16:1'
                }, {
                  value: 'xyz'
                }, {
                  value: 1234,
                  format: 'number'
                }, 'campaignGroup=test&campaignId=2']
              }]
            }]
          }]
        }
      };
    });
  };
  _exports.LeadPerformanceCampaignsInsights = LeadPerformanceCampaignsInsights;
});