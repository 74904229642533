define("additive-mc/models/service", ["exports", "@ember-data/model", "@ember/service", "date-fns/format", "date-fns/locale", "ember-svg-jar/utils/make-svg", "@additive-apps/ui/utils/date-util", "@additive-apps/ui/utils/model-action"], function (_exports, _model, _service, _format, _locale, _makeSvg, _dateUtil, _modelAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ServiceModel = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string', {
    defaultValue: 'facebookCatalogService'
  }), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)(), _dec5 = (0, _model.attr)(), _dec6 = (0, _modelAction.default)(), _dec7 = (0, _modelAction.default)(), _class = class ServiceModel extends _model.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "name", _descriptor3, this);
      _initializerDefineProperty(this, "type", _descriptor4, this);
      _initializerDefineProperty(this, "externalServiceURL", _descriptor5, this);
      _initializerDefineProperty(this, "status", _descriptor6, this);
      _initializerDefineProperty(this, "selectedContentTypes", _descriptor7, this);
      _initializerDefineProperty(this, "publish", _descriptor8, this);
      _initializerDefineProperty(this, "unpublish", _descriptor9, this);
    }
    get description() {
      var _this$status;
      const description = this.intl.t(`services.types.${this.type}`);
      const dateFormat = 'dd.MM.yyyy';
      const timeFormat = 'HH:mm';
      let date = '';
      if ((_this$status = this.status) !== null && _this$status !== void 0 && _this$status.lastPublishedAt) {
        const formattedDate = (0, _format.default)((0, _dateUtil.parseDate)(this.status.lastPublishedAt), dateFormat, {
          locale: _locale.de
        });
        const formattedTime = (0, _format.default)((0, _dateUtil.parseDate)(this.status.lastPublishedAt), timeFormat, {
          locale: _locale.de
        });
        date = ` － ${this.intl.t('services.service.date', {
          date: formattedDate,
          time: formattedTime
        })}`;
      }
      let svg = '';
      if (this.type === 'facebookCatalogService') {
        svg = (0, _makeSvg.default)('facebook', {
          class: 'mr05',
          width: '16',
          height: '16'
        },
        // eslint-disable-next-line
        id => require(`ember-svg-jar/inlined/${id}`).default);
      }
      return `<span class="flex items-center">${svg}${description}${date}</span>`;
    }
    get isPublished() {
      var _this$status2;
      return ((_this$status2 = this.status) === null || _this$status2 === void 0 ? void 0 : _this$status2.state) === 'success';
    }
    get isUnpublished() {
      var _this$status3;
      return ((_this$status3 = this.status) === null || _this$status3 === void 0 ? void 0 : _this$status3.state) === 'error';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "externalServiceURL", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedContentTypes", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "publish", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "unpublish", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});