define("additive-mc/components/amc-campaign/search-ad/ads/preview-item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@glimmer/tracking", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _service, _tracking, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="w-100 flex-column pa2 ba b--black-10">
    <div class="flex">
      <div class="font-sm font-medium {{if this.isDisabled "secondary" "primary"}}">
        {{t "searchAdCampaigns.ads.preview.ad"}}
      </div>
      <div class="font-sm font-medium secondary mr1 ml1">
        &middot;
      </div>
      <div class="font-sm font-medium secondary">
        {{this.urlText}}
      </div>
    </div>
    <div class="font-lg font-medium {{if this.isDisabled "secondary" "blue"}} mv1">
      {{this.headlinesText}}
    </div>
    <div class="flex">
      <div class="font-sm font-medium secondary">
        {{this.descriptionsText}}
      </div>
    </div>
  </div>
  */
  {
    "id": "QBCBN/+y",
    "block": "[[[10,0],[14,0,\"w-100 flex-column pa2 ba b--black-10\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"font-sm font-medium \",[52,[30,0,[\"isDisabled\"]],\"secondary\",\"primary\"]]]],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"searchAdCampaigns.ads.preview.ad\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"font-sm font-medium secondary mr1 ml1\"],[12],[1,\"\\n      ·\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"font-sm font-medium secondary\"],[12],[1,\"\\n      \"],[1,[30,0,[\"urlText\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"font-lg font-medium \",[52,[30,0,[\"isDisabled\"]],\"secondary\",\"blue\"],\" mv1\"]]],[12],[1,\"\\n    \"],[1,[30,0,[\"headlinesText\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"font-sm font-medium secondary\"],[12],[1,\"\\n      \"],[1,[30,0,[\"descriptionsText\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"t\"]]",
    "moduleName": "additive-mc/components/amc-campaign/search-ad/ads/preview-item.hbs",
    "isStrictMode": false
  });
  let AmcCampaignSearchAdAdsPreviewItemComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.array), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.array), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class AmcCampaignSearchAdAdsPreviewItemComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      /**
       * the search-ad headlines
       *
       * @argument headlines
       * @type {Array}
       */
      _initializerDefineProperty(this, "headlines", _descriptor2, this);
      /**
       * whether the preview-item is disabled
       *
       * @argument isDisabled
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isDisabled", _descriptor3, this);
      /**
       * the search-ad descriptions
       *
       * @argument descriptions
       * @type {Array}
       */
      _initializerDefineProperty(this, "descriptions", _descriptor4, this);
      /**
       * the search-ad url
       *
       * @argument url
       * @type {String}
       */
      _initializerDefineProperty(this, "url", _descriptor5, this);
    }
    get urlText() {
      if (this.url) {
        return this.url;
      }
      return 'http://www.example.com';
    }
    get headlinesText() {
      if (!this.headlines || this.headlines.length < 3) {
        return this.intl.t('searchAdCampaigns.ads.createDialog.headlines.title');
      }

      // Get headlines sorted by different positions
      const firstHeadlines = this.headlines.filter(headline => headline.position === 1 || !headline.position);
      const secondHeadlines = this.headlines.filter(headline => headline.position === 2 || !headline.position);
      const thirdHeadlines = this.headlines.filter(headline => headline.position === 3 || !headline.position);
      if (firstHeadlines.length === 0 || secondHeadlines.length === 0 || thirdHeadlines.length === 0) {
        return this.intl.t('searchAdCampaigns.ads.createDialog.headlines.title');
      }
      const firstHeadline = firstHeadlines[Math.floor(Math.random() * firstHeadlines.length)].text;
      const secondHeadline = secondHeadlines[Math.floor(Math.random() * secondHeadlines.length)].text;
      const thirdHeadline = thirdHeadlines[Math.floor(Math.random() * thirdHeadlines.length)].text;
      return `${firstHeadline} | ${secondHeadline} | ${thirdHeadline}`;
    }
    get descriptionsText() {
      if (!this.descriptions || this.descriptions.length < 2) {
        return this.intl.t('searchAdCampaigns.ads.createDialog.descriptions.title');
      }

      // Get descriptions sorted by different positions
      const firstDescriptions = this.descriptions.filter(description => description.position === 1 || !description.position);
      const secondDescriptions = this.descriptions.filter(description => description.position === 2 || !description.position);
      if (firstDescriptions.length === 0 || secondDescriptions.length === 0) {
        return this.intl.t('searchAdCampaigns.ads.createDialog.descriptions.title');
      }
      const firstDescription = firstDescriptions[Math.floor(Math.random() * firstDescriptions.length)].text;
      const secondDescription = secondDescriptions[Math.floor(Math.random() * secondDescriptions.length)].text;
      return `${firstDescription} | ${secondDescription}`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "headlines", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "descriptions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignSearchAdAdsPreviewItemComponent);
});