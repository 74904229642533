define("additive-mc/templates/components/amc-campaign/category-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "avcOTAYP",
    "block": "[[[18,3,null],[1,\"\\n\"],[41,[30,0,[\"svg\"]],[[[1,\"  \"],[1,[28,[35,2],[[30,0,[\"svg\"]]],null]],[1,\"\\n\"]],[]],null],[10,0],[14,0,\"font-lg primary\"],[12],[1,\"\\n  \"],[1,[30,1]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"font-sm secondary pt2\"],[12],[1,\"\\n  \"],[1,[30,2]],[1,\"\\n\"],[13]],[\"@title\",\"@description\",\"&default\"],false,[\"yield\",\"if\",\"svg-jar\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/category-card.hbs",
    "isStrictMode": false
  });
});