define("additive-mc/adapters/trigger-email-campaign", ["exports", "additive-mc/config/environment", "additive-mc/adapters/organization-campaign"], function (_exports, _environment, _organizationCampaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _organizationCampaign.default.extend({
    type: 'trigger-email-campaigns',
    urlForMultiple(modelName, snapshot) {
      var _snapshot$adapterOpti;
      let url = this.buildURL() + `${this.type === 'trigger-email' ? '/organisation-campaigns' : ''}/${this.type}`;
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.campaignId) {
        url += `/${snapshot.adapterOptions.campaignId}`;
      }
      return url;
    },
    urlForSingle(id, modelName, snapshot) {
      return `${this.urlForMultiple(modelName, snapshot)}/${id}`;
    },
    urlForFindRecord(id, modelName, snapshot) {
      var _snapshot$adapterOpti2, _snapshot$adapterOpti3;
      this.host = snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti2 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti2 !== void 0 && _snapshot$adapterOpti2.useNewApi ? _environment.default.APP.apiBaseHostV2 : _environment.default.APP.apiBaseHost;
      this.type = snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti3 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti3 !== void 0 && _snapshot$adapterOpti3.useNewApi ? 'trigger-email-campaigns' : 'trigger-email';
      return this.urlForSingle(...arguments);
    },
    urlForFindAll(modelName, snapshot) {
      var _snapshot$adapterOpti4, _snapshot$adapterOpti5;
      this.host = snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti4 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti4 !== void 0 && _snapshot$adapterOpti4.useNewApi ? _environment.default.APP.apiBaseHostV2 : _environment.default.APP.apiBaseHost;
      this.type = snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti5 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti5 !== void 0 && _snapshot$adapterOpti5.useNewApi ? 'trigger-email-campaigns' : 'trigger-email';
      return this.urlForMultiple(...arguments);
    },
    urlForQuery() {
      this.host = _environment.default.APP.apiBaseHostV2;
      this.type = 'trigger-email-campaigns';
      return this.urlForMultiple(...arguments);
    },
    urlForCreateRecord(modelName, snapshot) {
      var _snapshot$adapterOpti6, _snapshot$adapterOpti7;
      this.host = snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti6 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti6 !== void 0 && _snapshot$adapterOpti6.useNewApi ? _environment.default.APP.apiBaseHostV2 : _environment.default.APP.apiBaseHost;
      this.type = snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti7 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti7 !== void 0 && _snapshot$adapterOpti7.useNewApi ? 'trigger-email-campaigns' : 'trigger-email';
      return this.urlForMultiple(...arguments);
    }
  });
});