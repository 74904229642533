define("additive-mc/templates/components/amc-campaign/configuration-item/multimedia", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "aoXCHuu1",
    "block": "[[[8,[39,0],null,[[\"@onSave\",\"@onClose\",\"@childChangeset\",\"@item\",\"@isReadOnly\"],[[28,[37,1],[[30,0,[\"_save\"]]],null],[30,0,[\"onClose\"]],[30,0,[\"_multimediaChangeset\"]],[30,0,[\"item\"]],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"amc-campaign__configuration-item-multimedia-dialog\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@isReadOnly\",\"@resources\",\"@onDropzoneClick\",\"@onResourceRemove\",\"@onResourceEdit\"],[[30,0,[\"isReadOnly\"]],[30,0,[\"_multimediaChangeset\",\"medium\"]],[28,[37,3],[[30,0],\"_openMultimedia\"],null],[28,[37,3],[[30,0],\"_onResourceRemove\"],null],[28,[37,3],[[30,0],[28,[37,4],[[30,0,[\"_multimediaChangeset\"]],\"medium\"],null]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,5],null,[[\"@onClose\",\"@onAddMedium\"],[[28,[37,3],[[30,0],\"_closeMultimedia\"],null],[28,[37,3],[[30,0],\"_onResourceAdd\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[18,2,[[28,[37,7],null,[[\"multimediaOverlayContent\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"&default\"],false,[\"amc-campaign/configuration-item/with-modal\",\"perform\",\"mm-resource-gallery\",\"action\",\"changeset-set\",\"mm-multimedia-overlay\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/multimedia.hbs",
    "isStrictMode": false
  });
});