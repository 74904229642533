define("additive-mc/utils/engine", ["exports", "@additive-apps/multimedia-engine/utils/engine"], function (_exports, _engine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _engine.default;
    }
  });
});