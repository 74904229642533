define("additive-mc/components/amc-campaign/on-page/set-campaign-name-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _object, _service, _tracking, _emberChangeset, _emberChangesetValidations, _validators, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal @title={{this.title}} @onClose={{this.close}} as |m|>
    <m.header />
    <m.content as |c|>
      <c.body {{did-insert this.didInsert}}>
        <UiInput
          @label={{t "adCampaigns.properties.title"}}
          @value={{get this.changeset this.nameProperty}}
          @errorMessage={{get (get this.changeset.error this.nameProperty) "validation.firstObject"}}
          @isTouched={{this.isTouched}}
        />
      </c.body>
    </m.content>
    <m.footer>
      <UiButton @label={{t "global.actions.save"}} @isSecondary={{true}} @onClick={{this.save}} />
    </m.footer>
  </UiModal>
  
  <UiDiscardChanges
    @isOpen={{this.isDiscardChanges}}
    @onDiscard={{this.discardChanges}}
    @onClose={{fn (mut this.isDiscardChanges)}}
  />
  */
  {
    "id": "1orfRpK1",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onClose\"],[[30,0,[\"title\"]],[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],[[4,[38,1],[[30,0,[\"didInsert\"]]],null]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@errorMessage\",\"@isTouched\"],[[28,[37,3],[\"adCampaigns.properties.title\"],null],[28,[37,4],[[30,0,[\"changeset\"]],[30,0,[\"nameProperty\"]]],null],[28,[37,4],[[28,[37,4],[[30,0,[\"changeset\",\"error\"]],[30,0,[\"nameProperty\"]]],null],\"validation.firstObject\"],null],[30,0,[\"isTouched\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@label\",\"@isSecondary\",\"@onClick\"],[[28,[37,3],[\"global.actions.save\"],null],true,[30,0,[\"save\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@isOpen\",\"@onDiscard\",\"@onClose\"],[[30,0,[\"isDiscardChanges\"]],[30,0,[\"discardChanges\"]],[28,[37,7],[[28,[37,8],[[30,0,[\"isDiscardChanges\"]]],null]],null]]],null]],[\"m\",\"c\"],false,[\"ui-modal\",\"did-insert\",\"ui-input\",\"t\",\"get\",\"ui-button\",\"ui-discard-changes\",\"fn\",\"mut\"]]",
    "moduleName": "additive-mc/components/amc-campaign/on-page/set-campaign-name-dialog.hbs",
    "isStrictMode": false
  });
  let AmcCampaignAdsCreateDialogComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.object), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignAdsCreateDialogComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      /**
       * the created model
       *
       * @argument model
       * @type {DS.Model}
       */
      _initializerDefineProperty(this, "model", _descriptor5, this);
      /**
       * the name of the filter
       *
       * @argument filterName
       * @type {String}
       */
      _initializerDefineProperty(this, "filterName", _descriptor6, this);
      /**
       * the adapteroptions to save the model
       *
       * @argument adapterOptions
       * @type {Object}
       */
      _initializerDefineProperty(this, "adapterOptions", _descriptor7, this);
      /**
       * the property for the name
       *
       * @argument nameProperty
       * @type {String}
       */
      _initializerDefineProperty(this, "nameProperty", _descriptor8, this);
      /**
       * the title of the dialog
       *
       * @argument nameProperty
       * @type {String}
       */
      _initializerDefineProperty(this, "title", _descriptor9, this);
      /**
       * the changeset for the model
       *
       * @property changeset
       * @type {Changeset}
       */
      _initializerDefineProperty(this, "changeset", _descriptor10, this);
      /**
       * whether the discard changes dialog is open
       *
       * @property isDiscardChanges
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isDiscardChanges", _descriptor11, this);
      /**
       * whether the form has been touched
       *
       * @property isTouched
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isTouched", _descriptor12, this);
      /**
       * callback function to close the dialog
       *
       * @argument onClose
       * @type {Function}
       */
      _initializerDefineProperty(this, "onClose", _descriptor13, this);
    }
    didInsert() {
      const validation = {
        [this.nameProperty]: (0, _validators.validatePresence)({
          presence: true,
          message: this.intl.t('errors.required')
        })
      };
      this.changeset = new _emberChangeset.default(this.model, (0, _emberChangesetValidations.default)(validation), validation);
    }
    close() {
      if (this.changeset.get('isDirty')) {
        this.isDiscardChanges = true;
      } else {
        if (this.model.isNew) {
          this.model.destroyRecord();
        }
        this.onClose();
      }
    }
    discardChanges() {
      if (this.model.isNew) {
        this.model.destroyRecord();
      }
      this.isDiscardChanges = false;
      this.onClose();
    }
    async save() {
      try {
        this.isTouched = true;
        const {
          isNew
        } = this.model;
        await this.changeset.validate();
        if (this.changeset.get('isInvalid')) {
          return;
        }
        await this.changeset.save({
          adapterOptions: this.adapterOptions
        });
        if (isNew) {
          this.uiFilter.reset(this.filterName);
        }
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
        this.onClose();
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filterName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "adapterOptions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "nameProperty", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'title';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isDiscardChanges", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardChanges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "discardChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignAdsCreateDialogComponent);
});