define("additive-mc/components/amc-campaign/ads/configuration", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "@ember/object/computed", "prop-types", "ember-arg-types", "@additive-apps/auth/utils/roles-map", "@additive-apps/utils/utils/errors"], function (_exports, _component, _templateFactory, _component2, _service, _object, _computed, _propTypes, _emberArgTypes, _rolesMap, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! iterate over array keys to avoid rerender on change of configuration items }}
  {{#each this._configurationIds as |configurationId|}}
    <AmcCampaign::ConfigurationItem
      @item={{get this._orderedConfigurationsById configurationId}}
      @isEdit={{false}}
      @isReadOnly={{this._isReadOnly}}
      @onSave={{action "onConfigurationSave"}} as |configurationItem|
    >
      <configurationItem.multimediaOverlayContent>
        {{yield}}
      </configurationItem.multimediaOverlayContent>
    </AmcCampaign::ConfigurationItem>
  {{/each}}
  */
  {
    "id": "9WF2mWg5",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"_configurationIds\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@item\",\"@isEdit\",\"@isReadOnly\",\"@onSave\"],[[28,[37,3],[[30,0,[\"_orderedConfigurationsById\"]],[30,1]],null],false,[30,0,[\"_isReadOnly\"]],[28,[37,4],[[30,0],\"onConfigurationSave\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"multimediaOverlayContent\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]],null]],[\"configurationId\",\"configurationItem\",\"&default\"],false,[\"each\",\"-track-array\",\"amc-campaign/configuration-item\",\"get\",\"action\",\"yield\"]]",
    "moduleName": "additive-mc/components/amc-campaign/ads/configuration.hbs",
    "isStrictMode": false
  });
  /**
   *  Component for the campaign configuration
   *
   * @class AmcCampagnConfigurationComponent
   */
  let AmcCampagnConfigurationComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _computed.alias)('currentUser.user'), _dec3 = (0, _object.computed)('model.orderedConfigurations'), _dec4 = (0, _object.computed)('model.orderedConfigurations'), _dec5 = (0, _object.computed)('user.role'), _class = class AmcCampagnConfigurationComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor5, this);
      _initializerDefineProperty(this, "router", _descriptor6, this);
      _initializerDefineProperty(this, "model", _descriptor7, this);
      _initializerDefineProperty(this, "user", _descriptor8, this);
    }
    get _configurationIds() {
      var _this$model;
      let ids = [];
      (_this$model = this.model) === null || _this$model === void 0 || (_this$model = _this$model.orderedConfigurations) === null || _this$model === void 0 || _this$model.forEach(config => config.id && ids.push(config.id));
      return ids;
    }
    get _orderedConfigurationsById() {
      const configurations = this.model && this.model.orderedConfigurations;
      let configurationsById = {};
      configurations.forEach(config => config.id && (configurationsById[config.id] = config));
      return configurationsById;
    }
    get _isReadOnly() {
      var _this$user;
      if ((0, _rolesMap.hasAccess)(_rolesMap.ROLE_MANAGER, (_this$user = this.user) === null || _this$user === void 0 ? void 0 : _this$user.role)) {
        return false;
      }
      return true;
    }
    onConfigurationSave() {
      const promise = this.model.save();
      promise.catch(error => {
        this.model.rollbackAttributes();
        if (error) {
          const errorDetail = (0, _errors.getAdapterError)(error);
          if (errorDetail.custom) {
            this.uiDialog.showAlert(this.intl.t('campaigns.configurations.errors.custom.title'), errorDetail && errorDetail.custom);
            return null;
          }
        }
        this.uiDialog.showError();
      });
      return promise;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_configurationIds", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "_configurationIds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_orderedConfigurationsById", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_orderedConfigurationsById"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_isReadOnly", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_isReadOnly"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onConfigurationSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onConfigurationSave"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampagnConfigurationComponent);
});