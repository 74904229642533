define("additive-mc/routes/instance/campaigns/ad/tiktok-lead-ads/depictions/index/depiction", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsAdTikTokLeadAdsDepictionsIndexDepictionRoute = _exports.default = (_class = class InstanceCampaignsAdTikTokLeadAdsDepictionsIndexDepictionRoute extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor2, this);
    }
    model(params) {
      const {
        campaign
      } = this.modelFor('instance.campaigns.ad.tiktok-lead-ads');
      const storeModel = this.store.peekRecord('depiction', params.depiction_id, {
        adapterOptions: {
          campaignId: campaign.id,
          type: 'tiktok-lead-ads'
        }
      });
      if (storeModel) {
        this.uiAppSettings.setLocale(storeModel.language);
        return storeModel;
      }
      return this.store.findRecord('depiction', params.depiction_id, {
        adapterOptions: {
          campaignId: campaign.id,
          type: 'tiktok-lead-ads'
        }
      });
    }
    async setupController(controller) {
      super.setupController(...arguments);
      const {
        campaign
      } = this.modelFor('instance.campaigns.ad.tiktok-lead-ads');
      controller.campaignId = campaign.id;
    }
    resetController(controller, isExiting) {
      if (isExiting && this.uiAppSettings.languages.defaultLanguage !== controller.model.get('language')) {
        this.uiAppSettings.setLocale(this.uiAppSettings.languages.defaultLanguage);
      }
    }
    renderTemplate() {
      this.render('instance/campaigns/ad/tiktok-lead-ads/depictions/index/depiction', {
        into: 'instance/campaigns/ad/tiktok-lead-ads',
        outlet: 'detail'
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});