define("additive-mc/templates/components/amc-campaign/configuration-item/array", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qO53Y3+M",
    "block": "[[[8,[39,0],[[24,\"data-test-config-item-array-content\",\"\"]],[[\"@title\",\"@description\",\"@inputLabel\",\"@onClose\",\"@changeset\",\"@changesetProperty\",\"@isReadOnly\",\"@onSave\"],[[30,0,[\"item\",\"label\"]],[30,0,[\"item\",\"description\"]],[30,0,[\"item\",\"label\"]],[30,0,[\"onClose\"]],[30,0,[\"_changeset\"]],\"value\",[30,0,[\"isReadOnly\"]],[30,0,[\"onSave\"]]]],null]],[],false,[\"ui-tags-input-modal\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/array.hbs",
    "isStrictMode": false
  });
});