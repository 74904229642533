define("additive-mc/serializers/referral-campaign-voucher", ["exports", "additive-mc/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReferralCampaignVoucherApplicationSerializer extends _application.default {
    modelNameFromPayloadKey(payloadKey) {
      return payloadKey === 'voucher' ? 'referral-campaign-voucher' : super.modelNameFromPayloadKey(...arguments);
    }
  }
  _exports.default = ReferralCampaignVoucherApplicationSerializer;
});