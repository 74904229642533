define("additive-mc/mirage/routes/preview/email-preview", ["exports", "additive-mc/mirage/routes/helpers/response", "additive-mc/mirage/routes/preview/email-preview-html", "faker"], function (_exports, _response, _emailPreviewHtml, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmailPreviewRequests = void 0;
  const _splitArrayIntoChunks = (array, len) => {
    let res = [];
    for (let i = 0; i < array.length; i += len) {
      let chunk = array.slice(i, i + len);
      res.push(chunk);
    }
    return res;
  };
  const paginate = (all, request) => {
    let {
      queryParams: {
        page: currentPage,
        per_page: perPage
      }
    } = request;
    currentPage = parseInt(currentPage);
    perPage = parseInt(perPage);
    let pages = _splitArrayIntoChunks(all, perPage);
    let models = pages[currentPage - 1] || [];
    let lastPage = pages.length;
    let total = all.length;
    let meta = {
      currentPage,
      lastPage,
      perPage,
      // from,
      // to,
      total
    };
    return {
      meta,
      models
    };
  };
  const EmailPreviewRequests = self => {
    self.get('/preview/email-campaigns/:id', (schema, request) => {
      const {
        id
      } = request.params;
      const model = schema.emailPreviews.find(id);
      if (!model) {
        throw new Error('not_found');
      }
      return model;
    });
    self.get('/preview/email-campaigns/:id/subscribers', (schema, request) => {
      let data = schema.subscribers.all();
      let {
        models: subscribers,
        meta
      } = paginate(data.models, request);
      return {
        subscribers,
        meta
      };
    });
    self.put('/preview/email-campaigns/:id/subscribers/:subscriber_id/block', (schema, request) => {
      try {
        const model = subscriberOrFail(schema, request);
        return model.update('state', 'blocked');
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/preview/email-campaigns/:id/subscribers/:subscriber_id/unblock', (schema, request) => {
      try {
        const model = subscriberOrFail(schema, request);

        // set random state as we can't know the previous state
        return model.update('state', _faker.default.random.arrayElement(['canceled', 'incomplete', 'ready', 'sent']));
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/preview/email-campaigns/:id/subscribers/:subscriber_id/cancel', (schema, request) => {
      try {
        const model = subscriberOrFail(schema, request);
        return model.update('state', 'canceled');
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get('/preview/email-campaigns/:id/subscribers/:subscriber_id/html', (schema, request) => {
      subscriberOrFail(schema, request);
      return _emailPreviewHtml.EmailPreview;
    });
  };
  _exports.EmailPreviewRequests = EmailPreviewRequests;
  const subscriberOrFail = (schema, request) => {
    const {
      subscriber_id
    } = request.params;
    const model = schema.subscribers.find(subscriber_id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
});