define("additive-mc/components/amc-campaign/configuration-item/time", ["exports", "@ember/component", "rsvp", "@ember/utils", "@ember/object", "ember-concurrency"], function (_exports, _component, _rsvp, _utils, _object, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which handles campaigns configuration-item for `time`.
   *
   * `time` is a string with HOURS-MINUTES: `8:30` format HH:MM
   *
   * @class amc-campaigns/configuration-item/time
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    /**
     * The configuration-item
     *
     * @property item
     * @default null
     */
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * Whether the content is valid
     *
     * @property _contentIsValid
     * @private
     */
    _contentIsValid: false,
    /**
     * The initial value
     *
     * @property _initialValue
     * @private
     */
    _initialValue: null,
    init() {
      this._super(...arguments);
      (0, _utils.isPresent)(this.item.value) && (0, _object.set)(this, '_contentIsValid', true);
      this._initialValue = this.item && this.item.value;
    },
    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {},
    /**
     * Close event
     *
     * @function onClose
     * @return {Promise} Returns a promise
     * @public
     */
    onClose() {},
    /**
     * Save wrapper to reset item.value on error
     */
    _save: (0, _emberConcurrency.task)(function* () {
      const tasks = [];
      try {
        tasks.push(this.onSave());
        tasks.push((0, _emberConcurrency.timeout)(400));
        yield (0, _emberConcurrency.all)(tasks);
      } catch (error) {
        // reset
        (0, _object.set)(this, 'item.value', this._initialValue);
      }
    }),
    actions: {
      onSave(parentChangeset) {
        /* Execute and save */
        parentChangeset.execute();
        this.onSave(parentChangeset);
      },
      onTimePickerChange(parentChangeset, date) {
        if (!(date instanceof Date)) {
          /* Update changeset */
          parentChangeset.set('value', null);
          return;
        }
        let [hours, minutes] = [date.getHours(), date.getMinutes()];
        /* ensure it uses 2 digits (1 -> 01) */
        hours = ('0' + hours).slice(-2);
        minutes = ('0' + minutes).slice(-2);

        /* Update changeset */
        parentChangeset.set('value', `${hours}:${minutes}`);
      },
      beforeSave() {
        return (0, _rsvp.resolve)(this._contentIsValid);
      }
    }
  });
});