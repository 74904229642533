define("additive-mc/components/amc-campaign/ads/list-view", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object/computed", "ember-arg-types", "prop-types", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _service, _computed, _emberArgTypes, _propTypes, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.activeAds.length}}
    <AmcCampaign::Ads::List
      @items={{this.activeAds}}
      @renameAction={{this.renameAction}}
      @archiveAction={{this.archiveAction}}
      @deleteAction={{this.deleteAction}}
      @uiAppSettings={{this.uiAppSettings}}
      @isReadOnly={{@isReadOnly}}
      @meta={{this.meta}}
      @title={{t "adCampaigns.leadAds.activeAds"}}
      @type={{this.type}}
      data-test-ad-campaign-list-active
    />
  {{/if}}
  {{#if this.expiredAds.length}}
    <AmcCampaign::Ads::List
      @items={{this.expiredAds}}
      @renameAction={{this.renameAction}}
      @archiveAction={{this.archiveAction}}
      @deleteAction={{this.deleteAction}}
      @uiAppSettings={{this.uiAppSettings}}
      @isReadOnly={{@isReadOnly}}
      @hideActions={{true}}
      @meta={{this.meta}}
      @title={{t "adCampaigns.leadAds.expiredAds"}}
      @type={{this.type}}
      data-test-ad-campaign-list-expired
    />
  {{/if}}
  {{#if this.archivedAds.length}}
    <AmcCampaign::Ads::List
      @items={{this.archivedAds}}
      @uiAppSettings={{this.uiAppSettings}}
      @isReadOnly={{@isReadOnly}}
      @hideActions={{true}}
      @meta={{this.meta}}
      @title={{t "adCampaigns.leadAds.archivedAds"}}
      @type={{this.type}}
      data-test-ad-campaign-list-archived
    />
  {{/if}}
  */
  {
    "id": "d5Oretem",
    "block": "[[[41,[30,0,[\"activeAds\",\"length\"]],[[[1,\"  \"],[8,[39,1],[[24,\"data-test-ad-campaign-list-active\",\"\"]],[[\"@items\",\"@renameAction\",\"@archiveAction\",\"@deleteAction\",\"@uiAppSettings\",\"@isReadOnly\",\"@meta\",\"@title\",\"@type\"],[[30,0,[\"activeAds\"]],[30,0,[\"renameAction\"]],[30,0,[\"archiveAction\"]],[30,0,[\"deleteAction\"]],[30,0,[\"uiAppSettings\"]],[30,1],[30,0,[\"meta\"]],[28,[37,2],[\"adCampaigns.leadAds.activeAds\"],null],[30,0,[\"type\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"expiredAds\",\"length\"]],[[[1,\"  \"],[8,[39,1],[[24,\"data-test-ad-campaign-list-expired\",\"\"]],[[\"@items\",\"@renameAction\",\"@archiveAction\",\"@deleteAction\",\"@uiAppSettings\",\"@isReadOnly\",\"@hideActions\",\"@meta\",\"@title\",\"@type\"],[[30,0,[\"expiredAds\"]],[30,0,[\"renameAction\"]],[30,0,[\"archiveAction\"]],[30,0,[\"deleteAction\"]],[30,0,[\"uiAppSettings\"]],[30,1],true,[30,0,[\"meta\"]],[28,[37,2],[\"adCampaigns.leadAds.expiredAds\"],null],[30,0,[\"type\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"archivedAds\",\"length\"]],[[[1,\"  \"],[8,[39,1],[[24,\"data-test-ad-campaign-list-archived\",\"\"]],[[\"@items\",\"@uiAppSettings\",\"@isReadOnly\",\"@hideActions\",\"@meta\",\"@title\",\"@type\"],[[30,0,[\"archivedAds\"]],[30,0,[\"uiAppSettings\"]],[30,1],true,[30,0,[\"meta\"]],[28,[37,2],[\"adCampaigns.leadAds.archivedAds\"],null],[30,0,[\"type\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@isReadOnly\"],false,[\"if\",\"amc-campaign/ads/list\",\"t\"]]",
    "moduleName": "additive-mc/components/amc-campaign/ads/list-view.hbs",
    "isStrictMode": false
  });
  /**
   * Splits the lead ad advertisements list into sub lists
   *
   * @class AmcCampaignAdsListView
   */
  let AmcCampaignAdsListViewComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.array | _propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _computed.filterBy)('items', 'isActive', true), _dec6 = (0, _computed.filterBy)('items', 'isArchived', true), _dec7 = (0, _computed.filterBy)('items', 'isExpired', true), _dec8 = (0, _emberArgTypes.arg)(_propTypes.func), _dec9 = (0, _emberArgTypes.arg)(_propTypes.func), _dec10 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignAdsListViewComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor, this);
      /**
       * the list items
       *
       * @argument items
       * @type {Object}
       */
      _initializerDefineProperty(this, "items", _descriptor2, this);
      /**
       * The meta information
       *
       * @argument meta
       * @type {Object}
       */
      _initializerDefineProperty(this, "meta", _descriptor3, this);
      /**
       * whether the component is read only
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor4, this);
      /**
       * The items type
       *
       * @argument type
       * @type {Object}
       */
      _initializerDefineProperty(this, "type", _descriptor5, this);
      _initializerDefineProperty(this, "activeAds", _descriptor6, this);
      _initializerDefineProperty(this, "archivedAds", _descriptor7, this);
      _initializerDefineProperty(this, "expiredAds", _descriptor8, this);
      _initializerDefineProperty(this, "archiveAction", _descriptor9, this);
      _initializerDefineProperty(this, "renameAction", _descriptor10, this);
      _initializerDefineProperty(this, "deleteAction", _descriptor11, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "meta", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "activeAds", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "archivedAds", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "expiredAds", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "archiveAction", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "renameAction", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "deleteAction", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignAdsListViewComponent);
});