define("additive-mc/mirage/routes/preview/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OverviewRequests = void 0;
  const OverviewRequests = self => {
    self.get('/preview/email-campaigns', schema => {
      let data = schema.emailPreviews.all();
      return data;
    });
    self.get('/preview/ad-campaigns', schema => {
      let data = schema.adPreviews.all();
      return data;
    });
    self.get('/preview/social-campaigns', schema => {
      let data = schema.socialPreviews.all();
      return data;
    });
  };
  _exports.OverviewRequests = OverviewRequests;
});