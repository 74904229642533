define("additive-mc/mirage/factories/on-page-campaign", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const allowedValues = [{
    label: 'option1',
    value: 'option1-value'
  }, {
    label: 'option2',
    value: 'option2-value'
  }, {
    label: 'option3',
    value: 'option3-value'
  }];
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    title() {
      return 'On page Campaign';
    },
    group() {
      return 'onPage';
    },
    type: () => _faker.default.random.arrayElement(['OnPageLeadCampaign', 'OnPageIncentiveCampaign']),
    description(i) {
      return `${this.title} ${i}`;
    },
    code: () => _faker.default.random.arrayElement(['usecase1', 'usecase2a', 'usecase2b', 'usecase3a', 'usecase3b', 'usecase4']),
    active() {
      return _faker.default.random.boolean();
    },
    widgetConfigurations() {
      return [{
        id: 704,
        type: 'select',
        key: 'selected-widget',
        label: 'Widget',
        description: '',
        order: 0,
        value: {
          value: 123,
          label: 'website'
        },
        allowedValues: [{
          value: 123,
          label: 'website'
        }, {
          label: 124,
          value: 'landingpage'
        }]
      }, {
        id: 70,
        type: 'widget-display-variant',
        key: 'display-variant',
        label: 'Anzeigeart',
        description: '',
        order: 0,
        value: {
          position: 'center',
          size: 'md'
        }
      }];
    },
    configurations() {
      return [{
        id: 70,
        type: 'widget-trigger-event',
        key: 'trigger-event',
        label: 'Trigger Event',
        description: 'Bla Bla Bla 123',
        order: 0,
        value: {
          trigger: 'on-scroll',
          value: 20
        }
      }, {
        id: 70,
        type: 'widget-trigger-event',
        key: 'trigger-event',
        label: 'Trigger Event',
        description: '',
        order: 0,
        value: {
          trigger: 'on-exit'
        }
      }, {
        id: 70,
        type: 'utm-parameter',
        key: 'request-parameters',
        label: 'Show if following Parameters are provided',
        description: '',
        order: 0,
        value: {
          type: 'source',
          value: 'abc'
        }
      }];
    },
    afterCreate(campaign, server) {
      let voucher = null;
      if (campaign.type === 'OnPageIncentiveCampaign') {
        voucher = server.create('on-page-campaign-voucher');
        campaign.update({
          voucher: voucher.toJSON()
        });
      }
      const contents = server.createList('on-page-content', 3, Object.assign({
        campaign
      }));
      campaign.update({
        contents
      });
    }
  });
});