define("additive-mc/components/amc-campaign/configuration-item/array", ["exports", "@glimmer/component", "ember-changeset", "ember-changeset-validations", "ember-arg-types", "prop-types", "@glimmer/tracking"], function (_exports, _component, _emberChangeset, _emberChangesetValidations, _emberArgTypes, _propTypes, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Component which handles campaigns configuration-item for `array`.
   *
   * `array` is an array of strings
   *
   * @class amc-campaigns/configuration-item/array
   * @module amc-campaigns
   */
  let AmcCampaignConfigurationItemArrayComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignConfigurationItemArrayComponent extends _component.default {
    constructor() {
      super(...arguments);
      /**
       * The configuration-item
       *
       * @property item
       * @default null
       */
      _initializerDefineProperty(this, "item", _descriptor, this);
      /**
       * Whether the component is read only
       *
       * @argument isReadOnly
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor2, this);
      /**
       * the internal changeset
       *
       * @property _changeset
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_changeset", _descriptor3, this);
      _initializerDefineProperty(this, "onSave", _descriptor4, this);
      _initializerDefineProperty(this, "onClose", _descriptor5, this);
      this._changeset = new _emberChangeset.default(this.item, (0, _emberChangesetValidations.default)(this.validation || true), this.validation || true);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "item", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "onSave", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
});