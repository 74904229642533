define("additive-mc/templates/components/amc-campaign/configuration-item/text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "7JcRUary",
    "block": "[[[8,[39,0],null,[[\"@onSave\",\"@onClose\",\"@item\",\"@isReadOnly\"],[[28,[37,1],[[30,0,[\"_save\"]]],null],[30,0,[\"onClose\"]],[30,0,[\"item\"]],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@isDisabled\"],[[30,0,[\"item\",\"label\"]],[30,1,[\"changeset\",\"value\"]],[28,[37,3],[[30,1,[\"isLoading\"]],[30,0,[\"isReadOnly\"]]],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\"],false,[\"amc-campaign/configuration-item/with-modal\",\"perform\",\"ui-input\",\"amc-or\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/text.hbs",
    "isStrictMode": false
  });
});