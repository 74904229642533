define("additive-mc/templates/application-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "gpJyaWwT",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"ui-loading-page\"]]",
    "moduleName": "additive-mc/templates/application-loading.hbs",
    "isStrictMode": false
  });
});