define("additive-mc/serializers/on-page-campaign-voucher", ["exports", "additive-mc/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    modelNameFromPayloadKey(payloadKey) {
      return payloadKey === 'voucher' ? 'on-page-campaign-voucher' : this._super(...arguments);
    }
  });
});