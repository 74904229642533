define("additive-mc/mirage/factories/app", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    active: true,
    name: _faker.default.commerce.productName(),
    new: false,
    planName: 'starter_yearly',
    token: null
  });
});