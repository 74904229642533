define("additive-mc/templates/components/amc-campaign/configuration-item/percentage-distribution", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "NO91txP6",
    "block": "[[[8,[39,0],null,[[\"@beforeSave\",\"@onSave\",\"@onClose\",\"@childChangeset\",\"@item\",\"@isReadOnly\"],[[28,[37,1],[[30,0,[\"_setSum\"]]],null],[28,[37,1],[[30,0,[\"_save\"]]],null],[30,0,[\"onClose\"]],[30,0,[\"_changeset\"]],[30,0,[\"item\"]],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex flex-column w-100\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"_changeset\",\"value\"]]],null]],null],null,[[[1,\"        \"],[8,[39,4],[[16,\"data-test-config-item-percentage-distribution\",[30,3]]],[[\"@label\",\"@placeholder\",\"@type\",\"@value\",\"@errorMessage\",\"@isDisabled\"],[[30,2,[\"label\"]],[30,2,[\"label\"]],\"number\",[30,2,[\"value\"]],[28,[37,5],[[28,[37,5],[[30,0,[\"_changeset\",\"error\",\"value\",\"validation\"]],\"firstObject.value\"],null],[30,3]],null],[28,[37,6],[[30,1,[\"isLoading\"]],[30,0,[\"isReadOnly\"]]],null]]],null],[1,\"\\n\"]],[2,3]],null],[1,\"\\n\"],[41,[30,0,[\"_changeset\",\"error\",\"sum\"]],[[[1,\"        \"],[10,0],[14,0,\"font-xs red\"],[12],[1,\"\\n          \"],[1,[28,[35,5],[[30,0,[\"_changeset\",\"error\",\"sum\",\"validation\"]],\"firstObject\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"element\",\"index\"],false,[\"amc-campaign/configuration-item/with-modal\",\"perform\",\"each\",\"-track-array\",\"ui-input\",\"get\",\"amc-or\",\"if\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/percentage-distribution.hbs",
    "isStrictMode": false
  });
});