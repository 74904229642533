define("additive-mc/serializers/service", ["exports", "additive-mc/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ServiceSerializer extends _application.default {
    normalize(model, hash) {
      hash.selectedContentTypes.forEach(selectedContentType => {
        var _selectedContentType$;
        (_selectedContentType$ = selectedContentType.excludedContents) === null || _selectedContentType$ === void 0 || _selectedContentType$.forEach(excludedContent => {
          if (!Array.isArray(excludedContent.excluded)) {
            excludedContent.excluded = [];
          }
        });
      });
      return super.normalize(...arguments);
    }
  }
  _exports.default = ServiceSerializer;
});