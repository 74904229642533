define("additive-mc/components/amc-campaign/voucher-dialog", ["exports", "additive-mc/config/environment", "@ember/component", "@ember/service", "ember-concurrency", "@ember/object", "additive-mc/validations/email-campaign", "@additive-apps/media/constants"], function (_exports, _environment, _component, _service, _emberConcurrency, _object, _emailCampaign, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which represents the "voucher"-dialog part of an campaign with voucher
   *
   * @class componenents/amc-campaign/voucher-dialog
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    multimedia: (0, _service.inject)(),
    router: (0, _service.inject)(),
    authenticatedFetch: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    tagName: '',
    /**
     * The parents campaignId
     *
     * @property campaignId
     * @default null
     */
    campaignId: null,
    /**
     * The campaign type (email/trigger-email)
     *
     * @property campaignType
     * @default null
     */
    campaignType: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * The resource which contains the `voucher` child
     *
     * @property resource
     * @default null
     */
    resource: null,
    /**
     * The type of the model (`email-campaign-voucher` or `on-page-campaign-voucher`)
     *
     * @property voucherModelType
     * @default null
     * @private
     */
    voucherModelType: null,
    /**
     * Internal copy of the current resource
     *
     * @property _resource
     * @default null
     * @private
     */
    _resource: null,
    /**
     * the preview url
     *
     * @property preview
     * @type {String}
     * @default null
     */
    preview: null,
    /**
     * Whether the voucher preview is shown
     *
     * @property _showVoucherPreview
     * @type {Boolean}
     * @default false
     * @private
     */
    _showVoucherPreview: false,
    /**
     * default size for images
     *
     * @property defaultSize
     * @type {String}
     * @default 334x240
     */
    defaultSize: _constants.GRID_DEFAULT_SIZE,
    _adapterOptions: (0, _object.computed)('campaignId', {
      get() {
        return {
          adapterOptions: {
            campaignId: this.campaignId
          }
        };
      }
    }),
    _backRoute: (0, _object.computed)('campaignType', 'voucherModelType.length', {
      get() {
        if (this.voucherModelType === 'email-campaign-voucher') return `instance.campaigns.${this.campaignType}.detail.index`;else return `instance.campaigns.${this.voucherModelType.replace('-campaign-voucher', '')}.detail`;
      }
    }),
    _setup() {
      (0, _object.setProperties)(this, {
        _validation: _emailCampaign.voucherValidation,
        _resource: this.resource
      });
    },
    init() {
      this._super(...arguments);
      this._setup();

      // set options for media selection
      this.multimedia.setSelectionOptions({
        maxCount: 1,
        type: 'image',
        warningMessage: this.intl.t('global.warnings.multimedia.image')
      });
    },
    didUpdateAttrs() {
      this._super(...arguments);
      if (this._resource && this._resource.id === this.resource.id) {
        return;
      }

      /* Re-setup on resource attribute change */
      this._setup();
    },
    willDestroyElement() {
      this._super(...arguments);
      this.multimedia.resetSelectionOptions();
    },
    /**
     * opens the voucher preview carousel
     *
     * @type {Task}
     * @function openPreviewCarousel
     * @private
     */
    openPreviewCarousel: (0, _emberConcurrency.task)(function* () {
      (0, _object.set)(this, '_showVoucherPreview', true);
      try {
        const {
          campaignType,
          campaignId,
          resource,
          currentUser
        } = this;
        const url = `${_environment.default.APP.apiBaseHost}/${currentUser.currentOrganization.id}/organisation-campaigns/${campaignType}/${campaignId}/vouchers/${resource.id}/preview`;
        const response = yield this.authenticatedFetch.fetch(url, {
          method: 'GET',
          headers: {
            'Accept-Language': this.uiAppSettings.currentLocale
          }
        });
        if (!(response !== null && response !== void 0 && response.ok)) {
          throw new Error('Response not ok');
        }
        const json = yield response.json();
        (0, _object.set)(this, 'preview', json[0]);
      } catch (e) {
        this.uiToast.showToast({
          type: 'error',
          title: this.intl.t('toast.unexpectedError')
        });
      }
    }),
    openMultimedia() {},
    closeMultimedia() {},
    actions: {
      onChangeVoucherGreetingText(changeset, text) {
        const greetingText = changeset.get('greetingText');
        changeset.set('greetingText', Object.assign({}, greetingText, {
          value: text
        }));
      },
      onResourceRemove(changeset) {
        changeset.set('image', null);
        changeset.execute();
      },
      addMedium(changeset, _, _ref) {
        let [resource] = _ref;
        changeset.set('image', resource);
      }
    }
  });
});