define("additive-mc/components/amc-preview/upcoming-emails-subscriber", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/object/computed", "ember-concurrency"], function (_exports, _component, _object, _service, _computed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module amc-preview
   */
  var _default = _exports.default = _component.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    router: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiPaths: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    /**
     * the current organization slug
     *
     * @property organizationSlug
     * @type {String}
     * @default null
     */
    organizationSlug: (0, _computed.alias)('currentUser.currentOrganization.id'),
    /**
     * whether the table is empty or not
     *
     * @computed isEmpty
     * @type {Boolean}
     */
    isEmpty: (0, _computed.equal)('_subscribers.length', 0),
    _adapterOptions: (0, _object.computed)('model.id', {
      get() {
        return {
          adapterOptions: {
            previewId: this.model.id
          }
        };
      }
    }),
    /**
     * the model of the current email preview
     *
     * @property model
     * @type {Object}
     * @default null
     */
    model: null,
    /**
     * the filter object
     *
     * @property filter
     * @type {Object}
     * @default null
     */
    filter: null,
    /**
     * whether the component is read only
     *
     * @property isReadOnly
     * @type {Boolean}
     * @default null
     */
    isReadOnly: null,
    /**
     * the list of subscribers
     *
     * @property _subscribers
     * @type {Array}
     * @default null
     * @private
     */
    _subscribers: null,
    /**
     * the subscriber which html preview is shown in the dialog
     *
     * @property _previewSubscriber
     * @type {Object}
     * @default null
     * @private
     */
    _previewSubscriber: null,
    /**
     * whether the dialog with the email content is show or not
     *
     * @property _showEmailPreview
     * @type {Boolean}
     * @default false
     * @private
     */
    _showEmailPreview: false,
    init() {
      this._super(...arguments);
      (0, _object.set)(this, '_subscribers', []);
      (0, _object.set)(this, 'tableHeaderOptions', [{
        i18n: 'preview.upcomingEmails.subscribers.columns.name',
        minWidth: '80px'
      }, {
        i18n: 'preview.upcomingEmails.subscribers.columns.email',
        minWidth: '80px'
      }, {
        i18n: 'preview.upcomingEmails.subscribers.columns.deliveryDate',
        minWidth: '80px'
      }, {
        i18n: 'preview.upcomingEmails.subscribers.columns.state',
        minWidth: '80px'
      }]);
    },
    /**
     * Calls the API endpoint to block, unblock or cancel the subscriber
     * <slug>/preview/email-campaigns/<emailCampaignId>/subscribers/<subscriberId>/<action>
     *
     * @param {Object} subscriber
     * @param {String} action
     * @throws {Error}
     */
    _updateSubscriber: (0, _emberConcurrency.task)(function* (subscriber, action) {
      try {
        const {
          model
        } = this;
        const baseUrl = this.uiPaths.pathsByRouteName('instance.dashboard', this.organizationSlug).api().url;
        let url = `${baseUrl}/preview/email-campaigns/${model.id}/subscribers/${subscriber.id}/${action}`;
        const [response] = yield (0, _emberConcurrency.all)([this.authenticatedFetch.fetch(url, {
          method: 'put'
        }), (0, _emberConcurrency.timeout)(400)]);
        if (!response || !response.ok) {
          throw new Error('[DASHBOARD] Error in response');
        }
        const payload = yield response.json();
        this.store.pushPayload('subscriber', payload);
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }),
    actions: {
      cancelSubscriber(subscriber) {
        this._updateSubscriber.perform(subscriber, 'cancel');
      },
      openPreview(subscriber) {
        (0, _object.setProperties)(this, {
          _previewSubscriber: subscriber,
          _showEmailPreview: true
        });
      }
    }
  });
});