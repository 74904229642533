define("additive-mc/mirage/factories/ad-campaign", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    title(i) {
      return `Ad Campaign ${i}`;
    },
    description() {
      return this.title;
    },
    type: () => _faker.default.random.arrayElement(['FacebookAdsCampaign', 'FacebookLeadAdCampaign', 'FacebookContentAdCampaign', 'TikTokLeadAdCampaign']),
    code: () => _faker.default.random.arrayElement(['usecase1', 'usecase2a', 'usecase2b', 'usecase3a', 'usecase3b', 'usecase4']),
    campaignGoal: () => {
      return _faker.default.random.arrayElement(['conversions', 'brandAwareness', 'reach']);
    },
    active() {
      return _faker.default.random.boolean();
    },
    activeFrom() {
      if (this.active) {
        return _faker.default.date.past();
      }
      return null;
    },
    activeUntil() {
      if (this.active) {
        return _faker.default.date.future();
      }
    },
    configurations() {
      if (this.type === 'FacebookContentAdCampaign') {
        return [{
          id: 1,
          label: 'Facebook Kampagne ID',
          type: 'text',
          key: 'facebook-campaign-id',
          value: '123456789'
        }, {
          id: 2,
          label: 'Kampagnen Ziel',
          type: 'text',
          key: 'facebook-campaign-goal',
          value: this.campaignGoal
        }];
      } else {
        return [{
          id: 1,
          label: 'Zum welchem Zeitpunkt?',
          description: "You dont always have to f**k her hard, in fact sometimes that's not right to do",
          type: 'time',
          key: 'my-unique-time',
          order: _faker.default.random.number(),
          value: _faker.default.random.arrayElement([null, '15:30', '01:16', '08:09'])
        }, {
          id: 2,
          label: 'Bild',
          type: 'multimedia',
          key: 'my-unique-multimedia',
          order: _faker.default.random.number(),
          value: _faker.default.random.arrayElement([null, 'image'])
        }, {
          id: 3,
          label: 'multilingual-texts',
          type: 'multilingual-texts',
          key: 'my-multilingual-texts',
          order: _faker.default.random.number(),
          value: [{
            de: 'de text1',
            en: 'en text1'
          }, {
            de: 'de text2',
            en: 'en text2'
          }, {
            de: 'de text3'
          }]
        }];
      }
    },
    afterCreate(campaign, server) {
      var _campaign$configurati;
      let configurations = ((_campaign$configurati = campaign.configurations) === null || _campaign$configurati === void 0 ? void 0 : _campaign$configurati.slice(0)) || null;
      if (!configurations) {
        if (campaign.type === 'FacebookContentAdCampaign') {
          configurations = [{
            id: 1,
            label: 'Facebook Kampagne ID',
            type: 'text',
            key: 'facebook-campaign-id',
            value: '123456789'
          }, {
            id: 2,
            label: 'Kampagnen Ziel',
            type: 'text',
            key: 'facebook-campaign-goal',
            value: _faker.default.random.arrayElement(['conversions'])
          }];
        } else {
          configurations = [{
            id: 1,
            label: 'Zum welchem Zeitpunkt?',
            description: "You dont always have to f**k her hard, in fact sometimes that's not right to do",
            type: 'time',
            key: 'my-unique-time',
            order: _faker.default.random.number(),
            value: _faker.default.random.arrayElement([null, '15:30', '01:16', '08:09'])
          }, {
            id: 2,
            label: 'Bild',
            type: 'multimedia',
            key: 'my-unique-multimedia',
            order: _faker.default.random.number(),
            value: _faker.default.random.arrayElement([null, 'image'])
          }, {
            id: 3,
            label: 'multilingual-texts',
            type: 'multilingual-texts',
            key: 'my-multilingual-texts',
            order: _faker.default.random.number(),
            value: [{
              de: 'de text1',
              en: 'en text1'
            }, {
              de: 'de text2',
              en: 'en text2'
            }, {
              de: 'de text3'
            }]
          }];
        }
      }
      configurations.forEach(config => {
        if (config.type !== 'multimedia' || config.value !== 'image') {
          return config;
        }
        config.value = server.create('image');
      });
      campaign.update({
        configurations
      });
    }
  });
});