define("additive-mc/mirage/routes/settings/settings", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SettingRoutes = void 0;
  const SettingRoutes = self => {
    self.get('/settings', schema => {
      return schema.settings.first();
    });
    self.put('/settings', (schema, request) => {
      const attrs = JSON.parse(request.requestBody);
      if (attrs.facebookOAuth.userId && !attrs.facebookOAuth.userName) {
        attrs.facebookOAuth.userName = _faker.default.name.findName();
      }
      if (attrs.voucherValidityPeriod) {
        attrs.voucherValidityPeriod = {
          value: attrs.voucherValidityPeriod,
          allowedValues: [{
            label: 'Ende nächstes Jahr',
            value: 'end_of_next_year'
          }, {
            label: '1 Jahr',
            value: 'one_year'
          }, {
            label: '6 Monate',
            value: 'six_months'
          }, {
            label: '3 Monate',
            value: 'three_months'
          }, {
            label: '1 Monat',
            value: 'one_month'
          }]
        };
      }
      const model = schema.settings.first();
      return model.update(attrs);
    });
    self.get('/settings/tiktok/advertisers', () => {
      return {
        advertisers: [{
          id: 1,
          name: 'Advertiser 1'
        }, {
          id: 2,
          name: 'Advertiser 2'
        }, {
          id: 3,
          name: 'Advertiser 3'
        }]
      };
    });
  };
  _exports.SettingRoutes = SettingRoutes;
});