define("additive-mc/routes/instance/campaigns/ad/catalog-ads", ["exports", "@ember/routing/route", "@ember/service", "@glimmer/tracking", "ember-concurrency"], function (_exports, _route, _service, _tracking, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsAdCatalogAdsRoute = _exports.default = (_class = class InstanceCampaignsAdCatalogAdsRoute extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor2, this);
      _initializerDefineProperty(this, "meta", _descriptor3, this);
    }
    async model(params) {
      let tasks = [];
      const adapter = this.store.adapterFor('advertisement');
      const url = adapter.urlForFindAll('advertisement', {
        adapterOptions: {
          campaignId: params.campaign_id,
          type: 'catalog-ads'
        }
      });
      tasks.push(this.authenticatedFetch.fetch(`${url}/metadata`));
      let adCampaign = this.store.peekRecord('ad-campaign', params.campaign_id);
      if (!adCampaign) {
        tasks.push(this.store.findRecord('ad-campaign', params.campaign_id));
      }
      let depictions = this.store.peekAll('depiction', {
        adapterOptions: {
          campaignId: params.campaign_id,
          type: 'catalog-ads'
        }
      });
      if (!depictions.length) {
        tasks.push(this.store.findAll('depiction', {
          adapterOptions: {
            campaignId: params.campaign_id,
            type: 'catalog-ads'
          }
        }));
      }
      try {
        const response = await (0, _emberConcurrency.all)(tasks);
        this.meta = await response[0].json();
        if (response.length === 3) {
          adCampaign = response[1];
          depictions = response[2];
        } else if (response.length === 2) {
          if (adCampaign) {
            depictions = response[1];
          } else {
            adCampaign = response[1];
          }
        }
        return {
          campaign: adCampaign,
          meta: this.meta,
          depictions
        };
      } catch (e) {
        this.isError = true;
      }
    }
    setupController(controller, model) {
      super.setupController(...arguments);
      this.uiFilter.register('catalog-ads', 'advertisement', {
        searchable: false,
        request: () => {
          return this.store.findAll('advertisement', {
            reload: true,
            adapterOptions: {
              campaignId: model.campaign.id,
              type: 'catalog-ads'
            }
          });
        }
      });
      return controller;
    }
    resetController(controller) {
      controller.activeModel = null;
      controller.isCreateAdDialog = false;
      controller.isCreateDepictionDialog = false;
      this.uiFilter.unregister('catalog-ads');
      this.store.unloadAll('advertisement');
      this.store.unloadAll('depiction');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "meta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _class);
});