define("additive-mc/components/amc-campaign/voucher", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed"], function (_exports, _component, _service, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which represents the "voucher"-part of an campaign with voucher
   *
   * @class componenents/amc-campaign/voucher
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    tagName: '',
    /**
     * Whether it is read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * The resource which contains the `voucher` child
     *
     * @property resource
     * @default null
     */
    resource: null,
    /**
     * The type of the model (`email-campaign-voucher` or `on-page-campaign-voucher`)
     *
     * @property voucherModelType
     * @default null
     * @private
     */
    voucherModelType: null,
    /**
     * Internal copy of the current resource id
     *
     * @property _resourceId
     * @default null
     * @private
     */
    _resourceId: null,
    /**
     * The ember data model
     *
     * @property _voucherModel
     * @default null
     * @private
     */
    _voucherModel: null,
    _contentLanguages: (0, _computed.alias)('uiAppSettings.languages.contentLanguages'),
    _adapterOptions: (0, _object.computed)('resource.{campaignId,id}', {
      get() {
        return {
          adapterOptions: {
            campaignId: this.resource.id
          }
        };
      }
    }),
    _languageState: (0, _object.computed)('_contentLanguages.[]', '_voucherModel.availableLanguages.[]', {
      get() {
        if (!this._contentLanguages || !Array.isArray(this._contentLanguages)) {
          return 'error';
        }
        let isSuccess = true;
        const {
          availableLanguages
        } = this._voucherModel;
        this._contentLanguages.forEach(language => {
          if (!isSuccess) {
            return;
          }
          isSuccess = availableLanguages.indexOf(language) >= 0;
        });
        return isSuccess ? 'success' : availableLanguages.length === 0 ? 'error' : 'warning';
      }
    }),
    _setup() {
      const voucherModel = this.store.peekRecord(this.voucherModelType, this.resource.voucher.get('id'));
      (0, _object.setProperties)(this, {
        _voucherModel: voucherModel,
        _resourceId: this.resource.id
      });
    },
    init() {
      this._super(...arguments);
      this._setup();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      if (this._resourceId === this.resource.id) {
        return;
      }

      /* Re-setup on resource attribute change */
      this._setup();
    },
    onVoucherDetailOpen() {}
  });
});