define("additive-mc/validations/settings", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.settingsValidations = _exports.legalsValidations = void 0;
  const TIME_REGEX = /^(([0-1][0-9])|(2[0-3])):([0-5][0-9])$/;
  const URL_REGEX = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
  const RATE_REGEX = /^\d+:\d+$/;
  const settingsValidations = _exports.settingsValidations = function settingsValidations(intl) {
    let allowAttributionsBlank = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    return {
      email: (0, _validators.validateFormat)({
        type: 'email',
        message: intl.t('errors.email'),
        allowBlank: true
      }),
      landingPageUrl: [(0, _validators.validateFormat)({
        regex: /https:\/\/.*$/,
        message: intl.t('errors.urlStartWithHTTPS'),
        allowBlank: true
      }), (0, _validators.validateFormat)({
        regex: URL_REGEX,
        message: intl.t('errors.url'),
        allowBlank: true
      })],
      sendAt: (0, _validators.validateFormat)({
        regex: TIME_REGEX,
        allowBlank: true
      }),
      conversionRateRequestsToReservations: (0, _validators.validateFormat)({
        regex: RATE_REGEX,
        allowBlank: allowAttributionsBlank,
        message: intl.t(allowAttributionsBlank ? 'errors.rate' : 'errors.requiredRate')
      }),
      averageReservationRevenue: (0, _validators.validateNumber)({
        gt: 0,
        allowBlank: allowAttributionsBlank,
        integer: true,
        message: intl.t(allowAttributionsBlank ? 'errors.positiveInteger' : 'errors.requiredPositiveInteger')
      })
    };
  };
  const legalsValidations = intl => {
    return {
      privacyUrl: (0, _validators.validateFormat)({
        type: 'url',
        message: intl.t('errors.url'),
        allowBlank: true
      }),
      disclaimerUrl: (0, _validators.validateFormat)({
        type: 'url',
        message: intl.t('errors.url'),
        allowBlank: true
      })
    };
  };
  _exports.legalsValidations = legalsValidations;
});