define("additive-mc/routes/instance/campaigns/email/detail/index/create", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model() {},
    setupController(controller, model) {
      this._super(controller, model);
      const {
        campaign_id
      } = this.paramsFor('instance.campaigns.email.detail');
      (0, _object.set)(controller, 'campaignId', campaign_id);
    }
  });
});