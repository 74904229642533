define("additive-mc/validations/email-campaign", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/validate-if", "@additive-apps/utils/validators/numeral", "additive-mc/validators/test-email-validator", "additive-mc/utils/constants"], function (_exports, _validators, _validateIf, _numeral, _testEmailValidator, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.voucherValidation = _exports.testEmailValidation = _exports.emailValidation = _exports.emailSeriesValidation = void 0;
  const emailValidation = _exports.emailValidation = function emailValidation(intl) {
    let isCtaTextRequired = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    const presenceMessage = intl.t('errors.required');
    const urlMessage = intl.t('errors.url');
    const surveyMessage = intl.t('emailCampaigns.emails.dialog.surveys.error');
    let defaultValidations = {
      subject: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      ctaLink: [(0, _validateIf.default)({
        validate: (0, _validators.validateFormat)({
          type: 'url',
          message: urlMessage
        }),
        if: {
          ctaType: _constants.EMAIL_CAMPAIGN_CTA_TYPE_CTA
        },
        firstErrorMessage: true
      }), (0, _validateIf.default)({
        validate: (0, _validators.validatePresence)({
          presence: true,
          message: surveyMessage
        }),
        if: {
          ctaType: _constants.EMAIL_CAMPAIGN_CTA_TYPE_SURVEY
        },
        firstErrorMessage: true
      })]
    };
    let optionalValidations = {};
    if (isCtaTextRequired) {
      optionalValidations = {
        ctaText: (0, _validateIf.default)({
          validate: (0, _validators.validatePresence)({
            presence: true,
            message: presenceMessage
          }),
          if: (key, newValue, oldValue, changes, content) => {
            const value = changes.ctaType || content.ctaType;
            return value;
          },
          firstErrorMessage: true
        })
      };
    }
    return Object.assign(defaultValidations, optionalValidations);
  };
  const voucherValidation = intl => {
    const numberMessage = intl.t('errors.positiveNumber');
    const maxLengthMessage = intl.t('errors.maxLength', {
      maxlength: 200
    });
    return {
      amount: (0, _validators.validateNumber)({
        positive: true,
        gt: 0,
        message: numberMessage
      }),
      greetingText: (0, _validators.validateLength)({
        max: 200,
        message: maxLengthMessage
      })
    };
  };
  _exports.voucherValidation = voucherValidation;
  const emailSeriesValidation = (intl, isLabelRequired) => {
    const positiveInteger = intl.t('errors.positiveInteger');
    const presenceMessage = intl.t('errors.required');
    if (!isLabelRequired) {
      return {
        offsetInDays: [(0, _numeral.validateNumeral)({
          integer: true,
          positive: true,
          allowBlank: true,
          message: positiveInteger
        })]
      };
    }
    return {
      label: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      offsetInDays: [(0, _numeral.validateNumeral)({
        integer: true,
        positive: true,
        allowBlank: true,
        message: positiveInteger
      })]
    };
  };
  _exports.emailSeriesValidation = emailSeriesValidation;
  const testEmailValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const emailMessage = intl.t('errors.email');
    return {
      email: (0, _testEmailValidator.multipleEmailsValidator)({
        presence: true,
        presenceMessage: presenceMessage,
        emailMessage: emailMessage
      })
    };
  };
  _exports.testEmailValidation = testEmailValidation;
});