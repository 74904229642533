define("additive-mc/mirage/routes/campaigns/social", ["exports", "additive-mc/mirage/routes/helpers/response", "faker"], function (_exports, _response, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SocialRoutes = void 0;
  const SOCIAL_CAMPAIGNS = ['facebookPosts'];
  const SocialRoutes = self => {
    self.get('/organisation-campaigns/social', _ref => {
      let {
        socialCampaigns
      } = _ref;
      const campaigns = socialCampaigns.all();
      return {
        socialCampaigns: campaigns.models,
        meta: {
          total: campaigns.models.length
        }
      };
    });
    self.get('/organisation-campaigns/social/:id', (_ref2, request) => {
      let {
        socialCampaigns
      } = _ref2;
      const {
        id
      } = request.params;
      return socialCampaigns.find(id);
    });
    self.get('/organisation-campaigns/social/available', () => {
      return SOCIAL_CAMPAIGNS;
    });
    self.del('/organisation-campaigns/social/:id', (_ref3, request) => {
      let {
        socialCampaigns
      } = _ref3;
      const {
        id
      } = request.params;
      socialCampaigns.find(id).destroy();
    });
    self.post('/organisation-campaigns/social', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      const {
        title,
        campaignCode
      } = body;
      return schema.socialCampaigns.create({
        title,
        campaignCode
      });
    });
    self.put('/organisation-campaigns/social/:id', (_ref4, request) => {
      let {
        socialCampaigns
      } = _ref4;
      /* Randomly fail with 422 to be able to test task. Can be removed after done (https://app.clubhouse.io/additivapps/story/11050/beim-speichern-und-aktivieren-einer-kampagnen-konfiguration-sollte-die-fehlermeldung-der-api-angezeigt-werden) */
      if (_faker.default.random.boolean()) {
        return new Response(422, {}, {
          errors: [{
            custom: 'bla bla'
          }],
          message: 'The given data was invalid'
        });
      }
      const {
        id
      } = request.params;
      const body = JSON.parse(request.requestBody);
      return socialCampaigns.find(id).update(body);
    });
    self.put('/organisation-campaigns/social/:id/activate', (schema, request) => {
      try {
        const model = socialCampaignOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/social/:id/deactivate', (schema, request) => {
      try {
        const model = socialCampaignOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
  };
  _exports.SocialRoutes = SocialRoutes;
  const socialCampaignOrFail = (schema, request) => {
    const {
      id
    } = request.params;
    const model = schema.socialCampaigns.find(id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
});