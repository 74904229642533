define("additive-mc/routes/instance/campaigns/ad/lead-ads/lead-ad/edit", ["exports", "@ember/routing/route", "@additive-apps/ui/mixins/ui-full-width-view"], function (_exports, _route, _uiFullWidthView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceCampaignsAdLeadAdsLeadAdEditRoute extends _route.default.extend(_uiFullWidthView.default) {
    model() {
      return super.model(...arguments);
    }
    setupController(controller) {
      super.setupController(...arguments);
      const {
        campaign,
        meta
      } = this.modelFor('instance.campaigns.ad.lead-ads');
      controller.campaignId = campaign.id;
      controller.meta = meta;
      controller.setup();
    }
    resetController(controller) {
      controller.changeset = null;
      controller.adCreativeChangeset = null;
      controller.budgetChangeset = null;
    }
    renderTemplate() {
      this.render('instance/campaigns/ad/lead-ads/lead-ad/edit', {
        into: 'application'
      });
    }
  }
  _exports.default = InstanceCampaignsAdLeadAdsLeadAdEditRoute;
});