define("additive-mc/templates/components/amc-campaign/configuration-item/with-modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "7bD7EquU",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@isSimpleDialog\",\"@onClose\",\"@modalClassNames\"],[[30,0,[\"item\",\"label\"]],true,[28,[37,1],[[30,0],\"onClose\"],null],\"with-modal\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],[[16,\"data-test-with-modal-content\",true]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"pt1\"],[12],[1,\"\\n        \"],[18,3,[[28,[37,3],null,[[\"changeset\",\"isLoading\",\"content\"],[[30,0,[\"_changeset\"]],[30,0,[\"save\",\"isRunning\"]],[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[16,\"data-test-with-modal-save\",true]],[[\"@label\",\"@isSecondary\",\"@isDisabled\",\"@onClick\"],[[28,[37,6],[\"global.actions.save\"],null],true,[30,0,[\"isReadOnly\"]],[28,[37,7],[[30,0,[\"save\"]]],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,8],null,[[\"@isOpen\",\"@onDiscard\"],[[30,0,[\"_isDiscardChangesDialog\"]],[28,[37,1],[[30,0],\"confirmDiscard\"],null]]],null]],[\"m\",\"c\",\"&default\"],false,[\"ui-modal\",\"action\",\"yield\",\"hash\",\"component\",\"ui-button\",\"t\",\"perform\",\"ui-discard-changes\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/with-modal.hbs",
    "isStrictMode": false
  });
});