define("additive-mc/templates/components/amc-campaign/configuration-item/multiselect", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "GsWO6zzE",
    "block": "[[[8,[39,0],null,[[\"@onSave\",\"@onClose\",\"@childChangeset\",\"@item\",\"@isReadOnly\"],[[28,[37,1],[[30,0,[\"_save\"]]],null],[30,0,[\"onClose\"]],[30,0,[\"_multiselectChangeset\"]],[30,0,[\"item\"]],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@classNames\",\"@label\",\"@initialValue\",\"@selected\",\"@options\",\"@isReadOnly\",\"@isMultiSelect\",\"@objectKey\",\"@onChange\"],[\"w-100\",[30,0,[\"item\",\"label\"]],[30,0,[\"_multiselectChangeset\",\"options\"]],[30,0,[\"_multiselectChangeset\",\"options\"]],[30,1,[\"changeset\",\"allowedValues\"]],[30,0,[\"isReadOnly\"]],true,\"label\",[28,[37,3],[[30,0],\"onSelectChange\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"item\"]],null,[[\"@value\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"label\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"optionComponent\",\"option\"],false,[\"amc-campaign/configuration-item/with-modal\",\"perform\",\"ui-select\",\"action\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/multiselect.hbs",
    "isStrictMode": false
  });
});