define("additive-mc/components/amc-campaign/configuration-item", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    uiAppSettings: (0, _service.inject)(),
    classNames: ['amc-campaign__configuration-item', 'items-center bt b--black-10'],
    classNameBindings: ['hasNoAction:amc-campaign__configuration-item--has-no-action'],
    isToggable: _object.computed.equal('item.type', 'boolean'),
    hasNoAction: (0, _object.computed)('item.type', {
      get() {
        return this.item.type === 'string' || this.item.type === 'label';
      }
    }),
    /**
     * whether the configuration item is editable
     *
     * @property isEdit
     * @type {Boolean}
     * @default true
     */
    isEdit: true,
    model: null,
    /**
     * computed options used to format the configuration values
     *
     * @computed configurationValueOptions
     * @returns {Object}
     */
    configurationValueOptions: (0, _object.computed)('uiAppSettings.currency', {
      get() {
        return {
          currency: this.uiAppSettings.currency
        };
      }
    }),
    /**
     * the component which should be rendered
     *
     * @computed componentType
     * @returns {String}
     */
    componentType: (0, _object.computed)('item.type', {
      get() {
        let {
          item: {
            type
          }
        } = this;
        if (type === 'parametrized-string') {
          return 'text';
        }
        return type;
      }
    }),
    onSave() {}
  });
});