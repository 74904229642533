define("additive-mc/controllers/instance/campaigns/on-page/detail", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@additive-apps/utils/utils/errors", "ember-concurrency", "@glimmer/tracking"], function (_exports, _controller, _service, _object, _errors, _emberConcurrency, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsOnPageDetailController = _exports.default = (_dec = (0, _object.computed)('model.orderedConfigurations'), _dec2 = (0, _object.computed)('model.orderedWidgetConfigurations'), _dec3 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.model.save();
    } catch (error) {
      this.model.rollbackAttributes();
      if (error) {
        const errorDetail = (0, _errors.getAdapterError)(error);
        if (errorDetail.custom) {
          this.uiDialog.showAlert(this.intl.t('campaigns.configurations.errors.custom.title'), errorDetail === null || errorDetail === void 0 ? void 0 : errorDetail.custom);
          return null;
        }
      }
      this.uiDialog.showError();
    }
  }), _class = class InstanceCampaignsOnPageDetailController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "campaignId", _descriptor5, this);
      _initializerDefineProperty(this, "onConfigurationSave", _descriptor6, this);
    }
    get _configurationKeys() {
      var _this$model;
      const configurations = (_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.orderedConfigurations;
      return configurations && Object.keys(configurations) || [];
    }
    get _widgetConfigurationKeys() {
      var _this$model2;
      const configurations = (_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : _this$model2.orderedWidgetConfigurations;
      return configurations && Object.keys(configurations) || [];
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get _isIncentiveCampaign() {
      return this.model.type === 'OnPageIncentiveCampaign';
    }
    back() {
      this.router.transitionTo('instance.campaigns.on-page');
    }
    showDeleteDialog() {
      this.uiDialog.showAlert(this.intl.t('onPageCampaigns.onPageCampaign.deleteDialog.title'), this.intl.t('onPageCampaigns.onPageCampaign.deleteDialog.description'));
    }
    transitionToCreate() {
      this.router.transitionTo('instance.campaigns.on-page.detail.content.create');
    }
    goToVoucherDetail(id) {
      this.router.transitionTo('instance.campaigns.on-page.detail.voucher', id);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "campaignId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_configurationKeys", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_configurationKeys"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_widgetConfigurationKeys", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_widgetConfigurationKeys"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showDeleteDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showDeleteDialog"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onConfigurationSave", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToCreate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToCreate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToVoucherDetail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goToVoucherDetail"), _class.prototype), _class);
});