define("additive-mc/templates/components/amc-campaign/configuration-item/multilingual-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "MiyW78ns",
    "block": "[[[8,[39,0],null,[[\"@onClose\",\"@save\",\"@item\",\"@childChangeset\",\"@isReadOnly\"],[[30,0,[\"onClose\"]],[30,0,[\"_save\"]],[30,0,[\"item\"]],[30,0,[\"_changeset\"]],[28,[37,1],[[30,0,[\"isReadOnly\"]],[28,[37,2],[[30,0,[\"_changeset\",\"isValid\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex flex-column flex-grow-1\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@label\",\"@options\",\"@selected\",\"@onChange\"],[[28,[37,4],[\"components.amc-campaign-configuration.multilingual-link.labelLanguage\"],null],[30,0,[\"_organizationLanguages\"]],[28,[37,4],[[28,[37,5],[\"global.languages.\",[30,0,[\"_selectedLanguage\"]]],null]],null],[30,0,[\"selectLanguage\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"item\"]],null,[[\"@value\"],[[30,3,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,3,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[2,3]]]]],[1,\"\\n\\n      \"],[8,[39,6],[[16,\"data-test-config-item-multilingual-link-content\",true]],[[\"@label\",\"@value\",\"@isDisabled\",\"@errorMessage\"],[[28,[37,4],[\"components.amc-campaign-configuration.multilingual-link.labelText\"],null],[28,[37,7],[[30,0,[\"_changeset\"]],[30,0,[\"_selectedLanguage\"]]],null],[30,0,[\"_isDisabled\"]],[28,[37,7],[[28,[37,7],[[30,0,[\"_changeset\",\"error\"]],[30,0,[\"_selectedLanguage\"]]],null],\"validation\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"optionComponent\",\"language\"],false,[\"amc-campaign/configuration-item/with-modal\",\"or\",\"not\",\"ui-select\",\"t\",\"concat\",\"ui-input\",\"get\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/multilingual-link.hbs",
    "isStrictMode": false
  });
});