define("additive-mc/mirage/routes/widgets/corporate-design", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CorporateDesignRoutes = void 0;
  const CorporateDesignRoutes = self => {
    self.get('/corporate-design', _ref => {
      let {
        widgets
      } = _ref;
      return {
        corporateDesign: {
          typography: {
            p: {
              color: 'main',
              serif: true,
              fontSize: '20px',
              fontFamily: 'primary.light',
              lineHeight: '24px'
            },
            h1: {
              color: 'main',
              serif: true,
              fontSize: '48px',
              fontFamily: 'primary.light',
              lineHeight: '56px'
            },
            h2: {
              color: 'main',
              serif: true,
              fontSize: '40px',
              fontFamily: 'primary.bold',
              lineHeight: '48px'
            },
            h3: {
              color: 'main',
              serif: true,
              fontSize: '28px',
              fontFamily: 'primary.light',
              lineHeight: '28px'
            },
            label: {
              color: 'main',
              serif: true,
              fontSize: '11px',
              fontFamily: 'primary.light',
              lineHeight: '16px'
            },
            small: {
              color: 'main',
              serif: true,
              fontSize: '14px',
              fontFamily: 'primary.normal',
              lineHeight: '20px'
            },
            button: {
              color: 'accent',
              serif: true,
              fontSize: '14px',
              fontFamily: 'primary.normal',
              lineHeight: '20px'
            }
          },
          colors: {
            main: {
              color: '#3d3d3d',
              contrastColor: '#ffffff'
            },
            accent: {
              color: '#efefef',
              contrastColor: '#000'
            },
            ambient: {
              color: '#ffffff',
              contrastColor: '#111221'
            }
          },
          logoUrl: 'organizations/testhotel-post-com/corporate-design/logo-default',
          alternativeLogoUrl: 'organizations/testhotel-post-com/corporate-design/logo-alternative',
          fonts: {
            secondary: {
              id: '39038e15-9fe5-41bc-b3f2-8cd7cc6da909',
              fontFamily: 'Test',
              serif: true,
              normal: {
                id: 'a339cfcd-ab87-4431-913b-1305c8cfe67d',
                fontFamily: 'Test',
                weight: 400,
                url: 'https://staging.account.additive-apps.tech/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LXN0YWdpbmcuczMuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb20vb3JnYW5pemF0aW9ucy82OTA3L2ZvbnRzLzM5MDM4ZTE1LTlmZTUtNDFiYy1iM2YyLThjZDdjYzZkYTkwOS9ub3JtYWwub3RmP3RzPTE2MTc5NzQ0NzU=&ts=1617974475',
                mimeType: 'font/otf'
              },
              bold: {
                id: '2bf44987-e90e-4183-a84a-102b85131deb',
                fontFamily: 'Test',
                weight: 700,
                url: 'https://staging.account.additive-apps.tech/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LXN0YWdpbmcuczMuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb20vb3JnYW5pemF0aW9ucy82OTA3L2ZvbnRzLzM5MDM4ZTE1LTlmZTUtNDFiYy1iM2YyLThjZDdjYzZkYTkwOS9ib2xkLm90Zj90cz0xNjE3OTc0NDc1&ts=1617974475',
                mimeType: 'font/otf'
              }
            },
            primary: {
              id: '0de530c8-7fc2-4046-816d-5e60654671b8',
              fontFamily: 'museo-sans',
              serif: true,
              light: {
                id: 'b3aae535-81cc-482e-9fd7-18ededd6bc8a',
                fontFamily: 'museo-sans',
                weight: 200,
                url: 'https://staging.account.additive-apps.tech/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LXN0YWdpbmcuczMuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb20vb3JnYW5pemF0aW9ucy82OTA3L2ZvbnRzLzBkZTUzMGM4LTdmYzItNDA0Ni04MTZkLTVlNjA2NTQ2NzFiOC9saWdodC53b2ZmMj90cz0xNjE3OTc0NDc1&ts=1617974475',
                mimeType: 'font/woff2'
              },
              normal: {
                id: '73f540be-06d6-46ba-a4ec-34a27ceaa803',
                fontFamily: 'museo-sans',
                weight: 400,
                url: 'https://staging.account.additive-apps.tech/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LXN0YWdpbmcuczMuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb20vb3JnYW5pemF0aW9ucy82OTA3L2ZvbnRzLzBkZTUzMGM4LTdmYzItNDA0Ni04MTZkLTVlNjA2NTQ2NzFiOC9ub3JtYWwub3RmP3RzPTE2MTc5NzQ0NzU=&ts=1617974475',
                mimeType: 'font/otf'
              },
              bold: {
                id: '7fc5e51b-40df-4051-8079-48971164747e',
                fontFamily: 'museo-sans',
                weight: 700,
                url: 'https://staging.account.additive-apps.tech/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LXN0YWdpbmcuczMuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb20vb3JnYW5pemF0aW9ucy82OTA3L2ZvbnRzLzBkZTUzMGM4LTdmYzItNDA0Ni04MTZkLTVlNjA2NTQ2NzFiOC9ib2xkLm90Zj90cz0xNjE3OTc0NDc1&ts=1617974475',
                mimeType: 'font/otf'
              }
            }
          }
        }
      };
    });
  };
  _exports.CorporateDesignRoutes = CorporateDesignRoutes;
});