define("additive-mc/helpers/amc-not", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Returns true when first argument does not match second
   */
  var _default = _exports.default = (0, _helper.helper)(function not(params /*, hash*/) {
    return params[0] !== params[1];
  });
});