define("additive-mc/components/amc-campaign/configuration-item/multilingual-texts", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/string", "@ember/utils", "ember-concurrency", "rsvp"], function (_exports, _component, _object, _service, _string, _utils, _emberConcurrency, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which handles campaigns configuration-item for `multilingual-texts`.
   *
   * @class amc-campaigns/configuration-item/multilingual-texts
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    tagName: '',
    /**
     * The configuration-item
     *
     * @property item
     * @default null
     */
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * The value of the configuration item
     *
     * @property _resource
     * @default null
     * @private
     */
    _resource: null,
    /**
     * The currently selected language
     *
     * @property _selectedLanguage
     * @default undefined
     * @private
     */
    _selectedLanguage: null,
    /**
     * The currently selected text variant
     *
     * @property _selectedTextVariant
     * @default null
     * @private
     */
    _selectedTextVariant: null,
    /**
     * Whether text variant is being edited
     *
     * @property _isEditMode
     * @default false
     * @private
     */
    _isEditMode: false,
    /**
     * All activated languages for the current organization
     *
     * @computed _organizationLanguages
     * @default null
     * @private
     */
    _organizationLanguages: _object.computed.alias('uiAppSettings.languages.contentLanguages'),
    /**
     * Available text variants
     *
     * @computed _textVariants
     * @default null
     * @private
     */
    _textVariants: (0, _object.computed)('_organizationLanguages', '_resource', {
      get() {
        const resource = this._resource || [];
        const textVariantName = this.intl.t('components.amc-campaign-configuration.multilingual-texts.textVariant');
        let textVariants = [];
        resource.forEach((_textVariant, index) => {
          let textVariant = {
            label: `${textVariantName} ${index + 1}`,
            description: (0, _string.htmlSafe)(this._organizationLanguages.map(language => {
              const color = _textVariant[language] ? 'green' : 'red';
              return `<span class="font-sm pr1 ${color}">${language.toUpperCase()}</span>`;
            }).join('')),
            value: _textVariant,
            index
          };
          textVariants.push(textVariant);
        });
        return textVariants;
      }
    }),
    /**
     * Close event
     *
     * @function onClose
     * @return {Promise} Returns a promise
     * @public
     */
    onClose() {},
    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {},
    init() {
      this._super(...arguments);
      const resource = this.item && this.item.value || [];
      (0, _object.set)(this, '_resource', resource);
    },
    /**
     * Save the model
     *
     * @function _save
     * @type {Task}
     * @private
     */
    _save: (0, _emberConcurrency.task)(function* () {
      let copy = Object.assign({}, this.item);
      try {
        const resource = this._resource;
        if (!resource) {
          throw new Error();
        }
        (0, _object.set)(this, 'item.value', resource);
        yield (0, _emberConcurrency.all)([this.onSave(), (0, _emberConcurrency.timeout)(400)]);
        (0, _object.setProperties)(this, {
          _isEditMode: false,
          _selectedTextVariant: null
        });
        return (0, _rsvp.resolve)();
      } catch (error) {
        // reset
        (0, _object.set)(this, 'item', copy);
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }),
    _deleteTextVariant: (0, _emberConcurrency.task)(function* (textVariant) {
      const resource = this._resource;
      if (resource && (0, _utils.isPresent)(textVariant.index)) {
        resource.splice(textVariant.index, 1);
        (0, _object.set)(this, '_resource', [...resource]);
        yield this._save.perform();
      }
    }),
    actions: {
      addTextVariant() {
        (0, _object.setProperties)(this, {
          _selectedTextVariant: {
            label: this.intl.t('components.amc-campaign-configuration.multilingual-texts.newTextVariant'),
            value: {}
          },
          _isEditMode: true
        });
      },
      editTextVariant(textVariant) {
        (0, _object.setProperties)(this, {
          _selectedTextVariant: textVariant,
          _isEditMode: true
        });
      },
      saveTextVariant() {
        try {
          const selectedTextVariant = this._selectedTextVariant;
          const resource = this._resource;
          if (!selectedTextVariant || !resource) {
            throw new Error();
          }

          // check if saved text variant has texts
          let hasTexts = false;
          Object.keys(selectedTextVariant.value).forEach(language => {
            if ((0, _utils.isPresent)(selectedTextVariant.value[language])) {
              hasTexts = true;
            }
          });

          // if text variant is saved without texts, show confirm dialog to delete it
          if (!hasTexts) {
            if ((0, _utils.isPresent)(selectedTextVariant.index)) {
              this.send('showConfirmDeleteDialog', selectedTextVariant, true);
              return (0, _rsvp.resolve)();
            }
          }

          // if the text variant has an index it existed already, so we modify it
          if ((0, _utils.isPresent)(selectedTextVariant.index)) {
            resource[selectedTextVariant.index] = selectedTextVariant.value;
          } else {
            // else it's a new text variant and we can push it to the array
            resource.push(selectedTextVariant.value);
          }
          (0, _object.set)(this, '_resource', [...resource]);
          return this._save.perform();
        } catch (error) {
          this.uiToast.showToast({
            title: this.intl.t('toast.unexpectedError'),
            type: 'error'
          });
        }
      },
      showConfirmDeleteDialog(textVariant) {
        let isEmpty = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        const translationPath = isEmpty ? 'deleteEmptyTextVariant' : 'deleteTextVariant';
        const intl = this.intl;
        this.uiDialog.showConfirm(intl.t(`components.amc-campaign-configuration.multilingual-texts.${translationPath}.title`), (0, _string.htmlSafe)(intl.t(`components.amc-campaign-configuration.multilingual-texts.${translationPath}.description`)), () => {
          this._deleteTextVariant.perform(textVariant);
        }, intl.t('global.actions.delete'));
      }
    }
  });
});