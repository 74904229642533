define("additive-mc/mirage/routes/helpers/response", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notFoundResponse = void 0;
  const notFoundResponse = _exports.notFoundResponse = function notFoundResponse() {
    let body = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let headers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return (0, _emberCliMirage.Response)(404, headers, body);
  };
});