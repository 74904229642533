define("additive-mc/helpers/amc-or", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Returns true when at leats one value of params array is a truthy value
   */
  var _default = _exports.default = (0, _helper.helper)(function or(params /*, hash*/) {
    return (params || []).some(value => {
      return !!value;
    });
  });
});