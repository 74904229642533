define("additive-mc/serializers/on-page-content", ["exports", "additive-mc/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    keyForAttribute(key) {
      if (key === 'campaignId') {
        return 'organisationCampaignId';
      }
      return this._super(...arguments);
    }
  });
});