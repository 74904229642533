define("additive-mc/mirage/factories/on-page-content", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    availableLanguages() {
      const randomNumber = _faker.default.random.number(10);
      if (randomNumber > 5) {
        return ['de', 'it', 'en'];
      }
      return ['de'];
    },
    active() {
      return _faker.default.random.boolean();
    },
    headline() {
      return _faker.default.lorem.words();
    },
    text() {
      return _faker.default.lorem.text();
    },
    ctaText() {
      return 'Gutschein jetzt freischalten';
    },
    form() {
      return {
        "includeSalutation": true
      };
    },
    afterCreate(onPageContent, server) {
      const image = server.create('image');
      onPageContent.update({
        image: image.toJSON(),
        language: _faker.default.random.arrayElement(onPageContent.availableLanguages)
      });
    }
  });
});