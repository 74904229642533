define("additive-mc/templates/instance/campaigns/trigger-email/detail/email-series", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Q0YkKsVe",
    "block": "[[[10,0],[14,0,\"font-lg primary pb4\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"triggerEmailCampaigns.emailSeries.title\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"w-100\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@isReadOnly\",\"@campaignId\",\"@campaignType\"],[[30,0,[\"isReadOnly\"]],[30,0,[\"campaignId\"]],\"trigger-email\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[46,[28,[37,3],null,null],null,null,null]],[],false,[\"t\",\"amc-campaign/email-campaign/email-series-table\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-mc/templates/instance/campaigns/trigger-email/detail/email-series.hbs",
    "isStrictMode": false
  });
});