define("additive-mc/templates/components/ui-create-content-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "nSWoYY5D",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@modalClassNames\",\"@isSimpleDialog\",\"@onClose\"],[[30,0,[\"title\"]],\"create-dialog\",true,[28,[37,1],[[30,0],[30,0,[\"onClose\"]]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,[[\"@classNames\"],[\"pb1 ph0\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"w-100 ph1 pt1\"],[12],[1,\"\\n        \"],[18,3,[[28,[37,3],null,[[\"body\"],[[50,\"ui-blank-template\",0,null,null]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]]],[\"m\",\"c\",\"&default\"],false,[\"ui-modal\",\"action\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "additive-mc/templates/components/ui-create-content-dialog.hbs",
    "isStrictMode": false
  });
});