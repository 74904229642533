define("additive-mc/templates/components/amc-campaign/configuration-item/time", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ab2GzQQL",
    "block": "[[[8,[39,0],null,[[\"@beforeSave\",\"@onSave\",\"@onClose\",\"@item\",\"@isReadOnly\"],[[28,[37,1],[[30,0],\"beforeSave\"],null],[28,[37,2],[[30,0,[\"_save\"]]],null],[30,0,[\"onClose\"]],[30,0,[\"item\"]],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"isReadOnly\"]],[[[1,\"      \"],[8,[39,4],null,[[\"@label\",\"@value\",\"@isDisabled\"],[[28,[37,5],[\"components.amc-campaign-configuration.time.label\"],null],[30,0,[\"item\",\"value\"]],true]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,6],null,[[\"@allowEmptyValue\",\"@label\",\"@minTime\",\"@maxTime\",\"@initialValue\",\"@onChange\",\"@onValidStateChange\"],[true,[28,[37,5],[\"components.amc-campaign-configuration.time.label\"],null],\"00:00\",\"23:59\",[30,0,[\"item\",\"value\"]],[28,[37,1],[[30,0],\"onTimePickerChange\",[30,1,[\"changeset\"]]],null],[28,[37,1],[[30,0],[28,[37,7],[[30,0,[\"_contentIsValid\"]]],null]],null]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\"],false,[\"amc-campaign/configuration-item/with-modal\",\"action\",\"perform\",\"if\",\"ui-input\",\"t\",\"ui-timepicker\",\"mut\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/time.hbs",
    "isStrictMode": false
  });
});