define("additive-mc/utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.onboardingData = _exports.UPGRADE_ENQUIRY_URL = _exports.TRIGGER_EMAIL_CAMPAIGN_TYPES = _exports.MINIMUM_CALENDAR_DATE = _exports.LOCAL_STORAGE_FILTER_KEY = _exports.LAYOUT_PORTRAIT = _exports.LAYOUT_LANDSCAPE = _exports.GOOGLE_SEARCH_ADS_SITE_LINK = _exports.GOOGLE_SEARCH_ADS_ADDITIONAL_INFORMATION = _exports.EMAIL_CAMPAIGN_TYPES = _exports.EMAIL_CAMPAIGN_CTA_TYPE_VOUCHER = _exports.EMAIL_CAMPAIGN_CTA_TYPE_SURVEY = _exports.EMAIL_CAMPAIGN_CTA_TYPE_LANDINGPAGE = _exports.EMAIL_CAMPAIGN_CTA_TYPE_CTA = _exports.CHART_COLORS = void 0;
  const LOCAL_STORAGE_FILTER_KEY = _exports.LOCAL_STORAGE_FILTER_KEY = 'filter-storage';
  const TRIGGER_EMAIL_CAMPAIGN_TYPES = _exports.TRIGGER_EMAIL_CAMPAIGN_TYPES = {
    starter: [{
      type: 'UserBasedLeadCampaign',
      icon: 'lead'
    }, {
      type: 'UserBasedTriggerCampaign',
      icon: 'bolt',
      upgradeRequired: true
    }],
    enterprise: [{
      type: 'UserBasedTriggerCampaign',
      icon: 'bolt'
    }, {
      type: 'UserBasedLeadCampaign',
      icon: 'lead'
    }]
  };
  const EMAIL_CAMPAIGN_TYPES = _exports.EMAIL_CAMPAIGN_TYPES = {
    usecase1: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage', 'configuration']
    },
    usecase2a: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage']
    },
    usecase2b: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage']
    },
    usecase3a: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage', 'configuration']
    },
    usecase3b: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage', 'configuration']
    },
    usecase4: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage', 'configuration']
    },
    usecase5: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage']
    },
    usecase6: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage']
    },
    usecase7a: {
      steps: ['trigger', 'emails', 'landingpage', 'configuration']
    },
    usecase7b: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage', 'configuration']
    },
    usecase7c: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage', 'configuration']
    },
    usecase9a: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage', 'configuration']
    },
    usecase11: {
      steps: ['trigger', 'emails', 'voucher', 'configuration']
    },
    usecase12: {
      steps: ['trigger', 'emails', 'voucher', 'landingpage']
    }
  };
  const EMAIL_CAMPAIGN_CTA_TYPE_VOUCHER = _exports.EMAIL_CAMPAIGN_CTA_TYPE_VOUCHER = 'voucher';
  const EMAIL_CAMPAIGN_CTA_TYPE_CTA = _exports.EMAIL_CAMPAIGN_CTA_TYPE_CTA = 'cta';
  const EMAIL_CAMPAIGN_CTA_TYPE_LANDINGPAGE = _exports.EMAIL_CAMPAIGN_CTA_TYPE_LANDINGPAGE = 'landingpage';
  const EMAIL_CAMPAIGN_CTA_TYPE_SURVEY = _exports.EMAIL_CAMPAIGN_CTA_TYPE_SURVEY = 'survey';

  /* Google Search Ads */
  const GOOGLE_SEARCH_ADS_SITE_LINK = _exports.GOOGLE_SEARCH_ADS_SITE_LINK = 'Google::SearchAds::Extension::SiteLink';
  const GOOGLE_SEARCH_ADS_ADDITIONAL_INFORMATION = _exports.GOOGLE_SEARCH_ADS_ADDITIONAL_INFORMATION = 'Google::SearchAds::Extension::AdditionalInformation';

  /* template layouts */
  const LAYOUT_LANDSCAPE = _exports.LAYOUT_LANDSCAPE = 'landscape';
  const LAYOUT_PORTRAIT = _exports.LAYOUT_PORTRAIT = 'portrait';
  const CHART_COLORS = _exports.CHART_COLORS = [{
    borderColor: '#4D76F1',
    backgroundColor: '#4D76F1',
    pointBorderColor: 'white'
  }, {
    borderColor: '#00172f',
    backgroundColor: '#00172f',
    pointBorderColor: 'white'
  }, {
    borderColor: '#8f9ea4',
    backgroundColor: '#8f9ea4',
    pointBorderColor: 'white'
  }];
  const MINIMUM_CALENDAR_DATE = _exports.MINIMUM_CALENDAR_DATE = new Date(2017, 0, 1);
  const UPGRADE_ENQUIRY_URL = _exports.UPGRADE_ENQUIRY_URL = 'https://www.additive-apps.eu/apps/marketing-automation.html?utm_source=additive-ma&utm_campaign=upgrade-button&utm-medium=app#form-overlay';
  const onboardingData = intl => {
    return {
      1: [{
        title: intl.t('onboarding.1.title'),
        description: intl.t('onboarding.1.description'),
        illustration: 'marketing-cloud',
        isFirstStep: true
      }, {
        title: intl.t('onboarding.2.title'),
        description: intl.t('onboarding.2.description'),
        illustration: 'step-2',
        theme: 'dark'
      }, {
        title: intl.t('onboarding.3.title'),
        description: intl.t('onboarding.3.description'),
        illustration: 'step-3',
        theme: 'blue'
      }, {
        title: intl.t('onboarding.4.title'),
        description: intl.t('onboarding.4.description'),
        illustration: 'step-4',
        isLastStep: true
      }]
    };
  };
  _exports.onboardingData = onboardingData;
});