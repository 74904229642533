define("additive-mc/validators/test-email-validator", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.multipleEmailsValidator = multipleEmailsValidator;
  /**
   *  @module Validators
   */

  /**
   * Validates multiple emails in an array
   *
   * @function multipleEmailsValidator
   *
   * @param {Any} value
   * @param {Object} options
   * @param {String} options.message Error message
   *
   * @return {Boolean|ValidationErr}
   */
  function multipleEmailsValidator() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const presenceMessage = (options === null || options === void 0 ? void 0 : options.presenceMessage) || false;
    const emailMessage = (options === null || options === void 0 ? void 0 : options.emailMessage) || false;
    return (key, value) => {
      let isPresent = true;
      let isValid = true;
      const placeholders = value;
      if (!value.length) {
        isPresent = false;
      }
      const validation = (0, _validators.validateFormat)({
        type: 'email',
        message: emailMessage
      });
      placeholders === null || placeholders === void 0 || placeholders.forEach(placeholder => {
        if (validation(key, placeholder) !== true) {
          isValid = false;
        }
      });
      return !isPresent ? presenceMessage : isValid || emailMessage;
    };
  }
});