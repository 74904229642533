define("additive-mc/classes/query-parameters", ["exports", "@additive-apps/ui/classes/QueryParameters"], function (_exports, _QueryParameters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _QueryParameters.default;
    }
  });
});