define("additive-mc/components/ui-faq-dialog", ["exports", "@additive-apps/ui/components/ui-faq-dialog"], function (_exports, _uiFaqDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiFaqDialog.default;
    }
  });
});