define("additive-mc/adapters/subscriber", ["exports", "additive-mc/adapters/email-preview", "@ember/service"], function (_exports, _emailPreview, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emailPreview.default.extend({
    router: (0, _service.inject)(),
    _urlForMultiple(modelName, snapshot) {
      let url = '';
      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.previewId) {
        url = this.buildURL('email-preview', snapshot.adapterOptions.previewId) + '/subscribers';
      } else {
        url = this._super(...arguments);
      }
      return url;
    },
    urlForFindAll() {
      return this._urlForMultiple(...arguments);
    },
    // TODO: find a way to get id without router
    urlForQuery() {
      const routeParams = this.router && this.router.currentRoute && this.router.currentRoute.params;
      const emailId = routeParams && routeParams['email_id'];
      return this.buildURL('email-preview', emailId) + '/subscribers';
    }
  });
});