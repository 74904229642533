define("additive-mc/mirage/factories/advertisement", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    label: () => _faker.default.lorem.word(),
    fbPixel: () => 'pixel',
    budget: {
      type: () => _faker.default.random.arrayElement(['daily', 'lifetime']),
      amount: () => _faker.default.random.number(5000)
    },
    budgetSpend: {
      value: () => _faker.default.random.number(5000),
      percentage: () => Math.floor(Math.random() * 100) + 1
    },
    targetAudience: () => _faker.default.random.number(500000000),
    endDate: () => _faker.default.date.between('2020-01-05', '2020-12-05'),
    startDate: () => _faker.default.date.between('2020-01-01', '2020-01-05'),
    state: () => _faker.default.random.arrayElement(['published', 'expired', 'unpublished', 'archived', 'paused']),
    campaignPostfix: () => _faker.default.lorem.word(),
    type: 'ad',
    leadAd: (0, _emberCliMirage.trait)({
      type: 'lead-ad',
      language: () => _faker.default.random.arrayElement(['de', 'it', 'en']),
      adCreative: {
        cta: 'SUBSCRIBE',
        title: 'My Awesome Title',
        headline: 'My Awesome Headline',
        text: 'M SuperNice Text 👨‍🚒',
        form: {
          headline: 'lorem',
          text: 'ipsum'
        },
        multimediaVariants: [{
          mediumId: '2af74266-43f0-4357-9955-5a11964d5cd7',
          name: 'ausblick.jpeg',
          provider: 'imbo',
          remoteId: '9ab4116e450a286882fda4f7e7779202',
          type: 'image',
          url: 'https://staging.multimedia.additive-apps.tech/media/9ab4116e450a286882fda4f7e7779202'
        }, {
          mediumId: '2af74266-43f0-4357-9955-5a11964d5cd7',
          name: 'ausblick.jpeg',
          provider: 'imbo',
          remoteId: '9ab4116e450a286882fda4f7e7779202',
          type: 'image',
          url: 'https://staging.multimedia.additive-apps.tech/media/9ab4116e450a286882fda4f7e7779202'
        }, {
          mediumId: '2af74266-43f0-4357-9955-5a11964d5cd7',
          name: 'ausblick.jpeg',
          provider: 'imbo',
          remoteId: '9ab4116e450a286882fda4f7e7779202',
          type: 'image',
          url: 'https://staging.multimedia.additive-apps.tech/media/9ab4116e450a286882fda4f7e7779202'
        }]
      }
    }),
    catalogAd: (0, _emberCliMirage.trait)({
      type: 'catalog-ad',
      hasBeenPublished: _faker.default.random.boolean(),
      optimizationEvent: () => _faker.default.random.arrayElement([1, 2, 3, 4].map(i => `optimizationEvent-${i}`)),
      promotedProducts: () => {
        const products = [{
          value: 'offers',
          specifiedSelection: ['1', '2', '3', '4', '5']
        }, {
          value: 'rooms',
          specifiedSelection: ['1', '2', '3', '4', '5']
        }, {
          value: 'deals',
          specifiedSelection: ['1', '2', '3', '4', '5']
        }].map(value => ({
          value,
          sort: Math.random()
        })).sort((a, b) => a.sort - b.sort).map(_ref => {
          let {
            value
          } = _ref;
          return value;
        });
        const popCount = Math.floor(Math.random() * (products.length + 1));
        for (let i = 0; i < popCount; i++) {
          products.pop();
        }
        products.forEach(product => {
          const popSubtypesCount = Math.floor(Math.random() * (product.specifiedSelection.length + 1));
          for (let i = 0; i < popSubtypesCount; i++) {
            product.specifiedSelection.pop();
          }
        });
        return products;
      }
    }),
    facebookContentAd: (0, _emberCliMirage.trait)({
      type: 'content-ad',
      hasBeenPublished: _faker.default.random.boolean(),
      budget: {
        type: () => _faker.default.random.arrayElement(['daily', 'lifetime']),
        amount: () => _faker.default.random.number(5000)
      },
      optimizationEvent: () => _faker.default.random.arrayElement([1, 2, 3, 4].map(i => `optimizationEvent-${i}`)),
      depictions: () => [_faker.default.random.arrayElement(['1', '2', '3', '4', '5', '6'])],
      targetLanguages: () => {
        const randomNumber = _faker.default.random.number(10);
        if (randomNumber > 5) {
          return ['de', 'it', 'en'];
        }
        return ['de', 'it'];
      },
      language: 'de'
    }),
    tiktokLeadAd: (0, _emberCliMirage.trait)({
      type: 'tiktok-lead-ad',
      targetGroup: '1',
      identity: '1',
      hasBeenPublished() {
        if (this.state === 'published' || this.state === 'paused') {
          return true;
        }
        return false;
      },
      timeLimited: _faker.default.random.boolean(),
      timeLimitation: {
        days: () => [_faker.default.random.arrayElement(['monday', 'tuesday', 'wednesday'])],
        startTime: () => '00:00:00',
        endTime: () => '12:00:00'
      },
      budget: {
        type: () => _faker.default.random.arrayElement(['daily', 'lifetime']),
        amount: () => _faker.default.random.number(5000)
      },
      optimizationEvent: () => _faker.default.random.arrayElement([1, 2, 3, 4].map(i => `optimizationEvent-${i}`)),
      depictions: () => [_faker.default.random.arrayElement(['1', '2', '3', '4', '5', '6'])],
      targetLanguages: () => {
        const randomNumber = _faker.default.random.number(10);
        if (randomNumber > 5) {
          return ['de', 'it', 'en'];
        }
        return ['de', 'it'];
      },
      language: 'de'
    }),
    tiktokLeadAd: (0, _emberCliMirage.trait)({
      type: 'tiktok-lead-ad',
      targetGroup: '1',
      identity: '1',
      hasBeenPublished() {
        if (this.state === 'published' || this.state === 'paused') {
          return true;
        }
        return false;
      },
      timeLimited: _faker.default.random.boolean(),
      timeLimitation: {
        days: () => [_faker.default.random.arrayElement(['monday', 'tuesday', 'wednesday'])],
        startTime: () => '00:00:00',
        endTime: () => '12:00:00'
      },
      budget: {
        type: () => _faker.default.random.arrayElement(['daily', 'lifetime']),
        amount: () => _faker.default.random.number(5000)
      },
      optimizationEvent: () => _faker.default.random.arrayElement([1, 2, 3, 4].map(i => `optimizationEvent-${i}`)),
      depictions: () => [_faker.default.random.arrayElement(['1', '2', '3', '4', '5', '6'])],
      targetLanguages: () => {
        const randomNumber = _faker.default.random.number(10);
        if (randomNumber > 5) {
          return ['de', 'it', 'en'];
        }
        return ['de', 'it'];
      },
      language: 'de'
    }),
    tiktokLeadAd: (0, _emberCliMirage.trait)({
      type: 'tiktok-lead-ad',
      targetGroup: '1',
      identity: '1',
      hasBeenPublished() {
        if (this.state === 'published' || this.state === 'paused') {
          return true;
        }
        return false;
      },
      timeLimited: _faker.default.random.boolean(),
      timeLimitation: {
        days: () => [_faker.default.random.arrayElement(['monday', 'tuesday', 'wednesday'])],
        startTime: () => '00:00:00',
        endTime: () => '12:00:00'
      },
      budget: {
        type: () => _faker.default.random.arrayElement(['daily', 'lifetime']),
        amount: () => _faker.default.random.number(5000)
      },
      optimizationEvent: () => _faker.default.random.arrayElement([1, 2, 3, 4].map(i => `optimizationEvent-${i}`)),
      depictions: () => [_faker.default.random.arrayElement(['1', '2', '3', '4', '5', '6'])],
      targetLanguages: () => {
        const randomNumber = _faker.default.random.number(10);
        if (randomNumber > 5) {
          return ['de', 'it', 'en'];
        }
        return ['de', 'it'];
      },
      language: 'de'
    })
  });
});