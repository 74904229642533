define("additive-mc/controllers/instance/campaigns/referral/detail", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@additive-apps/utils/utils/errors"], function (_exports, _controller, _service, _object, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsReferralDetailController = _exports.default = (_class = class InstanceCampaignsReferralDetailController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _defineProperty(this, "campaignId", null);
    }
    get _configurationKeys() {
      var _this$model;
      const configurations = (_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.orderedConfigurations;
      return configurations && Object.keys(configurations) || [];
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get _isIncentiveCampaign() {
      var _this$model2;
      return ((_this$model2 = this.model) === null || _this$model2 === void 0 ? void 0 : _this$model2.type) === 'ReferralGiveAwayCampaign';
    }
    back() {
      this.router.transitionTo('instance.campaigns.referral');
    }
    showDeleteDialog() {
      this.uiDialog.showAlert(this.intl.t('referralCampaigns.referralCampaign.deleteDialog.title'), this.intl.t('referralCampaigns.referralCampaign.deleteDialog.description'));
    }
    onConfigurationSave() {
      const promise = this.model.save();
      promise.catch(error => {
        this.model.rollbackAttributes();
        if (error) {
          const errorDetail = (0, _errors.getAdapterError)(error);
          if (errorDetail.custom) {
            this.uiDialog.showAlert(this.intl.t('campaigns.configurations.errors.custom.title'), errorDetail === null || errorDetail === void 0 ? void 0 : errorDetail.custom);
            return null;
          }
        }
        this.uiDialog.showError();
      });
      return promise;
    }
    goToVoucherDetail(id) {
      this.router.transitionTo('instance.campaigns.referral.detail.voucher', id);
    }
    goToLandingpageDetail(id) {
      this.router.transitionTo('instance.campaigns.referral.detail.landingpage', id);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showDeleteDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showDeleteDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onConfigurationSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onConfigurationSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToVoucherDetail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goToVoucherDetail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToLandingpageDetail", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "goToLandingpageDetail"), _class.prototype), _class);
});