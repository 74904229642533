define("additive-mc/components/amc-dashboard/key-indicators", ["exports", "additive-mc/config/environment", "@additive-apps/ui/components/ui-property-watcher", "@ember/object", "ember-concurrency", "@ember/service", "@additive-apps/ui/utils/query-param-util"], function (_exports, _environment, _uiPropertyWatcher, _object, _emberConcurrency, _service, _queryParamUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This component fetches the data and displays the charts in the dashboard. The data of the charts
   * is updated everytime the organization slug changes.
   *
   * @class amc-dashboard/key-metrics
   */
  var _default = _exports.default = _uiPropertyWatcher.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    /**
     * the date range object
     *
     * @property _dateRange
     * @type {Object}
     */
    dateRange: null,
    /**
     * the current organization slug
     *
     * @property organizationSlug
     * @type {String}
     * @default null
     */
    organizationSlug: null,
    /**
     * AbortController for the current fetch
     *
     * @property _abortController
     * @type {AbortController}
     * @default null
     * @private
     */
    _abortController: null,
    init() {
      this._super(...arguments);
      (0, _object.setProperties)(this, {
        // property watcher properties
        _watchedProperties: ['dateRange', 'organizationSlug'],
        _onPropertyChange: () => this.keyIndicators.perform()
      });
    },
    /**
     * fetches the datasets for the reservations
     *
     * @type {Task}
     * @function reservationsChartData
     */
    keyIndicators: (0, _emberConcurrency.task)(function* () {
      this._isError = false;
      this._abortController && this._abortController.abort();
      const tasks = [];
      const baseUrl = _environment.default.APP.apiBaseHost;
      const {
        dateRange
      } = this;
      const qps = dateRange ? (0, _queryParamUtil.getQueryParams)(dateRange) : '';
      const chartsUrl = `${baseUrl}/${this.organizationSlug}/dashboard/key-indicators${qps}`;
      try {
        this._abortController = new AbortController();
        tasks.push(this.authenticatedFetch.fetch(chartsUrl, {
          signal: this._abortController.signal
        }, null, [500]));
        tasks.push((0, _emberConcurrency.timeout)(250));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        // chart-data
        const {
          valueAdded,
          reservations,
          conversions,
          sessions
        } = yield response.json();
        (0, _object.setProperties)(this, {
          valueAdded,
          reservations,
          conversions,
          sessions
        });
        yield (0, _emberConcurrency.timeout)(100);
      } catch (error) {
        this._isError = true;
      }
    }).restartable().on('init')
  });
});