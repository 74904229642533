define("additive-mc/adapters/keyword", ["exports", "additive-mc/adapters/organization-campaign"], function (_exports, _organizationCampaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class KeywordAdapter extends _organizationCampaign.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "type", 'google/search-ads');
    }
    handleResponse(status) {
      if (status >= 400 && status < 500) {
        const error = arguments[2];
        if (error) {
          return error;
        } else {
          return new Error(status);
        }
      }
      return super.handleResponse(...arguments);
    }
    urlForMultiple() {
      let url = this.buildURL() + `/organisation-campaigns/${this.type}`;
      return `${url}/keywords`;
    }
  }
  _exports.default = KeywordAdapter;
});