define("additive-mc/mirage/serializers/email-campaign", ["exports", "additive-mc/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    serialize(object, request) {
      // super call
      let json = _application.default.prototype.serialize.apply(this, arguments);

      // embed emails and emailSeries for new detail view, TODO: remove restriction for pre-stay-campaign
      if (request.params.id && object.type === 'pre-stay-campaign') {
        json.emailCampaign.emailSeries = object.emailSeries.models.map(emailSeries => {
          const emails = emailSeries.emails.models;
          emailSeries = Object.assign({}, emailSeries.attrs, {
            emails
          });
          return emailSeries;
        });
      }
      return json;
    }
  });
});