define("additive-mc/mirage/routes/campaigns/trigger-email", ["exports", "additive-mc/config/environment", "additive-mc/mirage/routes/helpers/response", "additive-mc/mirage/utils/constants"], function (_exports, _environment, _response, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TriggerEmailRoutes = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const TriggerEmailRoutes = self => {
    /* Email campaigns */
    self.get('/organisation-campaigns/trigger-email', schema => {
      const campaigns = schema.triggerEmailCampaigns.all();
      return {
        triggerEmailCampaigns: campaigns.models,
        meta: {
          total: campaigns.models.length
        }
      };
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/trigger-email-campaigns`, schema => {
      const campaigns = schema.triggerEmailCampaigns.all();
      return {
        campaigns: campaigns.models,
        meta: {
          total: campaigns.models.length
        }
      };
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/trigger-email-campaigns/campaign-types`, schema => {
      const existingCampaigns = schema.emailCampaigns.all().models;
      const allCampaignTypes = _constants.TRIGGER_EMAIL_CAMPAIGN_TYPES.map(campaignType => {
        const existingCampaign = existingCampaigns.find(ct => ct.type === campaignType.type);
        return _objectSpread(_objectSpread({}, campaignType), {}, {
          creatableChecks: existingCampaign ? [{
            key: 'campaign_type_already_created'
          }] : []
        });
      });
      return {
        campaignTypes: allCampaignTypes
      };
    });
    self.post('/organisation-campaigns/trigger-email', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      const {
        title,
        name,
        code
      } = body;
      return schema.triggerEmailCampaigns.create({
        title,
        name,
        code,
        active: false
      });
    });

    /* Email campaign */
    self.get('/organisation-campaigns/trigger-email/:id', (schema, request) => {
      try {
        return emailCampaignOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/trigger-email-campaigns/:id`, (schema, request) => {
      try {
        return emailCampaignOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/trigger-email/:id');
    self.put('/organisation-campaigns/trigger-email/:id', (schema, request) => {
      try {
        const model = emailCampaignOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return model.update(attrs);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get('/organisation-campaigns/trigger-email/:id/meta', (schema, request) => {
      return {
        meta: {
          surveys: [{
            label: 'Deutsche Umfrage 🇩🇪',
            value: '42e5b312-1060-4d18-b873-d4b408b2f37d'
          }],
          ctaTypes: [{
            label: 'Kein Button',
            value: null
          }, {
            label: 'Click-to-action(CTA)',
            value: 'cta'
          }, {
            label: 'Landing Page',
            value: 'landingpage'
          }, {
            label: 'Umfrage',
            value: 'survey'
          }]
        }
      };
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/trigger-campaign/meta`, (schema, request) => {
      return {
        meta: {
          ctaTypes: [{
            label: 'No Button',
            value: null
          }, {
            label: 'Link',
            value: 'cta'
          }, {
            label: 'Referral Campaign',
            value: 'referral'
          }, {
            label: 'Landing Page',
            value: 'landingpage'
          }, {
            label: 'Voucher',
            value: 'voucher'
          }, {
            label: 'Survey',
            value: 'survey'
          }],
          contribution: {
            greeting: 'Viel Spaß!'
          },
          configurations: [{
            key: 'campaign_postfix',
            type: 'text',
            label: 'Campaign postfix',
            description: 'Campaign postfix description..'
          }, {
            key: 'disable_after_booking',
            type: 'boolean',
            label: 'Stop campaign sending if contact makes an inquiry or booking',
            description: 'Description..'
          }]
        }
      };
    });
    self.get(`${_environment.default.APP.apiBaseHostV2}/**/lead-campaigns`, (schema, request) => {
      return {
        campaigns: [{
          label: 'MA::facebook::video_upload_test_27_02::123',
          value: '123'
        }, {
          label: 'MA::facebook::Testing21.5.v19::testing.v19',
          value: '456'
        }, {
          label: 'On-Page Incentive Campaign::lol',
          value: 10921
        }, {
          label: 'On-Page Incentive Campaign::Test',
          value: 10920
        }]
      };
    });
    self.put('/organisation-campaigns/trigger-email/:id/activate', (schema, request) => {
      try {
        const model = emailCampaignOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/trigger-email/:id/deactivate', (schema, request) => {
      try {
        const model = emailCampaignOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.post(`${_environment.default.APP.apiBaseHostV2}/**/trigger-campaign/:id/test-emails`, (schema, request) => {
      return new Response(204);
    });

    /* Email campaign emails */
    self.get('/organisation-campaigns/trigger-email/:id/emails', (schema, request) => {
      try {
        const campaign = emailCampaignOrFail(schema, request);
        return campaign.emails;
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.post('/organisation-campaigns/trigger-email/:id/emails', (schema, request) => {
      try {
        const campaign = emailCampaignOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return schema.triggerEmails.create(Object.assign(attrs, {
          campaign
        }));
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });

    /* Email campaign email */
    self.get('/organisation-campaigns/trigger-email/:id/emails/:email_id', (schema, request) => {
      try {
        return emailOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/trigger-email/:id/emails/:email_id', (schema, request) => {
      try {
        const model = emailOrFail(schema, request);
        return model.destroy();
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/trigger-email/:id/emails/:email_id', (schema, request) => {
      try {
        const model = emailOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return model.update(attrs);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/trigger-email/:id/emails/:email_id/:language', (schema, request) => {
      try {
        const {
          language
        } = request.params;
        if (['de', 'en', 'it'].indexOf(language) === -1) {
          throw (0, _response.notFoundResponse)(`'${language}' not supported`);
        }
        const model = emailOrFail(schema, request);
        if (model.availableLanguages.indexOf(language) === -1) {
          return Response(400, {}, {
            message: `'${language}' does not exist`
          });
        }
        model.availableLanguages.splice(model.availableLanguages.indexOf(language), 1);
        return model;
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/trigger-email/:id/emails/:email_id/activate', (schema, request) => {
      try {
        const model = emailOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/trigger-email/:id/emails/:email_id/deactivate', (schema, request) => {
      try {
        const model = emailOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });

    /* Voucher */
    self.get('/organisation-campaigns/trigger-email/:id/vouchers/:voucher_id', (schema, request) => {
      try {
        return voucherOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/trigger-email/:id/vouchers/:voucher_id', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return model.update(Object.assign({
          image: null
        }, attrs));
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/trigger-email/:id/vouchers/:voucher_id/:language', (schema, request) => {
      try {
        const {
          language
        } = request.params;
        if (['de', 'en', 'it'].indexOf(language) === -1) {
          throw (0, _response.notFoundResponse)(`'${language}' not supported`);
        }
        const model = voucherOrFail(schema, request);
        if (model.availableLanguages.indexOf(language) === -1) {
          return Response(400, {}, {
            message: `'${language}' does not exist`
          });
        }
        model.availableLanguages.splice(model.availableLanguages.indexOf(language), 1);
        return model;
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/trigger-email/:id/vouchers/:voucher_id/activate', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/trigger-email/:id/vouchers/:voucher_id/deactivate', (schema, request) => {
      try {
        const model = voucherOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });

    /* Email-series */
    self.get('/organisation-campaigns/trigger-email/:id/email-series', (schema, request) => {
      try {
        return emailSeriesOrFail(schema, request);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/trigger-email/:id/email-series/:email_series_id', (schema, request) => {
      try {
        const model = emailSeriesOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        return model.update(attrs);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.post('/organisation-campaigns/trigger-email/:id/email-series', (schema, request) => {
      try {
        const model = emailSeriesOrFail(schema, request);
        const attrs = JSON.parse(request.requestBody);
        const {
          label,
          mailsCount,
          offsetInDays,
          state
        } = attrs;
        return schema.triggerEmailSeries.create({
          label,
          mailsCount,
          offsetInDays,
          state
        });
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/trigger-email/:id/email-series/:email_series_id/activate', (schema, request) => {
      try {
        const model = emailSeriesOrFail(schema, request);
        return model.update('state', 'active');
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/trigger-email/:id/email-series/:email_series_id/deactivate', (schema, request) => {
      try {
        const model = emailSeriesOrFail(schema, request);
        return model.update('state', 'inactive');
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.del('/organisation-campaigns/trigger-email/:id/email-series/:email_series_id');
  };
  _exports.TriggerEmailRoutes = TriggerEmailRoutes;
  const emailCampaignOrFail = (schema, request) => {
    const {
      id
    } = request.params;
    const model = schema.triggerEmailCampaigns.find(id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
  const emailOrFail = (schema, request) => {
    const campaign = emailCampaignOrFail(schema, request);
    const {
      email_id
    } = request.params;
    const model = schema.triggerEmails.find(email_id);
    if (!model || model.campaignId !== campaign.id) {
      throw new Error('not_found');
    }
    return model;
  };
  const voucherOrFail = (schema, request) => {
    const {
      voucher_id
    } = request.params;
    const model = schema.emailCampaignVouchers.find(voucher_id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
  const emailSeriesOrFail = (schema, request) => {
    const campaign = emailCampaignOrFail(schema, request);
    const {
      id
    } = request.params;
    const model = schema.triggerEmailSeries.findBy({
      campaignId: id
    });
    if (!model || model.campaignId !== campaign.id) {
      throw new Error('not_found');
    }
    return model;
  };
});