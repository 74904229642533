define("additive-mc/components/amc-campaign/configuration-item/money", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "ember-changeset", "ember-changeset-validations", "additive-mc/validations/campaign-configurations", "additive-mc/utils/currency-serialization", "ember-concurrency"], function (_exports, _component, _object, _computed, _service, _emberChangeset, _emberChangesetValidations, _campaignConfigurations, _currencySerialization, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which handles campaigns configuration-item for `money`.
   *
   * `money` is a decimal number
   *
   * @class amc-campaigns/configuration-item/money
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    tagName: '',
    /**
     * The configuration-item
     *
     * @property item
     * @default null
     */
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * The internal changeset of the item
     *
     * @property _moneyChangeset
     * @default null
     * @private
     */
    _moneyChangeset: null,
    /**
     * The underlying object of the changeset
     *
     * @property _resource
     * @default null
     * @private
     */
    _resource: null,
    /**
     * Whether is disabled
     *
     * @computed _isDisabled
     * @default false
     * @private
     */
    _isDisabled: (0, _computed.or)('isReadOnly', '_save.isRunning'),
    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {},
    init() {
      this._super(...arguments);
      let resource = {
        value: 0
      };
      if (this.item && this.item.value) {
        resource.value = (0, _currencySerialization.centsToAmount)(this.item.value);
      }
      const validation = (0, _campaignConfigurations.moneyValidation)(this.intl);
      const changeset = new _emberChangeset.default(resource, (0, _emberChangesetValidations.default)(validation), validation);
      (0, _object.setProperties)(this, {
        _moneyChangeset: changeset,
        _resource: resource
      });
    },
    /**
     * Convert value to cents, sets it to parents changeset and trigger save
     *
     * @function _save
     * @type {Task}
     * @param {Changeset} parentChangeset The changeset of the `with-modal` hoc
     * @private
     */
    _save: (0, _emberConcurrency.task)(function* (parentChangeset) {
      const tasks = [];
      const snapshot = this.item.value;
      try {
        yield this._moneyChangeset.validate();
        if (this._moneyChangeset.get('isInvalid')) {
          return;
        }

        // Will update the underlying object, which in this case is `this._resource`
        this._moneyChangeset.execute();
        parentChangeset.set('value', (0, _currencySerialization.amountToCents)(this._resource.value));
        parentChangeset.execute();
        tasks.push(this.onSave());
        tasks.push((0, _emberConcurrency.timeout)(250));
        yield (0, _emberConcurrency.all)(tasks);
      } catch (error) {
        // reset
        (0, _object.set)(this, 'item.value', snapshot);
      }
    })
  });
});