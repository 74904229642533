define("additive-mc/routes/instance/campaigns/ad/content-ads/depictions/index/depiction/edit", ["exports", "@ember/routing/route", "@ember/service", "@additive-apps/ui/mixins/ui-full-width-view"], function (_exports, _route, _service, _uiFullWidthView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsAdFacebookAdsIndexFacebookAdEditRoute = _exports.default = (_class = class InstanceCampaignsAdFacebookAdsIndexFacebookAdEditRoute extends _route.default.extend(_uiFullWidthView.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor, this);
    }
    model() {
      return super.model(...arguments);
    }
    async setupController(controller, model) {
      var _campaign$configurati;
      super.setupController(...arguments);
      const {
        campaign
      } = this.modelFor('instance.campaigns.ad.content-ads');
      if (model.language !== this.uiAppSettings.currentLocale) {
        await model.reload({
          adapterOptions: {
            campaignId: campaign.id,
            type: 'content-ads'
          }
        });
      }

      // eslint-disable-next-line ember/no-controller-access-in-routes
      const facebookAdsController = this.controllerFor('instance.campaigns.ad.content-ads');
      controller.getDepictionMeta = facebookAdsController.getDepictionMeta;
      controller.campaignId = campaign.id;
      const campaignGoal = ((_campaign$configurati = campaign.configurations) === null || _campaign$configurati === void 0 || (_campaign$configurati = _campaign$configurati.find(item => item.key === 'facebook-campaign-goal')) === null || _campaign$configurati === void 0 ? void 0 : _campaign$configurati.value) || null;
      controller.campaignGoal = campaignGoal;
      controller.setupChangeset.perform(this.uiAppSettings.currentLocale);
    }
    resetController(controller) {
      this.uiAppSettings.setLocale(this.uiAppSettings.languages.defaultLanguage);
      controller.changeset = null;
      controller.activeVariant = 0;
    }
    renderTemplate() {
      this.render('instance/campaigns/ad/content-ads/depictions/index/depiction/edit', {
        into: 'application'
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});