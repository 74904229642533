define("additive-mc/mirage/factories/legal", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    disclaimerUrl() {
      return {
        sync: _faker.default.random.boolean(),
        syncedValue: _faker.default.internet.url(),
        value: _faker.default.internet.url()
      };
    },
    privacyUrl() {
      return {
        sync: _faker.default.random.boolean(),
        syncedValue: _faker.default.internet.url(),
        value: _faker.default.internet.url()
      };
    },
    bookingConditions: () => _faker.default.lorem.text(),
    requestConditions: () => _faker.default.lorem.text()
  });
});