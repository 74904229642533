define("additive-mc/controllers/instance/services", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency", "@additive-apps/auth/utils/roles-map"], function (_exports, _controller, _object, _service, _tracking, _emberConcurrency, _rolesMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceServicesController = _exports.default = (_dec = (0, _emberConcurrency.task)(function* (service) {
    var _service$status;
    if ((service === null || service === void 0 || (_service$status = service.status) === null || _service$status === void 0 ? void 0 : _service$status.state) !== 'pending') {
      return;
    }

    // wait for 30 seconds between multiple fetches
    yield (0, _emberConcurrency.timeout)(30000);
    yield service.reload();
    if (service.status.state === 'pending') {
      this.updatePendingServices.perform(service);
    }
  }), _class = class InstanceServicesController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor4, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor5, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor6, this);
      _initializerDefineProperty(this, "uiToast", _descriptor7, this);
      /**
       * the current service
       *
       * @property activeService
       * @type {Model}
       * @default null
       */
      _initializerDefineProperty(this, "activeService", _descriptor8, this);
      /**
       * whether the service dialog is open
       *
       * @property isServiceDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isServiceDialog", _descriptor9, this);
      /**
       * continuously fetches a pending service
       *
       * @function updatePendingServices
       * @type {Function}
       */
      _initializerDefineProperty(this, "updatePendingServices", _descriptor10, this);
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get hasManagerAccess() {
      var _this$currentUser$use;
      return (0, _rolesMap.hasAccess)(_rolesMap.ROLE_MANAGER, (_this$currentUser$use = this.currentUser.user) === null || _this$currentUser$use === void 0 ? void 0 : _this$currentUser$use.role);
    }
    onCloseServiceDialog() {
      this.activeService = null;
      this.isServiceDialog = false;
    }
    async deleteService(model) {
      try {
        await model.destroyRecord();
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        model.rollbackAttributes();
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
    async modelAction(model, publishAction) {
      try {
        const response = await model[publishAction]();
        if (!(response !== null && response !== void 0 && response.ok)) {
          // show error dialog
          const {
            message
          } = await response.json();
          throw new Error(message);
        }
        const json = await response.json();
        this.store.pushPayload(json);
        const serviceModel = this.store.peekRecord('service', json.service.id);
        this.updatePendingServices.perform(serviceModel);
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        this.uiDialog.showError(null, e.message);
      }
    }
    onDelete(model) {
      this.uiDialog.showConfirm(this.intl.t('services.dialogs.delete.title'), this.intl.t('services.dialogs.delete.description', {
        htmlSafe: true
      }), () => this.deleteService(model), this.intl.t('global.actions.delete'), true, true);
    }
    onPublish(model) {
      let isPublished = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const publishAction = isPublished ? 'unpublish' : 'publish';
      this.uiDialog.showConfirm(this.intl.t(`services.dialogs.${publishAction}.title`), this.intl.t(`services.dialogs.${publishAction}.description`), () => this.modelAction(model, publishAction), this.intl.t(`global.actions.${publishAction}`), false);
    }
    onRename(model) {
      this.activeService = model;
      this.isServiceDialog = true;
    }
    openExternalUrl(externalUrl) {
      window.open(externalUrl, '_blank');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "activeService", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isServiceDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "updatePendingServices", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onCloseServiceDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseServiceDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteService", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteService"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "modelAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "modelAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDelete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPublish", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onPublish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRename", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRename"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openExternalUrl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openExternalUrl"), _class.prototype), _class);
});