define("additive-mc/mirage/factories/social-campaign", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const allowedValues = [{
    label: 'option1',
    value: 'option1-value'
  }, {
    label: 'option2',
    value: 'option2-value'
  }, {
    label: 'option3',
    value: 'option3-value'
  }];
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    title() {
      return 'Social Campaign';
    },
    description(i) {
      return `${this.title} ${i}`;
    },
    code: () => _faker.default.random.arrayElement(['usecase1', 'usecase2a', 'usecase2b', 'usecase3a', 'usecase3b', 'usecase4']),
    active() {
      return _faker.default.random.boolean();
    },
    activeFrom() {
      if (this.active) {
        return _faker.default.date.past();
      }
      return null;
    },
    activeUntil() {
      if (this.active) {
        return _faker.default.date.future();
      }
      return null;
    },
    configurations() {
      return [{
        label: 'Zum welchem Zeitpunkt?',
        info: "You dont always have to f**k her hard, in fact sometimes that's not right to do",
        type: 'time',
        key: 'my-unique-time',
        order: 0,
        value: _faker.default.random.arrayElement([null, '15:30', '01:16', '08:09'])
      }, {
        label: 'sooo simon jetzt musst du dich entscheiden: 1, 2 oder 3?',
        description: 'this is not the best song in the world, but a tribute',
        type: 'select',
        key: 'my-unique-select',
        order: 2,
        value: _faker.default.random.arrayElement([null, ...allowedValues]),
        allowedValues
      }, {
        label: '1, 2 oder 3 letzte chance..... vorbei!',
        type: 'multiselect',
        key: 'my-unique-multi-select',
        order: 1,
        value: [_faker.default.random.arrayElement([null, ...allowedValues])].filter(e => e),
        allowedValues
      }, {
        label: 'Kuchennnn',
        type: 'percentage-distribution',
        key: 'my-unique-pd',
        order: 3,
        value: [{
          key: 'key-visuals',
          label: 'Key Visuals',
          value: 20
        }, {
          label: 'Convince Values',
          key: 'convince-visuals',
          value: 0
        }, {
          key: 'catalog-ads',
          label: 'Katalog Ads',
          value: 80
        }]
      }];
    }
  });
});