define("additive-mc/mirage/factories/ad-catalog", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    label() {
      return `Ad Catalog ${_faker.default.random.word()}`;
    }
  });
});