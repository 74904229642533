define("additive-mc/controllers/instance/campaigns/ad/content-ads/depictions/index/depiction", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@glimmer/tracking", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _computed, _tracking, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsAdFacebookAdsDepictionsDepictionController = _exports.default = (_dec = (0, _computed.alias)('uiAppSettings.languages.defaultLanguage'), _dec2 = (0, _computed.alias)('uiAppSettings.currentLocale'), _dec3 = (0, _emberConcurrency.task)(function* () {}), _dec4 = (0, _emberConcurrency.task)(function* (locale) {
    if (locale === this.selectedLanguage) {
      return;
    }
    this.uiAppSettings.setLocale(locale);
    yield this.model.reload({
      adapterOptions: this.adapterOptions
    });
  }), _class = class InstanceCampaignsAdFacebookAdsDepictionsDepictionController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor4, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor5, this);
      _initializerDefineProperty(this, "uiToast", _descriptor6, this);
      _initializerDefineProperty(this, "defaultLanguage", _descriptor7, this);
      _initializerDefineProperty(this, "currentLocale", _descriptor8, this);
      _initializerDefineProperty(this, "campaignId", _descriptor9, this);
      _initializerDefineProperty(this, "model", _descriptor10, this);
      _initializerDefineProperty(this, "depictionMeta", _descriptor11, this);
      _initializerDefineProperty(this, "isRenameDialog", _descriptor12, this);
      /**
       * Fetches and returns the depictions meta
       * Is replaced in setupController by task of parent controller
       *
       * @function getDepictionMeta
       * @type {Task}
       */
      _initializerDefineProperty(this, "getDepictionMeta", _descriptor13, this);
      _initializerDefineProperty(this, "changeLanguage", _descriptor14, this);
    }
    get adapterOptions() {
      return {
        campaignId: this.campaignId,
        type: 'content-ads'
      };
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get textVariantsInfo() {
      const variants = this.model.variants.length;
      return this.intl.t('adCampaigns.facebookContentAds.depiction.textVariants.value', {
        variants
      });
    }
    get imageVariantsInfo() {
      const variants = this.model.images.length;
      return this.intl.t('adCampaigns.facebookContentAds.depiction.imageVariants.value', {
        variants
      });
    }
    async onDelete() {
      try {
        await this.model.destroyRecord({
          adapterOptions: {
            campaignId: this.campaignId,
            type: 'content-ads'
          }
        });
        this.back();
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        if (e && e.code === 'in_use') {
          this.uiDialog.showError(this.intl.t('adCampaigns.facebookContentAds.depiction.errorMessages.depictionInUse.title'), this.intl.t('adCampaigns.facebookContentAds.depiction.errorMessages.depictionInUse.description'));
        } else {
          this.uiToast.showToast({
            title: this.intl.t('toast.unexpectedError'),
            type: 'error'
          });
        }
      }
    }
    delete() {
      this.uiDialog.showConfirm(this.intl.t('components.amc-delete-model.confirm.title'), this.intl.t('components.amc-delete-model.confirm.description', {
        htmlSafe: true
      }), () => this.onDelete(), this.intl.t('global.actions.delete'), false, true);
    }
    back() {
      this.router.transitionTo('instance.campaigns.ad.content-ads.depictions');
    }
    openEdit() {
      this.router.transitionTo('instance.campaigns.ad.content-ads.depictions.index.depiction.edit');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "defaultLanguage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currentLocale", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "campaignId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "depictionMeta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isRenameDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "getDepictionMeta", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "changeLanguage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openEdit"), _class.prototype), _class);
});