define("additive-mc/mirage/routes/reports/onPage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OnPageReportKeyIndicators = void 0;
  const OnPageReportKeyIndicators = self => {
    self.get('/reports/on-page/key-indicators', schema => {
      return {
        impressions: {
          type: 'compare',
          change: 38,
          current: {
            value: 95,
            type: 'yearly',
            date: 2024
          },
          compare: {
            value: 76,
            type: 'yearly',
            date: 2023
          },
          format: null,
          currency: null
        },
        conversions: {
          type: 'compare',
          change: 29,
          current: {
            value: 87,
            type: 'yearly',
            date: 2024
          },
          compare: {
            value: 43,
            type: 'yearly',
            date: 2023
          },
          format: null,
          currency: null
        },
        leads: {
          type: 'compare',
          change: 67,
          current: {
            value: 91,
            type: 'yearly',
            date: 2024
          },
          compare: {
            value: 36,
            type: 'yearly',
            date: 2023
          },
          format: null,
          currency: null
        },
        impressionsConversionsRatio: {
          type: 'compare',
          change: 58,
          current: {
            value: {
              value: 82.35,
              percentage: 74.56
            },
            type: 'yearly-relative',
            date: 2024
          },
          compare: {
            value: 54,
            type: 'yearly-relative',
            date: 2023
          },
          format: null,
          currency: null
        }
      };
    });
  };
  _exports.OnPageReportKeyIndicators = OnPageReportKeyIndicators;
});