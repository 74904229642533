define("additive-mc/mirage/routes/campaigns/search-ad", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SearchAdRoutes = void 0;
  const SearchAdRoutes = self => {
    self.get('/organisation-campaigns/google/search-ads', _ref => {
      let {
        searchAdCampaigns
      } = _ref;
      const campaigns = searchAdCampaigns.all();
      return {
        searchAdCampaigns: campaigns.models,
        meta: {
          total: campaigns.models.length
        }
      };
    });
    self.get('/organisation-campaigns/google/search-ads/:id', (_ref2, request) => {
      let {
        searchAdCampaigns
      } = _ref2;
      const {
        id
      } = request.params;
      return searchAdCampaigns.find(id);
    });
    self.get('/organisation-campaigns/google/search-ads/metadata', schema => {
      return {
        bids: [1, 2, 3, 4].map(i => ({
          value: `bid-${i}`,
          label: `Bid ${i}`
        })),
        targetGoals: [1, 2].map(i => ({
          value: `target-goal-${i}`,
          label: `Target Goal${i}`
        })),
        landingPages: [1, 2].map(i => ({
          value: `landing-page-${i}`,
          label: `Landing Page ${i}`,
          url: _faker.default.internet.url(),
          availableLanguages: ['de', 'en', 'it']
        }))
      };
    });
    self.get('/organisation-campaigns/google/search-ads/keywords', schema => {
      return {
        keywords: [{
          id: 1,
          name: 'keyword 1',
          count: 15
        }, {
          id: 2,
          name: 'keyword 2',
          count: 6
        }, {
          id: 3,
          name: 'keyword 3',
          count: 15
        }, {
          id: 4,
          name: 'keyword 4',
          count: 6
        }, {
          id: 5,
          name: 'keyword 5',
          count: 15
        }, {
          id: 6,
          name: 'keyword 6',
          count: 6
        }],
        meta: {
          currentPage: 1,
          from: 1,
          lastPage: 1,
          perPage: 40,
          to: 11,
          total: 11
        }
      };
    });
    self.post('/organisation-campaigns/search-ad', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      const {
        title,
        campaignCode
      } = body;
      return schema.searchAdCampaigns.create({
        title,
        campaignCode
      });
    });
    self.put('/organisation-campaigns/google/search-ads/:id', (_ref3, request) => {
      let {
        searchAdCampaigns
      } = _ref3;
      const {
        id
      } = request.params;
      const body = JSON.parse(request.requestBody);
      return searchAdCampaigns.find(id).update(body);
    });
    self.put('/organisation-campaigns/google/search-ads/:id/publish', (schema, request) => {
      const {
        id
      } = request.params;
      const model = schema.searchAdCampaigns.find(id);
      const newModel = model.update({
        state: 'published'
      });
      return newModel;
    });
    self.get('/organisation-campaigns/google/search-ads/:id/ad-sets', (_ref4, request) => {
      let {
        searchAdCampaigns,
        searchAds
      } = _ref4;
      return {
        adSets: [{
          id: 1,
          name: 'Anzeigegruppe 1',
          state: 'paused',
          keywords: [{
            id: 1,
            name: 'keyword 1',
            count: 15
          }, {
            id: 4,
            name: 'keyword 4',
            count: 6
          }, {
            id: 5,
            name: 'keyword 5',
            count: 15
          }]
        }, {
          id: 2,
          name: 'Anzeigegruppe 2',
          state: 'enabled',
          keywords: [{
            id: 1,
            name: 'keyword 1',
            count: 15
          }, {
            id: 2,
            name: 'keyword 2',
            count: 6
          }, {
            id: 3,
            name: 'keyword 3',
            count: 15
          }, {
            id: 4,
            name: 'keyword 4',
            count: 6
          }, {
            id: 5,
            name: 'keyword 5',
            count: 15
          }]
        }]
      };
    });
    self.put('/organisation-campaigns/google/search-ads/:id/ad-sets/:id', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      return body;
    });
    self.get('/organisation-campaigns/google/search-ads/:id/ad-sets/:id/ads', (_ref5, request) => {
      let {
        searchAdCampaigns,
        searchAds
      } = _ref5;
      const {
        id
      } = request.params;
      const ads = searchAds.all();
      return {
        ads: ads.models
      };
    });
    self.put('/organisation-campaigns/google/search-ads/:id/ad-sets/:id/ads/:id', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      return body;
    });
    self.get('/organisation-campaigns/google/search-ads/:id/pending-updates', (_ref6, request) => {
      let {
        searchAdCampaigns,
        searchAds
      } = _ref6;
      return {
        pendingAdUpdates: [{
          id: 4,
          name: 'Ad',
          type: 'added',
          user: {
            id: '557fe8f9-3b49-49dd-8268-9512d9f2bada',
            name: 'Jonas Niedermair'
          }
        }],
        pendingTargetGroupUpdates: [{
          id: 3,
          name: 'TargetGroup',
          type: 'updated',
          user: {
            id: '557fe8f9-3b49-49dd-8268-9512d9f2bada',
            name: 'Jonas Niedermair'
          },
          changes: [{
            oldValue: 'old name',
            newValue: 'new name'
          }]
        }],
        pendingExtensionUpdates: [{
          id: 1,
          name: 'Erweiterung1',
          type: 'removed',
          user: {
            id: '557fe8f9-3b49-49dd-8268-9512d9f2bada',
            name: 'Jonas Niedermair'
          }
        }]
      };
    });
    self.put('/organisation-campaigns/google/search-ads/:id/pending-updates', (schema, request) => {
      const {
        id
      } = request.params;
      const model = schema.searchAdCampaigns.find(id);
      const newModel = model.update({
        state: 'published'
      });
      return newModel;
    });
    self.put('/organisation-campaigns/google/search-ads/:id/unpublish', (schema, request) => {
      const {
        id
      } = request.params;
      const model = schema.searchAdCampaigns.find(id);
      const newModel = model.update({
        state: 'unpublished'
      });
      return newModel;
    });
    self.put('/organisation-campaigns/google/search-ads/:id/pause', (schema, request) => {
      const {
        id
      } = request.params;
      const model = schema.searchAdCampaigns.find(id);
      const newModel = model.update({
        state: 'paused'
      });
      return newModel;
    });
    self.put('/organisation-campaigns/google/search-ads/:id/continue', (schema, request) => {
      const {
        id
      } = request.params;
      const model = schema.searchAdCampaigns.find(id);
      const newModel = model.update({
        state: 'published'
      });
      return newModel;
    });
    self.get('/organisation-campaigns/search-ads/extensions', _ref7 => {
      let {
        extensions
      } = _ref7;
      const allExtensions = extensions.all();
      return {
        extensions: allExtensions.models,
        meta: {
          total: allExtensions.models.length
        }
      };
    });
    self.get('/organisation-campaigns/search-ads/extensions/metadata', schema => {
      return {
        availableLanguages: [{
          label: 'Deutsch',
          value: 'de'
        }, {
          label: 'Italienisch',
          value: 'it'
        }, {
          label: 'Englisch',
          value: 'en'
        }]
      };
    });
    self.post('/organisation-campaigns/search-ads/extensions', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      const {
        name
      } = body;
      return schema.extensions.create({
        name
      });
    });
    self.put('/organisation-campaigns/search-ads/extensions/:id', (_ref8, request) => {
      let {
        extensions
      } = _ref8;
      const {
        id
      } = request.params;
      const body = JSON.parse(request.requestBody);
      return extensions.find(id).update(body);
    });
    self.delete('/organisation-campaigns/search-ads/extensions/:id', (schema, request) => {
      const {
        id
      } = request.params;
      const model = schema.extensions.find(id);
      model.destroy();
      return;
    });
    self.get('/organisation-campaigns/search-ads/ad-templates', _ref9 => {
      let {
        searchAdTemplates
      } = _ref9;
      const allAds = searchAdTemplates.all();
      return {
        searchAdTemplates: allAds.models,
        meta: {
          total: allAds.models.length
        }
      };
    });
    self.get('/organisation-campaigns/search-ads/ad-templates/metadata', schema => {
      return {
        availableLanguages: [{
          label: 'Deutsch',
          value: 'de'
        }, {
          label: 'Italienisch',
          value: 'it'
        }, {
          label: 'Englisch',
          value: 'en'
        }]
      };
    });
    self.post('/organisation-campaigns/search-ads/ad-templates', (schema, request) => {
      return schema.searchAdTemplates.create();
    });
    self.put('/organisation-campaigns/search-ads/ad-templates/:id', (_ref10, request) => {
      let {
        searchAdTemplates
      } = _ref10;
      const {
        id
      } = request.params;
      const body = JSON.parse(request.requestBody);
      return searchAdTemplates.find(id).update(body);
    });
    self.delete('/organisation-campaigns/search-ads/ad-templates/:id', (schema, request) => {
      const {
        id
      } = request.params;
      const model = schema.searchAdTemplates.find(id);
      model.destroy();
      return;
    });
    self.get('/organisation-campaigns/search-ads/target-groups', _ref11 => {
      let {
        targetGroups
      } = _ref11;
      const allTargetGroups = targetGroups.all();
      return {
        targetGroups: allTargetGroups.models,
        meta: {
          total: allTargetGroups.models.length
        }
      };
    });
    self.post('/organisation-campaigns/search-ads/target-groups', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      const {
        name
      } = body;
      return schema.targetGroups.create({
        name
      });
    });
    self.put('/organisation-campaigns/search-ads/target-groups/:id', (_ref12, request) => {
      let {
        targetGroups
      } = _ref12;
      const {
        id
      } = request.params;
      const body = JSON.parse(request.requestBody);
      return targetGroups.find(id).update(body);
    });
    self.delete('/organisation-campaigns/search-ads/target-groups/:id', (schema, request) => {
      const {
        id
      } = request.params;
      const model = schema.targetGroups.find(id);
      model.destroy();
      return;
    });
    self.get('/organisation-campaigns/search-ads/target-groups/metadata', schema => {
      return {
        availableLanguages: [{
          label: 'Deutsch',
          value: 'de'
        }, {
          label: 'Italienisch',
          value: 'it'
        }, {
          label: 'Englisch',
          value: 'en'
        }],
        locations: [{
          label: 'Qatar',
          value: '289688',
          subData: []
        }, {
          label: 'Ukraine',
          value: '690791',
          subData: []
        }, {
          label: 'Sweden',
          value: '2661886',
          subData: [{
            label: 'Vastmanland',
            value: '2664179',
            subData: [{
              label: 'Vasteras',
              value: '2664454',
              subData: []
            }]
          }, {
            label: 'Uppsala',
            value: '2666218',
            subData: [{
              label: 'Uppsala',
              value: '2666199',
              subData: []
            }]
          }, {
            label: 'Jonkoping',
            value: '2702976',
            subData: [{
              label: 'Jonkoping',
              value: '2702979',
              subData: []
            }]
          }]
        }, {
          label: 'South Africa',
          value: '953987',
          subData: [{
            label: 'Orange Free State',
            value: '967573',
            subData: []
          }, {
            label: 'KwaZulu-Natal',
            value: '972062',
            subData: []
          }]
        }]
      };
    });
  };
  _exports.SearchAdRoutes = SearchAdRoutes;
});