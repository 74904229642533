define("additive-mc/mirage/factories/depiction", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    headline() {
      return _faker.default.lorem.sentence();
    },
    description() {
      return _faker.default.lorem.sentence();
    },
    text() {
      return _faker.default.lorem.sentence();
    },
    productTypes() {
      const productTypes = [{
        value: 'offers'
      }, {
        value: 'rooms',
        subTypes: ['room1', 'room2', 'room3', 'room4', 'room5']
      }, {
        value: 'deals',
        subTypes: ['deal1', 'deal2', 'deal3', 'deal4', 'deal5']
      }].map(value => ({
        value,
        sort: Math.random()
      })).sort((a, b) => a.sort - b.sort).map(_ref => {
        let {
          value
        } = _ref;
        return value;
      });
      const popCount = Math.floor(Math.random() * (productTypes.length + 1));
      for (let i = 0; i < popCount; i++) {
        productTypes.pop();
      }
      productTypes.forEach(product => {
        var _product$subTypes, _product$subTypes2;
        if (product.subTypes) {
          return;
        }
        const popSubtypesCount = Math.floor(Math.random() * ((_product$subTypes = product.subTypes) === null || _product$subTypes === void 0 ? void 0 : _product$subTypes.length)) + 1;
        for (let i = 1; i < popSubtypesCount; i++) {
          product.subTypes.pop();
        }
        if (((_product$subTypes2 = product.subTypes) === null || _product$subTypes2 === void 0 ? void 0 : _product$subTypes2.length) === 0) {
          delete product.subTypes;
        }
      });
      return productTypes;
    },
    availableLanguages() {
      const randomNumber = _faker.default.random.number(10);
      if (randomNumber > 5) {
        return ['de', 'it', 'en'];
      }
      return ['de'];
    },
    ctaType() {
      return _faker.default.random.arrayElement(['cta1', 'cta2', 'cta3']);
    },
    formats() {
      const formats = ['single', 'carousel'].map(value => ({
        value,
        sort: Math.random()
      })).sort((a, b) => a.sort - b.sort).map(_ref2 => {
        let {
          value
        } = _ref2;
        return value;
      });
      const number = Math.floor(Math.random() * (formats.length + 1));
      for (let i = 0; i < number; i++) {
        formats.pop();
      }
      return formats;
    },
    language: 'de',
    facebookContentAd: (0, _emberCliMirage.trait)({
      name(i) {
        return `Depiction ${i}`;
      },
      ctaType: 'cta2',
      postfix: 'rerum',
      url: () => _faker.default.internet.url(),
      headline: () => _faker.default.lorem.sentence(),
      images: [{
        position: 1,
        mediumId: '2af74266-43f0-4357-9955-5a11964d5cd7',
        url: 'https://staging.multimedia.additive-apps.tech/media/9ab4116e450a286882fda4f7e7779202',
        name: 'ausblick.jpeg',
        labels: ['portrait', 'landscape', 'square'],
        type: 'image',
        provider: 'imbo',
        remoteId: '9ab4116e450a286882fda4f7e7779202'
      }],
      variants: [{
        title: () => _faker.default.lorem.sentence(),
        text: () => _faker.default.lorem.sentence()
      }]
    }),
    tiktokLeadAd: (0, _emberCliMirage.trait)({
      name(i) {
        return `TikTok Depiction ${i}`;
      },
      postfix: _faker.default.lorem.word(),
      page: {
        id: '2',
        name: 'Formular B'
      },
      videos: [{
        position: 1,
        mediumId: 'ede3d4d1-2bb0-40ef-abb8-1db93b919c44',
        url: 'https://staging.multimedia.additive-apps.tech/media/97215e7b-1954-4b06-9537-6544391f8ea5',
        name: 'Ehrenburgerhof_Snippet_Ausflüge_Final.mp4',
        type: 'video',
        provider: 's3_video',
        remoteId: '97215e7b-1954-4b06-9537-6544391f8ea5',
        thumbnailUrl: 'https://staging.multimedia.additive-apps.tech/media/97215e7b-1954-4b06-9537-6544391f8ea5?video_thumbnail=1'
      }, {
        position: 2,
        mediumId: 'f227b8ed-2581-40ad-89e1-22c685ef7b43',
        url: 'https://staging.multimedia.additive-apps.tech/media/7a29a60a-6f0c-44b0-a65c-28bd4800c4e2',
        name: 'hippo.mp4',
        type: 'video',
        provider: 's3_video',
        remoteId: '7a29a60a-6f0c-44b0-a65c-28bd4800c4e2',
        thumbnailUrl: 'https://staging.multimedia.additive-apps.tech/media/7a29a60a-6f0c-44b0-a65c-28bd4800c4e2?video_thumbnail=1'
      }],
      variants: [{
        text: 'Ich bin der Anzeigetext.'
      }]
    })
  });
});