define("additive-mc/serializers/trigger-email-campaign-email", ["exports", "additive-mc/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Api treats empty ctaType with `null`.
   * But in ui we use it as `none` to simplify displaying
   *
   * API: `{ ctaType: null }`
   * UI: `{ ctaType: 'none' }`
   */
  var _default = _exports.default = _application.default.extend({
    serializeAttribute(snapshot, json, key) {
      this._super(...arguments);
      if (key === 'emailSeriesId' && json.emailSeriesId === '') {
        json.emailSeriesId = null;
      }
    },
    normalizeResponse() {
      const json = this._super(...arguments);
      const isArray = Array.isArray(json.data);
      const emails = isArray ? json.data : [json.data];
      let data = [];
      emails.forEach(email => {
        if (email.attributes.cta) {
          var _email$attributes, _email$attributes3, _email$attributes5;
          if ((_email$attributes = email.attributes) !== null && _email$attributes !== void 0 && (_email$attributes = _email$attributes.cta) !== null && _email$attributes !== void 0 && _email$attributes.type) {
            var _email$attributes2;
            email.attributes.ctaType = (_email$attributes2 = email.attributes) === null || _email$attributes2 === void 0 || (_email$attributes2 = _email$attributes2.cta) === null || _email$attributes2 === void 0 ? void 0 : _email$attributes2.type;
          }
          if ((_email$attributes3 = email.attributes) !== null && _email$attributes3 !== void 0 && (_email$attributes3 = _email$attributes3.cta) !== null && _email$attributes3 !== void 0 && _email$attributes3.text) {
            var _email$attributes4;
            email.attributes.ctaText = (_email$attributes4 = email.attributes) === null || _email$attributes4 === void 0 || (_email$attributes4 = _email$attributes4.cta) === null || _email$attributes4 === void 0 ? void 0 : _email$attributes4.text;
          }
          if ((_email$attributes5 = email.attributes) !== null && _email$attributes5 !== void 0 && (_email$attributes5 = _email$attributes5.cta) !== null && _email$attributes5 !== void 0 && _email$attributes5.link) {
            var _email$attributes6;
            email.attributes.ctaLink = (_email$attributes6 = email.attributes) === null || _email$attributes6 === void 0 || (_email$attributes6 = _email$attributes6.cta) === null || _email$attributes6 === void 0 ? void 0 : _email$attributes6.link;
          }
          delete email.attributes.cta;
        }
        if (!email.attributes.ctaType) {
          email.attributes.ctaType = null;
        }
        data.push(email);
      });
      json.data = isArray ? data : data[0];
      return json;
    },
    keyForAttribute(key) {
      if (key === 'campaignId') {
        return 'organisationCampaignId';
      }
      return this._super(...arguments);
    }
  });
});