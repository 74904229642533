define("additive-mc/mirage/routes/campaigns/tiktok-lead-ad", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TikTokLeadAdsRoutes = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const TikTokLeadAdsRoutes = self => {
    self.get('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements', schema => {
      return schema.advertisements.where({
        type: 'tiktok-lead-ad'
      });
    });
    self.get('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      return schema.advertisements.find(advertisement_id);
    });
    self.post('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      return schema.advertisements.create(_objectSpread(_objectSpread({}, body), {}, {
        state: 'unpublished',
        budgetSpend: {
          value: 0,
          percentage: 0
        },
        totalBudget: 0
      }));
    });
    self.put('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const body = JSON.parse(request.requestBody);
      const newModel = model.update(body);
      return newModel;
    });
    self.put('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id/archive', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'archived'
      });
      return newModel;
    });
    self.get('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id/validate', (schema, request) => {
      // TODO: 422-Validations
      return new _emberCliMirage.Response(200, {}, {});
    });
    self.put('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id/pendingDepictionUpdates', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'published'
      });
      return newModel;
    });
    self.get('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id/pendingDepictionUpdates', schema => {
      return {
        pendingDepictionUpdates: [{
          name: 'Depiction 1',
          id: 2,
          user: {
            id: 12332,
            name: 'Stefan Gasser'
          },
          type: 'added'
        }, {
          name: 'Depiction 2',
          id: 3,
          user: {
            id: 123321,
            name: 'Hannah Geier'
          },
          type: 'removed'
        }, {
          name: 'Depiction 4',
          id: 5,
          user: {
            id: 12311,
            name: 'Simon Knoll'
          },
          type: 'updated'
        }]
      };
    });
    self.put('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id/pendingTargetGroupUpdates', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'published'
      });
      return newModel;
    });
    self.get('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id/pendingTargetGroupUpdates', schema => {
      return {
        pendingTargetGroupUpdates: [{
          id: 'UUID',
          name: 'Test',
          type: 'updated',
          user: {
            id: '557fe8f9-3b49-49dd-8268-9512d9f2bada',
            name: 'Christoph Blank'
          }
        }]
      };
    });
    self.get('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/metadata', schema => {
      return {
        identities: [{
          id: '1',
          name: 'Additive'
        }],
        targetGroups: [{
          id: '1',
          name: 'ca_interessen_dach_de'
        }],
        budgetTypes: [{
          value: 'daily',
          label: 'Tagesbudget'
        }, {
          value: 'lifetime',
          label: 'Lebenslanges Budget'
        }],
        optimizationEvents: [1, 2, 3, 4].map(i => ({
          value: `optimizationEvent-${i}`,
          label: `Optimization event ${i}`
        })),
        availableLanguages: [{
          label: 'Deutsch',
          value: 'de'
        }, {
          label: 'Italienisch',
          value: 'it'
        }, {
          label: 'Englisch',
          value: 'en'
        }]
      };
    });
    self.put('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id/publish', (schema, request) => {
      const {
        advertisement_id
      } = request.params;

      // Randomly fail
      const random = Math.random();
      if (random > 0.5) {
        return new _emberCliMirage.Response(422, {}, {
          status: 422,
          code: 'boh',
          message: 'Random error, Facebook does not like this ad'
        });
      }
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'published'
      });
      return newModel;
    });
    self.put('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id/unpublish', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'unpublished'
      });
      return newModel;
    });
    self.put('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id/pause', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'paused'
      });
      return newModel;
    });
    self.put('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/advertisements/:advertisement_id/continue', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'published'
      });
      return newModel;
    });
    self.get('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/depictions', schema => {
      return schema.depictions.all();
    });
    self.get('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/depictions/:depiction_id', (schema, request) => {
      const {
        depiction_id
      } = request.params;
      const depiction = schema.depictions.find(depiction_id);
      depiction.language = request.requestHeaders['Accept-Language'];
      return depiction;
    });
    self.put('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/depictions/:depiction_id', (schema, request) => {
      const {
        depiction_id
      } = request.params;
      const model = schema.depictions.find(depiction_id);
      const body = JSON.parse(request.requestBody);
      body.variants.forEach(variant => {
        variant.id = _faker.default.random.uuid();
      });
      if (body.postfix === '') {
        body.postfix = body.name;
      }
      const newModel = model.update(body);
      return newModel;
    });
    self.post('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/depictions', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      return schema.depictions.create(_objectSpread(_objectSpread({}, body), {}, {
        availableLanguages: [request.requestHeaders['Accept-Language']]
      }));
    });
    self.delete('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/depictions/:depiction_id', (schema, request) => {
      // Randomly fail
      // const random = Math.random();
      // if (random > 0.6) {
      //   return new Response(
      //     422,
      //     {},
      //     {
      //       status: 422,
      //       message: 'API Error'
      //     }
      //   );
      // }

      const {
        depiction_id
      } = request.params;
      const model = schema.depictions.find(depiction_id);
      model.destroy();
      return;
    });
    self.get('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/depictions/:depiction_id/tiktok-pages', schema => {
      return {
        pages: [{
          id: '1',
          name: 'Formular A'
        }, {
          id: '2',
          name: 'Formular B'
        }, {
          id: '3',
          name: 'Formular C'
        }]
      };
    });
    self.get('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/depictions/:depiction_id/tiktok-form-editor', schema => {
      return {
        appId: 'your_appid',
        version: 'v1.0',
        accountId: 'your_adv_id',
        accessToken: 'your_access_token',
        display: 'popup',
        actionType: 'create',
        pageId: 'pageid',
        businessType: 1,
        countryCode: ['US'],
        siteType: 0,
        externalAction: 0
      };
    });
    self.delete('/organisation-campaigns/ad-campaigns/tiktok-lead-ads/:id/depictions/:depiction_id/:language', (schema, request) => {
      try {
        const {
          language
        } = request.params;
        if (['de', 'en', 'it'].indexOf(language) === -1) {
          throw notFoundResponse(`'${language}' not supported`);
        }
        const {
          depiction_id
        } = request.params;
        const model = schema.depictions.find(depiction_id);
        if (model.availableLanguages.indexOf(language) === -1) {
          return (0, _emberCliMirage.Response)(400, {}, {
            message: `'${language}' does not exist`
          });
        }
        model.availableLanguages.splice(model.availableLanguages.indexOf(language), 1);
        return model;
      } catch (error) {
        return notFoundResponse(error);
      }
    });
  };
  _exports.TikTokLeadAdsRoutes = TikTokLeadAdsRoutes;
});