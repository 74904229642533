define("additive-mc/components/amc-campaign/email-campaign/configuration-item/boolean", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "ember-arg-types", "@glimmer/tracking", "prop-types", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _service, _emberArgTypes, _tracking, _propTypes, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex justify-between br1 bg-secondary--10 pa2">
    <div class="font-sm primary">
      {{this.item.label}}
    </div>
    <UiSwitch @value={{this._value}} @onChange={{this.change}} />
  </div>
  */
  {
    "id": "cBLswsaf",
    "block": "[[[10,0],[14,0,\"flex justify-between br1 bg-secondary--10 pa2\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"font-sm primary\"],[12],[1,\"\\n    \"],[1,[30,0,[\"item\",\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@value\",\"@onChange\"],[[30,0,[\"_value\"]],[30,0,[\"change\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"ui-switch\"]]",
    "moduleName": "additive-mc/components/amc-campaign/email-campaign/configuration-item/boolean.hbs",
    "isStrictMode": false
  });
  let AmcCampaignConfigurationItemBooleanComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignConfigurationItemBooleanComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiToast", _descriptor2, this);
      /**
       * The configuration-item
       *
       * @property item
       * @default null
       */
      _initializerDefineProperty(this, "item", _descriptor3, this);
      _initializerDefineProperty(this, "_value", _descriptor4, this);
      /**
       * Whether the component is read only
       *
       * @argument isReadOnly
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor5, this);
      _initializerDefineProperty(this, "onChange", _descriptor6, this);
      this._value = this.item.value;
    }
    async change(value) {
      this._value = value;
      await this.onChange(value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignConfigurationItemBooleanComponent);
});