define("additive-mc/mirage/routes/services/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ServiceRoutes = void 0;
  const ServiceRoutes = self => {
    self.get('/services', _ref => {
      let {
        services
      } = _ref;
      const serviceModels = services.all();
      return {
        services: serviceModels.models,
        meta: {
          total: serviceModels.models.length
        }
      };
    });
    self.get('/services/:id', (_ref2, request) => {
      let {
        services
      } = _ref2;
      const {
        id
      } = request.params;
      return services.find(id);
    });
    self.get('/services/:id/meta', (_ref3, request) => {
      let {
        services
      } = _ref3;
      return {
        meta: {
          availableContentTypes: [{
            id: 'offers',
            name: 'Angebote',
            excludableContents: [{
              id: 'excludable_categories',
              name: 'Auszuschließende Kategorien',
              excludable: [{
                id: '1',
                name: 'Herbstangebote'
              }, {
                id: '2',
                name: 'Sommerangebote'
              }]
            }, {
              id: 'excludable_offers',
              name: 'Auszuschließende Angebote',
              excludable: [{
                id: '1',
                name: 'Urlaub zu Zweit'
              }, {
                id: '2',
                name: 'Wedelkurs'
              }]
            }]
          }, {
            id: 'rooms',
            name: 'Zimmer',
            excludableContents: [{
              id: 'excludable_categories',
              name: 'Auszuschließende Kategorien',
              excludable: [{
                id: '1',
                name: 'Einzelzimmer Bergblick'
              }, {
                id: '2',
                name: 'Doppelzimmer Panorama'
              }]
            }, {
              id: 'excludable_rooms',
              name: 'Auszuschließende Zimmer',
              excludable: [{
                id: '1',
                name: 'Einzelzimmer'
              }, {
                id: '2',
                name: 'Doppelzimmer'
              }]
            }]
          }, {
            id: 'deals',
            name: 'Restplätze',
            excludableContents: [{
              id: 'excludable_categories',
              name: 'Auszuschließende Kategorien',
              excludable: [{
                id: '1',
                name: 'Einzelzimmer Bergblick'
              }, {
                id: '2',
                name: 'Doppelzimmer Panorama'
              }]
            }, {
              id: 'excludable_deals',
              name: 'Auszuschließende Zimmer',
              excludable: [{
                id: '1',
                name: 'Einzelzimmer'
              }, {
                id: '2',
                name: 'Doppelzimmer'
              }]
            }]
          }]
        }
      };
    });
    self.del('/services/:id', (_ref4, request) => {
      let {
        services
      } = _ref4;
      const {
        id
      } = request.params;
      services.find(id).destroy();
    });
    self.post('/services', (_ref5, request) => {
      let {
        services
      } = _ref5;
      let body = JSON.parse(request.requestBody);
      body.status = {
        state: 'error'
      };
      return services.create(body);
    });
    self.put('/services/:id', (_ref6, request) => {
      let {
        services
      } = _ref6;
      const {
        id
      } = request.params;
      const body = JSON.parse(request.requestBody);
      return services.find(id).update(body);
    });
    self.put('/services/:id/publish', (_ref7, request) => {
      let {
        services
      } = _ref7;
      const {
        id
      } = request.params;
      const model = services.find(id);
      const pendingStatus = Object.assign({}, model.status, {
        state: 'pending'
      });
      const newModel = model.update({
        status: pendingStatus
      });
      setTimeout(() => {
        const successStatus = Object.assign({}, model.status, {
          state: 'success'
        });
        model.update({
          status: successStatus
        });
      }, 10000);
      return newModel;
    });
    self.put('/services/:id/unpublish', (_ref8, request) => {
      let {
        services
      } = _ref8;
      const {
        id
      } = request.params;
      const model = services.find(id);
      const errorStatus = Object.assign({}, model.status, {
        state: 'error'
      });
      const newModel = model.update({
        status: errorStatus
      });
      return newModel;
    });
  };
  _exports.ServiceRoutes = ServiceRoutes;
});