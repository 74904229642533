define("additive-mc/routes/instance/campaigns/email/detail/email-series/email-series", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    store: (0, _service.inject)(),
    setupController(controller, model) {
      this._super(controller, model);
      const {
        campaign_id
      } = this.paramsFor('instance.campaigns.email.detail');
      (0, _object.set)(controller, 'campaignId', campaign_id);
    },
    model(params) {
      const model = this.store.peekRecord('email-series', params.email_series_id);
      if (model) {
        return model;
      }
      const {
        campaign_id: campaignId
      } = this.paramsFor('instance.campaigns.email.detail');
      return this.store.findRecord('email-series', params.email_series_id, {
        adapterOptions: {
          campaignId
        }
      });
    }
  });
});