define("additive-mc/controllers/instance/campaigns/index", ["exports", "@ember/controller", "@ember/object/computed", "@additive-apps/utils/utils/plans", "@ember/object", "@ember/service"], function (_exports, _controller, _computed, _plans, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsIndexController = _exports.default = (_dec = (0, _computed.alias)('currentUser.currentOrganization.id'), _dec2 = (0, _object.computed)('organizationSlug', 'uiAppSettings.planName'), _dec3 = (0, _object.computed)('organizationSlug', 'uiAppSettings.planName'), _dec4 = (0, _object.computed)('organizationSlug', 'uiAppSettings.planName'), _dec5 = (0, _object.computed)('organizationSlug', 'uiAppSettings.planName'), _class = class InstanceCampaignsIndexController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      /**
       * The current organization slug
       *
       * @computed organizationsSlug
       * @type {string}
       */
      _initializerDefineProperty(this, "organizationSlug", _descriptor3, this);
    }
    get isStarter() {
      var _this$uiAppSettings;
      const currentPlan = (_this$uiAppSettings = this.uiAppSettings) === null || _this$uiAppSettings === void 0 ? void 0 : _this$uiAppSettings.planName;
      return (0, _plans.is)(currentPlan, _plans.PLAN_STARTER);
    }
    get isProfessional() {
      var _this$uiAppSettings2;
      const currentPlan = (_this$uiAppSettings2 = this.uiAppSettings) === null || _this$uiAppSettings2 === void 0 ? void 0 : _this$uiAppSettings2.planName;
      return (0, _plans.is)(currentPlan, _plans.PLAN_PROFESSIONAL);
    }
    get gteEnterprise() {
      var _this$uiAppSettings3;
      const currentPlan = (_this$uiAppSettings3 = this.uiAppSettings) === null || _this$uiAppSettings3 === void 0 ? void 0 : _this$uiAppSettings3.planName;
      return (0, _plans.gte)(currentPlan, _plans.PLAN_ENTERPRISE);
    }
    get gteStarter() {
      var _this$uiAppSettings4;
      const currentPlan = (_this$uiAppSettings4 = this.uiAppSettings) === null || _this$uiAppSettings4 === void 0 ? void 0 : _this$uiAppSettings4.planName;
      return (0, _plans.gte)(currentPlan, _plans.PLAN_STARTER);
    }
    get hasReferralModule() {
      var _this$uiAppSettings5;
      return (_this$uiAppSettings5 = this.uiAppSettings) === null || _this$uiAppSettings5 === void 0 || (_this$uiAppSettings5 = _this$uiAppSettings5.modules) === null || _this$uiAppSettings5 === void 0 ? void 0 : _this$uiAppSettings5.find(element => element === 'referral-marketing');
    }
    get isAdditive() {
      var _this$currentUser;
      return (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.user) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.isAdditive;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "organizationSlug", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isStarter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isStarter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isProfessional", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isProfessional"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "gteEnterprise", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "gteEnterprise"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "gteStarter", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "gteStarter"), _class.prototype), _class);
});