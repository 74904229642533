define("additive-mc/mirage/routes/dashboard/index", ["exports", "additive-mc/mirage/routes/dashboard/charts", "additive-mc/mirage/routes/dashboard/key-indicators", "additive-mc/mirage/routes/dashboard/overview", "additive-mc/mirage/routes/dashboard/email-campaigns", "additive-mc/mirage/routes/dashboard/social-campaigns", "additive-mc/mirage/routes/dashboard/ad-campaigns"], function (_exports, _charts, _keyIndicators, _overview, _emailCampaigns, _socialCampaigns, _adCampaigns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "DashboardAdCampaigns", {
    enumerable: true,
    get: function get() {
      return _adCampaigns.DashboardAdCampaigns;
    }
  });
  Object.defineProperty(_exports, "DashboardCharts", {
    enumerable: true,
    get: function get() {
      return _charts.DashboardCharts;
    }
  });
  Object.defineProperty(_exports, "DashboardEmailCampaigns", {
    enumerable: true,
    get: function get() {
      return _emailCampaigns.DashboardEmailCampaigns;
    }
  });
  Object.defineProperty(_exports, "DashboardKeyIndicators", {
    enumerable: true,
    get: function get() {
      return _keyIndicators.DashboardKeyIndicators;
    }
  });
  Object.defineProperty(_exports, "DashboardOverview", {
    enumerable: true,
    get: function get() {
      return _overview.DashboardOverview;
    }
  });
  Object.defineProperty(_exports, "DashboardSocialCampaigns", {
    enumerable: true,
    get: function get() {
      return _socialCampaigns.DashboardSocialCampaigns;
    }
  });
});