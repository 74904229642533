define("additive-mc/serializers/referral-landingpage", ["exports", "additive-mc/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReferralLandingpageApplicationSerializer extends _application.default {
    keyForAttribute(key) {
      if (key === 'campaignId') {
        return 'organisationCampaignId';
      }
      return super.keyForAttribute(...arguments);
    }
  }
  _exports.default = ReferralLandingpageApplicationSerializer;
});