define("additive-mc/components/amc-campaign/ads/advertisements/publish-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _object, _service, _tracking, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal
    @title={{t (concat this.intlBaseKey ".title")}}
    @modalClassNames="publish-dialog"
    @size="md"
    @onClose={{this.onClose}} as |modal|
  >
    <modal.header />
    <modal.content @classNames="pb0" as |content|>
      <content.body>
        <div class="bb b--secondary-10 w-100"></div>
        {{#each this.validationItems as |item|}}
          <AmcCampaign::Ads::Advertisements::PublishDialog::Item
            @title={{item.title}}
            @successMessage={{item.successMessage}}
            @errorMessage={{item.errorMessage}}
          />
        {{/each}}
      </content.body>
    </modal.content>
    <modal.footer>
      <UiButton
        @label={{t "global.actions.publish"}}
        @isDisabled={{this.isPublishDisabled}}
        @isSecondary={{true}}
        @onClick={{this.publish}}
      />
    </modal.footer>
  </UiModal>
  */
  {
    "id": "t+QMGvad",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@modalClassNames\",\"@size\",\"@onClose\"],[[28,[37,1],[[28,[37,2],[[30,0,[\"intlBaseKey\"]],\".title\"],null]],null],\"publish-dialog\",\"md\",[30,0,[\"onClose\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,[[\"@classNames\"],[\"pb0\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"bb b--secondary-10 w-100\"],[12],[13],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"validationItems\"]]],null]],null],null,[[[1,\"        \"],[8,[39,5],null,[[\"@title\",\"@successMessage\",\"@errorMessage\"],[[30,3,[\"title\"]],[30,3,[\"successMessage\"]],[30,3,[\"errorMessage\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@label\",\"@isDisabled\",\"@isSecondary\",\"@onClick\"],[[28,[37,1],[\"global.actions.publish\"],null],[30,0,[\"isPublishDisabled\"]],true,[30,0,[\"publish\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"content\",\"item\"],false,[\"ui-modal\",\"t\",\"concat\",\"each\",\"-track-array\",\"amc-campaign/ads/advertisements/publish-dialog/item\",\"ui-button\"]]",
    "moduleName": "additive-mc/components/amc-campaign/ads/advertisements/publish-dialog.hbs",
    "isStrictMode": false
  });
  let AmcCampaignAdsAdvertisementsPublishDialogComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.array), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _dec6 = (0, _object.computed)('changeset', 'intlBaseKey', 'validationProperties'), _class = class AmcCampaignAdsAdvertisementsPublishDialogComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      /**
       * current changeset
       *
       * @argument changeset
       * @type {Changeset}
       */
      _initializerDefineProperty(this, "changeset", _descriptor2, this);
      /**
       * the base key for the translations
       *
       * @argument intlBaseKey
       * @type {String}
       */
      _initializerDefineProperty(this, "intlBaseKey", _descriptor3, this);
      /**
       * the name of the propertys which should be validated
       *
       * @argument Array
       * @type {Array}
       */
      _initializerDefineProperty(this, "validationProperties", _descriptor4, this);
      /**
       * whether the publish button is disabled
       *
       * @property isPublishDisabled
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isPublishDisabled", _descriptor5, this);
      /**
       * callback function to close the dialog
       *
       * @argument onClose
       * @type {Function}
       */
      _initializerDefineProperty(this, "onClose", _descriptor6, this);
      /**
       * callback function to close the dialog
       *
       * @argument onClose
       * @type {Function}
       */
      _initializerDefineProperty(this, "onPublish", _descriptor7, this);
      this.validate();
    }
    get validationItems() {
      const items = [];
      this.validationProperties.forEach(key => {
        const successMessage = this.intl.t(`${this.intlBaseKey}.successMessages.${key}`);
        const title = this.intl.t(`${this.intlBaseKey}.properties.${key}`);
        let errorMessage;
        const errors = this.changeset.get(`errors`);
        if (errors.length > 0) {
          var _error$validation;
          const error = errors.find(error => error.key === key);
          errorMessage = error ? error === null || error === void 0 || (_error$validation = error.validation) === null || _error$validation === void 0 ? void 0 : _error$validation[0] : '';
        }
        items.push({
          title,
          successMessage,
          errorMessage
        });
      });
      return items;
    }
    async validate() {
      await this.changeset.validate();
      const isValid = this.changeset.get('isValid');
      this.isPublishDisabled = isValid ? false : true;
    }
    publish() {
      if (this.isPublishDisabled) {
        return;
      }
      this.onPublish();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intlBaseKey", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'adCampaigns.facebookContentAds.publishDialog';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "validationProperties", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isPublishDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onPublish", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "validationItems", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "validationItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "publish", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "publish"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignAdsAdvertisementsPublishDialogComponent);
});