define("additive-mc/templates/components/amc-campaign/configuration-item/yeardate", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "LcrggFPR",
    "block": "[[[8,[39,0],null,[[\"@onSave\",\"@onClose\",\"@childChangeset\",\"@item\",\"@isReadOnly\"],[[28,[37,1],[[30,0,[\"_save\"]]],null],[30,0,[\"onClose\"]],[30,0,[\"_changeset\"]],[30,0,[\"item\"]],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@class\",\"@label\",\"@selected\",\"@options\",\"@onChange\",\"@isReadOnly\"],[\"pr1\",[28,[37,3],[\"global.dates.days\"],[[\"value\"],[1]]],[30,0,[\"_changeset\",\"day\"]],[30,0,[\"_dayOptions\"]],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"_changeset\",\"day\"]]],null]],null],[28,[37,6],[[30,1,[\"isLoading\"]],[30,0,[\"isReadOnly\"]]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"item\"]],null,[[\"@value\"],[[30,3,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,3,[\"value\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[2,3]]]]],[1,\"\\n      \"],[8,[39,2],null,[[\"@label\",\"@selected\",\"@options\",\"@onChange\",\"@isReadOnly\"],[[28,[37,3],[\"global.dates.months\"],[[\"value\"],[1]]],[30,0,[\"_changeset\",\"month\"]],[30,0,[\"_monthOptions\"]],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"_changeset\",\"month\"]]],null]],null],[28,[37,6],[[30,1,[\"isLoading\"]],[30,0,[\"isReadOnly\"]]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"item\"]],null,[[\"@value\"],[[30,5,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,5,[\"value\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[4,5]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"option\",\"item\",\"option\",\"item\"],false,[\"amc-campaign/configuration-item/with-modal\",\"perform\",\"ui-select\",\"t\",\"action\",\"mut\",\"amc-or\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/yeardate.hbs",
    "isStrictMode": false
  });
});