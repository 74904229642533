define("additive-mc/routes/instance/campaigns/ad/catalog-ads/index/catalog-ad", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceCampaignsAdCatalogAdsIndexCatalogAdRoute extends _route.default {
    async model(params) {
      const {
        campaign,
        depictions
      } = this.modelFor('instance.campaigns.ad.catalog-ads');
      const catalogAd = await this.store.findRecord('advertisement', params.ad_id, {
        adapterOptions: {
          campaignId: campaign.id,
          type: 'catalog-ads'
        }
      });
      return {
        catalogAd,
        depictions
      };
    }
    async setupController(controller) {
      super.setupController(...arguments);
      const {
        campaign,
        meta
      } = this.modelFor('instance.campaigns.ad.catalog-ads');
      controller.campaignId = campaign.id;
      controller.meta = meta;

      // eslint-disable-next-line ember/no-controller-access-in-routes
      const catalogAdsController = this.controllerFor('instance.campaigns.ad.catalog-ads');
      controller.getDepictionMeta = catalogAdsController.getDepictionMeta;
      controller.depictionMeta = await controller.getDepictionMeta.perform();
    }
    renderTemplate() {
      this.render('instance/campaigns/ad/catalog-ads/index/catalog-ad', {
        into: 'instance/campaigns/ad/catalog-ads',
        outlet: 'detail'
      });
    }
  }
  _exports.default = InstanceCampaignsAdCatalogAdsIndexCatalogAdRoute;
});