define("additive-mc/templates/components/amc-campaign/info-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "IVo2KG/b",
    "block": "[[[10,1],[14,0,\"primary\"],[12],[1,\"\\n  \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,1],[14,0,\"secondary\"],[12],[1,\"\\n  \"],[1,[30,0,[\"value\"]]],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/info-item.hbs",
    "isStrictMode": false
  });
});