define("additive-mc/routes/instance/reports/ad", ["exports", "@ember/routing/route", "@ember/service", "@additive-apps/utils/utils/plans"], function (_exports, _route, _service, _plans) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    uiAppSettings: (0, _service.inject)(),
    router: (0, _service.inject)(),
    redirect() {
      const currentPlan = this.uiAppSettings && this.uiAppSettings.planName;
      if (currentPlan !== _plans.PLAN_PROFESSIONAL) {
        return;
      }
      return this.router.transitionTo('instance.reports');
    }
  });
});