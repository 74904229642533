define("additive-mc/mirage/serializers/trigger-email", ["exports", "additive-mc/mirage/serializers/application", "@ember/array"], function (_exports, _application, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    keyForForeignKey(relationshipName) {
      if (relationshipName === 'campaign') {
        return 'organisationCampaignId';
      }

      // super call
      return _application.default.prototype.keyForForeignKey.apply(this, arguments);
    },
    serialize(object, request) {
      // super call
      let json = _application.default.prototype.serialize.apply(this, arguments);
      // Some multilingual-fake behavior
      const languageBehavior = (entity, newLanguage) => {
        if (!newLanguage) {
          return entity;
        }

        // Update language property depending on `Accept-Langauge` header
        if (entity.language !== newLanguage) {
          entity.language = newLanguage;
        }

        // Add language in front of multilingual-property. to make language change visible.
        entity.subject = `${newLanguage}-${entity.subject}`;
        entity.body = `${newLanguage}-${entity.body}`;
        return entity;
      };
      if ((0, _array.isArray)(object)) {
        json.triggerEmails && json.triggerEmails.forEach((entity, key) => {
          json.triggerEmails[key] = languageBehavior(entity, request.requestHeaders['Accept-Language']);
          json.triggerEmails[key].organisationCampaignId = parseInt(json.triggerEmails[key].organisationCampaignId);
        });
      } else {
        json.triggerEmail = languageBehavior(json.triggerEmail, request.requestHeaders['Accept-Language']);
        json.organisationCampaignId = parseInt(json.organisationCampaignId);
      }
      return json;
    }
  });
});