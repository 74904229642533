define("additive-mc/components/amc-campaign/email-campaign/email-dialog/survey", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/object/computed", "@glimmer/tracking", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _object, _computed, _tracking, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-update this.getSurveys this.locale}}>
    {{#if this.isReadOnly}}
      <UiInput
        @label={{t "emailCampaigns.emails.dialog.surveys.label"}}
        @value={{this._selectedSurvey}}
        @isReadOnly={{true}}
      />
    {{else}}
      <UiSelect
        @classNames="w-100"
        @label={{t "emailCampaigns.emails.dialog.surveys.label"}}
        @placeholder={{t "emailCampaigns.emails.dialog.surveys.placeholder"}}
        @selected={{this._selectedSurvey}}
        @options={{this._surveys}}
        @onChange={{this.onChange}}
        @isTouched={{@isTouched}}
        @objectKey="label"
        @errorMessage={{@changeset.error.ctaLink.validation.firstObject}} as |optionComponent option|
      >
        <optionComponent.item @value={{option}}>
          {{option.label}}
        </optionComponent.item>
      </UiSelect>
    {{/if}}
  </div>
  */
  {
    "id": "Kzw5AnBw",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"getSurveys\"]],[30,0,[\"locale\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isReadOnly\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@isReadOnly\"],[[28,[37,3],[\"emailCampaigns.emails.dialog.surveys.label\"],null],[30,0,[\"_selectedSurvey\"]],true]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],null,[[\"@classNames\",\"@label\",\"@placeholder\",\"@selected\",\"@options\",\"@onChange\",\"@isTouched\",\"@objectKey\",\"@errorMessage\"],[\"w-100\",[28,[37,3],[\"emailCampaigns.emails.dialog.surveys.label\"],null],[28,[37,3],[\"emailCampaigns.emails.dialog.surveys.placeholder\"],null],[30,0,[\"_selectedSurvey\"]],[30,0,[\"_surveys\"]],[30,0,[\"onChange\"]],[30,1],\"label\",[30,2,[\"error\",\"ctaLink\",\"validation\",\"firstObject\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,3,[\"item\"]],null,[[\"@value\"],[[30,4]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"label\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[3,4]]]]],[1,\"\\n\"]],[]]],[13]],[\"@isTouched\",\"@changeset\",\"optionComponent\",\"option\"],false,[\"did-update\",\"if\",\"ui-input\",\"t\",\"ui-select\"]]",
    "moduleName": "additive-mc/components/amc-campaign/email-campaign/email-dialog/survey.hbs",
    "isStrictMode": false
  });
  /**
   * Component which represents a single email (or new) in dialog
   *
   * @class AmcCampaignEmailCampaignEmailDialogSurveyComponent
   */
  let AmcCampaignEmailCampaignEmailDialogSurveyComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec5 = (0, _computed.alias)('_fetchCampaignMeta.isRunning'), _class = class AmcCampaignEmailCampaignEmailDialogSurveyComponent extends _component2.default {
    get _selectedSurvey() {
      var _this$changeset, _this$_surveys;
      let selectedUrl = (_this$changeset = this.changeset) === null || _this$changeset === void 0 ? void 0 : _this$changeset.get('ctaLink');
      if (!selectedUrl) {
        return null;
      }

      // deconstruct survey link to get survey id
      selectedUrl = selectedUrl.replace('{#', '').replace('#}', '');
      const urlParts = selectedUrl.split(':');
      const surveyId = urlParts[urlParts.length - 1];
      const survey = (_this$_surveys = this._surveys) === null || _this$_surveys === void 0 ? void 0 : _this$_surveys.find(survey => survey.value === surveyId);
      return survey === null || survey === void 0 ? void 0 : survey.label;
    }
    constructor() {
      super(...arguments);
      /**
       * The email campaign model
       *
       * @argument campaign
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "campaign", _descriptor, this);
      /**
       * The email changeset
       *
       * @argument changeset
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "changeset", _descriptor2, this);
      /**
       * The current locale
       *
       * @argument locale
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "locale", _descriptor3, this);
      /**
       * Whether the form is touched
       *
       * @argument isTouched
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isTouched", _descriptor4, this);
      /**
       * Whether the input is read only
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor5, this);
      /**
       * The surveys
       *
       * @property _surveys
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "_surveys", _descriptor6, this);
      /**
       * The task to fetch the campaign meta
       *
       * @property _fetchCampaignMeta
       * @type {Task}
       */
      _initializerDefineProperty(this, "_fetchCampaignMeta", _descriptor7, this);
      _initializerDefineProperty(this, "isLoading", _descriptor8, this);
      if (!this.campaign) {
        return;
      }
      this._fetchCampaignMeta = this.campaign.fetchMeta;
      this.getSurveys(true);
    }
    async getSurveys() {
      let init = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const meta = await this._fetchCampaignMeta.perform(this.locale);
      this._surveys = meta.surveys;
      if (!init) {
        var _this$changeset2;
        (_this$changeset2 = this.changeset) === null || _this$changeset2 === void 0 || _this$changeset2.set('ctaLink', null);
      }
    }
    onChange(survey) {
      var _this$changeset3;
      if (!(survey !== null && survey !== void 0 && survey.value) || !this.locale) {
        return;
      }
      (_this$changeset3 = this.changeset) === null || _this$changeset3 === void 0 || _this$changeset3.set('ctaLink', `{#LANDING-PAGE:SURVEY:${this.locale}:${survey.value}#}`);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "campaign", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_surveys", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_fetchCampaignMeta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getSurveys", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getSurveys"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignEmailCampaignEmailDialogSurveyComponent);
});