define("additive-mc/templates/instance/preview/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Zrl0a65a",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@isWide\"],[[28,[37,1],[\"routes.preview.title\"],null],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"intro\"]],null,[[\"@class\",\"@title\",\"@description\",\"@size\",\"@isMultiline\"],[\"mw6\",[28,[37,1],[\"preview.title\"],null],[28,[37,1],[\"preview.description\"],null],\"xl\",true]],null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,[[\"@class\"],[\"pv4 ph1\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"view\"],false,[\"ui-content-views\",\"t\",\"amc-preview/upcoming-emails\"]]",
    "moduleName": "additive-mc/templates/instance/preview/index.hbs",
    "isStrictMode": false
  });
});