define("additive-mc/templates/instance/settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "tdlHke1I",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@theme\"],[[28,[37,1],[\"routes.settings.title\"],null],\"grey\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"action\"]],null,[[\"@icon\",\"@label\",\"@onClick\"],[\"help\",[28,[37,1],[\"global.actions.help\"],null],[28,[37,2],[[28,[37,3],[[30,0,[\"_isFaqDialog\"]]],null],true],null]]],null],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"intro\"]],null,[[\"@title\",\"@size\",\"@isMultiline\"],[[28,[37,1],[\"settings.title\"],null],\"xl\",true]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"tabs\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"header\"]],null,[[\"@isUnderlined\"],[true]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@plan\"],[[30,0,[\"PLAN_PROFESSIONAL\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"tab\"]],null,[[\"@routePath\",\"@tabName\"],[\"instance.settings.index\",[28,[37,1],[\"routes.settings.general\"],null]]],null],[1,\"\\n\\n        \"],[8,[30,4,[\"tab\"]],null,[[\"@routePath\",\"@tabName\"],[\"instance.settings.legals\",[28,[37,1],[\"routes.settings.legals\"],null]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n    \"],[8,[30,3,[\"content\"]],null,[[\"@class\"],[\"flex flex-column\"]],[[\"default\"],[[[[1,\"\\n      \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"_isFaqDialog\"]],[[[1,\"  \"],[8,[39,8],null,[[\"@title\",\"@subtitle\",\"@translationPrefix\",\"@questionKeys\",\"@onClose\"],[[28,[37,1],[\"faq.title\"],null],[28,[37,1],[\"routes.settings.title\"],null],\"faq.settings\",[30,0,[\"_faqKeys\"]],[28,[37,9],[[30,0],[28,[37,3],[[30,0,[\"_isFaqDialog\"]]],null],false],null]]],null],[1,\"\\n\"]],[]],null]],[\"view\",\"nav\",\"tabs\",\"header\"],false,[\"ui-content-views\",\"t\",\"fn\",\"mut\",\"ui-when\",\"component\",\"-outlet\",\"if\",\"ui-faq-dialog\",\"action\"]]",
    "moduleName": "additive-mc/templates/instance/settings.hbs",
    "isStrictMode": false
  });
});