define("additive-mc/mirage/factories/trigger-email", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    availableLanguages() {
      return ['de', 'it'];
    },
    active() {
      return _faker.default.random.boolean();
    },
    subject() {
      return _faker.default.lorem.words();
    },
    body() {
      return _faker.default.lorem.text() + '<br><br>{{CTA}}<br><br>' + _faker.default.lorem.text();
    },
    ctaText() {
      return 'Gutschein jetzt freischalten';
    },
    ctaLink() {
      return _faker.default.internet.url();
    },
    ctaType() {
      return _faker.default.random.arrayElement([null, 'landingpage', 'cta']);
    },
    afterCreate(email, server) {
      const image = server.create('image');
      email.update({
        image: image.toJSON(),
        language: _faker.default.random.arrayElement(email.availableLanguages)
      });
    }
  });
});