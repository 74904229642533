define("additive-mc/mirage/factories/reports-email", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    campaign(i) {
      return `E-Mail ${i}`;
    },
    emails() {
      return _faker.default.random.number();
    },
    impressions() {
      return _faker.default.random.number();
    },
    uniqueOpenings() {
      return _faker.default.random.number();
    },
    clicks() {
      return _faker.default.random.number();
    },
    uniqueClicks() {
      return _faker.default.random.number();
    },
    reservations() {
      return _faker.default.random.number();
    },
    conversions() {
      return _faker.default.random.number();
    }
  });
});