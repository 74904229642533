define("additive-mc/adapters/application", ["exports", "additive-mc/config/environment", "@additive-apps/auth/adapters/application", "@ember/object", "@ember/service", "@ember/string"], function (_exports, _environment, _application, _object, _service, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    currentUser: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    host: _environment.default.APP.apiBaseHost,
    currentLocale: _object.computed.alias('uiAppSettings.currentLocale'),
    headers: (0, _object.computed)('currentLocale', 'session.data.authenticated.access_token', {
      get() {
        const headers = {
          Accept: 'application/vnd.additive+json',
          'Accept-Language': this.currentLocale || 'de',
          'Content-Type': 'application/json'
        };
        return Object.assign({}, this._super('headers'), headers);
      }
    }),
    namespace: (0, _object.computed)('currentUser.currentOrganization.id', {
      get() {
        var _this$currentUser;
        return `${(_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id}`;
      }
    }),
    pathForType() {
      return (0, _string.dasherize)(this._super(...arguments));
    }
  });
});