define("additive-mc/mirage/routes/dashboard/social-campaigns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DashboardSocialCampaigns = void 0;
  const DashboardSocialCampaigns = self => {
    /*
     * Zugestellte Email mit % vergl. zum Vorjahr
     *
     * eindeutige Öffnungen mit % vergl. zum Vorjahr
     *
     * eindeutig Klicks mit % vergl. zum Vorjahr
     *
     * Online Konversionen mit % vergl. zum Vorjahr
     */
    self.get('/dashboard/social-campaigns', () => {
      return {
        count: 1,
        maxCount: 3,
        posts: {
          type: 'compare',
          change: 33.33,
          current: {
            value: 2400,
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: 1800,
            type: 'yearly',
            date: 2018
          }
        },
        impressions: {
          type: 'compare',
          change: -11.6,
          current: {
            value: 1326,
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: 1500,
            type: 'yearly',
            date: 2018
          }
        },
        clicks: {
          type: 'compare',
          change: -16.32,
          current: {
            value: 1066,
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: 1274,
            type: 'yearly',
            date: 2018
          }
        },
        conversions: {
          type: 'compare',
          change: 100,
          current: {
            value: 320,
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: 160,
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.DashboardSocialCampaigns = DashboardSocialCampaigns;
});