define("additive-mc/validations/iso-duration", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isoDurationValidation = void 0;
  const isoDurationValidation = intl => {
    const positiveMessage = intl.t('errors.positive');
    return {
      years: (0, _validators.validateNumber)({
        positive: true,
        allowBlank: true,
        message: positiveMessage
      }),
      months: (0, _validators.validateNumber)({
        positive: true,
        allowBlank: true,
        message: positiveMessage
      }),
      days: (0, _validators.validateNumber)({
        positive: true,
        allowBlank: true,
        message: positiveMessage
      }),
      hours: (0, _validators.validateNumber)({
        positive: true,
        allowBlank: true,
        message: positiveMessage
      }),
      minutes: (0, _validators.validateNumber)({
        positive: true,
        allowBlank: true,
        message: positiveMessage
      }),
      seconds: (0, _validators.validateNumber)({
        positive: true,
        allowBlank: true,
        message: positiveMessage
      })
    };
  };
  _exports.isoDurationValidation = isoDurationValidation;
});