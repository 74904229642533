define("additive-mc/components/amc-campaign/category-card", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _service, _object, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="amc-campaign-category-card relative flex flex-column items-center br1 ph2 pv3 pa4-md text-center bg-secondary--10 bg-secondary--20--hover
  
  
      {{unless @isDisabled "cpointer"}}"
    disabled={{@isDisabled}}
    role="button"
    ...attributes
    {{on "click" this._onClick}}
  >
    {{yield}}
  
    {{#if this.svg}}
      {{svg-jar this.svg}}
    {{/if}}
    <div class="font-lg primary">
      {{@title}}
    </div>
    <div class="font-sm secondary pt2">
      {{@description}}
    </div>
  </div>
  */
  {
    "id": "YQKJs5WG",
    "block": "[[[11,0],[16,0,[29,[\"amc-campaign-category-card relative flex flex-column items-center br1 ph2 pv3 pa4-md text-center bg-secondary--10 bg-secondary--20--hover\\n\\n\\n    \",[52,[51,[30,1]],\"cpointer\"]]]],[16,\"disabled\",[30,1]],[24,\"role\",\"button\"],[17,2],[4,[38,1],[\"click\",[30,0,[\"_onClick\"]]],null],[12],[1,\"\\n  \"],[18,5,null],[1,\"\\n\\n\"],[41,[30,0,[\"svg\"]],[[[1,\"    \"],[1,[28,[35,4],[[30,0,[\"svg\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"font-lg primary\"],[12],[1,\"\\n    \"],[1,[30,3]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"font-sm secondary pt2\"],[12],[1,\"\\n    \"],[1,[30,4]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@isDisabled\",\"&attrs\",\"@title\",\"@description\",\"&default\"],false,[\"unless\",\"on\",\"yield\",\"if\",\"svg-jar\"]]",
    "moduleName": "additive-mc/components/amc-campaign/category-card.hbs",
    "isStrictMode": false
  });
  let CategoryCard = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class CategoryCard extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "type", _descriptor2, this);
      _initializerDefineProperty(this, "title", _descriptor3, this);
      _initializerDefineProperty(this, "description", _descriptor4, this);
      _initializerDefineProperty(this, "svg", _descriptor5, this);
      _initializerDefineProperty(this, "isDisabled", _descriptor6, this);
      _initializerDefineProperty(this, "onClick", _descriptor7, this);
    }
    _onClick() {
      if (this.isDisabled || !this.type) return;
      return this.router.transitionTo(`instance.campaigns.${this.type}`);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "svg", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CategoryCard);
});