define("additive-mc/mirage/routes/reports/index", ["exports", "additive-mc/mirage/routes/reports/ad", "additive-mc/mirage/routes/reports/onPage", "additive-mc/mirage/routes/reports/overview", "additive-mc/mirage/routes/reports/social", "additive-mc/mirage/routes/reports/email", "additive-mc/mirage/routes/reports/referral", "additive-mc/mirage/routes/reports/leads"], function (_exports, _ad, _onPage, _overview, _social, _email, _referral, _leads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "AdReportsConversions", {
    enumerable: true,
    get: function get() {
      return _ad.AdReportsConversions;
    }
  });
  Object.defineProperty(_exports, "AdReportsFacebookAdMetrics", {
    enumerable: true,
    get: function get() {
      return _ad.AdReportsFacebookAdMetrics;
    }
  });
  Object.defineProperty(_exports, "AdReportsKeyIndicators", {
    enumerable: true,
    get: function get() {
      return _ad.AdReportsKeyIndicators;
    }
  });
  Object.defineProperty(_exports, "AdReportsLeadAdMetrics", {
    enumerable: true,
    get: function get() {
      return _ad.AdReportsLeadAdMetrics;
    }
  });
  Object.defineProperty(_exports, "AdReportsMetrics", {
    enumerable: true,
    get: function get() {
      return _ad.AdReportsMetrics;
    }
  });
  Object.defineProperty(_exports, "AdReportsOverall", {
    enumerable: true,
    get: function get() {
      return _ad.AdReportsOverall;
    }
  });
  Object.defineProperty(_exports, "AdReportsPastMarketingActions", {
    enumerable: true,
    get: function get() {
      return _ad.AdReportsPastMarketingActions;
    }
  });
  Object.defineProperty(_exports, "AdReportsReservationTable", {
    enumerable: true,
    get: function get() {
      return _ad.AdReportsReservationTable;
    }
  });
  Object.defineProperty(_exports, "AdReportsReservations", {
    enumerable: true,
    get: function get() {
      return _ad.AdReportsReservations;
    }
  });
  Object.defineProperty(_exports, "AdReportsTiktokLeadAdMetrics", {
    enumerable: true,
    get: function get() {
      return _ad.AdReportsTiktokLeadAdMetrics;
    }
  });
  Object.defineProperty(_exports, "EmailReportsConversions", {
    enumerable: true,
    get: function get() {
      return _email.EmailReportsConversions;
    }
  });
  Object.defineProperty(_exports, "EmailReportsKeyIndicators", {
    enumerable: true,
    get: function get() {
      return _email.EmailReportsKeyIndicators;
    }
  });
  Object.defineProperty(_exports, "EmailReportsMetrics", {
    enumerable: true,
    get: function get() {
      return _email.EmailReportsMetrics;
    }
  });
  Object.defineProperty(_exports, "EmailReportsPastMarketingActions", {
    enumerable: true,
    get: function get() {
      return _email.EmailReportsPastMarketingActions;
    }
  });
  Object.defineProperty(_exports, "EmailReportsReservationTable", {
    enumerable: true,
    get: function get() {
      return _email.EmailReportsReservationTable;
    }
  });
  Object.defineProperty(_exports, "EmailReportsReservations", {
    enumerable: true,
    get: function get() {
      return _email.EmailReportsReservations;
    }
  });
  Object.defineProperty(_exports, "LeadPerformanceCampaignsInsights", {
    enumerable: true,
    get: function get() {
      return _leads.LeadPerformanceCampaignsInsights;
    }
  });
  Object.defineProperty(_exports, "LeadPerformanceChart", {
    enumerable: true,
    get: function get() {
      return _leads.LeadPerformanceChart;
    }
  });
  Object.defineProperty(_exports, "LeadPerformanceMetrics", {
    enumerable: true,
    get: function get() {
      return _leads.LeadPerformanceMetrics;
    }
  });
  Object.defineProperty(_exports, "OnPageReportKeyIndicators", {
    enumerable: true,
    get: function get() {
      return _onPage.OnPageReportKeyIndicators;
    }
  });
  Object.defineProperty(_exports, "ReferralReportsCampaigns", {
    enumerable: true,
    get: function get() {
      return _referral.ReferralReportsCampaigns;
    }
  });
  Object.defineProperty(_exports, "ReferralReportsKeyIndicators", {
    enumerable: true,
    get: function get() {
      return _referral.ReferralReportsKeyIndicators;
    }
  });
  Object.defineProperty(_exports, "ReferralReportsPastMarketingActions", {
    enumerable: true,
    get: function get() {
      return _referral.ReferralReportsPastMarketingActions;
    }
  });
  Object.defineProperty(_exports, "ReportsConversions", {
    enumerable: true,
    get: function get() {
      return _overview.ReportsConversions;
    }
  });
  Object.defineProperty(_exports, "ReportsKeyIndicators", {
    enumerable: true,
    get: function get() {
      return _overview.ReportsKeyIndicators;
    }
  });
  Object.defineProperty(_exports, "ReportsLeadsTable", {
    enumerable: true,
    get: function get() {
      return _overview.ReportsLeadsTable;
    }
  });
  Object.defineProperty(_exports, "ReportsOverview", {
    enumerable: true,
    get: function get() {
      return _overview.ReportsOverview;
    }
  });
  Object.defineProperty(_exports, "ReportsReservationTable", {
    enumerable: true,
    get: function get() {
      return _overview.ReportsReservationTable;
    }
  });
  Object.defineProperty(_exports, "ReportsReservations", {
    enumerable: true,
    get: function get() {
      return _overview.ReportsReservations;
    }
  });
  Object.defineProperty(_exports, "ReportsValueAdded", {
    enumerable: true,
    get: function get() {
      return _overview.ReportsValueAdded;
    }
  });
  Object.defineProperty(_exports, "SocialReportsConversions", {
    enumerable: true,
    get: function get() {
      return _social.SocialReportsConversions;
    }
  });
  Object.defineProperty(_exports, "SocialReportsKeyIndicators", {
    enumerable: true,
    get: function get() {
      return _social.SocialReportsKeyIndicators;
    }
  });
  Object.defineProperty(_exports, "SocialReportsMetrics", {
    enumerable: true,
    get: function get() {
      return _social.SocialReportsMetrics;
    }
  });
  Object.defineProperty(_exports, "SocialReportsPastMarketingActions", {
    enumerable: true,
    get: function get() {
      return _social.SocialReportsPastMarketingActions;
    }
  });
  Object.defineProperty(_exports, "SocialReportsReservationTable", {
    enumerable: true,
    get: function get() {
      return _social.SocialReportsReservationTable;
    }
  });
  Object.defineProperty(_exports, "SocialReportsReservations", {
    enumerable: true,
    get: function get() {
      return _social.SocialReportsReservations;
    }
  });
});