define("additive-mc/mirage/scenarios/default", ["exports", "additive-mc/mirage/scenarios/app/default"], function (_exports, _default2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(server) {
    (0, _default2.default)(server);
  }
});