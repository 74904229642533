define("additive-mc/templates/components/amc-campaign/configuration-item/multilingual-text", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "mPe+FkBA",
    "block": "[[[8,[39,0],null,[[\"@onClose\",\"@save\",\"@item\",\"@childChangeset\",\"@isReadOnly\"],[[30,0,[\"onClose\"]],[30,0,[\"_save\"]],[30,0,[\"item\"]],[30,0,[\"_changeset\"]],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex flex-column flex-grow-1\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@label\",\"@options\",\"@selected\",\"@onChange\"],[[28,[37,2],[\"components.amc-campaign-configuration.multilingual-text.labelLanguage\"],null],[30,0,[\"_organizationLanguages\"]],[28,[37,2],[[28,[37,3],[\"global.languages.\",[30,0,[\"_selectedLanguage\"]]],null]],null],[28,[37,4],[[30,0],[28,[37,5],[[30,0,[\"_selectedLanguage\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"item\"]],null,[[\"@value\"],[[30,3,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,3,[\"name\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[2,3]]]]],[1,\"\\n\\n      \"],[8,[39,6],[[16,\"data-test-config-item-multilingual-text-content\",true]],[[\"@label\",\"@value\",\"@isDisabled\",\"@textarea\"],[[28,[37,2],[\"components.amc-campaign-configuration.multilingual-text.labelText\"],null],[28,[37,7],[[30,0,[\"_changeset\"]],[30,0,[\"_selectedLanguage\"]]],null],[30,0,[\"_isDisabled\"]],true]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"optionComponent\",\"language\"],false,[\"amc-campaign/configuration-item/with-modal\",\"ui-select\",\"t\",\"concat\",\"action\",\"mut\",\"ui-input\",\"get\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/multilingual-text.hbs",
    "isStrictMode": false
  });
});