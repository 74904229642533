define("additive-mc/adapters/organization-campaign", ["exports", "additive-mc/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Base adapter for all organization-campaign types,
   * intended to be extended per type.
   * `type` must be set
   */
  var _default = _exports.default = _application.default.extend({
    type: null,
    urlForMultiple(modelName, snapshot) {
      var _snapshot$adapterOpti;
      let url = this.buildURL() + `/organisation-campaigns/${this.type}`;
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.campaignId) {
        url += `/${snapshot.adapterOptions.campaignId}`;
      }
      return url;
    },
    urlForSingle(id, modelName, snapshot) {
      return `${this.urlForMultiple(modelName, snapshot)}/${id}`;
    },
    urlForFindAll() {
      return this.urlForMultiple(...arguments);
    },
    urlForCreateRecord() {
      return this.urlForMultiple(...arguments);
    },
    urlForDeleteRecord() {
      return this.urlForSingle(...arguments);
    },
    urlForFindRecord() {
      return this.urlForSingle(...arguments);
    },
    urlForUpdateRecord() {
      return this.urlForSingle(...arguments);
    },
    urlForQuery() {
      return this.urlForMultiple(...arguments);
    }
  });
});