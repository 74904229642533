define("additive-mc/mirage/factories/search-ad-campaign", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    title(i) {
      return `Search Ad Campaign ${i}`;
    },
    type: () => 'Google::SearchAdsCampaign',
    description(i) {
      return `${this.title} ${i}`;
    },
    code: () => _faker.default.random.arrayElement(['usecase1', 'usecase2a', 'usecase2b', 'usecase3a', 'usecase3b', 'usecase4']),
    startDate: () => '2023-02-05',
    endDate: () => '2023-06-05',
    searchPartner: _faker.default.random.boolean(),
    displayNetwork: _faker.default.random.boolean(),
    dailyBudget: () => _faker.default.random.number(5000),
    postfix: () => _faker.default.lorem.word(),
    hasBeenPublished: true,
    adSets: [{
      name: 'Anzeigengruppe 1',
      adTemplates: [{
        id: '2',
        label: 'Anzeige1'
      }, {
        id: '3',
        label: 'Anzeige2'
      }],
      keywords: [{
        id: 1,
        name: 'keyword 1',
        count: 15
      }, {
        id: 2,
        name: 'keyword 2',
        count: 6
      }, {
        id: 3,
        name: 'keyword 3',
        count: 15
      }, {
        id: 4,
        name: 'keyword 4',
        count: 6
      }, {
        id: 5,
        name: 'keyword 5',
        count: 15
      }]
    }, {
      name: 'Anzeigengruppe 2',
      adTemplates: [{
        id: '5',
        label: 'Anzeige4'
      }, {
        id: '3',
        label: 'Anzeige2'
      }],
      keywords: [{
        id: 1,
        name: 'keyword 1',
        count: 15
      }]
    }, {
      name: 'Anzeigengruppe 3',
      adTemplates: [{
        id: '3',
        label: 'Anzeige2'
      }, {
        id: '2',
        label: 'Anzeige1'
      }],
      keywords: [{
        id: 1,
        name: 'keyword 1',
        count: 15
      }, {
        id: 4,
        name: 'keyword 4',
        count: 6
      }, {
        id: 5,
        name: 'keyword 5',
        count: 15
      }]
    }],
    state: () => _faker.default.random.arrayElement(['published', 'expired', 'unpublished', 'archived', 'paused'])
  });
});