define("additive-mc/components/amc-campaign/ads/advertisements/publish-dialog/item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/object/computed", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _object, _computed, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class={{concat
      "publish-dialog__item flex items-center ph2 pv1 font-sm primary bb b--black-10 "
      (if this.isError "error bg-red" "")
    }}
  >
    <UiLoading @state={{this.state}} />
    <div class="flex flex-column flex-row-sm justify-between w-100">
      <div class="ml2 flex flex-column flex-row-sm flex-grow-1 items-center-sm">
        <span class={{concat "title pv1-sm " (if this.isError "white" "primary")}}>
          {{this.title}}
        </span>
        <span class={{if this.isError "white" "secondary"}}>
          {{#if this.isError}}
            {{this.errorMessage}}
          {{else}}
            {{this.successMessage}}
          {{/if}}
        </span>
      </div>
    </div>
  </div>
  */
  {
    "id": "SMeof3LZ",
    "block": "[[[10,0],[15,0,[28,[37,0],[\"publish-dialog__item flex items-center ph2 pv1 font-sm primary bb b--black-10 \",[52,[30,0,[\"isError\"]],\"error bg-red\",\"\"]],null]],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@state\"],[[30,0,[\"state\"]]]],null],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-column flex-row-sm justify-between w-100\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ml2 flex flex-column flex-row-sm flex-grow-1 items-center-sm\"],[12],[1,\"\\n      \"],[10,1],[15,0,[28,[37,0],[\"title pv1-sm \",[52,[30,0,[\"isError\"]],\"white\",\"primary\"]],null]],[12],[1,\"\\n        \"],[1,[30,0,[\"title\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[15,0,[52,[30,0,[\"isError\"]],\"white\",\"secondary\"]],[12],[1,\"\\n\"],[41,[30,0,[\"isError\"]],[[[1,\"          \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,0,[\"successMessage\"]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"concat\",\"if\",\"ui-loading\"]]",
    "moduleName": "additive-mc/components/amc-campaign/ads/advertisements/publish-dialog/item.hbs",
    "isStrictMode": false
  });
  let AmcCampaignAdsAdvertisementsPublishDialogItemComponent = _exports.default = (_dec = (0, _computed.equal)('state', 'success'), _dec2 = (0, _computed.equal)('state', 'error'), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _object.computed)('errorMessage'), _class = class AmcCampaignAdsAdvertisementsPublishDialogItemComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isSuccess", _descriptor, this);
      _initializerDefineProperty(this, "isError", _descriptor2, this);
      /**
       * the title of the item
       *
       * @argument title
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor3, this);
      /**
       * the success message of the item
       *
       * @argument successMessage
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "successMessage", _descriptor4, this);
      /**
       * the error message of the item
       *
       * @argument errorMessage
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "errorMessage", _descriptor5, this);
    }
    /**
     * the state of the item
     *  - error
     *  - success
     *
     * @computed state
     * @type {String}
     */
    get state() {
      if (this.errorMessage) {
        return 'error';
      }
      return 'success';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "isSuccess", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isError", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "successMessage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "state", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "state"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignAdsAdvertisementsPublishDialogItemComponent);
});