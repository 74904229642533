define("additive-mc/routes/instance/campaigns/search-ad/index/search-ad", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceCampaignsSearchAdIndexSearchAdRoute extends _route.default {
    async model(params) {
      try {
        this.searchAdCampaign = await this.store.findRecord('search-ad-campaign', params.search_ad_campaign_id);
        return {
          searchAdCampaign: this.searchAdCampaign
        };
      } catch (e) {
        this.isError = true;
      }
    }
    async setupController(controller, model) {
      super.setupController(...arguments);
      controller.fetchMeta.perform();
      controller.campaignId = model.searchAdCampaign.id;
      controller.fetchTargetGroups.perform();
      controller.fetchExtensions.perform();
      controller.fetchAdSets.perform();
      controller.fetchPendingUpdates.perform();
    }
    renderTemplate() {
      this.render('instance/campaigns/search-ad/index/search-ad', {
        into: 'instance/campaigns/search-ad',
        outlet: 'detail'
      });
    }
  }
  _exports.default = InstanceCampaignsSearchAdIndexSearchAdRoute;
});