define("additive-mc/controllers/instance/widgets/widget", ["exports", "additive-mc/config/environment", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "@ember/object/computed", "@ember/utils", "ember-changeset", "ember-concurrency", "lodash.merge", "@additive-apps/popup-widget", "additive-mc/utils/widget-constants", "additive-mc/utils/widget-default", "additive-mc/utils/fonts", "additive-mc/utils/widget-utils"], function (_exports, _environment, _controller, _object, _tracking, _service, _computed, _utils, _emberChangeset, _emberConcurrency, _lodash, _popupWidget, _widgetConstants, _widgetDefault, _fonts, _widgetUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const WIDGET_SELECTOR = '#widget-container';

  // Define here the properties which can be set to null
  const NULLABLE_PROPERTIES = ['backgroundImage'];
  let InstanceWidgetsWidgetController = _exports.default = (_dec = (0, _computed.alias)('currentUser.currentOrganization.id'), _dec2 = (0, _computed.bool)('options.config.content.image'), _dec3 = (0, _computed.bool)('options.config.content.button'), _dec4 = (0, _object.computed)('options.config.size'), _dec5 = (0, _object.computed)('options.config.content.type'), _dec6 = (0, _emberConcurrency.task)(function* () {
    this._setupChangeset();
    const response = yield this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/${this.orgId}/corporate-design`);
    const {
      corporateDesign
    } = yield response.json();
    this.corporateDesignTypography = corporateDesign.typography;
    let {
      fonts
    } = corporateDesign;
    this.fonts = fonts;
    if (corporateDesign.fonts) {
      yield this.loadFonts(corporateDesign.fonts);
    }
    this.fontOptions = (0, _fonts.getFontOptions)(fonts);
    this.colorOptions = (0, _widgetDefault.getColorOptions)(corporateDesign.colors);

    // setup the instance to have the same API as the other additive widgets
    this.instance = {
      init: (options, styles) => _popupWidget.default.init(WIDGET_SELECTOR, {
        options,
        styles
      }),
      update: (options, styles) => {
        _popupWidget.default.update(WIDGET_SELECTOR, {
          options,
          styles
        });
      }
    };
  }), _class = class InstanceWidgetsWidgetController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "uiToast", _descriptor5, this);
      _initializerDefineProperty(this, "changeset", _descriptor6, this);
      _initializerDefineProperty(this, "isDiscardChangesDialog", _descriptor7, this);
      _initializerDefineProperty(this, "options", _descriptor8, this);
      _initializerDefineProperty(this, "sizeOptions", _descriptor9, this);
      _initializerDefineProperty(this, "fontOptions", _descriptor10, this);
      _initializerDefineProperty(this, "colorOptions", _descriptor11, this);
      _initializerDefineProperty(this, "corporateDesignTypography", _descriptor12, this);
      _initializerDefineProperty(this, "fonts", _descriptor13, this);
      _initializerDefineProperty(this, "instance", _descriptor14, this);
      _initializerDefineProperty(this, "modelData", _descriptor15, this);
      _initializerDefineProperty(this, "orgId", _descriptor16, this);
      _initializerDefineProperty(this, "hasImage", _descriptor17, this);
      _initializerDefineProperty(this, "hasButton", _descriptor18, this);
      _initializerDefineProperty(this, "setup", _descriptor19, this);
      this.sizeOptions = _widgetConstants.SIZE_OPTIONS.map(value => ({
        label: this.intl.t(`widgets.configurator.sizes.${value}`),
        value
      }));
      this.typeOptions = _widgetConstants.CONTENT_OPTIONS.map(value => ({
        label: this.intl.t(`widgets.configurator.types.${value.type}`),
        value
      }));
      this.options = {
        config: _objectSpread(_objectSpread({}, _widgetConstants.DEFAULT_TYPOGRAPHY_CONFIG), _widgetConstants.DEFAULT_PREVIEW_CONFIG)
      };
      this.tabs = [{
        title: this.intl.t('widgets.configurator.general.title'),
        name: 'general'
      }, {
        title: this.intl.t('widgets.configurator.colors.title'),
        name: 'colors'
      }, {
        title: this.intl.t('widgets.configurator.typography.title'),
        name: 'typography'
      }];
      this.activeTab = this.tabs[0];
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get positionOptions() {
      const {
        size
      } = this.options.config;
      return _widgetConstants.POSITION_OPTIONS[size].map(value => ({
        label: this.intl.t(`widgets.configurator.positions${size === 'lg' ? 'Lg' : ''}.${value}`),
        value
      }));
    }
    get isInfoPopup() {
      return this.options.config.content.type === 'info';
    }
    get selectedContentType() {
      var _this$typeOptions$fin;
      return (_this$typeOptions$fin = this.typeOptions.find(c => c.value.type === this.options.config.content.type)) === null || _this$typeOptions$fin === void 0 ? void 0 : _this$typeOptions$fin.label;
    }
    loadFonts(fonts) {
      const fontPromises = [];
      Object.keys(fonts).forEach(key => {
        Object.keys(fonts[key]).forEach(type => {
          fonts[key][type] && fontPromises.push((0, _fonts.loadFont)(`${key}${type}`, fonts[key][type].url));
        });
      });
      return Promise.all(fontPromises);
    }
    updateOptions(update, key, value) {
      if (key === 'config.size') {
        (0, _object.set)(this.options.config, 'position', _widgetConstants.POSITION_OPTIONS[value][0]);
      }
      const data = update(key, value, 'options');
      this.options = data.options;
    }
    onBack() {
      if (this.changeset.get('isDirty')) {
        this.isDiscardChangesDialog = true;
        return;
      }
      this.router.transitionTo('instance.widgets');
    }
    confirmDiscard() {
      this.changeset.rollback();
      this.isDiscardChangesDialog = false;
      this.router.transitionTo('instance.widgets');
    }
    onImageCheck(update, checked) {
      const data = update('config.content.image', checked ? _objectSpread({}, _widgetConstants.DEFAULT_IMAGE) : null, 'options');
      this.options = data.options;
    }
    onButtonCheck(update, checked) {
      update('config.content.button', checked, 'options');
    }
    onUpdate(onUpdate, key, val) {
      const data = onUpdate(key, val);
      this.changeset.set('options', data.options);
      this.changeset.set('styles', data.styles);
    }
    async save() {
      try {
        const changesetChanges = this.changeset.get('changes');
        const changesObject = (0, _widgetUtils.nestChangesetChanges)(changesetChanges);
        let changedKeys = [];
        Object.keys(changesObject).forEach(propertyKey => {
          if (typeof changesObject[propertyKey] === 'object' && (0, _utils.isPresent)(changesObject[propertyKey])) {
            this._getChangedPropertyKeys(changesObject[propertyKey], this.modelData[propertyKey], changedKeys, propertyKey);
            return;
          }
          changedKeys.push(propertyKey);
        });

        // apply changes to model
        changedKeys.forEach(changedKey => {
          const splittedKey = changedKey.split('.');

          // get new value of the changed key
          const newValue = splittedKey.reduce((object, key) => object[key], changesObject);

          // initialize key at model if it not exists
          let alreadyIntialized = '';
          splittedKey.forEach(keyPart => {
            let key = alreadyIntialized ? `${alreadyIntialized}${keyPart}` : keyPart;
            if (!this.model.get(key)) {
              this.model.set(key, {});
            }
            alreadyIntialized = `${alreadyIntialized}${keyPart}.`;
          });
          this.model.set(changedKey, newValue);
        });
        const tasks = [];
        tasks.push(this.model.save());
        tasks.push((0, _emberConcurrency.timeout)(400));
        await Promise.all(tasks);
        this._setupChangeset();
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success',
          parent: '.aw-configurator__preview'
        });
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error',
          parent: '.aw-configurator__preview'
        });
      }
    }

    /**
     * compares two objects and returns the keys of the properties that changed
     *
     * @param {*} data the object which may have changed properties
     * @param {*} compare the object without changes
     * @param {*} changedKeys an array where the keys of the changed properties are pushed
     * @param {*} keyPrefix the concatenated keys to keep track on recursive calls
     *
     * @function _getChangedPropertyKeys
     */
    _getChangedPropertyKeys(data, compare, changedKeys, keyPrefix) {
      Object.keys(data).forEach(propertyKey => {
        const dataProperty = data[propertyKey];
        const compareProperty = compare && compare[propertyKey];
        if (!(0, _utils.isPresent)(dataProperty) && NULLABLE_PROPERTIES.includes(propertyKey)) {
          changedKeys.push(`${keyPrefix}.${propertyKey}`);
        }
        if (typeof dataProperty === 'object' && (0, _utils.isPresent)(dataProperty)) {
          this._getChangedPropertyKeys(dataProperty, compareProperty, changedKeys, `${keyPrefix}.${propertyKey}`);
          return;
        }
        if (data[propertyKey] !== null && dataProperty !== compareProperty) {
          changedKeys.push(`${keyPrefix}.${propertyKey}`);
        }
      });
    }
    _setupChangeset() {
      var _this$model;
      const modelStyles = (0, _lodash.default)({}, (_this$model = this.model) === null || _this$model === void 0 ? void 0 : _this$model.styles);

      // setup styles with default values
      let styles = (0, _lodash.default)({}, {
        colors: _widgetDefault.StyleValues.colors,
        typography: _widgetDefault.StyleValues.typography,
        globals: _widgetDefault.StyleValues.globals,
        fonts: _widgetDefault.DEFAULT_FONTS_CONFIG
      }, {
        colors: modelStyles === null || modelStyles === void 0 ? void 0 : modelStyles.colors,
        typography: modelStyles === null || modelStyles === void 0 ? void 0 : modelStyles.typography,
        globals: modelStyles === null || modelStyles === void 0 ? void 0 : modelStyles.globals,
        fonts: modelStyles === null || modelStyles === void 0 ? void 0 : modelStyles.fonts
      });
      this.modelData = (0, _lodash.default)({}, this.model, {
        styles
      });
      this.changeset = new _emberChangeset.default((0, _lodash.default)({}, this.modelData));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isDiscardChangesDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "options", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "sizeOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "fontOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "colorOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "corporateDesignTypography", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "fonts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "instance", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "modelData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "orgId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "hasImage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "hasButton", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "positionOptions", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "positionOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInfoPopup", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isInfoPopup"), _class.prototype), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "setup", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBack", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onBack"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmDiscard", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "confirmDiscard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onImageCheck", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onImageCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onButtonCheck", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onButtonCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
});