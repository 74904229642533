define("additive-mc/adapters/depiction", ["exports", "additive-mc/adapters/organization-campaign"], function (_exports, _organizationCampaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _organizationCampaign.default.extend({
    type: 'ad-campaigns',
    handleResponse(status) {
      if (status >= 400 && status < 500) {
        const error = arguments[2];
        if (error) {
          return error;
        } else {
          return new Error(status);
        }
      }
      return this._super(...arguments);
    },
    urlForMultiple(_, snapshot) {
      var _snapshot$adapterOpti, _snapshot$adapterOpti2;
      let url = this.buildURL() + `/organisation-campaigns/${this.type}`;
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.type) {
        url += `/${snapshot.adapterOptions.type}`;
      }
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti2 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti2 !== void 0 && _snapshot$adapterOpti2.campaignId) {
        url += `/${snapshot.adapterOptions.campaignId}`;
      }
      return `${url}/depictions`;
    }
  });
});