define("additive-mc/routes/auth/callback", ["exports", "@additive-apps/auth/routes/auth/callback"], function (_exports, _callback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _callback.default;
    }
  });
});