define("additive-mc/templates/components/amc-route-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ihfe0lp1",
    "block": "[[[41,[30,0,[\"isEmpty\"]],[[[1,\"  \"],[1,[28,[35,1],[\"thumb-up\"],[[\"class\"],[\"white\"]]]],[1,\"\\n  \"],[10,0],[14,0,\"font-sm white ph2\"],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"svg\"]]],[[\"class\"],[\"blue\"]]]],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-column flex-grow-1 ph2\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"font-sm blue\"],[12],[1,\"\\n      \"],[1,[30,0,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,1],[\"arrow-right\"],[[\"class\"],[\"blue\"]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"svg-jar\"]]",
    "moduleName": "additive-mc/templates/components/amc-route-card.hbs",
    "isStrictMode": false
  });
});