define("additive-mc/adapters/email-campaign-contribution", ["exports", "additive-mc/config/environment", "additive-mc/adapters/organization-campaign"], function (_exports, _environment, _organizationCampaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _organizationCampaign.default.extend({
    type: 'guest-lifecycle-mail-campaigns',
    urlForMultiple(modelName, snapshot) {
      var _snapshot$adapterOpti;
      let url = `${this.buildURL()}/${this.type}`;
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.campaignId) {
        url += `/${snapshot.adapterOptions.campaignId}`;
      }
      return `${url}/contributions`;
    },
    urlForSingle(id, modelName, snapshot) {
      return `${this.urlForMultiple(modelName, snapshot)}/${id}`;
    },
    urlForFindRecord() {
      this.host = _environment.default.APP.apiBaseHostV2;
      return this.urlForSingle(...arguments);
    },
    urlForQuery() {
      this.host = _environment.default.APP.apiBaseHostV2;
      return this.urlForMultiple(...arguments);
    }
  });
});