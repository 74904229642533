define("additive-mc/components/amc-campaign/configuration-item/timespan", ["exports", "@ember/component", "@ember/object", "additive-mc/utils/iso-duration", "additive-mc/validations/iso-duration", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-concurrency"], function (_exports, _component, _object, _isoDuration, _isoDuration2, _service, _emberChangeset, _emberChangesetValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which handles campaigns configuration-item for `timespan`.
   *
   * `timespan` is a iso8601-duration value which indicates a duration
   *
   * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
   *
   * @class amc-campaigns/configuration-item/timespan
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    tagName: '',
    /**
     * The configuration-item
     *
     * @property item
     * @default null
     */
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * The internal changeset of the item
     *
     * @property _durationChangeset
     * @default null
     * @private
     */
    _durationChangeset: null,
    /**
     * Changesets underlying object;
     *
     * @property _resource
     * @default null
     * @private
     */
    _resource: null,
    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {},
    init() {
      this._super(...arguments);
      let resource = {
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
      if (this.item && this.item.value) {
        resource = Object.assign(resource, (0, _isoDuration.parse)(this.item.value));
      }
      const validation = (0, _isoDuration2.isoDurationValidation)(this.intl);
      const changeset = new _emberChangeset.default(resource, (0, _emberChangesetValidations.default)(validation), validation);
      (0, _object.setProperties)(this, {
        _durationChangeset: changeset,
        _resource: resource
      });
    },
    /**
     * Build duration string, sets it to parents changeset and trigger save
     *
     * @function _save
     * @type {Task}
     * @param {Changeset} parentChangeset The changeset of the `with-modal` hoc
     * @private
     */
    _save: (0, _emberConcurrency.task)(function* (parentChangeset) {
      const tasks = [];
      const snapshot = this.item.value;
      try {
        yield this._durationChangeset.validate();
        if (this._durationChangeset.get('isInvalid')) {
          return;
        }

        // Will update the underlying object, which in this case is `this._resource`
        this._durationChangeset.execute();
        parentChangeset.set('value', (0, _isoDuration.toString)(this._resource));
        parentChangeset.execute();
        tasks.push(this.onSave());
        tasks.push((0, _emberConcurrency.timeout)(250));
        yield (0, _emberConcurrency.all)(tasks);
      } catch (error) {
        // reset
        (0, _object.set)(this, 'item.value', snapshot);
      }
    })
  });
});