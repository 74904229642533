define("additive-mc/routes/instance/widgets/widget", ["exports", "@ember/routing/route", "@additive-apps/ui/mixins/ui-full-width-view"], function (_exports, _route, _uiFullWidthView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceWidgetsWidgetRoute extends _route.default.extend(_uiFullWidthView.default) {
    renderTemplate() {
      this.render('instance/widgets/widget', {
        into: 'application'
      });
    }
    model(params) {
      return this.store.findRecord('widget', params.widget_id);
    }
  }
  _exports.default = InstanceWidgetsWidgetRoute;
});