define("additive-mc/components/ui-skeleton-card", ["exports", "@additive-apps/ui/components/ui-skeleton-card"], function (_exports, _uiSkeletonCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiSkeletonCard.default;
    }
  });
});