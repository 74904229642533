define("additive-mc/routes/instance/preview/email", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    store: (0, _service.inject)(),
    uiFilter: (0, _service.inject)(),
    model(params) {
      const model = this.store.peekRecord('email-preview', params.email_id);
      if (model) {
        return model;
      }
      return this.store.findRecord('email-preview', params.email_id);
    },
    setupController() {
      this._super(...arguments);
      this.uiFilter.register('subscribers', 'subscriber');
    },
    resetController() {
      this._super(...arguments);
      this.uiFilter.unregister('subscribers');
    }
  });
});