define("additive-mc/utils/widget-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UTM_OPTIONS = _exports.SM_POSTITION_OPTIONS = _exports.SIZE_OPTIONS = _exports.POSITION_OPTIONS = _exports.MD_POSTITION_OPTIONS = _exports.LG_POSTITION_OPTIONS = _exports.EVENT_OPTIONS = _exports.DEFAULT_PREVIEW_CONFIG = _exports.DEFAULT_IMAGE = _exports.CONTENT_OPTIONS = void 0;
  const DEFAULT_IMAGE = _exports.DEFAULT_IMAGE = {
    url: 'https://multimedia.additive-apps.tech/media/440c51b6342d89b28511688ad40f8416'
  };
  const DEFAULT_LEAD = {
    type: 'lead',
    headline: 'Anmelden und profitieren! ',
    text: 'Melden Sie sich jetzt zu unserem Newsletter an und profitieren Sie von spannenden Neuigkeiten, wertvollen Tipps und exklusiven Angeboten – Urlaubsfeeling für Ihr Postfach!',
    image: DEFAULT_IMAGE,
    showSalutation: true,
    cta: 'Anmelden'
  };
  const DEFAULT_VOUCHER = {
    type: 'voucher',
    headline: 'Anmelden und Gutschein erhalten!',
    text: 'Melden Sie sich jetzt zu unserem Newsletter an und profitieren Sie von wertvollen Tipps und exklusiven Angeboten. Obendrauf erhalten Sie einen Gutschein im Wert von 50€!',
    image: DEFAULT_IMAGE,
    requestSalutation: true,
    cta: 'Gutschein erhalten'
  };
  const DEFAULT_INFO = {
    type: 'info',
    headline: 'Information',
    text: 'Das ist eine Information',
    image: DEFAULT_IMAGE,
    requestSalutation: true,
    cta: 'OK'
  };
  const DEFAULT_PREVIEW_CONFIG = _exports.DEFAULT_PREVIEW_CONFIG = {
    content: DEFAULT_LEAD,
    size: 'md',
    position: 'bottom-right',
    isFixed: false,
    rules: {
      rule: 'on-view',
      scrollPosition: 0,
      delay: 0,
      utmParam: null
    },
    visibility: {
      device: 'all',
      visitors: 'all',
      excludeKnownContacts: false,
      excludedHosts: []
    }
  };
  const SIZE_OPTIONS = _exports.SIZE_OPTIONS = ['sm', 'md', 'lg'];
  const SM_POSTITION_OPTIONS = _exports.SM_POSTITION_OPTIONS = ['bottom-right', 'bottom-left', 'top-right', 'top-left'];
  const MD_POSTITION_OPTIONS = _exports.MD_POSTITION_OPTIONS = ['bottom-right', 'bottom-left', 'top-right', 'top-left', 'center'];
  const LG_POSTITION_OPTIONS = _exports.LG_POSTITION_OPTIONS = ['top-right', 'top-left'];
  const POSITION_OPTIONS = _exports.POSITION_OPTIONS = {
    sm: SM_POSTITION_OPTIONS,
    md: MD_POSTITION_OPTIONS,
    lg: LG_POSTITION_OPTIONS
  };
  const EVENT_OPTIONS = _exports.EVENT_OPTIONS = ['on-exit', 'on-scroll', 'on-view'];
  const UTM_OPTIONS = _exports.UTM_OPTIONS = ['none', 'campaign', 'medium', 'source'];
  const CONTENT_OPTIONS = _exports.CONTENT_OPTIONS = [DEFAULT_LEAD, DEFAULT_VOUCHER, DEFAULT_INFO];
});