define("additive-mc/components/amc-campaign/configuration-item/text", ["exports", "@ember/component", "@ember/object", "ember-concurrency"], function (_exports, _component, _object, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which handles campaigns configuration-item for `text`.
   *
   * `text` is an unformatted string
   *
   * @class amc-campaigns/configuration-item/text
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    /**
     * The configuration-item
     *
     * @property item
     * @default null
     */
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * The initial value
     *
     * @property _initialValue
     * @private
     */
    _initialValue: null,
    init() {
      this._super(...arguments);
      this._initialValue = this.item && this.item.value;
    },
    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {},
    /**
     * Close event
     *
     * @function onClose
     * @return {Promise} Returns a promise
     * @public
     */
    onClose() {},
    /**
     * Save wrapper to reset item.value on error
     */
    _save: (0, _emberConcurrency.task)(function* () {
      const tasks = [];
      try {
        tasks.push(this.onSave());
        tasks.push((0, _emberConcurrency.timeout)(400));
        yield (0, _emberConcurrency.all)(tasks);
      } catch (error) {
        // reset
        (0, _object.set)(this, 'item.value', this._initialValue);
      }
    })
  });
});