define("additive-mc/models/on-page-content", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    availableLanguages: (0, _model.attr)(),
    language: (0, _model.attr)(),
    headline: (0, _model.attr)('string'),
    text: (0, _model.attr)('string'),
    active: (0, _model.attr)('boolean'),
    image: (0, _model.attr)(),
    ctaText: (0, _model.attr)('string'),
    campaignId: (0, _model.attr)(),
    emailSeriesId: (0, _model.attr)(),
    form: (0, _model.attr)()
  });
});