define("additive-mc/controllers/instance/campaigns/search-ad/index/search-ad", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "ember-concurrency", "ember-changeset", "ember-changeset-validations", "additive-mc/validations/search-ad-campaign"], function (_exports, _controller, _object, _tracking, _service, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _searchAdCampaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsSearchAdIndexSearchAdController = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    try {
      const adapter = this.store.adapterFor('search-ad-campaign');
      const url = adapter.urlForFindAll('search-ad-campaign');
      const response = yield this.authenticatedFetch.fetch(`${url}/metadata`);
      this.meta = yield response.json();
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  }), _dec2 = (0, _emberConcurrency.task)(function* () {
    try {
      this.showUpdateNotification = false;
      const adapter = this.store.adapterFor('search-ad-campaign');
      const url = adapter.urlForSingle(this.model.searchAdCampaign.id, 'search-ad-campaign');
      const response = yield this.authenticatedFetch.fetch(`${url}/pending-updates`);
      const pendingUpdates = yield response.json();
      this.pendingUpdates = pendingUpdates;

      // Check if there are any pending updates
      const keys = Object.keys(pendingUpdates);
      keys.forEach(key => {
        if (pendingUpdates[key].length > 0) {
          this.showUpdateNotification = true;
          return;
        }
      });
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _dec3 = (0, _emberConcurrency.task)(function* () {
    try {
      let peekedExtensions = this.store.peekAll('extension');
      let fetchedExtensions = null;
      let tasks = [];
      if (!peekedExtensions.length) {
        tasks.push(this.store.findAll('extension'));
        tasks.push((0, _emberConcurrency.timeout)(400));
        const [extensions] = yield (0, _emberConcurrency.all)(tasks);
        fetchedExtensions = extensions;
      }
      const extensions = peekedExtensions || fetchedExtensions;
      if (!extensions || extensions.length === 0) {
        return;
      }
      this.allExtensions = extensions;
    } catch (e) {
      this.uiDialog.showError();
    }
  }), _dec4 = (0, _emberConcurrency.task)(function* () {
    try {
      let allTargetGroups = this.store.peekAll('target-group');
      if (!allTargetGroups.length) {
        let fetchTargetGroupsTask = this.store.findAll('target-group');
        let tasks = [];
        tasks.push(fetchTargetGroupsTask);
        tasks.push((0, _emberConcurrency.timeout)(400));
        const [targetGroups] = yield (0, _emberConcurrency.all)(tasks);
        allTargetGroups = targetGroups;
      }
      if (!allTargetGroups || allTargetGroups.length === 0) {
        return;
      }
      const selectableTargetGroups = allTargetGroups.map(targetGroup => {
        return {
          value: targetGroup.id,
          label: targetGroup.name
        };
      });
      this.selectableTargetGroups = selectableTargetGroups;
    } catch (e) {
      this.uiDialog.showError();
    }
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    try {
      const adapter = this.store.adapterFor('search-ad-campaign');
      const url = adapter.urlForSingle(this.model.searchAdCampaign.id, 'search-ad-campaign');
      const endpoint = `${url}/ad-sets`;
      const response = yield this.authenticatedFetch.fetch(endpoint);
      const {
        adSets
      } = yield response.json();
      this.adSets = adSets;
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _dec6 = (0, _emberConcurrency.task)(function* (editedAdSet) {
    try {
      const adapter = this.store.adapterFor('search-ad-campaign');
      const url = adapter.urlForSingle(this.model.searchAdCampaign.id, 'search-ad-campaign');
      const endpoint = `${url}/ad-sets/${editedAdSet.id}`;
      const response = yield this.authenticatedFetch.fetch(endpoint, {
        method: 'PUT',
        body: JSON.stringify(editedAdSet)
      });
      const adSet = yield response.json();
      if (adSet) {
        const index = this.adSets.findIndex(item => {
          return item.id === adSet.id;
        });
        this.adSets[index] = adSet;
      }
      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _class = class InstanceCampaignsSearchAdIndexSearchAdController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor6, this);
      _initializerDefineProperty(this, "uiToast", _descriptor7, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor8, this);
      _initializerDefineProperty(this, "campaignId", _descriptor9, this);
      _initializerDefineProperty(this, "meta", _descriptor10, this);
      _initializerDefineProperty(this, "selectableTargetGroups", _descriptor11, this);
      _initializerDefineProperty(this, "pendingUpdates", _descriptor12, this);
      _initializerDefineProperty(this, "showUpdateNotification", _descriptor13, this);
      _initializerDefineProperty(this, "isPendingUpdatesDialog", _descriptor14, this);
      _initializerDefineProperty(this, "isPublishDialog", _descriptor15, this);
      _initializerDefineProperty(this, "isAdEditDialog", _descriptor16, this);
      _initializerDefineProperty(this, "selectedAd", _descriptor17, this);
      _initializerDefineProperty(this, "adSets", _descriptor18, this);
      _initializerDefineProperty(this, "ads", _descriptor19, this);
      _initializerDefineProperty(this, "allExtensions", _descriptor20, this);
      _initializerDefineProperty(this, "fetchMeta", _descriptor21, this);
      _initializerDefineProperty(this, "fetchPendingUpdates", _descriptor22, this);
      /**
       * Fetches the selectable extensions
       *
       * @function fetchExtensions
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchExtensions", _descriptor23, this);
      /**
       * Fetches the selectable target-groups
       *
       * @function fetchTargetGroups
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchTargetGroups", _descriptor24, this);
      _initializerDefineProperty(this, "fetchAdSets", _descriptor25, this);
      _initializerDefineProperty(this, "updateAdSet", _descriptor26, this);
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get selectedTargetGoals() {
      const targetGoals = this.model.searchAdCampaign.get('targetGoals');
      if (targetGoals.length === 0) {
        return '';
      }
      const targetGoalTexts = targetGoals.map(targetGoal => {
        return targetGoal.label;
      });
      return targetGoalTexts.join(', ');
    }
    get finalUrl() {
      var _this$meta$landingPag;
      const landingPage = this.model.searchAdCampaign.get('landingPage');
      const landingPageLanguage = this.model.searchAdCampaign.get('landingPageLanguage');
      if (!landingPage) {
        return '';
      }
      let url = (_this$meta$landingPag = this.meta.landingPages.find(page => page.value === landingPage)) === null || _this$meta$landingPag === void 0 ? void 0 : _this$meta$landingPag.url;

      // Replace language placeholder with actual language
      url = url.replace('{language}', landingPageLanguage);
      return url;
    }
    get validationProperties() {
      return ['dailyBudget', 'ads', 'targetGroup', 'bid', 'targetGoals'];
    }
    get publishChangeset() {
      const validation = (0, _searchAdCampaign.searchAdCampaignPublishValidation)(this.intl);
      return new _emberChangeset.default(this.model.searchAdCampaign, (0, _emberChangesetValidations.default)(validation), validation);
    }
    get campaignState() {
      if (this.model.searchAdCampaign.isPublic) {
        return this.intl.t('searchAdCampaigns.states.published');
      }
      if (this.model.searchAdCampaign.isPaused) {
        return this.intl.t('searchAdCampaigns.states.paused');
      }
      return this.intl.t('searchAdCampaigns.states.unpublished');
    }
    get siteLinkExtensions() {
      var _this$allExtensions;
      const extensions = this.model.searchAdCampaign.extensions;
      if (((_this$allExtensions = this.allExtensions) === null || _this$allExtensions === void 0 ? void 0 : _this$allExtensions.length) === 0 || !extensions || (extensions === null || extensions === void 0 ? void 0 : extensions.length) === 0) {
        return [];
      }
      const selectedSiteLinks = [];
      const allSiteLinksExtensions = this.allExtensions.filter(extension => extension.type === 'Google::SearchAds::Extension::SiteLink');
      if ((allSiteLinksExtensions === null || allSiteLinksExtensions === void 0 ? void 0 : allSiteLinksExtensions.length) === 0) {
        return [];
      }
      extensions.forEach(extension => {
        const siteLink = allSiteLinksExtensions.find(siteLinkExtension => siteLinkExtension.id === extension.value);
        if (siteLink) {
          selectedSiteLinks.push(siteLink);
        }
      });
      return selectedSiteLinks;
    }
    get additionalInformationExtensions() {
      var _this$allExtensions2;
      const extensions = this.model.searchAdCampaign.extensions;
      if (((_this$allExtensions2 = this.allExtensions) === null || _this$allExtensions2 === void 0 ? void 0 : _this$allExtensions2.length) === 0 || !extensions || (extensions === null || extensions === void 0 ? void 0 : extensions.length) === 0) {
        return [];
      }
      const selectedAdditionalInformation = [];
      const allAdditionalInformationExtensions = this.allExtensions.filter(extension => extension.type === 'Google::SearchAds::Extension::AdditionalInformation');
      if ((allAdditionalInformationExtensions === null || allAdditionalInformationExtensions === void 0 ? void 0 : allAdditionalInformationExtensions.length) === 0) {
        return [];
      }
      extensions.forEach(extension => {
        const additionalInformation = allAdditionalInformationExtensions.find(additionalInformationExtension => additionalInformationExtension.id === extension.value);
        if (additionalInformation) {
          selectedAdditionalInformation.push(additionalInformation);
        }
      });
      return selectedAdditionalInformation;
    }
    back() {
      this.router.transitionTo('instance.campaigns.search-ad.index');
    }
    openEdit() {
      this.router.transitionTo('instance.campaigns.search-ad.index.search-ad.edit');
    }
    onPublish() {
      const publishAction = this.model.searchAdCampaign.isDraft || this.model.searchAdCampaign.isPaused ? 'publish' : 'unpublish';
      this.uiDialog.showConfirm(this.intl.t(`searchAdCampaigns.${publishAction}.dialog.title`), this.intl.t(`searchAdCampaigns.${publishAction}.dialog.description`), () => this.modelAction(publishAction), this.intl.t(`global.actions.${publishAction}`));
    }
    async modelAction(publishAction) {
      let toast;
      try {
        toast = this.uiToast.showToast({
          title: this.intl.t(`adCampaigns.facebookContentAds.publish.loading.${publishAction}`),
          description: this.intl.t('adCampaigns.facebookContentAds.publish.loading.text'),
          type: 'loading',
          duration: -1
        });
        const adapter = this.store.adapterFor('search-ad-campaign');
        const url = adapter.urlForSingle(this.model.searchAdCampaign.id, 'search-ad-campaign');
        const method = publishAction === 'publish' ? 'POST' : 'DELETE';
        const response = await this.authenticatedFetch.fetch(`${url}/publish`, {
          method
        });
        if (!(response !== null && response !== void 0 && response.ok)) {
          const {
            message
          } = await response.json();
          throw new Error(message);
        }
        this.uiToast.destroyToast(toast);
        this.model.searchAdCampaign.set('state', publishAction === 'publish' ? 'ENABLED' : 'PAUSED');
        this.isPublishDialog = false;
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        this.uiToast.destroyToast(toast);
        this.uiDialog.showError(null, e.message);
      }
    }
    onUpdate() {
      // Check if there are any pending updates left
      this.fetchPendingUpdates.perform();
      this.isPendingUpdatesDialog = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "campaignId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "meta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectableTargetGroups", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "pendingUpdates", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "showUpdateNotification", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isPendingUpdatesDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isPublishDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isAdEditDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "selectedAd", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "adSets", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "ads", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "allExtensions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "fetchMeta", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "fetchPendingUpdates", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "fetchExtensions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "fetchTargetGroups", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "fetchAdSets", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "updateAdSet", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEdit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onPublish", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onPublish"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "modelAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "modelAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _class);
});