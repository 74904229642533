define("additive-mc/templates/instance/services/service", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "1dM8Y6Yc",
    "block": "[[[8,[39,0],null,[[\"@service\",\"@onClose\",\"@isReadOnly\"],[[30,0,[\"model\"]],[30,0,[\"onClose\"]],[30,0,[\"isReadOnly\"]]]],null]],[],false,[\"amc-services/detail-dialog\"]]",
    "moduleName": "additive-mc/templates/instance/services/service.hbs",
    "isStrictMode": false
  });
});