define("additive-mc/templates/instance/reports/on-page/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "71BRZD8/",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@isWide\"],[[28,[37,1],[\"routes.reports.title\"],null],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,null,null],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"mw6\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@classNames\",\"@currentRouteName\",\"@rootName\"],[\"font-xl\",\"on-page\",\"reports\"]],null],[1,\"\\n      \"],[10,0],[14,0,\"font-md pv2\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"reports.onPageMetrics.description\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,[[\"@class\"],[\"pt2\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@onChange\",\"@initialRange\",\"@hasFilters\",\"@defaultFilter\"],[[30,0,[\"onFilterChange\"]],[28,[37,4],null,[[\"start\",\"end\"],[[30,0,[\"start\"]],[30,0,[\"end\"]]]]],true,[30,0,[\"_defaultFilter\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@organizationSlug\",\"@dateRange\",\"@abortController\"],[[30,0,[\"organizationSlug\"]],[28,[37,4],null,[[\"start\",\"end\"],[[30,0,[\"start\"]],[30,0,[\"end\"]]]]],[30,0,[\"abortController\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"view\"],false,[\"ui-content-views\",\"t\",\"ui-breadcrumbs\",\"amc-date-filter\",\"hash\",\"amc-reports/on-page-metrics\"]]",
    "moduleName": "additive-mc/templates/instance/reports/on-page/index.hbs",
    "isStrictMode": false
  });
});