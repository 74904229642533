define("additive-mc/components/amc-preview/upcoming-emails", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/object/computed", "ember-concurrency"], function (_exports, _component, _object, _service, _computed, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module amc-preview
   */
  var _default = _exports.default = _component.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    router: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uiPaths: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    /**
     * the current organization slug
     *
     * @property organizationSlug
     * @type {String}
     * @default null
     */
    organizationSlug: (0, _computed.alias)('currentUser.currentOrganization.id'),
    /**
     * the organization slug of which the chart-data was fetched,
     * used to detect whether the slug has changes
     *
     * @property _previousOrganization
     * @type {String}
     * @default null
     * @private
     */
    _previousOrganization: null,
    /**
     * whether the table is empty
     *
     * @computed isEmpty
     * @type {Boolean}
     */
    isEmpty: (0, _computed.equal)('previews.length', 0),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'tableHeaderOptions', [{
        i18n: 'preview.upcomingEmails.columns.name',
        minWidth: '80px'
      }, {
        i18n: 'preview.upcomingEmails.columns.campaignType',
        minWidth: '80px'
      }, {
        i18n: 'preview.upcomingEmails.columns.count',
        minWidth: '80px'
      }]);
      (0, _object.set)(this, 'previews', []);
    },
    /**
     * <slug>/preview/email-campaigns
     *
     * @throws {Error}
     */
    previewData: (0, _emberConcurrency.task)(function* () {
      try {
        const [emailPreviews] = yield (0, _emberConcurrency.all)([this.store.findAll('email-preview'), (0, _emberConcurrency.timeout)(400)]);
        (0, _object.set)(this, 'previews', emailPreviews);
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }).on('init'),
    actions: {
      transitionToDetail(id) {
        this.router.transitionTo('instance.preview.email', id);
      }
    }
  });
});