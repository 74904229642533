define("additive-mc/validations/advertisement", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/date", "@additive-apps/utils/validators/numeral", "@additive-apps/utils/validators/validate-if", "additive-mc/validators/depiction-placeholders"], function (_exports, _validators, _date, _numeral, _validateIf, _depictionPlaceholders) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tiktokLeadAdsDepictionValidation = _exports.tikTokLeadAdPublishValidation = _exports.tikTokAdValidation = _exports.leadAdsAdCreativeValidation = _exports.leadAdValidation = _exports.facebookAdValidation = _exports.facebookAdPublishValidation = _exports.facebookAdDepictionValidation = _exports.depictionValidation = _exports.catalogAdValidation = _exports.adTikTokTargetGroupValidation = _exports.adBudgetValidation = void 0;
  const leadAdValidation = intl => {
    const afterDateMessage = intl.t('errors.afterStartDate');
    const beforeEndDate = intl.t('errors.beforeEndDate');
    return {
      startDate: (0, _validateIf.default)({
        validate: (0, _date.validateDateDependency)({
          type: 'before',
          on: 'endDate',
          allowBlank: false,
          allowEmptyDependency: true,
          message: beforeEndDate
        }),
        if: {
          unlimitedDuration: false
        },
        firstErrorMessage: true
      }),
      endDate: (0, _validateIf.default)({
        validate: (0, _date.validateDateDependency)({
          type: 'after',
          on: 'startDate',
          allowBlank: true,
          allowEmptyDependency: true,
          message: afterDateMessage
        }),
        if: {
          unlimitedDuration: false
        },
        firstErrorMessage: true
      })
    };
  };
  _exports.leadAdValidation = leadAdValidation;
  const leadAdsAdCreativeValidation = intl => {
    return {
      multimediaVariants: [(0, _validators.validateLength)({
        allowBlank: true,
        is: 2,
        message: intl.t('adCampaigns.leadAds.edit.errors.wrongLabels')
      }),
      /*
       * special validation for multimediaVariants of lead ads that will probably be removed in the future
       *
       * TODO: move to validator if we decide to keep this validation
       */
      (key, value) => {
        // validate labels only if there are two images
        if ((value === null || value === void 0 ? void 0 : value.length) !== 2) {
          return true;
        }
        let usedLabels = [];
        value.forEach(item => {
          if (Array.isArray(item.labels)) {
            usedLabels = [...usedLabels, ...item.labels];
          }
        });
        if (usedLabels.length !== 2 || usedLabels.length !== usedLabels.uniq().length) {
          return intl.t('adCampaigns.leadAds.edit.errors.wrongLabels');
        }
        return true;
      }]
    };
  };
  _exports.leadAdsAdCreativeValidation = leadAdsAdCreativeValidation;
  const catalogAdValidation = intl => {
    const afterDateMessage = intl.t('errors.afterStartDate');
    const beforeEndDate = intl.t('errors.beforeEndDate');
    const presenceMessage = intl.t('errors.required');
    return {
      startDate: (0, _validateIf.default)({
        validate: (0, _date.validateDateDependency)({
          type: 'before',
          on: 'endDate',
          allowBlank: false,
          allowEmptyDependency: true,
          message: beforeEndDate
        }),
        if: {
          unlimitedDuration: false
        },
        firstErrorMessage: true
      }),
      endDate: (0, _validateIf.default)({
        validate: (0, _date.validateDateDependency)({
          type: 'after',
          on: 'startDate',
          allowBlank: true,
          allowEmptyDependency: true,
          message: afterDateMessage
        }),
        if: {
          unlimitedDuration: false
        },
        firstErrorMessage: true
      }),
      optimizationEvent: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.catalogAdValidation = catalogAdValidation;
  const adBudgetValidation = intl => {
    const greaterZeroMessage = intl.t('errors.greaterZero');
    const presenceMessage = intl.t('errors.required');
    return {
      type: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      amount: (0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: false,
        message: greaterZeroMessage
      })
    };
  };
  _exports.adBudgetValidation = adBudgetValidation;
  const adTikTokTargetGroupValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    return {
      label: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      location: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      language: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      age: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.adTikTokTargetGroupValidation = adTikTokTargetGroupValidation;
  const facebookAdValidation = intl => {
    const afterDateMessage = intl.t('errors.afterStartDate');
    const beforeEndDate = intl.t('errors.beforeEndDate');
    const greaterZeroMessage = intl.t('errors.greaterZero');
    const presenceMessage = intl.t('errors.required');
    const depictionRequired = intl.t('adCampaigns.facebookContentAds.edit.errors.depictionRequired');
    return {
      startDate: (0, _validateIf.default)({
        validate: (0, _date.validateDateDependency)({
          type: 'before',
          on: 'endDate',
          allowBlank: false,
          allowEmptyDependency: true,
          message: beforeEndDate
        }),
        if: {
          unlimitedDuration: false
        },
        firstErrorMessage: true
      }),
      endDate: (0, _validateIf.default)({
        validate: (0, _date.validateDateDependency)({
          type: 'after',
          on: 'startDate',
          allowBlank: true,
          allowEmptyDependency: true,
          message: afterDateMessage
        }),
        if: {
          unlimitedDuration: false
        },
        firstErrorMessage: true
      }),
      'budget.type': (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      'budget.amount': (0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: false,
        message: greaterZeroMessage
      }),
      targetAudience: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      depictions: [(0, _validators.validatePresence)({
        presence: true,
        message: depictionRequired
      }), (0, _validators.validateLength)({
        min: 1,
        message: depictionRequired
      })]
    };
  };
  _exports.facebookAdValidation = facebookAdValidation;
  const TIME_REGEX = /^(([0-1][0-9])|(2[0-3])):([0-5][0-9])$/;
  const tikTokAdValidation = intl => {
    const afterDateMessage = intl.t('errors.afterStartDate');
    const beforeEndDate = intl.t('errors.beforeEndDate');
    const presenceMessage = intl.t('errors.required');
    const depictionRequired = intl.t('adCampaigns.facebookContentAds.edit.errors.depictionRequired');
    const invalidFormatMessage = intl.t('errors.invalidFormat');
    return {
      startDate: (0, _validateIf.default)({
        validate: (0, _date.validateDateDependency)({
          type: 'before',
          on: 'endDate',
          allowBlank: false,
          allowEmptyDependency: true,
          message: beforeEndDate
        }),
        if: {
          unlimitedDuration: false
        },
        firstErrorMessage: true
      }),
      endDate: (0, _validateIf.default)({
        validate: (0, _date.validateDateDependency)({
          type: 'after',
          on: 'startDate',
          allowBlank: true,
          allowEmptyDependency: true,
          message: afterDateMessage
        }),
        if: {
          unlimitedDuration: false
        },
        firstErrorMessage: true
      }),
      'budget.type': (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      'budget.amount': (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      identity: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      targetGroup: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      depictions: [(0, _validators.validatePresence)({
        presence: true,
        message: depictionRequired
      }), (0, _validators.validateLength)({
        min: 1,
        message: depictionRequired
      })],
      'timeLimitation.days': (0, _validateIf.default)({
        validate: (0, _validators.validateLength)({
          min: 1,
          message: presenceMessage
        }),
        if: {
          timeLimited: true
        },
        firstErrorMessage: true
      }),
      'timeLimitation.startTime': (0, _validateIf.default)({
        validate: [(0, _validators.validatePresence)({
          presence: true,
          message: presenceMessage
        }), (0, _validators.validateFormat)({
          allowBlank: true,
          regex: TIME_REGEX,
          message: invalidFormatMessage
        })],
        if: {
          timeLimited: true
        },
        firstErrorMessage: true
      }),
      'timeLimitation.endTime': (0, _validateIf.default)({
        validate: [(0, _validators.validatePresence)({
          presence: true,
          message: presenceMessage
        }), (0, _validators.validateFormat)({
          allowBlank: true,
          regex: TIME_REGEX,
          message: invalidFormatMessage
        })],
        if: {
          timeLimited: true
        },
        firstErrorMessage: true
      })
    };
  };
  _exports.tikTokAdValidation = tikTokAdValidation;
  const facebookAdPublishValidation = intl => {
    const baseIntlKey = 'adCampaigns.facebookContentAds.publishDialog.errorMessages';
    const invalidDate = intl.t(`${baseIntlKey}.endDate`);
    const budgetRequired = intl.t(`${baseIntlKey}.budget.amount`);
    const budgetTypeRequired = intl.t(`${baseIntlKey}.budget.type`);
    const targetAudience = intl.t(`${baseIntlKey}.targetAudience`);
    const depictionRequired = intl.t(`${baseIntlKey}.depictions`);
    const languageRequired = intl.t(`${baseIntlKey}.language`);
    return {
      endDate: (0, _date.validateDateDependency)({
        type: 'after',
        on: 'today',
        message: invalidDate
      }),
      targetAudience: (0, _validators.validatePresence)({
        presence: true,
        message: targetAudience
      }),
      'budget.type': (0, _validators.validatePresence)({
        presence: true,
        message: budgetTypeRequired
      }),
      'budget.amount': (0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: false,
        message: budgetRequired
      }),
      depictions: [(0, _validators.validatePresence)({
        presence: true,
        message: depictionRequired
      }), (0, _validators.validateLength)({
        min: 1,
        message: depictionRequired
      })],
      language: (0, _validators.validatePresence)({
        presence: true,
        message: languageRequired
      })
    };
  };
  _exports.facebookAdPublishValidation = facebookAdPublishValidation;
  const tikTokLeadAdPublishValidation = intl => {
    const baseIntlKey = 'adCampaigns.tiktokLeadAds.publishDialog.errorMessages';
    const invalidDate = intl.t(`${baseIntlKey}.endDate`);
    const budgetRequired = intl.t(`${baseIntlKey}.budget.amount`);
    const budgetTypeRequired = intl.t(`${baseIntlKey}.budget.type`);
    const targetGroup = intl.t(`${baseIntlKey}.targetGroup`);
    const identity = intl.t(`${baseIntlKey}.identity`);
    const depictionRequired = intl.t(`${baseIntlKey}.depictions`);
    return {
      endDate: (0, _date.validateDateDependency)({
        type: 'after',
        on: 'today',
        message: invalidDate
      }),
      identity: (0, _validators.validatePresence)({
        presence: true,
        message: identity
      }),
      targetGroup: (0, _validators.validatePresence)({
        presence: true,
        message: targetGroup
      }),
      'budget.type': (0, _validators.validatePresence)({
        presence: true,
        message: budgetTypeRequired
      }),
      'budget.amount': (0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: false,
        message: budgetRequired
      }),
      depictions: [(0, _validators.validatePresence)({
        presence: true,
        message: depictionRequired
      }), (0, _validators.validateLength)({
        min: 1,
        message: depictionRequired
      })]
    };
  };
  _exports.tikTokLeadAdPublishValidation = tikTokLeadAdPublishValidation;
  const depictionValidation = (intl, allowedPlaceholders) => {
    const presenceMessage = intl.t('errors.required');
    return {
      text: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _depictionPlaceholders.validateDepictionPlaceholders)({
        message: intl.t('components.amcCampaign.ads.advertisements.depictionDetail.placeholderValidationMessage'),
        allowedPlaceholders
      })],
      productTypes: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _validators.validateLength)({
        min: 1,
        message: presenceMessage
      })],
      headline: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _depictionPlaceholders.validateDepictionPlaceholders)({
        message: intl.t('components.amcCampaign.ads.advertisements.depictionDetail.placeholderValidationMessage'),
        allowedPlaceholders
      })],
      description: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _depictionPlaceholders.validateDepictionPlaceholders)({
        message: intl.t('components.amcCampaign.ads.advertisements.depictionDetail.placeholderValidationMessage'),
        allowedPlaceholders
      })],
      ctaType: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      formats: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.depictionValidation = depictionValidation;
  const facebookAdDepictionValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const invalidFormatMessage = intl.t('errors.invalidFormat');
    return {
      title: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      headline: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      text: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      url: (0, _validators.validateFormat)({
        // eslint-disable-next-line
        regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[A-Za-z0-9]+([\-\.]{1}[A-Za-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        allowBlank: true,
        message: invalidFormatMessage
      }),
      ctaType: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      images: (0, _validators.validateLength)({
        min: 1,
        message: intl.t('errors.imagesRequired')
      })
    };
  };
  _exports.facebookAdDepictionValidation = facebookAdDepictionValidation;
  const tiktokLeadAdsDepictionValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const maxLengthMessage = intl.t('errors.maxLength', {
      maxLength: 100
    });
    return {
      text: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _validators.validateLength)({
        max: 100,
        message: maxLengthMessage
      })],
      videos: (0, _validators.validateLength)({
        min: 1,
        message: intl.t('errors.videosRequired')
      }),
      page: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.tiktokLeadAdsDepictionValidation = tiktokLeadAdsDepictionValidation;
});