define("additive-mc/mirage/scenarios/test/default", ["exports", "additive-mc/mirage/scenarios/organization", "additive-mc/mirage/scenarios/user"], function (_exports, _organization, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_ORG_ID = void 0;
  const DEFAULT_ORG_ID = _exports.DEFAULT_ORG_ID = 'test-org';
  var _default = _exports.default = function _default(server) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      loadOrganization: true,
      loadUser: true
    };
    server.create('organization', {
      id: DEFAULT_ORG_ID
    });
    server.create('user');
    server.create('user', {
      role: 'manager'
    });
    server.create('user', {
      role: 'admin'
    });
    options.loadOrganization && (0, _organization.starter)(server);
    options.loadUser && (0, _user.partnerMember)(server);
  };
});