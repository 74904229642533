define("additive-mc/components/amc-campaign/configuration-item/with-modal", ["exports", "@ember/component", "ember-changeset", "ember-changeset-validations", "@ember/object", "ember-concurrency", "@ember/service", "rsvp", "lodash.clonedeep", "lodash.isequal"], function (_exports, _component, _emberChangeset, _emberChangesetValidations, _object, _emberConcurrency, _service, _rsvp, _lodash, _lodash2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * HOC which renders a modal
   * and provides basic logic which is needed for the modal
   * like changeset, triggern save and stuff
   *
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    uiToast: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    tagName: '',
    /**
     * The childs changeset which will be validated as well
     *
     * @property childChangeset
     * @default null
     */
    childChangeset: null,
    /**
     * The description
     *
     * @property description
     * @default null
     */
    description: null,
    /**
     * The configuration-item
     *
     * @property item
     * @default null
     */
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * Validation for the changeset
     *
     * @property item
     * @default null
     */
    validation: null,
    /**
     * The internal changeset of the item
     *
     * @property _changeset
     * @default null
     * @private
     */
    _changeset: null,
    /**
     * Whether discard changes dialog should be open
     *
     * @property _isDiscardChangesDialog
     * @default null
     * @private
     */
    _isDiscardChangesDialog: false,
    /**
     * The initial configuration-item
     *
     * @property item
     * @default null
     * @private
     */
    _initialItem: null,
    /**
     * Computed description, priority:
     *
     * - this.args.item.description
     * - this.args.description
     * - ''
     *
     * @computed _description
     * @default ''
     * @private
     */
    _description: (0, _object.computed)('item.description', 'description', {
      get() {
        if (this.item && this.item.description) {
          return this.item.description;
        }
        return this.description || '';
      }
    }),
    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {},
    /**
     * Close event
     *
     * @function onClose
     * @return {Promise} Returns a promise
     * @public
     */
    onClose() {},
    /**
     * Event which will be trigger before validation or saving.
     * Aborting save is possible by returning a promise which resolves with false
     *
     * @function beforeSave
     * @return {Promise}  Returns a promise
     *                    which should resolve with a boolean
     *                    whether it should continue saving or not
     * @public
     */
    beforeSave() {
      return (0, _rsvp.resolve)(true);
    },
    init() {
      this._super(...arguments);
      let changeset = null;
      if (this.validation && typeof this.validation === 'function') {
        const validation = this.validation(this.intl);
        changeset = new _emberChangeset.default(this.item, (0, _emberChangesetValidations.default)(validation), validation);
      } else {
        changeset = new _emberChangeset.default(this.item);
      }
      (0, _object.set)(this, '_changeset', changeset);
      (0, _object.set)(this, '_initialItem', (0, _lodash.default)(this.item));
    },
    /**
     * Validate changeset and childChangeset and trigger onSave
     *
     * @function _save
     * @type {Task}
     * @param {Changeset} parentChangeset The changeset of the `with-modal` hoc
     * @private
     */
    save: (0, _emberConcurrency.task)(function* () {
      const changeset = this._changeset;
      const shouldContinue = yield this.beforeSave(changeset);
      if (shouldContinue === false) {
        return;
      }

      /* When childChangeset is present we additionally validate it */
      const childChangeset = this.childChangeset;
      if (childChangeset) {
        yield childChangeset.validate();
        if (childChangeset.get('isInvalid')) {
          return;
        }
      }
      yield changeset.validate();
      if (changeset.get('isInvalid')) {
        return;
      }
      changeset.execute();
      yield (0, _emberConcurrency.all)([this.onSave(changeset), (0, _emberConcurrency.timeout)(400)]);
      yield this.onClose();
      return (0, _rsvp.resolve)();
    }),
    actions: {
      onClose() {
        const tasks = [];
        tasks.push(this._changeset.validate());
        this.childChangeset && tasks.push(this.childChangeset.validate());
        return (0, _emberConcurrency.all)(tasks).then(() => {
          /* Validate changeset and child changeset if present */
          if (this._changeset.get('isPristine') && (!this.childChangeset || this.childChangeset.get('isPristine'))) {
            if (this.item.value && !(0, _lodash2.default)(this.item.value, this._initialItem.value)) {
              (0, _object.set)(this, '_isDiscardChangesDialog', true);
              return (0, _rsvp.resolve)();
            }
            return this.onClose(this._changeset);
          } else {
            (0, _object.set)(this, '_isDiscardChangesDialog', true);
            return (0, _rsvp.resolve)();
          }
        });
      },
      confirmDiscard() {
        if (this.item.value && !(0, _lodash2.default)(this.item.value, this._initialItem.value)) {
          (0, _object.set)(this, 'item.value', this._initialItem.value);
          (0, _object.set)(this, '_changeset', new _emberChangeset.default(this._initialItem));
        }
        (0, _object.set)(this, '_isDiscardChangesDialog', false);
        return this.onClose(this._changeset);
      }
    }
  });
});