define("additive-mc/models/ui-transactionable-model", ["exports", "@ember-data/model", "@ember/service", "@ember/string"], function (_exports, _model, _service, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // TODO: move to ui addon when it's ready
  let UiTransactionableModel = _exports.default = (_class = class UiTransactionableModel extends _model.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiTransactionManager", _descriptor, this);
      _defineProperty(this, "transactionCreateRecord", async () => {
        await this.uiTransactionManager.createModelRecord(this);
      });
      _defineProperty(this, "transactionDeleteRecord", async () => {
        await this.uiTransactionManager.deleteModelRecord(this);
      });
      _defineProperty(this, "transactionChange", async (property, value) => {
        await this.uiTransactionManager.changeModelProperty(this, property, value);
      });
      _defineProperty(this, "transactionChangeModelState", async () => {
        await this.uiTransactionManager.changeModelState(this);
      });
      _defineProperty(this, "transactionDeleteLanguage", async locale => {
        await this.uiTransactionManager.deleteLanguage(this, locale);
      });
      _defineProperty(this, "transactionChangeLanguage", async locale => {
        await this.uiTransactionManager.changeLanguage(this, locale);
      });
      _defineProperty(this, "transactionAddLanguage", async locale => {
        await this.uiTransactionManager.addLanguage(this, locale);
      });
      _defineProperty(this, "transactionPublish", async language => {
        await this.uiTransactionManager.publish(this, language);
      });
      _defineProperty(this, "transactionUnpublish", async language => {
        await this.uiTransactionManager.unpublish(this, language);
      });
      _defineProperty(this, "transactionSchedulePublication", async (property, value) => {
        await this.uiTransactionManager.schedulePublication(this, property, value);
      });
      _defineProperty(this, "onTransactionDiscard", () => {
        this.rollbackAttributes();
      });
    }
    get transactionUrl() {
      return `${(0, _string.pluralize)((0, _string.dasherize)(this.constructor.modelName))}/${this.id}`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiTransactionManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});