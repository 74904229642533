define("additive-mc/components/amc-widget-configuration/colors", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _object, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PanelGroup @title={{t "widgets.configurator.colors.title"}} as |group|>
    {{#each (array "main" "accent" "ambient") as |colorVariant|}}
      <group.item
        @label={{t (concat "widgets.configurator.colors." colorVariant)}}
        @description={{t (concat "widgets.configurator.helpTexts.colors." colorVariant)}}
        @isDisabled={{true}}
      >
        {{#let (get this.styles.colors colorVariant) (concat "colors." colorVariant) as |color path|}}
          <AwColorsMenu
            @active={{color}}
            @options={{this.selectOptions}}
            @path={{path}}
            @isReadOnly={{@isReadOnly}}
            @onChange={{this.update}}
          />
          <UiPopover as |pop|>
            <pop.trigger>
              {{#if @isReadOnly}}
                {{svg-jar
                  "dna"
                  class=(concat "ml1 " (if color.sync "blue" "secondary"))
                  width="20"
                  height="20"
                }}
              {{else}}
                <UiIconButton
                  @classNames="ml1"
                  @icon="dna"
                  @isTertiary={{not color.sync}}
                  @onClick={{fn this.toggleSync path color.sync}}
                />
              {{/if}}
            </pop.trigger>
            <pop.content>
              {{t "settings.inheritanceDescription"}}
            </pop.content>
          </UiPopover>
        {{/let}}
      </group.item>
    {{/each}}
  </PanelGroup>
  */
  {
    "id": "GoxNpIaj",
    "block": "[[[8,[39,0],null,[[\"@title\"],[[28,[37,1],[\"widgets.configurator.colors.title\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[28,[37,4],[\"main\",\"accent\",\"ambient\"],null]],null]],null],null,[[[1,\"    \"],[8,[30,1,[\"item\"]],null,[[\"@label\",\"@description\",\"@isDisabled\"],[[28,[37,1],[[28,[37,5],[\"widgets.configurator.colors.\",[30,2]],null]],null],[28,[37,1],[[28,[37,5],[\"widgets.configurator.helpTexts.colors.\",[30,2]],null]],null],true]],[[\"default\"],[[[[1,\"\\n\"],[44,[[28,[37,7],[[30,0,[\"styles\",\"colors\"]],[30,2]],null],[28,[37,5],[\"colors.\",[30,2]],null]],[[[1,\"        \"],[8,[39,8],null,[[\"@active\",\"@options\",\"@path\",\"@isReadOnly\",\"@onChange\"],[[30,3],[30,0,[\"selectOptions\"]],[30,4],[30,5],[30,0,[\"update\"]]]],null],[1,\"\\n        \"],[8,[39,9],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,6,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,5],[[[1,\"              \"],[1,[28,[35,11],[\"dna\"],[[\"class\",\"width\",\"height\"],[[28,[37,5],[\"ml1 \",[52,[30,3,[\"sync\"]],\"blue\",\"secondary\"]],null],\"20\",\"20\"]]]],[1,\"\\n\"]],[]],[[[1,\"              \"],[8,[39,12],null,[[\"@classNames\",\"@icon\",\"@isTertiary\",\"@onClick\"],[\"ml1\",\"dna\",[28,[37,13],[[30,3,[\"sync\"]]],null],[28,[37,14],[[30,0,[\"toggleSync\"]],[30,4],[30,3,[\"sync\"]]],null]]],null],[1,\"\\n\"]],[]]],[1,\"          \"]],[]]]]],[1,\"\\n          \"],[8,[30,6,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,1],[\"settings.inheritanceDescription\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[6]]]]],[1,\"\\n\"]],[3,4]]],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[2]],null]],[1]]]]]],[\"group\",\"colorVariant\",\"color\",\"path\",\"@isReadOnly\",\"pop\"],false,[\"panel-group\",\"t\",\"each\",\"-track-array\",\"array\",\"concat\",\"let\",\"get\",\"aw-colors-menu\",\"ui-popover\",\"if\",\"svg-jar\",\"ui-icon-button\",\"not\",\"fn\"]]",
    "moduleName": "additive-mc/components/amc-widget-configuration/colors.hbs",
    "isStrictMode": false
  });
  let AmcWidgetConfigurationColorsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.array), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcWidgetConfigurationColorsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "selectOptions", _descriptor, this);
      _initializerDefineProperty(this, "styles", _descriptor2, this);
      _initializerDefineProperty(this, "onUpdate", _descriptor3, this);
    }
    toggleSync(key, sync) {
      this.onUpdate(key, {
        sync: !sync
      });
    }
    update(key, val, sync, colorOption) {
      // if custom color then colorOption is true
      if (colorOption === true) {
        val.sync = false;
      } else {
        val.syncedColor = val.color;
        val.syncedContrastColor = val.contrastColor;
        val.sync = true;
      }
      this.onUpdate(key, val);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "selectOptions", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "styles", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleSync", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSync"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcWidgetConfigurationColorsComponent);
});