define("additive-mc/mirage/routes/campaigns/ad", ["exports", "additive-mc/mirage/routes/helpers/response", "faker"], function (_exports, _response, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AdRoutes = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const AD_CAMPAIGNS = ['facebookContentAds', 'boostContents', 'facebookRemarketing'];
  const AdRoutes = self => {
    self.get('/organisation-campaigns/ad', _ref => {
      let {
        adCampaigns
      } = _ref;
      const campaigns = adCampaigns.all();
      return {
        adCampaigns: campaigns.models,
        meta: {
          total: campaigns.models.length
        }
      };
    });
    self.get('/organisation-campaigns/ad/:id', (_ref2, request) => {
      let {
        adCampaigns
      } = _ref2;
      const {
        id
      } = request.params;
      return adCampaigns.find(id);
    });
    self.get('/organisation-campaigns/ad/available', () => {
      return AD_CAMPAIGNS;
    });
    self.del('/organisation-campaigns/ad/:id', (_ref3, request) => {
      let {
        adCampaigns
      } = _ref3;
      const {
        id
      } = request.params;
      adCampaigns.find(id).destroy();
    });
    self.post('/organisation-campaigns/ad', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      const {
        title,
        campaignCode
      } = body;
      return schema.adCampaigns.create({
        title,
        campaignCode
      });
    });
    self.put('/organisation-campaigns/ad/:id', (_ref4, request) => {
      let {
        adCampaigns
      } = _ref4;
      const {
        id
      } = request.params;
      const body = JSON.parse(request.requestBody);

      // Change order randomly. Can be removed if this is fixed in the API.
      body.configurations.forEach(function (c) {
        c.order = _faker.default.random.number();
      });
      return adCampaigns.find(id).update(body);
    });
    self.put('/organisation-campaigns/ad/:id/activate', (schema, request) => {
      try {
        const model = adCampaignOrFail(schema, request);
        return model.update('active', true);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.put('/organisation-campaigns/ad/:id/deactivate', (schema, request) => {
      try {
        const model = adCampaignOrFail(schema, request);
        return model.update('active', false);
      } catch (error) {
        return (0, _response.notFoundResponse)(error);
      }
    });
    self.get('/organisation-campaigns/ad/catalogs', schema => {
      return schema.adCatalogs.all();
    });
    self.get('/organisation-campaigns/ad/alerts/grouped', () => {
      return [];
    });
    self.get('/organisation-campaigns/ad/settings', () => {
      return {
        settings: [{
          id: 1,
          key: 'monthly-ad-spend-threshold',
          description: 'Monatlicher AdSpend Threshold',
          type: 'money',
          value: null
        }]
      };
    });
    self.get('/organisation-campaigns/ad/settings/tik-tok-identities', () => {
      return {
        identities: [{
          label: 'ADDITIVE',
          image: {
            position: 1,
            mediumId: '2af74266-43f0-4357-9955-5a11964d5cd7',
            url: 'https://staging.multimedia.additive-apps.tech/media/9ab4116e450a286882fda4f7e7779202',
            name: 'ausblick.jpeg',
            type: 'image',
            provider: 'imbo',
            remoteId: '9ab4116e450a286882fda4f7e7779202'
          }
        }, {
          label: 'ADDITIVE+ APPS',
          image: {
            position: 1,
            mediumId: '2af74266-43f0-4357-9955-5a11964d5cd7',
            url: 'https://staging.multimedia.additive-apps.tech/media/9ab4116e450a286882fda4f7e7779202',
            name: 'ausblick.jpeg',
            type: 'image',
            provider: 'imbo',
            remoteId: '9ab4116e450a286882fda4f7e7779202'
          }
        }]
      };
    });
    self.post('/organisation-campaigns/ad/settings/tik-tok-identities', (schema, request) => {
      const identities = JSON.parse(request.requestBody);
      if (identities === null) {
        return {
          identities: []
        };
      }
      return {
        identities
      };
    });
    self.get('/organisation-campaigns/ad/settings/tik-tok-target-groups', () => {
      return {
        targetGroups: [{
          label: 'ca_de',
          location: [],
          language: [],
          gender: '',
          age: []
        }]
      };
    });
    self.get('/organisation-campaigns/ad/settings/tik-tok-target-groups/metadata', () => {
      return {
        locations: [{
          label: 'Deutschland',
          value: 'de'
        }, {
          label: 'Italien',
          value: 'it'
        }, {
          label: 'Österreich',
          value: 'at',
          subData: [{
            label: 'Tirol',
            value: 'tirol'
          }]
        }],
        availableLanguages: [{
          label: 'Deutsch',
          value: 'de'
        }, {
          label: 'Italienisch',
          value: 'it'
        }, {
          label: 'Französisch',
          value: 'fr'
        }, {
          label: 'Niederländisch',
          value: 'nl'
        }, {
          label: 'Spanisch',
          value: 'es'
        }, {
          label: 'Portugisisch',
          value: 'pt'
        }],
        age: [{
          label: '13-17',
          value: '13-17'
        }, {
          label: '18-24',
          value: '18-24'
        }, {
          label: '25-40',
          value: '25-40'
        }, {
          label: '40-60',
          value: '40-60'
        }, {
          label: '60-100',
          value: '60-100'
        }],
        interests: [{
          label: 'Sports & Outdoors',
          value: 'sports',
          subData: [{
            label: 'Outdoor Equipment',
            value: 'outdoor_equipment'
          }, {
            label: 'Sports Equipment',
            value: 'sports_equipment',
            subData: [{
              label: 'Fitness Training Supplies',
              value: 'fitness'
            }, {
              label: 'Golf',
              value: 'golf'
            }]
          }]
        }, {
          label: 'Life Services',
          value: 'life_services',
          subData: [{
            label: 'Photography',
            value: 'photography'
          }, {
            label: 'Gardening',
            value: 'gardening'
          }]
        }],
        videoInteractionTypes: [{
          label: 'Watched till end',
          value: 'watched_till_end'
        }, {
          label: 'Liked',
          value: 'liked'
        }, {
          label: 'Commented',
          value: 'commented'
        }, {
          label: 'Shared',
          value: 'shared'
        }],
        videoInteractions: [{
          label: 'Nature',
          value: 'nature',
          subData: [{
            label: 'Animals',
            value: 'animals',
            subData: [{
              label: 'Pets',
              value: 'pets'
            }, {
              label: 'Farm Animals',
              value: 'farm_anmials'
            }]
          }]
        }],
        creatorInteractionTypes: [{
          label: 'Followed',
          value: 'followed'
        }, {
          label: 'Viewed Profile',
          value: 'viewed_profile'
        }],
        creatorInteractions: [{
          label: 'Talent',
          value: 'talent',
          subData: [{
            label: 'Music',
            value: 'music'
          }, {
            label: 'Other Talent',
            value: 'other_talent'
          }]
        }],
        hashtagInteractions: [{
          label: 'Talent',
          value: 'talent',
          subData: [{
            label: 'Music',
            value: 'music'
          }, {
            label: 'Other Talent',
            value: 'other_talent'
          }]
        }]
      };
    });
    self.post('/organisation-campaigns/ad/settings/tik-tok-target-groups', (schema, request) => {
      const targetGroups = JSON.parse(request.requestBody);
      if (targetGroups === null) {
        return {
          targetGroups: []
        };
      }
      return {
        targetGroups
      };
    });
    self.get('/organisation-campaigns/ad-campaigns/:id/advertisements', schema => {
      return schema.advertisements.where({
        type: 'ad'
      });
    });
    self.get('/organisation-campaigns/ad-campaigns/:id/advertisements/:advertisement_id', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      return schema.advertisements.find(advertisement_id);
    });
    self.put('/organisation-campaigns/ad-campaigns/:id/advertisements/:advertisement_id', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const body = JSON.parse(request.requestBody);
      const newModel = model.update(body);
      return newModel;
    });
    self.post('/organisation-campaigns/ad-campaigns/:id/advertisements', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      return schema.advertisements.create(_objectSpread(_objectSpread({}, body), {}, {
        state: 'active',
        budgetSpend: {
          value: 0,
          percent: 0
        }
      }));
    });
    self.delete('/organisation-campaigns/ad-campaigns/:id/advertisements/:advertisement_id', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      model.destroy();
      return;
    });
    self.put('/organisation-campaigns/ad-campaigns/:id/advertisements/:advertisement_id/:action_type', (schema, request) => {
      const {
        advertisement_id,
        action_type
      } = request.params;
      const actionTypes = {
        continue: 'active',
        pause: 'paused',
        abort: 'aborted'
      };
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: actionTypes[action_type]
      });
      return newModel;
    });
    self.get('/organisation-campaigns/ad-campaigns/:id/advertisements/meta', schema => {
      return {
        targetAudiences: [1, 2, 3, 4].map(i => ({
          value: `audience-${i}`,
          label: `Audience ${i}`
        })),
        contents: [{
          label: 'Content',
          value: 'advertisement-test'
        }, {
          label: 'Content2',
          value: 'advertisement-test2'
        }],
        pixels: [{
          label: 'Pixel',
          value: 'pixel'
        }]
      };
    });
  };
  _exports.AdRoutes = AdRoutes;
  const adCampaignOrFail = (schema, request) => {
    const {
      id
    } = request.params;
    const model = schema.adCampaigns.find(id);
    if (!model) {
      throw new Error('not_found');
    }
    return model;
  };
});