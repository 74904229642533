define("additive-mc/components/amc-toggle-activation", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/debug", "ember-concurrency"], function (_exports, _component, _service, _object, _debug, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    store: (0, _service.inject)(),
    tagName: '',
    resourceId: null,
    modelName: null,
    modelOptions: null,
    isActive: null,
    defaultLanguage: _object.computed.alias('uiAppSettings.languages.defaultLanguage'),
    baseUrl: (0, _object.computed)('modelName', 'resourceId', 'modelOptions', {
      get() {
        (true && !(this.modelName) && (0, _debug.assert)(`[amc-toggle-activation] modelName not provided`, this.modelName));
        const adapter = this.store.adapterFor(this.modelName);
        (true && !(adapter) && (0, _debug.assert)(`[amc-toggle-activation] Adapter for "${this.modelName}" not found`, adapter));
        return adapter.urlForFindRecord(this.resourceId, this.modelName, this.modelOptions);
      }
    }),
    /**
     * Send toggling active
     *
     * @type {Task}
     * @function _send
     * @private
     */
    _send: (0, _emberConcurrency.task)(function* () {
      try {
        const url = this.baseUrl + (this.isActive ? '/deactivate' : '/activate');
        const response = yield this.authenticatedFetch.fetch(url, {
          method: 'PUT',
          headers: {
            'Accept-Language': this.defaultLanguage || 'de'
          }
        });
        if (!response || !response.ok) {
          const errorJson = yield response.json();
          if (errorJson && Array.isArray(errorJson.errors) && errorJson.errors[0].custom) {
            this.uiDialog.showAlert(this.intl.t('campaigns.configurations.errors.custom.title'), errorJson.errors[0].custom);
            return null;
          }
          if (errorJson && errorJson.message) {
            this.uiToast.showToast({
              title: errorJson.message,
              type: 'error'
            });
            return null;
          }
          throw new Error();
        }
        const payload = yield response.json();
        this.store.pushPayload(this.modelName, payload);
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
        const newModel = this.store.peekRecord(this.modelName, this.resourceId);
        this.afterToggle(newModel.active);
      } catch (error) {
        this.uiDialog.showError();
      }
    }).drop(),
    actions: {
      confirm() {
        let {
          confirmation,
          _send
        } = this;
        let perform = () => _send.perform();
        if (!confirmation) {
          return perform();
        }
        let {
          label,
          description,
          action
        } = confirmation;
        this.uiDialog.showConfirm(label, description, () => perform(), action, true);
      }
    },
    didInsertElement() {
      this._super(...arguments);
      (0, _object.set)(this, 'modelOptions', this.modelOptions || {});
    },
    afterToggle() {}
  });
});