define("additive-mc/components/amc-dashboard/charts", ["exports", "additive-mc/config/environment", "@additive-apps/ui/components/ui-property-watcher", "@ember/object", "ember-concurrency", "@ember/service", "date-fns/format", "date-fns/locale/de", "additive-mc/utils/constants", "@additive-apps/ui/utils/query-param-util", "additive-mc/utils/currency-serialization"], function (_exports, _environment, _uiPropertyWatcher, _object, _emberConcurrency, _service, _format, _de, _constants, _queryParamUtil, _currencySerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This component fetches the data and displays the charts in the dashboard. The data of the charts
   * is updated everytime the organization slug changes.
   *
   * @class amc-dashboard/charts
   */
  var _default = _exports.default = _uiPropertyWatcher.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    /**
     * the date range object
     *
     * @property _dateRange
     * @type {Object}
     */
    dateRange: null,
    /**
     * the current organization slug
     *
     * @property organizationSlug
     * @type {String}
     * @default null
     */
    organizationSlug: null,
    /**
     * the chart datasets for reservations and conversions
     *
     * @property _reservationsConversionsData
     * @type {Object}
     * @default null
     */
    _reservationsConversionsData: null,
    /**
     * whether the reservations and conversions fetch resulted in an error
     *
     * @property _isReservationsConversionsError
     * @type {Boolean}
     * @default false
     * @private
     */
    _isReservationsConversionsError: false,
    /**
     * the chart datasets for added value
     *
     * @property _valueAddedData
     * @type {Object}
     * @default null
     */
    _valueAddedData: null,
    /**
     * whether the value added fetch resulted in an error
     *
     * @property _isValueAddedError
     * @type {Boolean}
     * @default false
     * @private
     */
    _isValueAddedError: false,
    /**
     * query params for the charts requests
     *
     * @computed queryParams
     * @type {String}
     */
    queryParams: (0, _object.computed)('dateRange.[]', {
      get() {
        const {
          dateRange
        } = this;
        return dateRange ? (0, _queryParamUtil.getQueryParams)(dateRange) : '';
      }
    }),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'chartTabs', [{
        title: this.intl.t('dashboard.charts.valueAdded.title'),
        name: 'valueAdded'
      }, {
        title: this.intl.t('dashboard.charts.conversionsReservations.title'),
        name: 'conversions-reservations'
      }]);
      (0, _object.set)(this, 'activeTab', this.chartTabs[0]);
      (0, _object.setProperties)(this, {
        // property watcher properties
        _watchedProperties: ['dateRange', 'organizationSlug'],
        _onPropertyChange: () => {
          this.valueAddedChartData.perform();
          (0, _object.set)(this, 'activeTab', this.chartTabs[0]);
        }
      });
    },
    /**
     * fetches the datasets for the reservations
     *
     * @type {Task}
     * @function conversionsReservationsChartData
     */
    conversionsReservationsChartData: (0, _emberConcurrency.task)(function* () {
      this._isReservationsConversionsError = false;
      const tasks = [];
      const baseUrl = _environment.default.APP.apiBaseHost;
      const chartsUrl = `${baseUrl}/${this.organizationSlug}/dashboard/conversions-reservations-graph${this.queryParams}`;
      try {
        var _chartData$conversion;
        tasks.push(this.authenticatedFetch.fetch(chartsUrl, {}, null, [500]));
        tasks.push((0, _emberConcurrency.timeout)(250));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        // chart-data
        const chartData = yield response.json();
        if (chartData !== null && chartData !== void 0 && (_chartData$conversion = chartData.conversionsRerservationsGraph) !== null && _chartData$conversion !== void 0 && _chartData$conversion.datasets) {
          const data = this._formatChartData(chartData.conversionsRerservationsGraph);
          (0, _object.setProperties)(this, {
            _reservationsConversionsData: data
          });
        }
      } catch (error) {
        this._isReservationsConversionsError = true;
      }
    }).restartable(),
    /**
     * fetches the datasets for the value added
     *
     * @type {Task}
     * @function valueAddedChartData
     */
    valueAddedChartData: (0, _emberConcurrency.task)(function* () {
      this._isValueAddedError = false;
      const tasks = [];
      const baseUrl = _environment.default.APP.apiBaseHost;
      const chartsUrl = `${baseUrl}/${this.organizationSlug}/dashboard/added-value-graph${this.queryParams}`;
      try {
        var _chartData$addedValue;
        tasks.push(this.authenticatedFetch.fetch(chartsUrl, {}, null, [500]));
        tasks.push((0, _emberConcurrency.timeout)(250));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        // chart-data
        const chartData = yield response.json();
        if (chartData !== null && chartData !== void 0 && (_chartData$addedValue = chartData.addedValueGraph) !== null && _chartData$addedValue !== void 0 && _chartData$addedValue.datasets) {
          const data = this._formatChartData(chartData.addedValueGraph, true);
          (0, _object.setProperties)(this, {
            _valueAddedData: data
          });
        }
      } catch (error) {
        this._isValueAddedError = true;
      }
    }).restartable().on('init'),
    /**
     * Formats the apis response to the proper format in order to be
     * consumed by the `ui-chart`-component
     * @param {Object} data the data-object
     * @param {boolean} reverse flag for reversing the order of the colors
     */
    _formatChartData(data, reverse) {
      const chartData = Object.assign({}, data);
      // format the date labels
      const labels = chartData.labels.map(label => {
        // Regex to check if the date contains days.
        const regex = new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}', 'g');
        // We choose the correct date format by using the regex.
        const dateFormat = regex.test(label) ? 'dd MMM' : 'MMM yyyy';
        return (0, _format.default)(new Date(label), dateFormat, {
          locale: _de.default
        });
      });
      const datasets = chartData.datasets.map((dataset, i) => {
        // format currency
        if (dataset.format === 'currency') {
          const data = dataset.data.map(cents => {
            return (0, _currencySerialization.centsToAmount)(cents);
          });
          dataset.data = data;
        }
        if (reverse) {
          return Object.assign({}, dataset, _constants.CHART_COLORS.reverse()[i]);
        }
        return Object.assign({}, dataset, _constants.CHART_COLORS[i]);
      });
      chartData.datasets = datasets;
      chartData.labels = labels;
      chartData.comparison = {
        label: this.intl.t('dashboard.charts.comparison.lastYear')
      };
      return chartData;
    },
    actions: {
      selected(_ref) {
        let {
          name
        } = _ref;
        switch (name) {
          case 'conversions-reservations':
            this.conversionsReservationsChartData.perform();
            break;
          case 'valueAdded':
            this.valueAddedChartData.perform();
            break;
          default:
            break;
        }
      }
    }
  });
});