define("additive-mc/mirage/serializers/trigger-email-series", ["exports", "additive-mc/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    serialize(object, request) {
      // super call
      let json = _application.default.prototype.serialize.apply(this, arguments);

      // embed emails
      json.emailSeries.emails = object.emails.models;
      return json;
    }
  });
});