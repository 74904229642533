define("additive-mc/router", ["exports", "@ember/routing/router", "additive-mc/config/environment", "@additive-apps/auth/router"], function (_exports, _router, _environment, _router2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Router extends _router.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('auth', function () {
      (0, _router2.default)(this);
    });
    this.route('instance', {
      path: ':instance_id'
    }, function () {
      this.route('dashboard', {
        path: '/'
      });
      this.route('campaigns', function () {
        this.route('social', function () {
          this.route('detail', {
            path: ':campaign_id'
          }, function () {});
        });
        this.route('ad', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.mount('@additive-apps/multimedia-engine', {
              as: 'additive-multimedia-engine',
              path: 'mm'
            });
          });
          this.route('lead-ads', {
            path: 'lead-ads/:campaign_id'
          }, function () {
            this.route('lead-ad', {
              path: ':ad_id'
            }, function () {
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
          });
          this.route('content-ads', {
            path: 'content-ads/:campaign_id'
          }, function () {
            this.route('index', {
              path: '/'
            }, function () {
              this.route('content-ad', {
                path: ':ad_id'
              }, function () {
                this.route('edit');
              });
            });
            this.route('depictions', function () {
              this.route('index', {
                path: '/'
              }, function () {
                this.route('depiction', {
                  path: ':depiction_id'
                }, function () {
                  this.route('edit', function () {
                    this.mount('@additive-apps/multimedia-engine', {
                      as: 'additive-multimedia-engine',
                      path: 'mm'
                    });
                  });
                });
              });
            });
          });
          this.route('tiktok-lead-ads', {
            path: 'tiktok-lead-ads/:campaign_id'
          }, function () {
            this.route('index', {
              path: '/'
            }, function () {
              this.route('lead-ad', {
                path: ':ad_id'
              }, function () {
                this.route('edit');
              });
            });
            this.route('depictions', function () {
              this.route('index', {
                path: '/'
              }, function () {
                this.route('depiction', {
                  path: ':depiction_id'
                }, function () {
                  this.route('edit', function () {
                    this.mount('@additive-apps/multimedia-engine', {
                      as: 'additive-multimedia-engine',
                      path: 'mm'
                    });
                  });
                });
              });
            });
          });
          this.route('catalog-ads', {
            path: 'catalog-ads/:campaign_id'
          }, function () {
            this.route('index', {
              path: '/'
            }, function () {
              this.route('catalog-ad', {
                path: ':ad_id'
              }, function () {
                this.route('edit');
              });
            });
            this.route('depictions');
          });
        });
        this.route('email', function () {
          this.route('detail', {
            path: ':campaign_id'
          }, function () {
            this.route('index', {
              path: ''
            }, function () {
              this.route('voucher', {
                path: 'vouchers/:voucher_id'
              }, function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
              this.route('email', {
                path: 'emails/:email_id'
              }, function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
              this.route('create', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('email-series', function () {
              this.route('create');
              this.route('email-series', {
                path: ':email_series_id'
              });
            });
          });
        });
        this.route('trigger-email', function () {
          this.route('detail', {
            path: ':campaign_id'
          }, function () {
            this.route('index', {
              path: ''
            }, function () {
              this.route('voucher', {
                path: 'vouchers/:voucher_id'
              }, function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
              this.route('email', {
                path: 'emails/:email_id'
              }, function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
              this.route('create', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
              this.route('edit', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
            this.route('email-series', function () {
              this.route('create');
              this.route('email-series', {
                path: ':email_series_id'
              });
            });
          });
        });
        this.route('on-page', function () {
          this.route('detail', {
            path: ':campaign_id'
          }, function () {
            this.route('voucher', {
              path: 'vouchers/:voucher_id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('content', function () {
              this.route('create', function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
              this.route('content', {
                path: ':content_id'
              }, function () {
                this.mount('@additive-apps/multimedia-engine', {
                  as: 'additive-multimedia-engine',
                  path: 'mm'
                });
              });
            });
          });
        });
        this.route('referral', function () {
          this.route('detail', {
            path: ':campaign_id'
          }, function () {
            this.route('voucher', {
              path: 'vouchers/:voucher_id'
            }, function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('landingpage', {
              path: 'landingpages/:landingpage_id'
            }, function () {});
          });
        });
        this.route('search-ad', function () {
          this.route('index', {
            path: '/'
          }, function () {
            this.route('search-ad', {
              path: ':search_ad_campaign_id'
            }, function () {
              this.route('edit');
            });
          });
          this.route('ad-templates', function () {});
          this.route('extensions', function () {});
          this.route('target-groups', function () {});
        });
      });
      this.route('reports', function () {
        this.route('social', function () {
          this.route('past-marketing-actions');
        });
        this.route('ad', function () {
          this.route('past-marketing-actions');
        });
        this.route('email', function () {
          this.route('past-marketing-actions');
        });
        this.route('trigger-email', function () {
          this.route('past-marketing-actions');
        });
        this.route('on-page', function () {
          this.route('past-marketing-actions');
        });
        this.route('referral', function () {
          this.route('past-marketing-actions');
        });
        this.route('leads', function () {
          this.route('past-marketing-actions');
        });
      });
      this.route('preview', function () {
        this.route('email', {
          path: 'email/:email_id'
        });
      });
      this.route('services', function () {
        this.route('service', {
          path: ':service_id'
        });
      });
      this.route('settings', function () {
        this.route('index', {
          path: '/'
        }, function () {
          this.mount('@additive-apps/multimedia-engine', {
            as: 'additive-multimedia-engine',
            path: 'mm'
          });
        });
        this.route('legals');
      });
      this.route('widgets', function () {
        this.route('widget', {
          path: ':widget_id'
        }, function () {
          this.mount('@additive-apps/multimedia-engine', {
            as: 'additive-multimedia-engine',
            path: 'mm'
          });
        });
      });
      this.route('auditlog');
    });
    this.route('page-not-found', {
      path: '/*wildcard'
    });
  });
});