define("additive-mc/controllers/instance/campaigns/ad/index", ["exports", "additive-mc/config/environment", "@ember/controller", "@ember/service", "@ember/object", "@ember/object/computed", "@glimmer/tracking", "ember-concurrency"], function (_exports, _environment, _controller, _service, _object, _computed, _tracking, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsAdIndexController = _exports.default = (_dec = (0, _computed.alias)('currentUser.currentOrganization.id'), _dec2 = (0, _object.computed)('router.currentRouteName'), _dec3 = (0, _emberConcurrency.task)(function* (type) {
    try {
      const url = `${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/organisation-campaigns/ad/alerts/grouped`;
      const response = yield this.authenticatedFetch.fetch(url, {
        method: 'GET'
      });
      const json = yield response.json();
      this.integrityAlertData = json;
      if (type === 'check') {
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      }
    } catch (e) {
      this.isError = true;
      this.uiDialog.showError();
    }
  }), _dec4 = (0, _emberConcurrency.task)(function* () {
    try {
      const url = `${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/organisation-campaigns/ad/alerts/check`;
      const response = yield this.authenticatedFetch.fetch(url, {
        method: 'POST'
      });
      if (!response.ok) throw new Error();
      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (e) {
      this.isError = true;
      this.uiDialog.showError();
    }
  }), _class = class InstanceCampaignsAdIndexController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor3, this);
      _initializerDefineProperty(this, "currentUser", _descriptor4, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor5, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor6, this);
      _initializerDefineProperty(this, "uiToast", _descriptor7, this);
      _initializerDefineProperty(this, "uiState", _descriptor8, this);
      _initializerDefineProperty(this, "intl", _descriptor9, this);
      _defineProperty(this, "navdrawerStateKey", 'settings-drawer');
      /**
       * the campaign that is currently active
       *
       * @argument activeCampaign
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "activeCampaign", _descriptor10, this);
      /**
       * whether the create campaign dialog is active
       *
       * @argument activeCampaign
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isCreateCampaignDialog", _descriptor11, this);
      /**
       * whether the integrity alert dialog is open
       *
       * @argument activeCampaign
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isIntegrityDialogOpen", _descriptor12, this);
      /**
       * the array of integrity alerts that get displayed
       *
       * @argument integrityAlertData
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "integrityAlertData", _descriptor13, this);
      /**
       * whether an error occurred
       *
       * @argument isError
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isError", _descriptor14, this);
      /**
       * the query parameters for the fetch of individual alerts
       *
       * @argument queryParams
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "queryParams", _descriptor15, this);
      _initializerDefineProperty(this, "organizationSlug", _descriptor16, this);
      /**
       * fetches the alerts that get displayed
       *
       * @function fetchAlerts
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchAlerts", _descriptor17, this);
      /**
       * performs the integrity check anew
       *
       * @function performCheck
       * @type {Task}
       */
      _initializerDefineProperty(this, "performCheck", _descriptor18, this);
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get detailRoute() {
      if (this.router.currentRouteName.startsWith('instance.campaigns.ad.index.detail')) {
        return this.router.currentRouteName;
      }
      return null;
    }
    get isAdditive() {
      // TODO: Remove when Tik-Tok Lead Ads is finished
      // Additive user check deactivated on testing branch for outsourcing team
      // return this.currentUser?.user?.isAdditive;
      return true;
    }
    toggleDrawer() {
      this.uiState.getState(this.navdrawerStateKey).toggle();
    }
    openIntegrityDialog(params) {
      this.isIntegrityDialogOpen = true;
      this.queryParams = params;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "activeCampaign", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isCreateCampaignDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isIntegrityDialogOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "integrityAlertData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "queryParams", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "organizationSlug", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "detailRoute", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "detailRoute"), _class.prototype), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "fetchAlerts", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "performCheck", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleDrawer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDrawer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openIntegrityDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openIntegrityDialog"), _class.prototype), _class);
});