define("additive-mc/templates/instance/reports/email/past-marketing-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/Th4ChxW",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@isPlainView\"],[[28,[37,1],[\"reports.pastMarketingActions.title\"],null],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,[[\"@breadcrumbs\",\"@hasBreadcrumbs\"],[[28,[37,2],null,[[\"currentRouteName\",\"rootName\"],[\"past-marketing-actions\",\"reports\"]]],true]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"contentHeader\"]],null,[[\"@classNames\"],[\"w-100 ma-auto ph2 ph3-sm ph4-md mw8\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@classNames\",\"@currentRouteName\",\"@rootName\"],[\"font-xl\",\"past-marketing-actions\",\"reports\"]],null],[1,\"\\n    \"],[10,0],[14,0,\"font-md pv1 mb4\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"reports.pastMarketingActions.description\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,[[\"@classNames\"],[\"table-view\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@type\"],[\"email\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[1,\"  \"],[8,[30,1,[\"content\"]],null,[[\"@class\"],[\"pb4 mb4\"]],null],[1,\"\\n\"]],[1]]]]]],[\"view\"],false,[\"ui-content-views\",\"t\",\"hash\",\"ui-breadcrumbs\",\"amc-reports/past-marketing-actions\"]]",
    "moduleName": "additive-mc/templates/instance/reports/email/past-marketing-actions.hbs",
    "isStrictMode": false
  });
});