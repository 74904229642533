define("additive-mc/components/amc-campaign/ads/list-info-item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="amc-list-info-item flex flex-column font-sm">
    {{#if @display}}
      <div>
        {{yield}}
      </div>
      <div class="secondary mt05">
        {{@label}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "PSMZmn4T",
    "block": "[[[10,0],[14,0,\"amc-list-info-item flex flex-column font-sm\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"secondary mt05\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@display\",\"@label\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "additive-mc/components/amc-campaign/ads/list-info-item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});