define("additive-mc/mirage/routes/campaigns/lead-ad", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LeadAdRoutes = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const LeadAdRoutes = self => {
    self.get('/organisation-campaigns/ad-campaigns/lead-ads/:id/advertisements', schema => {
      return schema.advertisements.where({
        type: 'lead-ad'
      });
    });
    self.get('/organisation-campaigns/ad-campaigns/lead-ads/:id/advertisements/:advertisement_id', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      return schema.advertisements.find(advertisement_id);
    });
    self.post('/organisation-campaigns/ad-campaigns/lead-ads/:id/advertisements', (schema, request) => {
      let body = JSON.parse(request.requestBody);
      return schema.advertisements.create(_objectSpread(_objectSpread({}, body), {}, {
        state: 'unpublished',
        budgetSpend: {
          value: 0,
          percentage: 0
        }
      }));
    });
    self.put('/organisation-campaigns/ad-campaigns/lead-ads/:id/advertisements/:advertisement_id', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const body = JSON.parse(request.requestBody);
      const newModel = model.update(body);
      return newModel;
    });
    self.put('/organisation-campaigns/ad-campaigns/lead-ads/:id/advertisements/:advertisement_id/archive', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'archived'
      });
      return newModel;
    });
    self.get('/organisation-campaigns/ad-campaigns/lead-ads/:id/advertisements/:advertisement_id/validate', (schema, request) => {
      var _model$adCreative;
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const validations = [];
      if (!(model !== null && model !== void 0 && (_model$adCreative = model.adCreative) !== null && _model$adCreative !== void 0 && (_model$adCreative = _model$adCreative.multimediaVariants) !== null && _model$adCreative !== void 0 && _model$adCreative.length)) {
        validations.push({
          message: 'Es braucht mindestens ein Bild',
          property: 'adCreative.multimediaVariants',
          action: 'edit'
        });
      }
      ['cta', 'title', 'headline', 'text'].forEach(prop => {
        if (model !== null && model !== void 0 && model.adCreative && !(model !== null && model !== void 0 && model.adCreative[prop])) {
          validations.push({
            message: `${prop} darf nicht leer sein!`,
            prop: `adCreative.${prop}`,
            action: 'edit'
          });
        }
      });
      if (!validations.length) {
        return new _emberCliMirage.Response(200, {}, {});
      }
      return new _emberCliMirage.Response(422, {}, {
        validations
      });
    });
    self.get('/organisation-campaigns/ad-campaigns/lead-ads/:id/advertisements/metadata', schema => {
      return {
        budgetTypes: [{
          value: 'daily',
          label: 'Tagesbudget'
        }, {
          value: 'lifetime',
          label: 'Lebenslanges Budget'
        }],
        targetAudiences: [1, 2, 3, 4].map(i => ({
          value: `audience-${i}`,
          label: `Audience ${i}`
        })),
        pixels: [{
          label: 'Pixel',
          value: 'pixel'
        }],
        languages: [{
          label: 'Deutsch',
          value: 'de'
        }, {
          label: 'Italienisch',
          value: 'it'
        }, {
          label: 'Englisch',
          value: 'en'
        }],
        ctaTypes: [{
          label: 'Anmelden',
          value: 'SUBSCRIBE'
        }, {
          label: 'Herunterladen',
          value: 'DOWNLOAD'
        }]
      };
    });
    self.put('/organisation-campaigns/ad-campaigns/lead-ads/:id/advertisements/:advertisement_id/publish', (schema, request) => {
      const {
        advertisement_id
      } = request.params;

      // Randomly fail
      const random = Math.random();
      if (random > 0.5) {
        return new _emberCliMirage.Response(422, {}, {
          status: 422,
          code: 'boh',
          message: 'Random error, Facebook does not like this ad'
        });
      }
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'published'
      });
      return newModel;
    });
    self.put('/organisation-campaigns/ad-campaigns/lead-ads/:id/advertisements/:advertisement_id/unpublish', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'unpublished'
      });
      return newModel;
    });
    self.put('/organisation-campaigns/ad-campaigns/lead-ads/:id/advertisements/:advertisement_id/pause', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'paused'
      });
      return newModel;
    });
    self.put('/organisation-campaigns/ad-campaigns/lead-ads/:id/advertisements/:advertisement_id/continue', (schema, request) => {
      const {
        advertisement_id
      } = request.params;
      const model = schema.advertisements.find(advertisement_id);
      const newModel = model.update({
        state: 'published'
      });
      return newModel;
    });
  };
  _exports.LeadAdRoutes = LeadAdRoutes;
});