define("additive-mc/components/amc-campaign/configuration-item/multimedia", ["exports", "@ember/component", "@ember/object", "ember-changeset", "ember-concurrency", "@ember/service"], function (_exports, _component, _object, _emberChangeset, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which handles campaigns configuration-item for `multimedia`.
   *
   * @class amc-campaigns/configuration-item/multimedia
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    multimedia: (0, _service.inject)(),
    router: (0, _service.inject)(),
    tagName: '',
    /**
     * The configuration-item
     *
     * @property item
     * @default null
     */
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * The internal changeset of the item
     *
     * @property _multimediaChangeset
     * @default null
     * @private
     */
    _multimediaChangeset: null,
    /**
     * Changesets underlying object
     *
     * @property _resource
     * @default null
     * @private
     */
    _resource: null,
    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {},
    init() {
      this._super(...arguments);

      // set options for media selection
      this.multimedia.setSelectionOptions({
        maxCount: 1
      });
      let resource = {
        medium: []
      };
      if (this.item && this.item.value) {
        resource.medium = this.item.value;
      }
      const changeset = new _emberChangeset.default(resource);
      (0, _object.setProperties)(this, {
        _multimediaChangeset: changeset,
        _resource: resource
      });
    },
    willDestroyElement() {
      this._super(...arguments);
      this.multimedia.resetSelectionOptions();
    },
    /**
     * Sets options it to parents changeset and trigger save
     *
     * @function _save
     * @type {Task}
     * @param {Changeset} parentChangeset The changeset of the `with-modal` hoc
     * @private
     */
    _save: (0, _emberConcurrency.task)(function* (parentChangeset) {
      const tasks = [];
      const snapshot = this.item.value && Object.assign({}, this.item.value) || null;
      try {
        yield this._multimediaChangeset.validate();
        if (this._multimediaChangeset.get('isInvalid')) {
          return;
        }

        // Will update the underlying object, which in this case is `this._resource`
        this._multimediaChangeset.execute();
        parentChangeset.set('value', this._resource.medium);
        parentChangeset.execute();
        tasks.push(this.onSave());
        tasks.push((0, _emberConcurrency.timeout)(250));
        yield (0, _emberConcurrency.all)(tasks);
      } catch (error) {
        (0, _object.set)(this, 'item.value', snapshot);
      }
    }).drop(),
    actions: {
      _closeMultimedia() {
        /* TODO: dynamic type, currently only used for ad-campaign-types */
        this.router.transitionTo('instance.campaigns.ad.index.detail');
      },
      _openMultimedia() {
        /* TODO: dynamic type, currently only used for ad-campaign-types */
        this.router.transitionTo('instance.campaigns.ad.index.detail.additive-multimedia-engine');
      },
      _onResourceRemove() {
        this._multimediaChangeset.set('medium', null);
      },
      _onResourceAdd(_, _ref) {
        let [resource] = _ref;
        this._multimediaChangeset.set('medium', resource);
      }
    }
  });
});