define("additive-mc/templates/components/amc-campaign/configuration-item/boolean", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "m+TzMsJ7",
    "block": "[[[8,[39,0],[[16,\"data-test-config-item-boolean\",true]],[[\"@value\",\"@isReadOnly\",\"@onChange\"],[[30,0,[\"item\",\"value\"]],[30,0,[\"isReadOnly\"]],[28,[37,1],[[30,0],\"save\"],null]]],null]],[],false,[\"ui-switch\",\"action\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/boolean.hbs",
    "isStrictMode": false
  });
});