define("additive-mc/validations/search-ad-campaign", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/date", "@additive-apps/utils/validators/validate-if", "@additive-apps/utils/validators/numeral"], function (_exports, _validators, _date, _validateIf, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchAdCampaignValidation = _exports.searchAdCampaignPublishValidation = _exports.searchAdCampaignAdSetValidation = void 0;
  const searchAdCampaignValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const afterDateMessage = intl.t('errors.afterStartDate');
    const beforeEndDate = intl.t('errors.beforeEndDate');
    const minAdSetsLength = intl.t('searchAdCampaigns.errors.minAdSets');
    return {
      adSets: [(0, _validators.validatePresence)({
        presence: true,
        message: minAdSetsLength
      })],
      targetGroup: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      bid: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      landingPage: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      landingPageLanguage: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      targetGoals: (0, _validateIf.default)({
        validate: (0, _validators.validatePresence)({
          presence: true,
          message: presenceMessage
        }),
        if: {
          useDefaultTargetGoals: false
        },
        firstErrorMessage: true
      }),
      displayNetwork: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      searchPartner: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      dailyBudget: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      postfix: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      'displayedPath.first': (0, _validators.validateLength)({
        max: 15,
        message: intl.t('errors.maxLength', {
          maxLength: 15
        })
      }),
      'displayedPath.second': (0, _validators.validateLength)({
        max: 15,
        message: intl.t('errors.maxLength', {
          maxLength: 15
        })
      }),
      startDate: (0, _date.validateDateDependency)({
        type: 'before',
        on: 'endDate',
        allowBlank: false,
        allowEmptyDependency: true,
        message: beforeEndDate
      }),
      endDate: (0, _date.validateDateDependency)({
        type: 'after',
        on: 'startDate',
        allowBlank: true,
        allowEmptyDependency: true,
        message: afterDateMessage
      })
    };
  };
  _exports.searchAdCampaignValidation = searchAdCampaignValidation;
  const searchAdCampaignAdSetValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const maxAdsLength = intl.t('searchAdCampaigns.errors.maxAds');
    const minKeywordsLength = intl.t('searchAdCampaigns.errors.minKeywords');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      adTemplates: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _validators.validateLength)({
        max: 3,
        message: maxAdsLength
      })],
      keywords: (0, _validators.validatePresence)({
        presence: true,
        message: minKeywordsLength
      })
    };
  };
  _exports.searchAdCampaignAdSetValidation = searchAdCampaignAdSetValidation;
  const searchAdCampaignPublishValidation = intl => {
    const baseIntlKey = 'searchAdCampaigns.publishDialog.errorMessages';
    const budgetRequired = intl.t(`${baseIntlKey}.budget`);
    const targetGroupRequired = intl.t(`${baseIntlKey}.targetGroup`);
    const bidRequired = intl.t(`${baseIntlKey}.bid`);
    const targetGoalsRequired = intl.t(`${baseIntlKey}.targetGoals`);
    return {
      dailyBudget: (0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: false,
        message: budgetRequired
      }),
      targetGroup: [(0, _validators.validatePresence)({
        presence: true,
        message: targetGroupRequired
      }), (0, _validators.validateLength)({
        min: 1,
        message: targetGroupRequired
      })],
      targetGoals: (0, _validateIf.default)({
        validate: (0, _validators.validatePresence)({
          presence: true,
          message: targetGoalsRequired
        }),
        if: {
          useDefaultTargetGoals: false
        },
        firstErrorMessage: true
      }),
      bid: [(0, _validators.validatePresence)({
        presence: true,
        message: bidRequired
      }), (0, _validators.validateLength)({
        min: 1,
        message: bidRequired
      })]
    };
  };
  _exports.searchAdCampaignPublishValidation = searchAdCampaignPublishValidation;
});