define("additive-mc/mirage/routes/reports/social", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SocialReportsReservations = _exports.SocialReportsReservationTable = _exports.SocialReportsPastMarketingActions = _exports.SocialReportsMetrics = _exports.SocialReportsKeyIndicators = _exports.SocialReportsConversions = void 0;
  const SocialReportsKeyIndicators = self => {
    self.get('/reports/social/key-indicators', () => {
      return {
        valueAddedSum: {
          type: 'compare',
          change: 32,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        },
        valueAdded: {
          type: 'compare',
          change: 59.35,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        },
        turnover: {
          type: 'compare',
          change: 25,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.SocialReportsKeyIndicators = SocialReportsKeyIndicators;
  const SocialReportsReservations = self => {
    self.get('/reports/social/reservations', () => {
      return {
        reservations: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        newGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        reactivatedGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        returningGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.SocialReportsReservations = SocialReportsReservations;
  const SocialReportsConversions = self => {
    self.get('/reports/social/conversions', () => {
      return {
        conversions: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        enquiries: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        newsletterSubscriptions: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        vouchers: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        bookings: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        other: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.SocialReportsConversions = SocialReportsConversions;
  const SocialReportsMetrics = self => {
    self.get('/reports/social/metrics', schema => {
      return {
        metrics: {
          postings: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          impressions: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          clicks: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          }
        },
        campaigns: schema.reportsSocialPostings.all()
      };
    });
  };
  _exports.SocialReportsMetrics = SocialReportsMetrics;
  const SocialReportsPastMarketingActions = self => {
    self.get('/reports/social/past-marketing-actions', schema => {
      return schema.socialPastMarketingActions.all();
    });
  };
  _exports.SocialReportsPastMarketingActions = SocialReportsPastMarketingActions;
  const SocialReportsReservationTable = self => {
    self.get('/reports/social/reservations-table', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      const data = [];
      for (let i = 0; i < 20; i++) {
        data.push([{
          value: _faker.default.name.findName(),
          link: 'https://google.com',
          format: 'custom',
          customKey: 'name'
        }, 'Neue Reservierung', {
          value: _faker.default.date.past(),
          format: 'date'
        }, {
          value: _faker.default.random.number(),
          format: 'currency_cent'
        }, {
          value: {
            start: _faker.default.date.between(start, end),
            end: _faker.default.date.between(start, end)
          },
          format: 'custom',
          customKey: 'range'
        }, '3 Erw. 2 Kinder', _faker.default.name.findName()]);
      }
      return {
        labels: ['Reservierende Person', 'Typ', 'Reservierungsdatum', 'Res.-Umsatz', 'Aufenthalt', 'Reisegruppe', 'Kampagne'],
        data
      };
    });
  };
  _exports.SocialReportsReservationTable = SocialReportsReservationTable;
});