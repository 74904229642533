define("additive-mc/components/amc-reports/past-marketing-actions", ["exports", "additive-mc/config/environment", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service", "ember-concurrency"], function (_exports, _environment, _component, _object, _computed, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module amc-reports
   */
  var _default = _exports.default = _component.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    organizationSlug: (0, _computed.alias)('currentUser.currentOrganization.id'),
    /**
     * the table header options
     *
     * @property tableHeaderOptions
     * @type {Array}
     * @default null
     */
    tableHeaderOptions: null,
    /**
     * the type of campaigns
     *
     * @property type
     * @type {String}
     * @default undefined
     */
    type: undefined,
    /**
     * fetches the past marketing actions
     *
     * @type {Task}
     * @function pastMarketingActions
     */
    pastMarketingActions: (0, _emberConcurrency.task)(function* () {
      const tasks = [];
      const baseUrl = _environment.default.APP.apiBaseHost;
      const base = `${baseUrl}/${this.organizationSlug}/reports/${this.type}/past-marketing-actions`;
      try {
        tasks.push(this.authenticatedFetch.fetch(base));
        tasks.push((0, _emberConcurrency.timeout)(250));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        const data = yield response.json();
        (0, _object.set)(this, 'pastMarketingActions', data[`${this.type}PastMarketingActions`]);
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }).on('init'),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'tableHeaderOptions', [{
        i18n: 'reports.pastMarketingActions.social.columns.title',
        minWidth: '80px'
      }, {
        i18n: 'reports.pastMarketingActions.social.columns.type',
        minWidth: '80px'
      }, {
        i18n: 'reports.pastMarketingActions.social.columns.publishedDate',
        minWidth: '80px'
      }, {
        i18n: 'reports.pastMarketingActions.social.columns.impressions',
        minWidth: '80px'
      }, {
        i18n: 'reports.pastMarketingActions.social.columns.clicks',
        minWidth: '80px'
      }, {
        i18n: 'reports.pastMarketingActions.social.columns.conversions',
        minWidth: '80px'
      }, {
        i18n: 'reports.pastMarketingActions.social.columns.reservations',
        minWidth: '80px'
      }]);
    }
  });
});