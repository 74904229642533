define("additive-mc/mirage/routes/reports/overview", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ReportsValueAdded = _exports.ReportsReservations = _exports.ReportsReservationTable = _exports.ReportsOverview = _exports.ReportsLeadsTable = _exports.ReportsKeyIndicators = _exports.ReportsConversions = void 0;
  /**
   * Routes for the general reports overview
   * @module routes: instance/reports
   */

  /**
   *
   * @param {*} self
   */
  const ReportsOverview = self => {
    self.get('/reports', () => {
      return {
        meta: {
          emailCampaignsCount: _faker.default.random.number(4),
          adCampaignsCount: _faker.default.random.number(2),
          socialCampaignsCount: _faker.default.random.number(3)
        }
      };
    });
  };
  _exports.ReportsOverview = ReportsOverview;
  const ReportsKeyIndicators = self => {
    self.get('/reports/key-indicators', () => {
      return {
        valueAddedSum: {
          type: 'compare',
          change: 32,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        },
        valueAdded: {
          type: 'compare',
          change: 59.35,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        },
        turnover: {
          type: 'compare',
          change: 25,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.ReportsKeyIndicators = ReportsKeyIndicators;
  const ReportsReservations = self => {
    self.get('/reports/reservations', () => {
      return {
        reservations: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        newGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        reactivatedGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        returningGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.ReportsReservations = ReportsReservations;
  const ReportsReservationTable = self => {
    self.get('/reports/reservations-table', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      const data = [];
      for (let i = 0; i < 36; i++) {
        data.push([{
          value: _faker.default.name.findName(),
          link: 'https://google.com',
          format: 'custom',
          customKey: 'name'
        }, 'returning', 'Reservation', {
          value: _faker.default.date.past(),
          format: 'date'
        }, {
          value: _faker.default.random.number(),
          format: 'currency_cent'
        }, {
          value: {
            start: _faker.default.date.between(start, end),
            end: _faker.default.date.between(start, end)
          },
          format: 'custom',
          customKey: 'range'
        }, '3 Erw. 2 Kinder', {
          value: 'Guest Lifecycle Email Campaign',
          link: 'https://google.com',
          format: 'custom',
          customKey: 'campaign_name'
        }, 'Geburtstagsgutschein von Freunden']);
      }
      return {
        labels: ['Confirmed Guest', 'Type', 'Res. Status', 'Res. Date', 'Res. Revenue', 'Stay', 'Group', 'Campaign Type', 'Campaign Name'],
        data
      };
    });
    self.get('/reports/leads/reservations-table', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      const data = [];
      for (let i = 0; i < 36; i++) {
        data.push([{
          value: _faker.default.name.findName(),
          link: 'https://google.com',
          format: 'custom',
          customKey: 'name'
        }, 'returning', 'Reservation', {
          value: _faker.default.date.past(),
          format: 'date'
        }, {
          value: _faker.default.random.number(),
          format: 'currency_cent'
        }, {
          value: {
            start: _faker.default.date.between(start, end),
            end: _faker.default.date.between(start, end)
          },
          format: 'custom',
          customKey: 'range'
        }, '3 Erw. 2 Kinder', {
          value: 'Guest Lifecycle Email Campaign',
          link: 'https://google.com',
          format: 'custom',
          customKey: 'campaign_name'
        }, 'Geburtstagsgutschein von Freunden']);
      }
      return {
        labels: ['Confirmed Guest', 'Type', 'Res. Status', 'Res. Date', 'Res. Revenue', 'Stay', 'Group', 'Campaign Type', 'Campaign Name'],
        data
      };
    });
  };
  _exports.ReportsReservationTable = ReportsReservationTable;
  const ReportsLeadsTable = self => {
    self.get('/reports/leads-list', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      const data = [];
      for (let i = 0; i < 20; i++) {
        data.push([{
          value: _faker.default.name.findName(),
          link: 'https://google.com',
          format: 'custom',
          customKey: 'name'
        }, {
          value: _faker.default.date.past(),
          format: 'date'
        }, 'Facebook Lead Campaign', 'ca-d-de-lead-tb']);
      }
      return {
        labels: ['Person', 'Sign In Date', 'Campaign Type', 'Campaign Name'],
        data
      };
    });
    self.get('/reports/leads/leads-list', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      const data = [];
      for (let i = 0; i < 20; i++) {
        data.push([{
          value: _faker.default.name.findName(),
          link: 'https://google.com',
          format: 'custom',
          customKey: 'name'
        }, {
          value: _faker.default.date.past(),
          format: 'date'
        }, 'Facebook Lead Campaign', 'ca-d-de-lead-tb']);
      }
      return {
        labels: ['Person', 'Sign In Date', 'Campaign Type', 'Campaign Name'],
        data
      };
    });
  };
  _exports.ReportsLeadsTable = ReportsLeadsTable;
  const ReportsConversions = self => {
    self.get('/reports/conversions', () => {
      return {
        conversions: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        enquiries: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        newsletterSubscriptions: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        vouchers: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        bookings: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        leadsIncentives: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        other: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.ReportsConversions = ReportsConversions;
  const ReportsValueAdded = self => {
    self.get('/reports/value-added', schema => {
      let data = schema.valueAddeds.all();
      return data;
    });
  };
  _exports.ReportsValueAdded = ReportsValueAdded;
});