define("additive-mc/models/on-page-campaign", ["exports", "additive-mc/models/campaign", "@ember-data/model", "@ember/object/computed"], function (_exports, _campaign, _model, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _campaign.default.extend({
    widgetConfigurations: (0, _model.attr)('amc-configurations'),
    orderedWidgetConfigurations: (0, _computed.sort)('widgetConfigurations', 'configurationSorting'),
    voucher: (0, _model.belongsTo)('on-page-campaign-voucher', {
      async: false
    })
  });
});