define("additive-mc/adapters/on-page-campaign-voucher", ["exports", "additive-mc/adapters/organization-campaign"], function (_exports, _organizationCampaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _organizationCampaign.default.extend({
    type: 'on-page',
    urlForMultiple() {
      return `${this._super(...arguments)}/vouchers`;
    }
  });
});