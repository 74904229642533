define("additive-mc/routes/application", ["exports", "@ember/routing/route", "@additive-apps/auth/mixins/application-auth-route-mixin", "@ember/service", "ember-concurrency"], function (_exports, _route, _applicationAuthRouteMixin, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ApplicationRoute = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    try {
      // check if the browser supports serviceWorker at all
      if ('serviceWorker' in navigator) {
        const registration = yield navigator.serviceWorker.getRegistration();

        // detect serviceWorker update available and wait for it to become installed
        registration.addEventListener('updatefound', () => {
          if (registration.installing) {
            // wait until the new serviceWorker is actually installed (ready to take over)
            registration.installing.addEventListener('statechange', () => {
              // if there's an existing controller (previous serviceWorker), show the update toast and clear the interval
              if (registration.waiting && navigator.serviceWorker.controller) {
                this.showUpdateToast();
              }
            });
          }
        });
      }
    } catch (e) {
      // don't display anything if this task fails, because it runs in the background
    }
  }).drop(), _class = class ApplicationRoute extends _route.default.extend(_applicationAuthRouteMixin.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiPaths", _descriptor2, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _defineProperty(this, "routeAfterAuthentication", 'instance');
      _defineProperty(this, "intervalId", null);
      _defineProperty(this, "isToastActive", false);
      _initializerDefineProperty(this, "updateServiceWorker", _descriptor5, this);
    }
    setupUpdateCheck() {
      const task = this.updateServiceWorker;
      // sets interval that checks for new SW registrations and updates those registrations
      // updating the registration will then trigger the installing and waiting events
      if (!this.intervalId) {
        this.intervalId = setInterval(function () {
          if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(registration => {
              // eslint-disable-next-line
              registration.update();
              task.perform();
            });
          }
        }, 120000);
      }
    }
    showUpdateToast() {
      if (!this.isToastActive) {
        clearInterval(this.intervalId);
        const toast = this.uiToast.showToast({
          type: 'warning',
          title: this.intl.t('global.toast.update.title'),
          description: this.intl.t('global.toast.update.description'),
          actionLabel: this.intl.t('global.toast.update.actionLabel'),
          duration: -1,
          icon: 'announcement',
          onClick: () => {
            this.uiToast.destroyToast(toast);
            this.isToastActive = false;
            this.applyUpdate();
          },
          onAbort: () => {
            this.uiToast.destroyToast(toast);
            this.isToastActive = false;
          }
        });
        this.isToastActive = true;
      }
    }
    applyUpdate() {
      // refresh the page; this causes the new serviceWorker to be applied.
      window.location.reload();
    }
    async beforeModel() {
      await this.uiLocale.setup();
      // update to api.<baseHost>.tech host format
      this.uiPaths.setApiRootKey('');
    }
    setupController() {
      super.setupController(...arguments);

      // checks if serviceWorker update is available once
      this.updateServiceWorker.perform();

      // sets up an interval of 120sec, where it checks again
      this.setupUpdateCheck();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiPaths", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "updateServiceWorker", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});