define("additive-mc/components/amc-reports/social-metrics", ["exports", "additive-mc/config/environment", "@additive-apps/ui/components/ui-property-watcher", "@ember/object", "ember-concurrency", "@ember/service", "@additive-apps/ui/utils/query-param-util"], function (_exports, _environment, _uiPropertyWatcher, _object, _emberConcurrency, _service, _queryParamUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class amc-reports/key-indicators
   * @module amc-reports
   */
  var _default = _exports.default = _uiPropertyWatcher.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    /**
     * AbortController for the current fetch
     *
     * @property abortController
     * @type {AbortController}
     * @default null
     */
    abortController: null,
    /**
     * the date range object
     *
     * @property _dateRange
     * @type {Object}
     */
    dateRange: null,
    /**
     * the current organization slug
     *
     * @property organizationSlug
     * @type {String}
     * @default null
     */
    organizationSlug: null,
    /**
     * Whether the table is empty
     *
     * @computed isTableEmpty
     * @type {Boolean}
     */
    get isTableEmpty() {
      var _this$campaigns;
      return !((_this$campaigns = this.campaigns) !== null && _this$campaigns !== void 0 && _this$campaigns.length) && !this.socialMetrics.isRunning;
    },
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'tableHeaderOptions', [{
        i18n: 'reports.socialMetrics.columns.campaign',
        minWidth: '80px'
      }, {
        i18n: 'reports.socialMetrics.columns.postings',
        minWidth: '80px'
      }, {
        i18n: 'reports.socialMetrics.columns.impressions',
        minWidth: '80px'
      }, {
        i18n: 'reports.socialMetrics.columns.clicks',
        minWidth: '80px'
      }, {
        i18n: 'reports.socialMetrics.columns.reservations',
        minWidth: '80px'
      }, {
        i18n: 'reports.socialMetrics.columns.conversions',
        minWidth: '80px'
      }]);
      (0, _object.setProperties)(this, {
        // property watcher properties
        _watchedProperties: ['dateRange', 'organizationSlug'],
        _onPropertyChange: () => this.socialMetrics.perform()
      });
    },
    /**
     * fetches social metrics for the social report
     *
     * @type {Task}
     * @function socialMetrics
     */
    socialMetrics: (0, _emberConcurrency.task)(function* () {
      const tasks = [];
      const baseUrl = _environment.default.APP.apiBaseHost;
      const {
        dateRange
      } = this;
      const qps = dateRange ? (0, _queryParamUtil.getQueryParams)(dateRange) : '';
      const chartsUrl = `${baseUrl}/${this.organizationSlug}/reports/social/metrics${qps}`;
      try {
        tasks.push(this.authenticatedFetch.fetch(chartsUrl, {
          signal: this.abortController && this.abortController.signal
        }));
        tasks.push((0, _emberConcurrency.timeout)(250));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        const {
          metrics,
          campaigns
        } = yield response.json();
        (0, _object.setProperties)(this, {
          metrics,
          campaigns: campaigns.models
        });
      } catch (error) {
        /* When AbortControllers signal gets aborted, this is intended, so do not show a toast */
        if (error && error.name === 'AbortError') {
          return;
        }
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }).restartable().on('init')
  });
});