define("additive-mc/validators/depiction-placeholders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateDepictionPlaceholders = validateDepictionPlaceholders;
  /**
   *  @module Validators
   */

  /**
   * @function validateDepictionPlaceholders
   *
   * @param {Any} value
   * @param {Object} options
   * @param {String} options.message Error message
   *
   * @return {Boolean|ValidationErr}
   */
  function validateDepictionPlaceholders() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const message = (options === null || options === void 0 ? void 0 : options.message) || false;
    const allowedPlaceholders = (options === null || options === void 0 ? void 0 : options.allowedPlaceholders) || [];
    return (key, value) => {
      let isValid = true;
      const placeholders = value.match(/{{[a-zA-Z._| ]*}}/g) || [];
      placeholders.forEach(placeholder => {
        if (allowedPlaceholders.indexOf(placeholder) === -1) {
          isValid = false;
        }
      });
      const openingBracketsCount = (value.match(/{{/g) || []).length;
      const closingBracketsCount = (value.match(/}}/g) || []).length;

      /**
       * check if there are incomplete placeholders
       * or other variations ignore by the regex above
       */
      if (openingBracketsCount !== closingBracketsCount || openingBracketsCount !== placeholders.length) {
        isValid = false;
      }
      return isValid || message;
    };
  }
});