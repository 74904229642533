define("additive-mc/helpers/ui-synced-value", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uiSyncedValue = uiSyncedValue;
  function uiSyncedValue(params, _ref) {
    let {
      value = '',
      sync = false
    } = _ref;
    const {
      syncedValue
    } = params && params[0] || {};
    return sync ? syncedValue : value;
  }
  var _default = _exports.default = (0, _helper.helper)(uiSyncedValue);
});