define("additive-mc/mirage/serializers/voucher", ["exports", "additive-mc/mirage/serializers/application", "@ember/array"], function (_exports, _application, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    serialize(object, request) {
      // super call
      let json = _application.default.prototype.serialize.apply(this, arguments);

      // Some multilingual-fake behavior
      const languageBehavior = (entity, newLanguage) => {
        if (!newLanguage) {
          return entity;
        }

        // Update language property depending on `Accept-Langauge` header
        if (entity.language !== newLanguage) {
          entity.language = newLanguage;
        }

        // Add language in front of multilingual-property. to make language change visible.
        entity.policies = `${entity.policies}<p>${newLanguage}</p>`;
        entity.buttonText = `${entity.buttonText}-${newLanguage}`;
        return entity;
      };
      if ((0, _array.isArray)(object)) {
        json.vouchers && json.vouchers.forEach((entity, key) => {
          json.vouchers[key] = languageBehavior(entity, request.requestHeaders['Accept-Language']);
        });
      } else {
        json.voucher = languageBehavior(json.voucher, request.requestHeaders['Accept-Language']);
      }
      return json;
    }
  });
});