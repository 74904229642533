define("additive-mc/mirage/routes/dashboard/charts", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DashboardCharts = void 0;
  const DashboardCharts = self => {
    self.get('/dashboard/added-value-graph', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      const startDate = new Date(start);
      const endDate = new Date(end);
      const numOfMonths = Math.abs((0, _dateFns.differenceInMonths)(startDate, endDate));
      if (isNaN(numOfMonths)) {
        return null;
      }
      return {
        addedValueGraph: {
          type: 'compare',
          labels: [...Array(numOfMonths).keys()].map(i => (0, _dateFns.format)((0, _dateFns.addMonths)(new Date(start.toString()), i), 'yyyy-MM')),
          datasets: [{
            label: 'Wertschöpfung',
            format: 'currency',
            currency: 'EUR',
            data: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 1000)),
            compare: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 1000)),
            tooltipData: null
          }, {
            label: 'Reservierungsumsätze',
            format: 'currency',
            currency: 'EUR',
            data: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 1000)),
            compare: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 1000)),
            tooltipData: null
          }, {
            label: 'Gesamtumsätze',
            format: 'currency',
            currency: 'EUR',
            data: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 1000)),
            compare: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 1000)),
            tooltipData: null
          }],
          comparison: {
            label: 'last_year'
          }
        }
      };
    });
    self.get('/dashboard/conversions-reservations-graph', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      const startDate = new Date(start);
      const endDate = new Date(end);
      const numOfMonths = Math.abs((0, _dateFns.differenceInMonths)(startDate, endDate));
      return {
        conversionsRerservationsGraph: {
          type: 'compare',
          labels: [...Array(numOfMonths).keys()].map(i => (0, _dateFns.format)((0, _dateFns.addMonths)(new Date(start.toString()), i), 'yyyy-MM')),
          datasets: [{
            label: 'Conversions',
            format: 'number',
            data: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 1000)),
            compare: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 1000)),
            tooltipData: null
          }, {
            label: 'Reservations',
            format: 'number',
            data: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 1000)),
            compare: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 1000)),
            tooltipData: null
          }],
          comparison: {
            label: 'last_year'
          }
        }
      };
    });
  };
  _exports.DashboardCharts = DashboardCharts;
});