define("additive-mc/serializers/advertisement", ["exports", "additive-mc/serializers/application", "additive-mc/utils/currency-serialization", "@ember/object", "lodash.clonedeep"], function (_exports, _application, _currencySerialization, _object, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdvertisementSerializer extends _application.default {
    normalize(model, hash) {
      var _hash$budgetSpend, _hash$budget, _hash$adCreative;
      if (hash.totalBudget) {
        (0, _object.set)(hash, 'totalBudget', (0, _currencySerialization.centsToAmount)(hash.totalBudget).toString());
      }
      if ((_hash$budgetSpend = hash.budgetSpend) !== null && _hash$budgetSpend !== void 0 && _hash$budgetSpend.value) {
        (0, _object.set)(hash, 'budgetSpend.value', (0, _currencySerialization.centsToAmount)(hash.budgetSpend.value).toString());
      }
      if ((_hash$budget = hash.budget) !== null && _hash$budget !== void 0 && _hash$budget.amount) {
        (0, _object.set)(hash, 'budget.amount', (0, _currencySerialization.centsToAmount)(hash.budget.amount).toString());
      }
      if (!hash.startDate && !hash.endDate) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        (0, _object.set)(hash, 'startDate', tomorrow.toISOString());
        const nextWeek = new Date();
        nextWeek.setDate(tomorrow.getDate() + 7);
        (0, _object.set)(hash, 'endDate', nextWeek.toISOString());
      }

      // sort images
      if ((_hash$adCreative = hash.adCreative) !== null && _hash$adCreative !== void 0 && _hash$adCreative.multimediaVariants) {
        var _hash$adCreative$mult;
        const sortedMedia = (_hash$adCreative$mult = hash.adCreative.multimediaVariants) === null || _hash$adCreative$mult === void 0 ? void 0 : _hash$adCreative$mult.sort((a, b) => a.position - b.position);
        (0, _object.set)(hash, 'adCreative.multimediaVariants', sortedMedia);
      }
      return super.normalize(...arguments);
    }
    serialize(snapshot, options) {
      var _json$budgetSpend, _json$budget;
      let json = (0, _lodash.default)(super.serialize(snapshot, options));

      // set position of images
      if (json.adCreative) {
        var _json$adCreative$mult;
        const media = ((_json$adCreative$mult = json.adCreative.multimediaVariants) === null || _json$adCreative$mult === void 0 ? void 0 : _json$adCreative$mult.map((item, index) => {
          item.position = index;
          return item;
        })) || null;
        (0, _object.set)(json, 'adCreative.multimediaVariants', media);
      }

      // transform currency properties to cents
      if (json !== null && json !== void 0 && json.totalBudget) {
        (0, _object.set)(json, 'totalBudget', (0, _currencySerialization.amountToCents)(json.totalBudget));
      }
      if (json !== null && json !== void 0 && (_json$budgetSpend = json.budgetSpend) !== null && _json$budgetSpend !== void 0 && _json$budgetSpend.value) {
        (0, _object.set)(json, 'budgetSpend.value', (0, _currencySerialization.amountToCents)(json.budgetSpend.value));
      }
      if (json !== null && json !== void 0 && (_json$budget = json.budget) !== null && _json$budget !== void 0 && _json$budget.amount) {
        (0, _object.set)(json, 'budget.amount', (0, _currencySerialization.amountToCents)(json.budget.amount));
      }
      if (json.promotedProducts) {
        (0, _object.set)(json, 'promotedProducts', json.promotedProducts.filter(promotedProduct => promotedProduct.selected).map(promotedProduct => {
          delete promotedProduct.selected;
          promotedProduct.specifiedSelection = promotedProduct.specifiedSelection.map(selection => selection.value);
          return promotedProduct;
        }));
      }
      return json;
    }
  }
  _exports.default = AdvertisementSerializer;
});