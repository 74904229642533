define("additive-mc/templates/components/amc-toggle-activation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "V8iyHKG9",
    "block": "[[[18,1,[[28,[37,1],null,[[\"toggleAction\",\"isRunning\"],[[28,[37,2],[[30,0],\"confirm\"],null],[30,0,[\"_send\",\"isRunning\"]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"action\"]]",
    "moduleName": "additive-mc/templates/components/amc-toggle-activation.hbs",
    "isStrictMode": false
  });
});