define("additive-mc/components/ae-configurations/apps/journal-post", ["exports", "@additive-apps/configurator/components/ae-configurations/apps/journal-post"], function (_exports, _journalPost) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _journalPost.default;
    }
  });
});