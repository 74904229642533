define("additive-mc/models/advertisement", ["exports", "@ember-data/model", "@ember/object", "@ember/object/computed", "@ember/service", "@additive-apps/ui/utils/model-action"], function (_exports, _model, _object, _computed, _service, _modelAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AdvertisementModel = _exports.default = (_dec = (0, _model.attr)('date'), _dec2 = (0, _model.attr)('date'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)(), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.attr)(), _dec13 = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('boolean'), _dec19 = (0, _model.attr)(), _dec20 = (0, _model.attr)(), _dec21 = (0, _model.attr)(), _dec22 = (0, _model.attr)(), _dec23 = (0, _model.attr)(), _dec24 = (0, _model.attr)(), _dec25 = (0, _model.attr)(), _dec26 = (0, _model.attr)('boolean'), _dec27 = (0, _model.attr)('string'), _dec28 = (0, _computed.equal)('state', 'published'), _dec29 = (0, _computed.equal)('state', 'paused'), _dec30 = (0, _computed.equal)('state', 'unpublished'), _dec31 = (0, _computed.equal)('state', 'expired'), _dec32 = (0, _computed.equal)('state', 'archived'), _dec33 = (0, _computed.or)('isPublic', 'isDraft', 'isPaused'), _dec34 = (0, _object.computed)('state'), _dec35 = (0, _modelAction.default)(), _dec36 = (0, _modelAction.default)(), _dec37 = (0, _modelAction.default)(), _dec38 = (0, _modelAction.default)(), _dec39 = (0, _modelAction.default)('GET'), _dec40 = (0, _modelAction.default)('PUT', async response => {
    if (!(response !== null && response !== void 0 && response.ok)) {
      const {
        message
      } = await response.json();
      throw new Error(message);
    }
    const advertisement = await response.json();
    return advertisement;
  }), _class = class AdvertisementModel extends _model.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "startDate", _descriptor3, this);
      _initializerDefineProperty(this, "endDate", _descriptor4, this);
      _initializerDefineProperty(this, "state", _descriptor5, this);
      _initializerDefineProperty(this, "fbPixel", _descriptor6, this);
      _initializerDefineProperty(this, "targetAudience", _descriptor7, this);
      _initializerDefineProperty(this, "budgetSpend", _descriptor8, this);
      _initializerDefineProperty(this, "totalBudget", _descriptor9, this);
      _initializerDefineProperty(this, "label", _descriptor10, this);
      _initializerDefineProperty(this, "campaignPostfix", _descriptor11, this);
      _initializerDefineProperty(this, "type", _descriptor12, this);
      _initializerDefineProperty(this, "unlimitedDuration", _descriptor13, this);
      // lead-ad specific
      _initializerDefineProperty(this, "adCreative", _descriptor14, this);
      _initializerDefineProperty(this, "isDynamicCreative", _descriptor15, this);
      _initializerDefineProperty(this, "language", _descriptor16, this);
      // tiktok-lead-ad specific
      _initializerDefineProperty(this, "targetGroup", _descriptor17, this);
      _initializerDefineProperty(this, "identity", _descriptor18, this);
      _initializerDefineProperty(this, "utmParameters", _descriptor19, this);
      _initializerDefineProperty(this, "timeLimited", _descriptor20, this);
      _initializerDefineProperty(this, "timeLimitation", _descriptor21, this);
      // content-ad specific
      _initializerDefineProperty(this, "targetLanguages", _descriptor22, this);
      _initializerDefineProperty(this, "availableLanguages", _descriptor23, this);
      _initializerDefineProperty(this, "language", _descriptor24, this);
      _initializerDefineProperty(this, "depictions", _descriptor25, this);
      // catalog-ad specific
      _initializerDefineProperty(this, "promotedProducts", _descriptor26, this);
      _initializerDefineProperty(this, "budget", _descriptor27, this);
      _initializerDefineProperty(this, "hasBeenPublished", _descriptor28, this);
      _initializerDefineProperty(this, "optimizationEvent", _descriptor29, this);
      _initializerDefineProperty(this, "isPublic", _descriptor30, this);
      _initializerDefineProperty(this, "isPaused", _descriptor31, this);
      _initializerDefineProperty(this, "isDraft", _descriptor32, this);
      _initializerDefineProperty(this, "isExpired", _descriptor33, this);
      _initializerDefineProperty(this, "isArchived", _descriptor34, this);
      _initializerDefineProperty(this, "isActive", _descriptor35, this);
      _initializerDefineProperty(this, "pause", _descriptor36, this);
      _initializerDefineProperty(this, "continue", _descriptor37, this);
      _initializerDefineProperty(this, "publish", _descriptor38, this);
      _initializerDefineProperty(this, "unpublish", _descriptor39, this);
      _initializerDefineProperty(this, "validate", _descriptor40, this);
      _initializerDefineProperty(this, "archive", _descriptor41, this);
    }
    get isLeadAd() {
      return this.type === 'lead-ad';
    }
    get isTiktokLeadAd() {
      return this.type === 'tiktok-lead-ad';
    }
    get isContentAd() {
      return this.type === 'content-ad';
    }
    get isCatalogAd() {
      return this.type === 'catalog-ad';
    }
    get image() {
      var _this$adCreative;
      return (_this$adCreative = this.adCreative) === null || _this$adCreative === void 0 || (_this$adCreative = _this$adCreative.multimediaVariants) === null || _this$adCreative === void 0 ? void 0 : _this$adCreative.firstObject;
    }
    get publishedState() {
      if (this.state === 'published') {
        return 'success';
      } else if (this.state === 'unpublished') {
        return 'error';
      } else if (this.state === 'paused') {
        return 'warning';
      }
      return null;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fbPixel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "targetAudience", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "budgetSpend", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "totalBudget", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "campaignPostfix", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "unlimitedDuration", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "adCreative", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isDynamicCreative", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "targetGroup", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "identity", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "utmParameters", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "timeLimited", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "timeLimitation", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "targetLanguages", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "availableLanguages", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "depictions", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "promotedProducts", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "budget", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "hasBeenPublished", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "optimizationEvent", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "isPublic", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "isPaused", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "isDraft", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "isExpired", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "isArchived", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "publishedState", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "publishedState"), _class.prototype), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "pause", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "continue", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "publish", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "unpublish", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "validate", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "archive", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});