define("additive-mc/routes/instance/campaigns/ad/tiktok-lead-ads/index/lead-ad", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsAdTikTokLeadAdsIndexLeadAdRoute = _exports.default = (_class = class InstanceCampaignsAdTikTokLeadAdsIndexLeadAdRoute extends _route.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor, this);
    }
    async model(params) {
      const {
        campaign,
        depictions
      } = this.modelFor('instance.campaigns.ad.tiktok-lead-ads');
      const tiktokLeadAd = await this.store.findRecord('advertisement', params.ad_id, {
        adapterOptions: {
          campaignId: campaign.id,
          type: 'tiktok-lead-ads'
        }
      });
      return {
        tiktokLeadAd,
        depictions
      };
    }
    async setupController(controller, model) {
      var _model$tiktokLeadAd;
      super.setupController(...arguments);
      const {
        campaign,
        meta
      } = this.modelFor('instance.campaigns.ad.tiktok-lead-ads');
      (0, _object.set)(controller, 'campaignId', campaign.id);
      (0, _object.set)(controller, 'meta', meta);

      // Refetch depictions if language of ad does not match with current language
      if (((_model$tiktokLeadAd = model.tiktokLeadAd) === null || _model$tiktokLeadAd === void 0 ? void 0 : _model$tiktokLeadAd.language) !== this.uiAppSettings.currentLocale) {
        this.uiAppSettings.setLocale(model.tiktokLeadAd.language);
        await controller.fetchDepictions.perform();
      }
    }
    renderTemplate() {
      this.render('instance/campaigns/ad/tiktok-lead-ads/index/lead-ad', {
        into: 'instance/campaigns/ad/tiktok-lead-ads',
        outlet: 'detail'
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});