define("additive-mc/mirage/factories/widget", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    name: () => _faker.default.random.word(),
    styles: {
      globals: {
        borderRadius: '2px',
        boxShadowSpreadRadius: '1px',
        boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)',
        zIndex: 9999
      },
      colors: {
        main: {
          sync: true,
          syncedColor: '#3d3d3d',
          syncedContrastColor: '#ffffff'
        },
        accent: {
          sync: true,
          syncedColor: '#707070',
          syncedContrastColor: '#ffffff'
        },
        ambient: {
          sync: true,
          syncedColor: '#ffffff',
          syncedContrastColor: '#707070'
        }
      },
      typography: {
        h2: {
          fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
          fontSize: '32px',
          lineHeight: '40px',
          color: 'main',
          serif: false,
          sync: true,
          syncedTypography: 'h2'
        },
        h3: {
          fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
          fontSize: '24px',
          lineHeight: '28px',
          color: 'main',
          serif: false,
          sync: true,
          syncedTypography: 'h3'
        },
        p: {
          fontFamily: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
          fontSize: '18px',
          lineHeight: '24px',
          color: 'main',
          serif: true,
          sync: true,
          syncedTypography: 'p'
        },
        small: {
          fontFamily: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
          fontSize: '14px',
          lineHeight: '20px',
          color: 'main',
          serif: true,
          sync: true,
          syncedTypography: 'small'
        },
        label: {
          fontFamily: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
          fontSize: '11px',
          lineHeight: '16px',
          color: 'main',
          serif: true,
          sync: true,
          syncedTypography: 'label'
        },
        button: {
          fontFamily: '"Book Antiqua",Palatino,"Palatino Linotype","Palatino LT STD",Georgia,serif',
          fontSize: '14px',
          lineHeight: '20px',
          color: 'accent',
          serif: true,
          sync: true,
          syncedTypography: 'button'
        }
      }
    }
  });
});