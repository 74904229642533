define("additive-mc/mirage/factories/email-campaign-voucher", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    availableLanguages() {
      return ['de', 'it'];
    },
    active: () => true,
    buttonText: () => _faker.default.lorem.sentence(),
    value: () => _faker.default.finance.amount(1000, 9999, 0),
    greetingText() {
      const hasValue = _faker.default.random.boolean();
      return {
        value: hasValue ? _faker.default.lorem.sentence() : null,
        sync: !hasValue,
        syncedValue: _faker.default.lorem.sentence()
      };
    },
    greeting() {
      const hasValue = _faker.default.random.boolean();
      return hasValue ? _faker.default.lorem.sentence() : null;
    },
    policies: () => `<p>${_faker.default.lorem.lines(1)}</p>`,
    afterCreate(voucher, server) {
      const image = server.create('image');
      voucher.update({
        image: image.toJSON(),
        language: _faker.default.random.arrayElement(voucher.availableLanguages)
      });
    }
  });
});