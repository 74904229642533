define("additive-mc/models/on-page-campaign-voucher", ["exports", "@ember-data/model", "@ember/template", "@ember/object", "additive-mc/utils/currency-serialization"], function (_exports, _model, _template, _object, _currencySerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    active: (0, _model.attr)('boolean'),
    availableLanguages: (0, _model.attr)(),
    language: (0, _model.attr)('string'),
    buttonText: (0, _model.attr)('string'),
    policies: (0, _model.attr)('string'),
    greetingText: (0, _model.attr)(),
    value: (0, _model.attr)('number'),
    image: (0, _model.attr)(),
    policiesHtmlSafe: (0, _object.computed)('policies', {
      get() {
        return (0, _template.htmlSafe)(this.policies);
      }
    }),
    amount: (0, _object.computed)('value', {
      get() {
        return (0, _currencySerialization.centsToAmount)(this.value);
      },
      set(key, value) {
        (0, _object.set)(this, 'value', (0, _currencySerialization.amountToCents)(value));
        return value;
      }
    })
  });
});