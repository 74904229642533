define("additive-mc/templates/instance/campaigns/trigger-email/detail/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "G8ctZaUg",
    "block": "[[[41,[51,[30,0,[\"hasNewDetailView\"]]],[[[1,\"  \"],[10,0],[14,0,\"w-100\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@isReadOnly\",\"@campaignId\",\"@campaignType\"],[[30,0,[\"isReadOnly\"]],[30,0,[\"campaignId\"]],\"trigger-email\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[46,[28,[37,3],null,null],null,null,null]],[],false,[\"unless\",\"amc-campaign/email-campaign/email-list\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-mc/templates/instance/campaigns/trigger-email/detail/index.hbs",
    "isStrictMode": false
  });
});