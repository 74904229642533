define("additive-mc/mirage/routes/reports/email", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EmailReportsReservations = _exports.EmailReportsReservationTable = _exports.EmailReportsPastMarketingActions = _exports.EmailReportsMetrics = _exports.EmailReportsKeyIndicators = _exports.EmailReportsConversions = void 0;
  const EmailReportsKeyIndicators = self => {
    self.get('/reports/email/key-indicators', () => {
      return {
        valueAddedSum: {
          type: 'compare',
          change: 32,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        },
        valueAdded: {
          type: 'compare',
          change: 59.35,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        },
        turnover: {
          type: 'compare',
          change: 25,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.EmailReportsKeyIndicators = EmailReportsKeyIndicators;
  const EmailReportsReservations = self => {
    self.get('/reports/email/reservations', () => {
      return {
        reservations: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        newGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        reactivatedGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        returningGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.EmailReportsReservations = EmailReportsReservations;
  const EmailReportsConversions = self => {
    self.get('/reports/email/conversions', () => {
      return {
        conversions: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        enquiries: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        newsletterSubscriptions: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        vouchers: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        bookings: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        other: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.EmailReportsConversions = EmailReportsConversions;
  const EmailReportsMetrics = self => {
    self.get('/reports/email/metrics', schema => {
      return {
        metrics: {
          emails: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          impressions: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019,
              rate: _faker.default.random.number({
                min: 10,
                max: 100
              })
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          uniqueOpenings: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019,
              rate: _faker.default.random.number({
                min: 10,
                max: 100
              })
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          clicks: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019,
              rate: _faker.default.random.number({
                min: 10,
                max: 100
              })
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          uniqueClicks: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019,
              rate: _faker.default.random.number({
                min: 10,
                max: 100
              })
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          turnoverPerContact: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          valueAddedPerContact: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          contactedPercentage: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number({
                min: 0,
                max: 100
              }),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number({
                min: 0,
                max: 100
              }),
              type: 'yearly',
              date: 2018
            }
          }
        },
        campaigns: schema.reportsEmails.all()
      };
    });
  };
  _exports.EmailReportsMetrics = EmailReportsMetrics;
  const EmailReportsPastMarketingActions = self => {
    self.get('/reports/email/past-marketing-actions', schema => {
      return schema.emailPastMarketingActions.all();
    });
  };
  _exports.EmailReportsPastMarketingActions = EmailReportsPastMarketingActions;
  const EmailReportsReservationTable = self => {
    self.get('/reports/email/reservations-table', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      const data = [];
      for (let i = 0; i < 20; i++) {
        data.push([{
          value: _faker.default.name.findName(),
          link: 'https://google.com',
          format: 'custom',
          customKey: 'name'
        }, 'Neue Reservierung', {
          value: _faker.default.date.past(),
          format: 'date'
        }, {
          value: _faker.default.random.number(),
          format: 'currency_cent'
        }, {
          value: {
            start: _faker.default.date.between(start, end),
            end: _faker.default.date.between(start, end)
          },
          format: 'custom',
          customKey: 'range'
        }, '3 Erw. 2 Kinder', _faker.default.name.findName()]);
      }
      return {
        labels: ['Reservierende Person', 'Typ', 'Reservierungsdatum', 'Res.-Umsatz', 'Aufenthalt', 'Reisegruppe', 'Kampagne'],
        data
      };
    });
  };
  _exports.EmailReportsReservationTable = EmailReportsReservationTable;
});