define("additive-mc/mirage/factories/target-group", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    name(i) {
      return `Target Group ${i}`;
    },
    languages: [{
      label: 'Deutsch',
      value: 'de'
    }, {
      label: 'Italienisch',
      value: 'it'
    }],
    locations: [{
      label: 'Qatar',
      subData: [],
      value: '289688'
    }]
  });
});