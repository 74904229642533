define("additive-mc/components/amc-legals", ["exports", "@ember/component", "ember-concurrency", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "additive-mc/validations/settings", "lodash.merge", "rsvp"], function (_exports, _component, _emberConcurrency, _object, _service, _emberChangeset, _emberChangesetValidations, _settings, _lodash, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which represent apps legals page
   *
   * @class amc-legals
   */
  var _default = _exports.default = _component.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    uiPaths: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    tagName: '',
    /**
     * Organizations available languages
     *
     * @property availableLanguages
     * @type {String}
     * @default null
     */
    availableLanguages: null,
    /**
     * the current organization slug
     *
     * @property organizationSlug
     * @type {String}
     * @default null
     */
    organizationSlug: null,
    /**
     * whether the component is read only
     *
     * @property isReadOnly
     * @type {Boolean}
     * @default false
     */
    isReadOnly: false,
    /**
     * Changeset of the resource
     *
     * @property _changeset
     * @type {Changeset}
     * @default null
     * @private
     */
    _changeset: null,
    /**
     * Current selected language
     *
     * @property _currentLanguage
     * @type {String}
     * @private
     */
    _currentLanguage: 'de',
    /**
     * Legals resource
     *
     * @property _resource
     * @type {Object}
     * @default null
     * @private
     */
    _resource: null,
    /**
     * Api url for legals
     *
     * @computed _apiUrl
     * @private
     */
    _apiUrl: (0, _object.computed)('organizationSlug', {
      get() {
        return this.uiPaths.pathsByRouteName('instance', this.organizationSlug).api().join('legals');
      }
    }),
    init() {
      this._super(...arguments);
      (0, _object.setProperties)(this, {
        _resource: {},
        _changeset: {}
      });
    },
    /**
     * Loads legals
     *
     * @type {Task}
     * @function _loadLegals
     * @private
     */
    _loadLegals: (0, _emberConcurrency.task)(function () {
      var _this = this;
      let language = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'de';
      return function* () {
        try {
          let tasks = [];
          tasks.push(_this.authenticatedFetch.fetch(_this._apiUrl, {
            headers: {
              'Accept-Language': language || 'de'
            }
          }));
          tasks.push((0, _emberConcurrency.timeout)(250));
          const [response] = yield (0, _emberConcurrency.all)(tasks);
          if (!response || !response.ok) {
            throw new Error(`[Legals] ${response}`);
          }
          const json = yield response.json();
          _this._handleApiResponse(json);
          (0, _object.set)(_this, '_currentLanguage', language);
        } catch (error) {
          _this.uiToast.showToast({
            title: _this.intl.t('toast.unexpectedError'),
            type: 'error'
          });
        }
      }();
    }).on('init'),
    /**
     * Updates legals with data of given changeset
     *
     * @param {Object} changeset the changeset
     * @type {Task}
     * @function _updateLegalsWithChangeset
     * @private
     */
    _updateLegalsWithChangeset: (0, _emberConcurrency.task)(function* (changeset) {
      try {
        yield changeset.validate();

        // reject if there are errors or no changes have been made
        if (changeset.get('isInvalid') || changeset.get('isPristine')) {
          return (0, _rsvp.reject)();
        }
        let newResource = (0, _lodash.default)({}, this._resource);
        (0, _object.setProperties)(newResource, {
          bookingConditions: changeset.get('bookingConditions'),
          'disclaimerUrl.value': changeset.get('disclaimerUrl'),
          'privacyUrl.value': changeset.get('privacyUrl'),
          requestConditions: changeset.get('requestConditions')
        });
        yield this._updateLegals.perform(newResource);
        /* Save changeset to reset dirty flag, otherwise it will trigger discard changes dialog */
        yield changeset.save();
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }),
    /**
     * Updates legals with data of given resource
     *
     * @type {Task}
     * @function _updateLegals
     * @param {Object} resource The resource which should be saved
     * @private
     */
    _updateLegals: (0, _emberConcurrency.task)(function* (resource) {
      try {
        const body = (0, _lodash.default)({}, resource);
        const request = yield this.authenticatedFetch.fetch(this._apiUrl, {
          headers: {
            'Accept-Language': this._currentLanguage || 'de'
          },
          method: 'PUT',
          body: JSON.stringify(body)
        });
        const [response] = yield (0, _emberConcurrency.all)([request, (0, _emberConcurrency.timeout)(250)]);
        if (!response || !response.ok) {
          throw new Error(`[LEGALS - UPDATE] general ${response}`);
        } else {
          const json = yield response.json();
          this._handleApiResponse(json);
          this.uiToast.showToast({
            title: this.intl.t('toast.success'),
            type: 'success'
          });
        }
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }),
    /**
     * Toggles sync value and update
     *
     * @param {String} name the property name
     * @type {Task}
     * @function _toggleSync
     * @private
     */
    _toggleSync: (0, _emberConcurrency.task)(function* (name) {
      try {
        if (!name) return;
        const synced = this._resource[name].sync;
        /* Toggle value */
        (0, _object.set)(this, `_resource.${name}.sync`, !synced);
        yield this._updateLegals.perform(this._resource);
      } catch (error) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }),
    /**
     * Manage apis response and set internal values
     *
     * @param {Object} response Apis response
     * @function _handleApiResponse
     */
    _handleApiResponse(response) {
      const {
        legals
      } = response;
      const validation = (0, _settings.legalsValidations)(this.intl);
      const changeset = new _emberChangeset.default({
        bookingConditions: legals.bookingConditions,
        disclaimerUrl: legals.disclaimerUrl.value,
        privacyUrl: legals.privacyUrl.value,
        requestConditions: legals.requestConditions
      }, (0, _emberChangesetValidations.default)(validation), validation);
      (0, _object.setProperties)(this, {
        _resource: legals,
        _changeset: changeset
      });
    },
    actions: {
      changeLanguage(lang) {
        this._loadLegals.perform(lang);
      }
    }
  });
});