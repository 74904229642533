define("additive-mc/templates/components/amc-campaign/configuration-item/money", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Sq1O2mjA",
    "block": "[[[8,[39,0],null,[[\"@onSave\",\"@onClose\",\"@item\",\"@childChangeset\",\"@isReadOnly\"],[[28,[37,1],[[30,0,[\"_save\"]]],null],[30,0,[\"onClose\"]],[30,0,[\"item\"]],[30,0,[\"_moneyChangeset\"]],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[1,\"    \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@isDisabled\",\"@type\",\"@errorMessage\"],[[30,0,[\"item\",\"label\"]],[30,0,[\"_moneyChangeset\",\"value\"]],[30,0,[\"_isDisabled\"]],\"number\",[28,[37,3],[[30,0,[\"_moneyChangeset\",\"error\",\"value\"]],\"validation\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"left\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[10,1],[14,0,\"pr1\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[[30,0,[\"uiAppSettings\",\"currency\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"input\"],false,[\"amc-campaign/configuration-item/with-modal\",\"perform\",\"ui-input\",\"get\",\"currency-symbol\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/money.hbs",
    "isStrictMode": false
  });
});