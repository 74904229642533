define("additive-mc/routes/instance/campaigns/ad", ["exports", "@ember/routing/route", "@ember/service", "ember-concurrency", "@additive-apps/utils/utils/plans"], function (_exports, _route, _service, _emberConcurrency, _plans) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    uiAppSettings: (0, _service.inject)(),
    router: (0, _service.inject)(),
    async redirect() {
      await (0, _emberConcurrency.waitForProperty)(this.uiAppSettings, 'planName');
      const currentPlan = this.uiAppSettings && this.uiAppSettings.planName;
      if (currentPlan && ((0, _plans.is)(currentPlan, _plans.PLAN_STARTER) || (0, _plans.is)(currentPlan, _plans.PLAN_ENTERPRISE))) {
        return;
      }
      return this.router.transitionTo('instance.campaigns');
    }
  });
});