define("additive-mc/models/search-ad-campaign", ["exports", "additive-mc/models/campaign", "@ember/object", "@ember/object/computed", "@ember-data/model", "@ember/service", "@additive-apps/ui/utils/model-action"], function (_exports, _campaign, _object, _computed, _model, _service, _modelAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CampaignModel = _exports.default = (_dec = (0, _computed.equal)('state', 'ENABLED'), _dec2 = (0, _computed.equal)('state', 'PAUSED'), _dec3 = (0, _computed.equal)('state', null), _dec4 = (0, _model.attr)('boolean'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)(), _dec7 = (0, _model.attr)(), _dec8 = (0, _model.attr)('boolean'), _dec9 = (0, _model.attr)('boolean'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('number'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('string'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)(), _dec17 = (0, _model.attr)('boolean'), _dec18 = (0, _model.attr)('string'), _dec19 = (0, _model.attr)('string'), _dec20 = (0, _model.attr)(), _dec21 = (0, _model.attr)('boolean'), _dec22 = (0, _object.computed)('state'), _dec23 = (0, _modelAction.default)(), _dec24 = (0, _modelAction.default)(), _dec25 = (0, _modelAction.default)(), _dec26 = (0, _modelAction.default)(), _dec27 = (0, _modelAction.default)('GET'), _class = class CampaignModel extends _campaign.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "isPublic", _descriptor3, this);
      _initializerDefineProperty(this, "isPaused", _descriptor4, this);
      _initializerDefineProperty(this, "isDraft", _descriptor5, this);
      _initializerDefineProperty(this, "hasBeenPublished", _descriptor6, this);
      _initializerDefineProperty(this, "campaignGoal", _descriptor7, this);
      _initializerDefineProperty(this, "adSets", _descriptor8, this);
      _initializerDefineProperty(this, "extensions", _descriptor9, this);
      _initializerDefineProperty(this, "searchPartner", _descriptor10, this);
      _initializerDefineProperty(this, "displayNetwork", _descriptor11, this);
      _initializerDefineProperty(this, "startDate", _descriptor12, this);
      _initializerDefineProperty(this, "endDate", _descriptor13, this);
      _initializerDefineProperty(this, "dailyBudget", _descriptor14, this);
      _initializerDefineProperty(this, "postfix", _descriptor15, this);
      _initializerDefineProperty(this, "targetGroup", _descriptor16, this);
      _initializerDefineProperty(this, "bid", _descriptor17, this);
      _initializerDefineProperty(this, "targetGoals", _descriptor18, this);
      _initializerDefineProperty(this, "useDefaultTargetGoals", _descriptor19, this);
      _initializerDefineProperty(this, "landingPage", _descriptor20, this);
      _initializerDefineProperty(this, "landingPageLanguage", _descriptor21, this);
      _initializerDefineProperty(this, "displayedPath", _descriptor22, this);
      _initializerDefineProperty(this, "unlimitedDuration", _descriptor23, this);
      _initializerDefineProperty(this, "pause", _descriptor24, this);
      _initializerDefineProperty(this, "continue", _descriptor25, this);
      _initializerDefineProperty(this, "publish", _descriptor26, this);
      _initializerDefineProperty(this, "unpublish", _descriptor27, this);
      _initializerDefineProperty(this, "validate", _descriptor28, this);
    }
    get publishedState() {
      if (this.state === 'ENABLED') {
        return 'success';
      } else if (this.state === 'PAUSED') {
        return 'warning';
      }
      return 'error';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isPublic", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isPaused", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isDraft", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasBeenPublished", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "campaignGoal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "adSets", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "extensions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "searchPartner", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "displayNetwork", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "dailyBudget", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "postfix", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "targetGroup", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "bid", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "targetGoals", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "useDefaultTargetGoals", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "landingPage", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "landingPageLanguage", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "displayedPath", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "unlimitedDuration", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "publishedState", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "publishedState"), _class.prototype), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "pause", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "continue", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "publish", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "unpublish", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "validate", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});