define("additive-mc/templates/instance/campaigns/ad/tiktok-lead-ads/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "7DOmmhAg",
    "block": "[[[8,[39,0],null,[[\"@key\",\"@modelName\"],[\"tiktok-lead-ads\",\"advertisement\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"isLoading\"]],[[[1,\"    \"],[10,0],[14,0,\"mv3\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@items\",\"@itemsPerRow\",\"@cardHeight\",\"@gap\"],[10,1,64,4]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,1,[\"isEmpty\"]],[[[1,\"    \"],[10,0],[14,0,\"flex flex-column items-center justify-center text-center pt4 mt4\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"empty-content-grey\"],null]],[1,\"\\n      \"],[10,0],[14,0,\"font-sm secondary\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"adCampaigns.tiktokLeadAds.empty\"],[[\"htmlSafe\"],[true]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],null,[[\"@items\",\"@isReadOnly\",\"@archiveAction\",\"@renameAction\",\"@meta\",\"@type\"],[[30,1,[\"data\"]],[30,0,[\"isReadOnly\"]],[30,0,[\"onArchive\"]],[30,0,[\"openRenameDialog\"]],[30,0,[\"model\",\"meta\"]],\"tiktokLeadAds\"]],null],[1,\"\\n  \"]],[]]]],[]]]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"isRenameAdDialog\"]],[[[1,\"  \"],[8,[39,6],null,[[\"@onClose\",\"@title\",\"@filterName\",\"@nameProperty\",\"@adapterOptions\",\"@model\"],[[28,[37,7],[[28,[37,8],[[30,0,[\"isRenameAdDialog\"]]],null],false],null],[28,[37,4],[\"adCampaigns.advertisements.edit.title.tiktokLeadAd\"],null],\"tiktok-lead-ads\",\"label\",[28,[37,9],null,[[\"campaignId\",\"type\"],[[30,0,[\"model\",\"campaign\",\"id\"]],\"tiktok-lead-ads\"]]],[30,0,[\"activeModel\"]]]],null],[1,\"\\n\"]],[]],null]],[\"filter\"],false,[\"ui-filterable\",\"if\",\"ui-skeleton\",\"svg-jar\",\"t\",\"amc-campaign/ads/list-view\",\"amc-campaign/ads/advertisements/create-dialog\",\"fn\",\"mut\",\"hash\"]]",
    "moduleName": "additive-mc/templates/instance/campaigns/ad/tiktok-lead-ads/index.hbs",
    "isStrictMode": false
  });
});