define("additive-mc/models/depiction", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    productTypes: (0, _model.attr)(),
    text: (0, _model.attr)('string'),
    headline: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    ctaType: (0, _model.attr)('string'),
    images: (0, _model.attr)(),
    postfix: (0, _model.attr)('string'),
    formats: (0, _model.attr)(),
    availableLanguages: (0, _model.attr)(),
    language: (0, _model.attr)(),
    // content-ad specific
    name: (0, _model.attr)('string'),
    variants: (0, _model.attr)(),
    // tiktok-lead-ads specific
    page: (0, _model.attr)(),
    videos: (0, _model.attr)()
  });
});