define("additive-mc/components/amc-campaign/info-item", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['amc-campaign__configuration-item', 'flex', 'items-center', 'h2', 'font-sm', 'bt', 'b--black-10'],
    label: null,
    value: null
  });
});