define("additive-mc/helpers/amc-ember-array-to-array", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Calls toArray on input, to ensure array is iterable.
   *
   * Withou this, ui-table throws an `not iterable` error.
   * TODO: fix table to work with ember-array (exaple ember-data-arrays) as well
   */
  var _default = _exports.default = (0, _helper.helper)(function amcEmberArrayToArray(_ref) {
    let [input] = _ref;
    return Array.isArray(input) && typeof input.toArray === 'function' ? input.toArray() : input;
  });
});