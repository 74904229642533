define("additive-mc/helpers/amc-inner-text", ["exports", "@ember/component/helper", "@ember/template"], function (_exports, _helper, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.amcInnerText = amcInnerText;
  _exports.default = void 0;
  /**
   * Converts given html into text
   */
  function amcInnerText(_ref) {
    let [html] = _ref;
    const divElement = document.createElement('div');
    divElement.innerHTML = (0, _template.htmlSafe)(html);
    return (0, _template.htmlSafe)(divElement.innerText.trim());
  }
  var _default = _exports.default = (0, _helper.helper)(amcInnerText);
});