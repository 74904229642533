define("additive-mc/mirage/routes/preview/index", ["exports", "additive-mc/mirage/routes/preview/overview", "additive-mc/mirage/routes/preview/email-preview"], function (_exports, _overview, _emailPreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "EmailPreviewRequests", {
    enumerable: true,
    get: function get() {
      return _emailPreview.EmailPreviewRequests;
    }
  });
  Object.defineProperty(_exports, "OverviewRequests", {
    enumerable: true,
    get: function get() {
      return _overview.OverviewRequests;
    }
  });
});