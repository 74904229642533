define("additive-mc/mirage/factories/subscriber", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const date = new Date();
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    id() {
      return _faker.default.random.uuid();
    },
    name() {
      return _faker.default.name.findName();
    },
    email() {
      return _faker.default.internet.email();
    },
    deliveryDate() {
      return _faker.default.date.between(new Date(new Date().setDate(new Date().getDate() - 2)), new Date(new Date().setDate(new Date().getDate() + 3)));
    },
    state() {
      return _faker.default.random.arrayElement(['blocked', 'canceled', 'incomplete', 'ready', 'sent']);
    }
  });
});