define("additive-mc/templates/instance/campaigns/on-page/detail/content/create", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "KKKqiATP",
    "block": "[[[8,[39,0],null,[[\"@campaignId\"],[[30,0,[\"campaignId\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"multimediaOverlayContent\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"ed\"],false,[\"amc-campaign/on-page/content-dialog\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-mc/templates/instance/campaigns/on-page/detail/content/create.hbs",
    "isStrictMode": false
  });
});