define("additive-mc/mirage/factories/trigger", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    campaigns() {
      return ['123', '456'];
    },
    deliveryTime() {
      return {
        type: 'delayed',
        offsetInDays: 2,
        dayTime: '05:00'
      };
    },
    startsAt() {
      return _faker.default.date.past();
    },
    endsAt() {
      return _faker.default.date.future();
    }
  });
});