define("additive-mc/routes/instance/campaigns/ad/catalog-ads/index/catalog-ad/edit", ["exports", "@ember/routing/route", "@additive-apps/ui/mixins/ui-full-width-view"], function (_exports, _route, _uiFullWidthView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceCampaignsAdCatalogAdsIndexCatalogAdEditRoute extends _route.default.extend(_uiFullWidthView.default) {
    model() {
      return super.model(...arguments);
    }
    setupController(controller) {
      super.setupController(...arguments);
      const {
        campaign,
        meta
      } = this.modelFor('instance.campaigns.ad.catalog-ads');
      controller.campaignId = campaign.id;
      controller.meta = meta;
      if (!controller.model.catalogAd.budget) {
        controller.model.catalogAd.budget = {};
      }
      if (!controller.model.catalogAd.promotedProducts) {
        controller.model.catalogAd.promotedProducts = [];
      }
      controller.setupChangeset();
    }
    resetController(controller) {
      controller.changeset = null;
    }
    renderTemplate() {
      this.render('instance/campaigns/ad/catalog-ads/index/catalog-ad/edit', {
        into: 'application'
      });
    }
  }
  _exports.default = InstanceCampaignsAdCatalogAdsIndexCatalogAdEditRoute;
});