define("additive-mc/components/amc-campaign/configuration-item/multilingual-text", ["exports", "additive-mc/components/amc-campaign/configuration-item/-multilingual-text", "ember-changeset"], function (_exports, _multilingualText, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which handles campaigns configuration-item for `multilingual-text`.
   *
   * @module amc-campaigns
   */
  class MultilingualTextComponent extends _multilingualText.default {
    createChangeset(resource) {
      return new _emberChangeset.default(resource);
    }
  }
  _exports.default = MultilingualTextComponent;
});