define("additive-mc/models/email", ["exports", "@ember-data/model", "additive-mc/models/ui-transactionable-model"], function (_exports, _model, _uiTransactionableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Email = _exports.default = (_dec = (0, _model.attr)(), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('boolean'), _dec6 = (0, _model.attr)(), _dec7 = (0, _model.attr)(), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.belongsTo)('email-series', {
    async: false
  }), _dec12 = (0, _model.belongsTo)('email-campaign', {
    async: false
  }), _dec13 = (0, _model.attr)(), _dec14 = (0, _model.attr)(), _class = class Email extends _uiTransactionableModel.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "availableLanguages", _descriptor, this);
      _initializerDefineProperty(this, "language", _descriptor2, this);
      _initializerDefineProperty(this, "subject", _descriptor3, this);
      _initializerDefineProperty(this, "body", _descriptor4, this);
      _initializerDefineProperty(this, "active", _descriptor5, this);
      _initializerDefineProperty(this, "image", _descriptor6, this);
      _initializerDefineProperty(this, "cta", _descriptor7, this);
      _initializerDefineProperty(this, "ctaText", _descriptor8, this);
      _initializerDefineProperty(this, "ctaLink", _descriptor9, this);
      _initializerDefineProperty(this, "ctaType", _descriptor10, this);
      _initializerDefineProperty(this, "emailSeries", _descriptor11, this);
      _initializerDefineProperty(this, "campaign", _descriptor12, this);
      _initializerDefineProperty(this, "campaignId", _descriptor13, this);
      _initializerDefineProperty(this, "emailSeriesId", _descriptor14, this);
      _defineProperty(this, "transactionChange", async (property, item, oldItem) => {
        const ctaSettings = ['ctaText', 'ctaType', 'ctaLink'];
        if (!ctaSettings.includes(property)) {
          return await this.uiTransactionManager.changeModelProperty(this, property, item);
        }
        const splittedProperty = property.split('cta');
        const propertKey = splittedProperty[1].toLowerCase();
        let value = item;
        let oldReadableValue;
        let newReadableValue;
        if (property === 'ctaType' || property === 'ctaLink' && this.ctaType === 'referral' || property === 'ctaLink' && this.ctaType === 'survey') {
          value = item !== null && item !== void 0 && item.value ? item.value : null;

          // Readable values (=labels) needed for internal change tracking
          oldReadableValue = (oldItem === null || oldItem === void 0 ? void 0 : oldItem.label) || null;
          newReadableValue = (item === null || item === void 0 ? void 0 : item.label) || null;
        }
        const body = {
          cta: {
            [propertKey]: value
          }
        };
        await this.uiTransactionManager.changeModelProperty(this, property, value, null, null, body, oldReadableValue, newReadableValue);
      });
      _defineProperty(this, "transactionChangeLanguage", async locale => {
        const adapterOptions = {
          emailSeriesId: this.emailSeriesId
        };
        await this.uiTransactionManager.changeLanguage(this, locale, adapterOptions);
      });
      _defineProperty(this, "transactionDeleteLanguage", async locale => {
        const adapterOptions = {
          emailSeriesId: this.emailSeriesId
        };
        await this.uiTransactionManager.deleteLanguage(this, locale, adapterOptions);
      });
      _defineProperty(this, "transactionAddLanguage", async locale => {
        const adapterOptions = {
          emailSeriesId: this.emailSeriesId
        };

        // Reset values
        this.subject = null;
        this.body = null;
        this.ctaType = null;
        this.ctaText = null;
        this.ctaLink = null;
        await this.uiTransactionManager.addLanguage(this, locale, adapterOptions);
      });
    }
    // Define properties which are not translatable into different languages
    get nonTranslatablePropertyKeys() {
      return ['image'];
    }

    // override default transactionUrl from UiTransactionableModel
    get transactionUrl() {
      let url = this.emailSeries.transactionUrl;
      return `${url}/emails/${this.id}`;
    }
    get transactionCreateUrl() {
      let url = this.emailSeries.transactionUrl;
      return `${url}/emails`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "availableLanguages", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "subject", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "body", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "active", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "image", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "cta", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "ctaText", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "ctaLink", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "ctaType", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "emailSeries", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "campaign", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "campaignId", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "emailSeriesId", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});