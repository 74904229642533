define("additive-mc/controllers/instance/campaigns/search-ad", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _controller, _tracking, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsSearchAdController = _exports.default = (_dec = (0, _object.computed)('router.currentRouteName'), _dec2 = (0, _emberConcurrency.task)(function* () {
    try {
      const adapter = this.store.adapterFor('extension');
      const url = adapter.urlForMultiple('extension');
      const endpoint = `${url}/metadata`;
      const response = yield this.authenticatedFetch.fetch(endpoint);
      this.extensionMetaData = yield response.json();
      return this.extensionMetaData;
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _dec3 = (0, _emberConcurrency.task)(function* () {
    try {
      const adapter = this.store.adapterFor('target-group');
      const url = adapter.urlForMultiple('target-group');
      const endpoint = `${url}/metadata`;
      const response = yield this.authenticatedFetch.fetch(endpoint, {}, null, [500]);
      if (!response.ok) {
        if (response.status === 500) {
          const errorJson = yield response.json();
          if (errorJson.code === 'GOOGLE_CONNECTION_ERROR') {
            // ignore error because it also occurs and is handled while fetching the target groups, which is done in parallel
            return;
          }
        }
        throw new Error(response);
      }
      this.targetGroupMetaData = yield response.json();
      return this.targetGroupMetaData;
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _dec4 = (0, _emberConcurrency.task)(function* () {
    try {
      const adapter = this.store.adapterFor('search-ad-template');
      const url = adapter.urlForMultiple('search-ad-template');
      const endpoint = `${url}/metadata`;
      const response = yield this.authenticatedFetch.fetch(endpoint);
      this.searchAdMetaData = yield response.json();
      return this.searchAdMetaData;
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _class = class InstanceCampaignsSearchAdController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor6, this);
      _initializerDefineProperty(this, "googleConnectionState", _descriptor7, this);
      _initializerDefineProperty(this, "uiToast", _descriptor8, this);
      /**
       * whether the create dialog is open
       *
       * @property showCreateDialog
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "showCreateDialog", _descriptor9, this);
      /**
       * The current model
       *
       * @property model
       * @type {Model}
       */
      _initializerDefineProperty(this, "activeModel", _descriptor10, this);
      /**
       * The extension metadata
       *
       * @property extensionMetadata
       * @type {Object}
       */
      _initializerDefineProperty(this, "extensionMetadata", _descriptor11, this);
      /**
       * The search-ad metadata
       *
       * @property searchAdMetaData
       * @type {Object}
       */
      _initializerDefineProperty(this, "searchAdMetaData", _descriptor12, this);
      /**
       * The target-group metadata
       *
       * @property targetGroupMetaData
       * @type {Object}
       */
      _initializerDefineProperty(this, "targetGroupMetaData", _descriptor13, this);
      /**
       * whether the create campaign dialog is open
       *
       * @property showCreateDialog
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isCreateCampaignDialog", _descriptor14, this);
      /**
       * whether the create ad dialog is open
       *
       * @property isCreateAdDialog
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isCreateAdDialog", _descriptor15, this);
      /**
       * whether the create extension dialog is open
       *
       * @property isCreateExtensionDialog
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isCreateExtensionDialog", _descriptor16, this);
      /**
       * whether the create target-group dialog is open
       *
       * @property isCreateTargetGroupDialog
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isCreateTargetGroupDialog", _descriptor17, this);
      _initializerDefineProperty(this, "fetchExtensionMeta", _descriptor18, this);
      _initializerDefineProperty(this, "fetchTargetGroupMeta", _descriptor19, this);
      _initializerDefineProperty(this, "fetchSearchAdMeta", _descriptor20, this);
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get detailRoute() {
      return this.router.currentRouteName.indexOf('instance.campaigns.search-ad.index.search-ad') >= 0 ? this.router.currentRouteName : null;
    }
    get isAddButtonHidden() {
      if (this.isReadOnly) {
        return true;
      }
      if (this.googleConnectionState.isError && (this.router.currentRouteName.startsWith('instance.campaigns.search-ad.index') || this.router.currentRouteName.startsWith('instance.campaigns.search-ad.target-groups'))) {
        return true;
      }
      return false;
    }
    openCreateDialog() {
      if (this.router.currentRouteName.indexOf('instance.campaigns.search-ad.index') >= 0) {
        this.activeModel = this.store.createRecord('search-ad-campaign');
        this.isCreateCampaignDialog = true;
      } else if (this.router.currentRouteName.indexOf('instance.campaigns.search-ad.ad-templates') >= 0) {
        this.activeModel = this.store.createRecord('searchAdTemplate');
        this.isCreateAdDialog = true;
      } else if (this.router.currentRouteName.indexOf('instance.campaigns.search-ad.extensions') >= 0) {
        this.activeModel = this.store.createRecord('extension');
        this.isCreateExtensionDialog = true;
      } else if (this.router.currentRouteName.indexOf('instance.campaigns.search-ad.target-groups') >= 0) {
        this.activeModel = this.store.createRecord('target-group');
        this.isCreateTargetGroupDialog = true;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "googleConnectionState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showCreateDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "activeModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "extensionMetadata", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "searchAdMetaData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "targetGroupMetaData", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isCreateCampaignDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isCreateAdDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "isCreateExtensionDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "isCreateTargetGroupDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "detailRoute", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "detailRoute"), _class.prototype), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "fetchExtensionMeta", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "fetchTargetGroupMeta", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "fetchSearchAdMeta", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openCreateDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openCreateDialog"), _class.prototype), _class);
});