define("additive-mc/mirage/factories/email-series", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    label() {
      return _faker.default.lorem.words();
    },
    mails_count() {
      return 0;
    },
    emails() {
      return [];
    },
    offsetInDays() {
      return _faker.default.random.number(14);
    },
    state() {
      return _faker.default.random.arrayElement(['active', 'inactive']);
    },
    afterCreate(emailSeries, server) {
      var _emailSeries$campaign;
      if ((_emailSeries$campaign = emailSeries.campaign) !== null && _emailSeries$campaign !== void 0 && _emailSeries$campaign.isNewStructure) {
        const emails = server.createList('email', 3, {
          emailSeries
        });
      }
    }
  });
});