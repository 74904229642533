define("additive-mc/components/amc-dashboard/progress-indicators", ["exports", "@ember/component", "@ember/template-factory", "additive-mc/config/environment", "@glimmer/component", "ember-arg-types", "@glimmer/tracking", "ember-concurrency", "prop-types", "@ember/service"], function (_exports, _component, _templateFactory, _environment, _component2, _emberArgTypes, _tracking, _emberConcurrency, _propTypes, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.fetchCampaignsInfo.isRunning}}
    <div class="flex flex-wrap pt2">
      <UiSkeleton
        @items={{5}}
        @itemsPerRow={{3}}
        @itemsPerRowSmartphone={{1}}
        @cardHeight={{130}}
        @gap={{32}}
      />
    </div>
  {{else}}
    <div class="amc-progress-indicators flex flex-wrap pt2">
      {{#each this.campaignsInfo as |item|}}
        <AmcDashboard::ProgressIndicator
          @title={{item.header}}
          @text={{item.text}}
          @activeCount={{item.activeCount}}
          @totalCount={{item.totalCount}}
          @linkTo={{item.link}}
          @campaigns={{item.campaigns}}
        />
      {{/each}}
    </div>
  {{/if}}
  */
  {
    "id": "gjj8KdBA",
    "block": "[[[41,[30,0,[\"fetchCampaignsInfo\",\"isRunning\"]],[[[1,\"  \"],[10,0],[14,0,\"flex flex-wrap pt2\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@items\",\"@itemsPerRow\",\"@itemsPerRowSmartphone\",\"@cardHeight\",\"@gap\"],[5,3,1,130,32]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"amc-progress-indicators flex flex-wrap pt2\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"campaignsInfo\"]]],null]],null],null,[[[1,\"      \"],[8,[39,4],null,[[\"@title\",\"@text\",\"@activeCount\",\"@totalCount\",\"@linkTo\",\"@campaigns\"],[[30,1,[\"header\"]],[30,1,[\"text\"]],[30,1,[\"activeCount\"]],[30,1,[\"totalCount\"]],[30,1,[\"link\"]],[30,1,[\"campaigns\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"item\"],false,[\"if\",\"ui-skeleton\",\"each\",\"-track-array\",\"amc-dashboard/progress-indicator\"]]",
    "moduleName": "additive-mc/components/amc-dashboard/progress-indicators.hbs",
    "isStrictMode": false
  });
  let AmcDashboardProgressIndicatorsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberConcurrency.task)(function* () {
    const tasks = [];
    const baseUrl = _environment.default.APP.apiBaseHost;
    const url = `${baseUrl}/${this.organizationSlug}/dashboard/overview`;
    try {
      tasks.push(this.authenticatedFetch.fetch(url));
      tasks.push((0, _emberConcurrency.timeout)(250));
      const [response] = yield (0, _emberConcurrency.all)(tasks);
      const data = yield response.json();
      if (data) {
        this.campaignsInfo = data;
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _class = class AmcDashboardProgressIndicatorsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "uiToast", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      /**
       * the current organization slug
       *
       * @property organizationSlug
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "organizationSlug", _descriptor4, this);
      /**
       * information about campaigns which is used for progress indicators
       *
       * @property campaignsInfo
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "campaignsInfo", _descriptor5, this);
      /**
       * fetches campaigns information used for the progress indicators
       *
       * @type {Task}
       * @function fetchCampaignsInfo
       * @private
       */
      _initializerDefineProperty(this, "fetchCampaignsInfo", _descriptor6, this);
      this.fetchCampaignsInfo.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "organizationSlug", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "campaignsInfo", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fetchCampaignsInfo", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcDashboardProgressIndicatorsComponent);
});