define("additive-mc/components/amc-campaign/configuration-item/multiselect", ["exports", "@ember/component", "@ember/object", "ember-changeset", "ember-concurrency", "@ember/service"], function (_exports, _component, _object, _emberChangeset, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which handles campaigns configuration-item for `multiselect`.
   *
   * @class amc-campaigns/configuration-item/multiselect
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    tagName: '',
    /**
     * The configuration-item
     *
     * @property item
     * @default null
     */
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * The internal changeset of the item
     *
     * @property _multiselectChangeset
     * @default null
     * @private
     */
    _multiselectChangeset: null,
    /**
     * Changesets underlying object
     *
     * @property _resource
     * @default null
     * @private
     */
    _resource: null,
    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {},
    init() {
      this._super(...arguments);
      let resource = {
        options: []
      };
      if (this.item && this.item.value && Array.isArray(this.item.value)) {
        resource.options = this.item.value.slice(0);
      }
      const changeset = new _emberChangeset.default(resource);
      (0, _object.setProperties)(this, {
        _multiselectChangeset: changeset,
        _resource: resource
      });
    },
    /**
     * Sets options it to parents changeset and trigger save
     *
     * @function _save
     * @type {Task}
     * @param {Changeset} parentChangeset The changeset of the `with-modal` hoc
     * @private
     */
    _save: (0, _emberConcurrency.task)(function* (parentChangeset) {
      const tasks = [];
      yield this._multiselectChangeset.validate();
      if (this._multiselectChangeset.get('isInvalid')) {
        return;
      }

      // Will update the underlying object, which in this case is `this._resource`
      this._multiselectChangeset.execute();
      parentChangeset.set('value', this._resource.options);
      parentChangeset.execute();
      tasks.push(this.onSave());
      tasks.push((0, _emberConcurrency.timeout)(250));
      yield (0, _emberConcurrency.all)(tasks);
    }).drop(),
    actions: {
      onSelectChange(option, checked) {
        // Create clone and re-set whole array, otherwise changeset does not detect "changes"
        let options = (this._multiselectChangeset.get('options') || []).slice(0);
        const checkedValue = option.value;
        if (checked) {
          // Only push if not allready in
          if (!options.findBy('value', checkedValue)) {
            options.pushObject(option);
          }
        } else {
          // Remove unchecked option
          options = options.filter(_option => _option.value === checkedValue ? false : true);
        }
        this._multiselectChangeset.set('options', options);
        this._multiselectChangeset.execute();
      }
    }
  });
});