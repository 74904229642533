define("additive-mc/validations/campaign-configurations", ["exports", "@additive-apps/utils/validators/validate-each", "ember-changeset-validations/validators"], function (_exports, _validateEach, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.utmParameterValidation = _exports.triggerEventValidation = _exports.percentageDistributionValidation = _exports.numberValidation = _exports.moneyValidation = _exports.interval = _exports.deliveryTimeValidation = void 0;
  const interval = () => {
    return {
      value: (0, _validators.validateNumber)({
        positive: true,
        integer: true,
        allowBlank: false
      }),
      period: (0, _validators.validateInclusion)({
        list: ['Y', 'M', 'D']
      })
    };
  };
  _exports.interval = interval;
  const moneyValidation = intl => {
    return {
      value: (0, _validators.validateNumber)({
        positive: true,
        allowBlank: true,
        message: intl.t('errors.positiveNumber')
      })
    };
  };
  _exports.moneyValidation = moneyValidation;
  const numberValidation = intl => {
    return {
      value: (0, _validators.validateFormat)({
        regex: /^(0|[1-9]+[0-9]*)$/,
        allowBlank: true,
        message: intl.t('errors.positiveInteger')
      })
    };
  };
  _exports.numberValidation = numberValidation;
  const triggerEventValidation = intl => {
    return {
      'value.value': (0, _validators.validateFormat)({
        regex: /^(0|[1-9]+[0-9]*)$/,
        allowBlank: false,
        message: intl.t('errors.positiveInteger')
      })
    };
  };
  _exports.triggerEventValidation = triggerEventValidation;
  const utmParameterValidation = intl => {
    return {
      'value.value': function valueValue(key, newValue, oldValue, changes, content) {
        if (content.value.type && content.value.type !== 'none') {
          return (0, _validators.validatePresence)({
            presence: true,
            message: intl.t('errors.required')
          })(...arguments);
        } else {
          return true;
        }
      }
    };
  };
  _exports.utmParameterValidation = utmParameterValidation;
  const deliveryTimeValidation = intl => {
    return {
      'value.deliveryType': (0, _validators.validatePresence)({
        presence: true,
        message: intl.t('errors.required')
      })
    };
  };

  /**
   * input: { value : [{ label: 'ma label', value: 10}, { label: 'ma', value: 50}]}
   *
   * @param {*} intl
   */
  _exports.deliveryTimeValidation = deliveryTimeValidation;
  const percentageDistributionValidation = intl => {
    return {
      value: (0, _validateEach.default)({
        value: (0, _validators.validateNumber)({
          positive: true,
          integer: true,
          allowBlank: true,
          message: intl.t('errors.positiveInteger')
        })
      }),
      /* Dummy property */
      sum: (0, _validators.validateNumber)({
        is: 100,
        message: intl.t('components.amc-campaign-configuration.percentage-distribution.sumError')
      })
    };
  };
  _exports.percentageDistributionValidation = percentageDistributionValidation;
});