define("additive-mc/models/subscriber", ["exports", "@ember-data/model", "@ember/object"], function (_exports, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    deliveryDate: (0, _model.attr)(),
    state: (0, _model.attr)('string'),
    previewId: (0, _model.attr)(),
    stateColor: (0, _object.computed)('state', {
      get() {
        const {
          state
        } = this;
        if (['ready', 'sent'].includes(state)) {
          return 'green';
        }
        if (state === 'incomplete') {
          return 'yellow';
        }
        return 'red';
      }
    }),
    stateBadge: (0, _object.computed)('state', {
      get() {
        const {
          state
        } = this;
        if (['ready', 'sent'].includes(state)) {
          return 'success';
        }
        if (state === 'incomplete') {
          return 'warning';
        }
        return 'error';
      }
    }),
    // TODO: Define when which action is executable
    notCancelable: (0, _object.computed)('state', {
      get() {
        return !['ready', 'incomplete'].includes(this.state);
      }
    }),
    // TODO: Define when which action is executable
    notBlockable: (0, _object.computed)('state', {
      get() {
        return !['ready', 'incomplete', 'canceled'].includes(this.state);
      }
    })
  });
});