define("additive-mc/adapters/trigger-email-campaign-email", ["exports", "additive-mc/config/environment", "additive-mc/adapters/organization-campaign"], function (_exports, _environment, _organizationCampaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _organizationCampaign.default.extend({
    type: 'email',
    urlForMultiple(modelName, snapshot) {
      var _snapshot$adapterOpti, _snapshot$adapterOpti2;
      let url = this.buildURL() + `${this.type === 'email' ? '/organisation-campaigns' : ''}/${this.type}`;
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.campaignId) {
        url += `/${snapshot.adapterOptions.campaignId}`;
      }
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti2 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti2 !== void 0 && _snapshot$adapterOpti2.emailSeriesId) {
        url += `/email-series/${snapshot.adapterOptions.emailSeriesId}`;
      }
      return `${url}/emails`;
    },
    urlForSingle(id, modelName, snapshot) {
      return `${this.urlForMultiple(modelName, snapshot)}/${id}`;
    },
    urlForFindRecord(id, modelName, snapshot) {
      var _snapshot$adapterOpti3, _snapshot$adapterOpti4;
      this.host = snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti3 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti3 !== void 0 && _snapshot$adapterOpti3.useNewApi ? _environment.default.APP.apiBaseHostV2 : _environment.default.APP.apiBaseHost;
      this.type = snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti4 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti4 !== void 0 && _snapshot$adapterOpti4.useNewApi ? 'guest-lifecycle-mail-campaigns' : 'email';
      return this.urlForSingle(...arguments);
    },
    urlForQuery() {
      this.host = _environment.default.APP.apiBaseHostV2;
      return this.urlForMultiple(...arguments);
    },
    handleResponse(status) {
      if (status >= 400 && status < 500) {
        const error = arguments[2];
        if (error) {
          return new Error(JSON.stringify(error));
        } else {
          return new Error(status);
        }
      }
      return this._super(...arguments);
    }
  });
});