define("additive-mc/routes/instance/reports/index", ["exports", "@ember/routing/route", "additive-mc/utils/helpers"], function (_exports, _route, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceReportsRoute extends _route.default {
    setupController(controller) {
      super.setupController();
      controller.abortController = new AbortController();
      if (!controller.start && !controller.end) {
        const defaultFilterDate = (0, _helpers.getDefaultFilterDates)();
        controller.start = defaultFilterDate.start;
        controller.end = defaultFilterDate.end;
      }
    }
  }
  _exports.default = InstanceReportsRoute;
});