define("additive-mc/components/amc-campaign/detail-header", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex mb2" ...attributes>
    {{#let
      (if
        (eq this.state "active") "success" (if (eq this.state "deactivated") "error" "warning")
      ) as |s|
    }}
      <UiStatusBadge @state={{s}}>
        <div class="flex items-center pr1">
          <UiPopover as |pop|>
            <pop.trigger>
              <UiStatus @state={{s}} />
            </pop.trigger>
            <pop.content>
              {{#if this.message}}
                {{this.message}}
              {{else}}
                {{t
                  (concat
                    "global.stateMessage."
                    (if
                      (eq this.state "active")
                      "active"
                      (if (eq this.state "deactivated") "inactive" "incomplete")
                    )
                  )
                }}
              {{/if}}
            </pop.content>
          </UiPopover>
  
          {{t
            (concat
              "global.states."
              (if
                (eq this.state "active")
                "active"
                (if (eq this.state "deactivated") "inactive" "incomplete")
              )
            )
          }}
        </div>
      </UiStatusBadge>
    {{/let}}
  </div>
  <UiIntro @title={{this.title}} @description={{this.description}} @size="xl" @classNames="mb4" />
  */
  {
    "id": "N9lLpzl3",
    "block": "[[[11,0],[24,0,\"flex mb2\"],[17,1],[12],[1,\"\\n\"],[44,[[52,[28,[37,2],[[30,0,[\"state\"]],\"active\"],null],\"success\",[52,[28,[37,2],[[30,0,[\"state\"]],\"deactivated\"],null],\"error\",\"warning\"]]],[[[1,\"    \"],[8,[39,3],null,[[\"@state\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"flex items-center pr1\"],[12],[1,\"\\n        \"],[8,[39,4],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,3,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n            \"],[8,[39,5],null,[[\"@state\"],[[30,2]]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n          \"],[8,[30,3,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"message\"]],[[[1,\"              \"],[1,[30,0,[\"message\"]]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[28,[35,6],[[28,[37,7],[\"global.stateMessage.\",[52,[28,[37,2],[[30,0,[\"state\"]],\"active\"],null],\"active\",[52,[28,[37,2],[[30,0,[\"state\"]],\"deactivated\"],null],\"inactive\",\"incomplete\"]]],null]],null]],[1,\"\\n\"]],[]]],[1,\"          \"]],[]]]]],[1,\"\\n        \"]],[3]]]]],[1,\"\\n\\n        \"],[1,[28,[35,6],[[28,[37,7],[\"global.states.\",[52,[28,[37,2],[[30,0,[\"state\"]],\"active\"],null],\"active\",[52,[28,[37,2],[[30,0,[\"state\"]],\"deactivated\"],null],\"inactive\",\"incomplete\"]]],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[2]]],[13],[1,\"\\n\"],[8,[39,8],null,[[\"@title\",\"@description\",\"@size\",\"@classNames\"],[[30,0,[\"title\"]],[30,0,[\"description\"]],\"xl\",\"mb4\"]],null]],[\"&attrs\",\"s\",\"pop\"],false,[\"let\",\"if\",\"eq\",\"ui-status-badge\",\"ui-popover\",\"ui-status\",\"t\",\"concat\",\"ui-intro\"]]",
    "moduleName": "additive-mc/components/amc-campaign/detail-header.hbs",
    "isStrictMode": false
  });
  let AmcCampaignDetailHeaderComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class AmcCampaignDetailHeaderComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * title
       * @argument title
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor, this);
      /**
       * message
       * @argument message
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "message", _descriptor2, this);
      /**
       * state
       * @argument state
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "state", _descriptor3, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "message", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignDetailHeaderComponent);
});