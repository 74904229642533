define("additive-mc/components/amc-campaign/email-campaign/email-series-table", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "ember-concurrency", "@ember/service"], function (_exports, _component, _object, _computed, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which represents email-series email series
   *
   * @class email-series
   */
  var _default = _exports.default = _component.default.extend({
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    tagName: '',
    /**
     * The current campaign id
     *
     * @property campaignId
     * @default null
     * @private
     */
    campaignId: null,
    /**
     * The campaign type (email/trigger-email)
     * @property campaignType
     * @default null
     */
    campaignType: null,
    /**
     * Whether the table is read-only
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * Property to know if we are still
     * fetching data
     *
     * @computed isLoading
     * @type {Boolean}
     */
    isLoading: (0, _computed.alias)('_fetchEmailSeries.isRunning'),
    /**
     * Internal copy of the current campaign id
     *
     * @property _campaignId
     * @default null
     * @private
     */
    _campaignId: null,
    _emailSeries: null,
    _adapterOptions: (0, _object.computed)('campaignId', {
      get() {
        return {
          adapterOptions: {
            campaignId: this.campaignId
          }
        };
      }
    }),
    _setup() {
      (0, _object.set)(this, '_campaignId', this.campaignId);
      this._fetchEmailSeries.perform();
    },
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'tableHeaderOptions', [{
        name: this.intl.t('emailCampaigns.emailSeries.columns.serialSteps'),
        key: 'label',
        minWidth: '224px',
        sort: 'String'
      }, {
        name: this.intl.t('emailCampaigns.emailSeries.columns.timePast'),
        key: 'offsetInDays',
        minWidth: '184px',
        classNames: 'text-right',
        sort: 'Integer'
      }, {
        name: this.intl.t('emailCampaigns.emailSeries.columns.variants'),
        key: 'mails_count',
        minWidth: '128px',
        classNames: 'text-right',
        sort: 'Integer'
      }, {
        name: this.intl.t('emailCampaigns.emailSeries.columns.state'),
        key: 'state',
        minWidth: '40px',
        sort: 'String'
      }]);
    },
    didUpdateAttrs() {
      this._super(...arguments);
      if (this._campaignId === this.campaignId) {
        return;
      }

      /* Re-setup on resource attribute change */
      this._setup();
    },
    /**
     * fetches the email series for the current campaign
     *
     * @type {Task}
     * @function _fetchEmailSeries
     * @private
     */
    _fetchEmailSeries: (0, _emberConcurrency.task)(function* () {
      try {
        const [emailSeries] = yield (0, _emberConcurrency.all)([this.store.findAll('email-series', Object.assign({}, this._adapterOptions, {
          reload: true
        })), (0, _emberConcurrency.timeout)(250)]);
        (0, _object.set)(this, '_emailSeries', emailSeries);
      } catch (error) {
        this.uiDialog.showError();
      }
    }).on('didInsertElement'),
    actions: {
      afterDelete(item) {
        this._emailSeries.removeObject(item);
      },
      onRowClick(item) {
        this.router.transitionTo(`instance.campaigns.${this.campaignType}.detail.email-series.email-series`, item.id);
      },
      reloadCampaign() {
        if (this.campaignId) {
          this.store.findRecord(`${this.campaignType}-campaign`, this.campaignId);
        }
      }
    }
  });
});