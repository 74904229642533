define("additive-mc/mirage/utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TRIGGER_EMAIL_CAMPAIGN_TYPES = _exports.EMAIL_CAMPAIGN_TYPES = void 0;
  const EMAIL_CAMPAIGN_TYPES = _exports.EMAIL_CAMPAIGN_TYPES = [{
    name: 'TODO: usecase1',
    type: 'convert-ota-to-direct-booker-campaign',
    code: 'usecase1',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Descriptiony',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase2a',
    type: 'reactivate-enquirer-without-booking-campaign',
    code: 'usecase2a',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase2b',
    type: 'reactivate-lost-guest-campaign',
    code: 'usecase2b',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase3a',
    type: 'come-back-campaign',
    code: 'usecase3a',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase3b',
    type: 'come-back-multiple-campaign',
    code: 'usecase3b',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase4',
    type: 'upselling-for-second-stay-campaign',
    code: 'usecase4',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Descriptiony',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase5',
    type: 'early-booker-benefits-campaign',
    code: 'usecase5',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Descriptiony',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase6',
    type: 'follow-up-on-voucher-campaign',
    code: 'usecase6',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Descriptiony',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase7a',
    type: 'birthday-voucher-from-friends-campaign',
    code: 'usecase7a',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase7b',
    type: 'birthday-wishes-campaign',
    code: 'usecase7b',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase7c',
    type: 'birthday-gift-campaign',
    code: 'usecase7c',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase9a',
    type: 'last-minute-deal-campaign',
    code: 'usecase9a',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase9b',
    type: 'deals-campaign',
    code: 'usecase9b',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase11',
    type: 'pre-stay-campaign',
    code: 'usecase11',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'TODO: usecase12',
    type: 'post-stay-campaign',
    code: 'usecase12',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }];
  const TRIGGER_EMAIL_CAMPAIGN_TYPES = _exports.TRIGGER_EMAIL_CAMPAIGN_TYPES = [{
    name: 'Trigger Email Campaign',
    type: 'trigger-campaign',
    code: 'trigger-email',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }, {
    name: 'Lead Email Campaign',
    type: 'lead-campaign',
    code: 'lead-email',
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'TODO: Description',
      details: [{
        title: 'Campaign objective',
        description: 'Encouraging direct bookings from previous online travel agency (OTA) guests.',
        icon: 'target'
      }, {
        title: 'Second detail',
        description: 'Second description',
        icon: 'compass'
      }]
    },
    availablityChecks: []
  }];
});