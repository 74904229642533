define("additive-mc/mirage/factories/organization", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Defaultly a sub-organization of additive
   */
  /* TODO: Add me to an addon */
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    name: () => _faker.default.company.companyName(),
    legalName: () => _faker.default.company.companyName(),
    website: () => _faker.default.internet.domainName(),
    email: () => _faker.default.internet.email(),
    phone: () => _faker.default.phone.phoneNumber(),
    vatId: 'IT02476330218',
    addressStreet: () => _faker.default.address.streetAddress(),
    addressLocality: () => _faker.default.address.city(),
    addressPostalCode: () => _faker.default.address.zipCode(),
    addressCountry: () => _faker.default.address.country(),
    addressRegion: () => _faker.default.address.county(),
    slug: () => _faker.default.lorem.slug(),
    partnerSlug: 'additive-eu',
    partnerName: 'ADDITIVE +',
    isPartner: false
  });
});