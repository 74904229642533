define("additive-mc/components/amc-campaign/ads/advertisements/depiction-detail/nested-multiselect/select-option", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-select-option relative flex items-center cpointer
  
      {{if (or this.isChecked this.isPartiallyChecked) "ui-select-option--checked"}}
  
  
  
      {{this.classNames}}"
    role="button"
    {{on "click" (fn this.onClick this.item)}}
  >
    <UiCheckbox
      @bubbles={{true}}
      @classNames="mr1"
      @isPartiallyChecked={{this.isPartiallyChecked}}
      @theme={{if (or this.isChecked this.isPartiallyChecked) "white" "blue"}}
      @value={{this.isChecked}}
    />
  
    {{yield (hash item=this.item)}}
  </div>
  */
  {
    "id": "Mym/ueZr",
    "block": "[[[11,0],[16,0,[29,[\"ui-select-option relative flex items-center cpointer\\n\\n    \",[52,[28,[37,1],[[30,0,[\"isChecked\"]],[30,0,[\"isPartiallyChecked\"]]],null],\"ui-select-option--checked\"],\"\\n\\n\\n\\n    \",[30,0,[\"classNames\"]]]]],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"onClick\"]],[30,0,[\"item\"]]],null]],null],[12],[1,\"\\n  \"],[8,[39,4],null,[[\"@bubbles\",\"@classNames\",\"@isPartiallyChecked\",\"@theme\",\"@value\"],[true,\"mr1\",[30,0,[\"isPartiallyChecked\"]],[52,[28,[37,1],[[30,0,[\"isChecked\"]],[30,0,[\"isPartiallyChecked\"]]],null],\"white\",\"blue\"],[30,0,[\"isChecked\"]]]],null],[1,\"\\n\\n  \"],[18,1,[[28,[37,6],null,[[\"item\"],[[30,0,[\"item\"]]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"if\",\"or\",\"on\",\"fn\",\"ui-checkbox\",\"yield\",\"hash\"]]",
    "moduleName": "additive-mc/components/amc-campaign/ads/advertisements/depiction-detail/nested-multiselect/select-option.hbs",
    "isStrictMode": false
  });
  let AmcCampaignAdsAdvertisementsDepictionDetailNestedMultiselectSelectOptionComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.object), _dec5 = (0, _emberArgTypes.arg)(_propTypes.object), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignAdsAdvertisementsDepictionDetailNestedMultiselectSelectOptionComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the class names
       *
       * @argument classNames
       * @type {String}
       * @default false
       */
      _initializerDefineProperty(this, "classNames", _descriptor, this);
      /**
       * whether the option is checked
       *
       * @argument isChecked
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isChecked", _descriptor2, this);
      /**
       * whether the option is partially checked
       *
       * @argument isPartiallyChecked
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isPartiallyChecked", _descriptor3, this);
      /**
       * the item data
       *
       * @argument item
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "item", _descriptor4, this);
      /**
       * the item data
       *
       * @argument item
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "value", _descriptor5, this);
      _initializerDefineProperty(this, "onClick", _descriptor6, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "classNames", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isChecked", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isPartiallyChecked", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "item", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignAdsAdvertisementsDepictionDetailNestedMultiselectSelectOptionComponent);
});