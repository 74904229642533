define("additive-mc/mirage/factories/referral-landingpage", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    availableLanguages() {
      const randomNumber = _faker.default.random.number(10);
      if (randomNumber > 5) {
        return ['de', 'it', 'en'];
      }
      return ['de'];
    },
    active: () => _faker.default.random.boolean(),
    columnLeft: () => _faker.default.lorem.sentence(),
    columnRight: () => _faker.default.lorem.sentence(),
    afterCreate(landingpage, server) {
      landingpage.update({
        language: _faker.default.random.arrayElement(landingpage.availableLanguages)
      });
    }
  });
});