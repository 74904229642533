define("additive-mc/controllers/instance/campaigns/ad/tiktok-lead-ads/index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking"], function (_exports, _controller, _object, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsAdTikTokLeadAdsIndexController = _exports.default = (_class = class InstanceCampaignsAdTikTokLeadAdsIndexController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor4, this);
      /**
       * the model that is currently renamed
       *
       * @property activeModel
       * @type {Model}
       * @default null
       */
      _initializerDefineProperty(this, "activeModel", _descriptor5, this);
      /**
       * whether the rename dialog is open
       *
       * @property isRenameAdDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isRenameAdDialog", _descriptor6, this);
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    onArchive(item) {
      if (!item.isPaused && item.isPublic) {
        return;
      }
      this.uiDialog.showConfirm(this.intl.t('adCampaigns.tiktokLeadAds.archive.title'), this.intl.t('adCampaigns.tiktokLeadAds.archive.description'), () => this.archive(item), this.intl.t('global.actions.archive'), false, true);
    }
    async archive(item) {
      try {
        const advertisement = await item.archive({
          campaignId: this.model.campaign.id,
          type: 'tiktok-lead-ads'
        });
        this.store.pushPayload('advertisement', advertisement);
      } catch (e) {
        this.uiDialog.showError(null, e.message);
      }
    }
    openRenameDialog(model) {
      if (!model.isPaused && model.isPublic) {
        return;
      }
      this.activeModel = model;
      this.isRenameAdDialog = true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "activeModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isRenameAdDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onArchive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onArchive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "archive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "archive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openRenameDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openRenameDialog"), _class.prototype), _class);
});