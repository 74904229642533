define("additive-mc/components/amc-campaign/email-campaign/email-list", ["exports", "@ember/component", "@ember/object", "ember-concurrency", "@ember/service"], function (_exports, _component, _object, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which represents email-campagnes email list
   *
   * @class email-list
   */
  var _default = _exports.default = _component.default.extend({
    store: (0, _service.inject)(),
    router: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    tagName: '',
    /**
     * The campaign type (email/trigger-email)
     * @property campaignType
     * @default null
     */
    campaignType: null,
    campaignId: null,
    isReadOnly: false,
    /**
     * Internal copy of the current campaign id
     *
     * @property _campaignId
     * @default null
     * @private
     */
    _campaignId: null,
    /**
     * whether the modal to send a test email is open
     *
     * @property _isTestEmailDialogOpen
     * @type {Boolean}
     * @default false
     * @private
     */
    _isTestEmailDialogOpen: false,
    /**
     * whether the modal of the email preview is open
     *
     * @property _showEmailPreview
     * @type {Boolean}
     * @default false
     * @private
     */
    _showEmailPreview: false,
    /**
     * The active campaign item for the dialogs
     * @property activeItem
     * @type {Object}
     * @default null
     */
    activeItem: null,
    _emails: null,
    _emailSeries: null,
    _adapterOptions: (0, _object.computed)('campaignId', {
      get() {
        return {
          adapterOptions: {
            campaignId: this.campaignId
          }
        };
      }
    }),
    _setup() {
      (0, _object.set)(this, '_campaignId', this.campaignId);
      this._fetchEmails.perform();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      if (this._campaignId === this.campaignId) {
        return;
      }

      /* Re-setup on resource attribute change */
      this._setup();
    },
    didInsertElement() {
      this._super(...arguments);
      (0, _object.set)(this, '_emails', []);
      (0, _object.set)(this, '_emailSeries', []);
      this.router.on('routeWillChange', transition => {
        if (this.isDestroying) {
          return;
        }

        /**
         * When we come from a email-detail route chances are the opened entity got deleted.
         * So re-peek all models and set to internal value.
         */
        if (transition.from.name === `instance.campaigns.${this.campaignType}.detail.index.email.index`) {
          const emailSeries = this.store.peekAll('email-series', this._adapterOptions);
          (0, _object.set)(this, '_emailSeries', emailSeries);
          let emails = this.store.peekAll('email', this._adapterOptions);
          emails = this._appendEmailSeriesNames(emails);
          (0, _object.set)(this, '_emails', this._filterDataByCampaign(emails));
        }
      });
    },
    async _fetchEmailSeries() {
      try {
        const emailSeries = await this.store.findAll('email-series', Object.assign({}, this._adapterOptions, {
          reload: true
        }));
        (0, _object.set)(this, '_emailSeries', emailSeries);
      } catch (error) {
        throw new Error(error);
      }
    },
    _appendEmailSeriesNames(emails) {
      return emails.map(email => {
        const seriesOfEmail = this._emailSeries.find(series => {
          return email.emailSeriesId && series.id === email.emailSeriesId.toString();
        });
        seriesOfEmail && email.set('emailSeriesName', seriesOfEmail.label);
        return email;
      });
    },
    /**
     * fetches the emails for the current campaign
     *
     * @type {Task}
     * @function _fetchEmails
     * @private
     */
    _fetchEmails: (0, _emberConcurrency.task)(function* () {
      try {
        let [emails] = yield (0, _emberConcurrency.all)([this.store.findAll('email', Object.assign({}, this._adapterOptions, {
          reload: true
        })), (0, _emberConcurrency.timeout)(250)]);
        yield this._fetchEmailSeries();
        emails = this._appendEmailSeriesNames(emails);
        /* campaignId will be string, but emails' campaignId's are integeres, so convert to filter properly */
        (0, _object.set)(this, '_emails', this._filterDataByCampaign(emails));
      } catch (error) {
        this.uiDialog.showError();
      }
    }).on('didInsertElement'),
    _filterDataByCampaign(data) {
      return (data || []).filterBy('campaignId', parseInt(this.campaignId));
    },
    actions: {
      afterDelete(item) {
        this._emails.removeObject(item);
      },
      reloadCampaign() {
        if (this.campaignId) {
          this.store.findRecord(`${this.campaignType}-campaign`, this.campaignId);
        }
      },
      openTestEmailDialog(item) {
        (0, _object.set)(this, '_isTestEmailDialogOpen', true);
        (0, _object.set)(this, 'activeItem', item);
      },
      openEmailPreview(item) {
        (0, _object.set)(this, '_showEmailPreview', true);
        (0, _object.set)(this, 'activeItem', item);
      }
    }
  });
});