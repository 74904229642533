define("additive-mc/mirage/factories/email-campaign-landingpage", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    availableLanguages() {
      return ['de', 'it'];
    },
    columnLeft: () => _faker.default.lorem.sentence(),
    columnRight: () => _faker.default.lorem.sentence(),
    metaTitle: () => _faker.default.lorem.sentence(),
    ctaText: () => _faker.default.lorem.sentence(),
    bookingConditions: () => _faker.default.lorem.sentence(),
    enquiryConditions: () => _faker.default.lorem.sentence(),
    afterCreate(landingPage, server) {
      landingPage.update({
        language: _faker.default.random.arrayElement(landingPage.availableLanguages)
      });
    }
  });
});