define("additive-mc/transforms/amc-configurations", ["exports", "@ember-data/serializer/transform", "@ember/object"], function (_exports, _transform, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ALLOWED_STATES = ['error', 'warning'];

  /**
   * Transformer for campaign configuration-items
   */
  class AmcConfigurationsTransform extends _transform.default {
    deserialize(serialized) {
      return serialized === null || serialized === void 0 ? void 0 : serialized.map(configuration => {
        if (configuration.state && ALLOWED_STATES.indexOf(configuration.state) === -1) {
          const copy = Object.assign({}, configuration);
          delete copy.state;
          return copy;
        }
        return configuration;
      });
    }
    serialize(deserialized) {
      const content = Array.isArray(deserialized) ? deserialized : [];
      if (content.length === 0) {
        return deserialized;
      }
      content.forEach(item => {
        /* cast value of 'number' items to integers */
        if (item.type === 'number') {
          (0, _object.set)(item, 'value', parseInt(item.value));
        }
      });
      return content;
    }
  }
  _exports.default = AmcConfigurationsTransform;
});