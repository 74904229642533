define("additive-mc/serializers/email-campaign-contribution", ["exports", "additive-mc/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    modelNameFromPayloadKey(payloadKey) {
      return payloadKey === 'contribution' ? 'email-campaign-contribution' : this._super(...arguments);
    }
  });
});