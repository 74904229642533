define("additive-mc/adapters/ad-campaign", ["exports", "additive-mc/adapters/organization-campaign"], function (_exports, _organizationCampaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _organizationCampaign.default.extend({
    type: 'ad'
  });
});