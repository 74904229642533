define("additive-mc/components/ui-skeleton", ["exports", "@additive-apps/ui/components/ui-skeleton"], function (_exports, _uiSkeleton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiSkeleton.default;
    }
  });
});