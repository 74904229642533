define("additive-mc/components/amc-campaign/ads/configurations-navdrawer", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "prop-types", "ember-arg-types"], function (_exports, _component, _templateFactory, _component2, _service, _propTypes, _emberArgTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiNavigationDrawer
    @navDrawerStateKey={{this.stateKey}}
    @isRight={{true}}
    @isPermanent={{false}} as |navDrawer|
  >
    <navDrawer.navbar
      @title={{t "routes.settings.title"}}
      @isMenuHidden={{true}}
      @onClose={{this.onClose}}
      @classNames="ui-navigation-bar--full-screen"
    />
  
    <navDrawer.content>
      <div class="flex flex-column pt3">
        <div class="font-sm secondary">
          {{this.model.title}}
        </div>
        <div class="font-lg primary">
          {{t "socialCampaigns.socialCampaign.configurations"}}
        </div>
      </div>
  
      <div class="ac-settings-navdrawer flex flex-column pb2 pt4 pb3-sm">
        <AmcCampaign::Ads::Configuration @model={{this.model}} />
      </div>
    </navDrawer.content>
  </UiNavigationDrawer>
  */
  {
    "id": "6cLIpMBq",
    "block": "[[[8,[39,0],null,[[\"@navDrawerStateKey\",\"@isRight\",\"@isPermanent\"],[[30,0,[\"stateKey\"]],true,false]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,[[\"@title\",\"@isMenuHidden\",\"@onClose\",\"@classNames\"],[[28,[37,1],[\"routes.settings.title\"],null],true,[30,0,[\"onClose\"]],\"ui-navigation-bar--full-screen\"]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex flex-column pt3\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"font-sm secondary\"],[12],[1,\"\\n        \"],[1,[30,0,[\"model\",\"title\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"font-lg primary\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"socialCampaigns.socialCampaign.configurations\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"ac-settings-navdrawer flex flex-column pb2 pt4 pb3-sm\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"navDrawer\"],false,[\"ui-navigation-drawer\",\"t\",\"amc-campaign/ads/configuration\"]]",
    "moduleName": "additive-mc/components/amc-campaign/ads/configurations-navdrawer.hbs",
    "isStrictMode": false
  });
  /**
   *  Component used to for a content types settings
   *
   * @class AmcCampaignAdsConfigurationsNavdrawer
   */
  let AmcCampaignAdsConfigurationsNavdrawerComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignAdsConfigurationsNavdrawerComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _initializerDefineProperty(this, "router", _descriptor5, this);
      _initializerDefineProperty(this, "stateKey", _descriptor6, this);
      _initializerDefineProperty(this, "model", _descriptor7, this);
      _initializerDefineProperty(this, "onClose", _descriptor8, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "stateKey", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignAdsConfigurationsNavdrawerComponent);
});