define("additive-mc/templates/instance/campaigns/on-page/detail/voucher", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Z47USQEQ",
    "block": "[[[8,[39,0],null,[[\"@campaignId\",\"@resource\",\"@voucherModelType\",\"@openMultimedia\",\"@closeMultimedia\",\"@isReadOnly\"],[[30,0,[\"_campaignId\"]],[30,0,[\"model\"]],\"on-page-campaign-voucher\",[28,[37,1],[[30,0],\"openMultimedia\"],null],[28,[37,1],[[30,0],\"closeMultimedia\"],null],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"multimediaOverlayContent\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"vd\"],false,[\"amc-campaign/voucher-dialog\",\"action\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-mc/templates/instance/campaigns/on-page/detail/voucher.hbs",
    "isStrictMode": false
  });
});