define("additive-mc/mirage/factories/referral-campaign", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const allowedValues = [{
    label: 'option1',
    value: 'option1-value'
  }, {
    label: 'option2',
    value: 'option2-value'
  }, {
    label: 'option3',
    value: 'option3-value'
  }];
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    group() {
      return 'referral';
    },
    type: () => _faker.default.random.arrayElement(['ReferralLeadCampaign', 'ReferralGiveAwayCampaign']),
    code: () => _faker.default.random.arrayElement(['referral-lead', 'referral-give-away']),
    title() {
      return 'Referral Campaign';
    },
    active() {
      return _faker.default.random.boolean();
    },
    configurations: [{
      id: 13590,
      type: 'datetime',
      key: 'start-date',
      label: 'Kampagnen Start Datum',
      description: null,
      order: 1,
      value: '2022-11-16T00:00:00+01:00'
    }, {
      id: 13590,
      type: 'datetime',
      key: 'end-date',
      label: 'Kampagnen End Datum',
      description: null,
      order: 2,
      value: '2023-11-16T00:00:00+01:00'
    }, {
      id: 13589,
      type: 'parametrized-string',
      key: 'utm-campaign-postfix',
      label: 'Kampagnen-Postfix (utm_campaign)',
      description: null,
      order: 3,
      value: 'kampagne_02',
      allowedValues: null
    }, {
      id: 13522,
      type: 'number',
      key: 'voucher-pool',
      label: 'Erstellbare Gutscheine',
      description: 'Maximale Anzahl an Gutscheinen, die über einen Referral Link erstellt werden können.',
      order: 4,
      value: _faker.default.random.number({
        min: 1,
        max: 10
      }),
      allowedValues: null
    }],
    afterCreate(campaign, server) {
      let voucher = null;
      if (campaign.type === 'ReferralGiveAwayCampaign') {
        voucher = server.create('referral-campaign-voucher');
        campaign.update({
          voucher: voucher.toJSON()
        });
      } else {
        // Remove voucher-pool if campaign is no referral give-away campaign
        campaign.configurations.pop();
        campaign.update({
          configurations: campaign.configurations
        });
      }
      const friendLandingPage = server.create('referral-landingpage', {
        type: 'friend-landingpage',
        columnRight: null
      });
      campaign.update({
        friendLandingPage: friendLandingPage.toJSON()
      });
      const guestLandingPage = server.create('referral-landingpage', {
        type: 'guest-landingpage'
      });
      campaign.update({
        guestLandingPage: guestLandingPage.toJSON()
      });
    }
  });
});