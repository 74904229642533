define("additive-mc/components/amc-campaign/configuration-item/-multilingual-text", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@glimmer/tracking", "@ember/service", "ember-concurrency"], function (_exports, _component, _object, _computed, _tracking, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Base component impl for multilingual-text and multilingual-link
   *
   * @module amc-campaigns
   */
  let BaseMultilingualTextComponent = _exports.default = (_dec = (0, _object.computed)(), _dec2 = (0, _computed.readOnly)('uiAppSettings.languages.contentLanguages'), _dec3 = (0, _emberConcurrency.task)(function* () {
    const tasks = [];
    const snapshot = Object.assign({}, this.item.value);
    try {
      // Will update the underlying object, which in this case is `this._resource`
      this._changeset.execute();
      (0, _object.set)(this, 'item.value', this._resource);
      tasks.push(this.onSave());
      tasks.push((0, _emberConcurrency.timeout)(250));
      yield (0, _emberConcurrency.all)(tasks);
      if (this.closeOnSave) {
        yield this.onClose();
      }
    } catch (error) {
      // reset
      (0, _object.set)(this, 'item.value', snapshot);
    }
  }), _class = class BaseMultilingualTextComponent extends _component.default {
    /**
     * The internal changeset of the item
     *
     * @computed _changeset
     * @private
     */
    get _changeset() {
      return this._createChangeset();
    }

    /**
     * Changesets underlying object
     *
     * @property _resource
     * @default null
     * @private
     */

    /**
     * Whether is disabled
     *
     * @computed _isDisabled
     * @default false
     * @private
     */
    get _isDisabled() {
      return this.isReadOnly || this._save.isRunning;
    }

    /**
     * @computed read only array of content languages
     * @returns {Array}
     */

    /**
     * All activated languages for the current organization mapped as select options
     *
     * @computed _organizationLanguages
     * @type {Array}
     * @private
     */
    get _organizationLanguages() {
      const {
        contentLanguages
      } = this;
      if (!contentLanguages) {
        return null;
      }
      return contentLanguages.map(language => {
        return {
          name: this.intl.t(`global.languages.${language}`),
          value: language
        };
      });
    }

    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {}

    /**
     * Close event
     *
     * @function onClose
     * @return {Promise} Returns a promise
     * @public
     */
    onClose() {}

    /**
     * Override to create a Changeset
     *
     * @overload
     * @protected
     * @returns {Changeset}
     */
    createChangeset(/*resource*/
    ) {
      throw new Error("Override and don't call super");
    }

    /**
     * Sets `_resource` based on `item` and the Changeset
     *
     * @returns {Changeset}
     */
    _createChangeset() {
      let resource = this.item && this.item.value || {};
      this._resource = resource;
      return this.createChangeset(resource);
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor2, this);
      _initializerDefineProperty(this, "uiToast", _descriptor3, this);
      /**
       * The configuration-item
       *
       * @property item
       * @default null
       */
      _initializerDefineProperty(this, "item", _descriptor4, this);
      /**
       * Whether its read only
       *
       * @property isReadOnly
       * @default false
       */
      _defineProperty(this, "isReadOnly", false);
      /**
       * Whether onClose should be executed after save or not
       *
       * @property closeOnSave
       * @type {Boolean}
       * @default true
       */
      _defineProperty(this, "closeOnSave", true);
      _initializerDefineProperty(this, "_resource", _descriptor5, this);
      /**
       * The currently selected language
       *
       * @property _selectedLanguage
       * @default undefined
       * @private
       */
      _initializerDefineProperty(this, "_selectedLanguage", _descriptor6, this);
      _initializerDefineProperty(this, "contentLanguages", _descriptor7, this);
      /**
       * Sets options it to parents changeset and trigger save
       *
       * @function _save
       * @type {Task}
       * @private
       */
      _initializerDefineProperty(this, "_save", _descriptor8, this);
      this._selectedLanguage = this.uiAppSettings.languages.defaultLanguage || 'de';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "item", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_changeset", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_changeset"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_resource", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_selectedLanguage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "contentLanguages", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_save", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});