define("additive-mc/adapters/on-page-content", ["exports", "additive-mc/adapters/organization-campaign"], function (_exports, _organizationCampaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _organizationCampaign.default.extend({
    type: 'on-page',
    urlForMultiple() {
      return `${this._super(...arguments)}/contents`;
    },
    handleResponse(status) {
      if (status >= 400 && status < 500) {
        const error = arguments[2];
        if (error) {
          return new Error(JSON.stringify(error));
        } else {
          return new Error(status);
        }
      }
      return this._super(...arguments);
    }
  });
});