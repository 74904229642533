define("additive-mc/components/amc-campaign/voucher-preview-dialog", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiModal @modalClassNames={{this._modalClassNames}} @size="lg">
    <div class="mm-media-gallery__header flex flex-row justify-between absolute right-0 left-0 white">
      <div class="w-100 flex flex-row items-center">
        <MmMediaGallery::HeaderLeft @class="flex-grow-1" @onClose={{this.onClose}} />
        <MmMediaGallery::HeaderRight />
      </div>
    </div>
  
    {{#if this.preview}}
      <div class="voucher-preview relative w-100 h-100 flex items-center justify-center">
        <AmcCampaign::VoucherPreviewIframe @url={{this.preview}} />
      </div>
    {{/if}}
  
    {{yield}}
  </UiModal>
  */
  {
    "id": "DvxCV0nY",
    "block": "[[[8,[39,0],null,[[\"@modalClassNames\",\"@size\"],[[30,0,[\"_modalClassNames\"]],\"lg\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"mm-media-gallery__header flex flex-row justify-between absolute right-0 left-0 white\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"w-100 flex flex-row items-center\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@class\",\"@onClose\"],[\"flex-grow-1\",[30,0,[\"onClose\"]]]],null],[1,\"\\n      \"],[8,[39,2],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"preview\"]],[[[1,\"    \"],[10,0],[14,0,\"voucher-preview relative w-100 h-100 flex items-center justify-center\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@url\"],[[30,0,[\"preview\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"ui-modal\",\"mm-media-gallery/header-left\",\"mm-media-gallery/header-right\",\"if\",\"amc-campaign/voucher-preview-iframe\",\"yield\"]]",
    "moduleName": "additive-mc/components/amc-campaign/voucher-preview-dialog.hbs",
    "isStrictMode": false
  });
  let AmcCampaignVoucherPreviewDialogComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmcCampaignVoucherPreviewDialogComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * The preview url
       *
       * @argument previews
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "preview", _descriptor, this);
      /**
       * Boolean indicating if the dialog should fit the content or use default size
       *
       * @property fitContent
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "fitContent", _descriptor2, this);
      _initializerDefineProperty(this, "onClose", _descriptor3, this);
    }
    get _modalClassNames() {
      const className = 'voucher-preview-dialog';
      if (this.fitContent) {
        return `h-auto ${className} ${className}__fit-content`;
      }
      return className;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "preview", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fitContent", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignVoucherPreviewDialogComponent);
});