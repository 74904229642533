define("additive-mc/components/amc-campaign/configuration-item/boolean", ["exports", "@ember/component", "@ember/object", "ember-concurrency", "@ember/service"], function (_exports, _component, _object, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class amc-campaigns/configuration-item/interval
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    intl: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    tagName: '',
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    onSave() {},
    actions: {
      async save(val) {
        const snapshot = Object.assign({}, this.item);
        try {
          (0, _object.set)(this, 'item.value', val);
          await (0, _emberConcurrency.all)([this.onSave(), (0, _emberConcurrency.timeout)(400)]);
          this.uiToast.showToast({
            title: this.intl.t('toast.success'),
            type: 'success'
          });
        } catch (error) {
          // reset
          (0, _object.set)(this, 'item', snapshot);
        }
      }
    }
  });
});