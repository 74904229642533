define("additive-mc/serializers/trigger-email-campaign", ["exports", "additive-mc/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class EmailCampaign extends _application.default.extend(_rest.EmbeddedRecordsMixin) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "attrs", {
        emailSeries: {
          embedded: 'always'
        },
        contribution: {
          embedded: 'always'
        },
        voucher: {
          embedded: 'always'
        },
        trigger: {
          embedded: 'always'
        }
      });
    }
    normalizeResponse(store, primaryModelClass, payload) {
      // handle new root key
      if (payload.campaigns) {
        payload.triggerEmailCampaigns = payload.campaigns;
        delete payload.campaigns;
      }
      if (payload.campaign) {
        payload.triggerEmailCampaign = payload.campaign;
        delete payload.campaign;
      }
      const json = super.normalizeResponse(...arguments);
      const isArray = Array.isArray(json.included);
      const includedData = isArray ? json.included : [json.included];
      let data = [];
      includedData.forEach(included => {
        var _included$attributes;
        if (included.type !== 'email') {
          data.push(included);
          return;
        }
        if ((_included$attributes = included.attributes) !== null && _included$attributes !== void 0 && _included$attributes.cta) {
          var _included$attributes2, _included$attributes4, _included$attributes6;
          if ((_included$attributes2 = included.attributes) !== null && _included$attributes2 !== void 0 && (_included$attributes2 = _included$attributes2.cta) !== null && _included$attributes2 !== void 0 && _included$attributes2.type) {
            var _included$attributes3;
            included.attributes.ctaType = (_included$attributes3 = included.attributes) === null || _included$attributes3 === void 0 || (_included$attributes3 = _included$attributes3.cta) === null || _included$attributes3 === void 0 ? void 0 : _included$attributes3.type;
          }
          if ((_included$attributes4 = included.attributes) !== null && _included$attributes4 !== void 0 && (_included$attributes4 = _included$attributes4.cta) !== null && _included$attributes4 !== void 0 && _included$attributes4.text) {
            var _included$attributes5;
            included.attributes.ctaText = (_included$attributes5 = included.attributes) === null || _included$attributes5 === void 0 || (_included$attributes5 = _included$attributes5.cta) === null || _included$attributes5 === void 0 ? void 0 : _included$attributes5.text;
          }
          if ((_included$attributes6 = included.attributes) !== null && _included$attributes6 !== void 0 && (_included$attributes6 = _included$attributes6.cta) !== null && _included$attributes6 !== void 0 && _included$attributes6.link) {
            var _included$attributes7;
            included.attributes.ctaLink = (_included$attributes7 = included.attributes) === null || _included$attributes7 === void 0 || (_included$attributes7 = _included$attributes7.cta) === null || _included$attributes7 === void 0 ? void 0 : _included$attributes7.link;
          }
          delete included.attributes.cta;
        } else {
          included.attributes.ctaType = null;
          included.attributes.ctaText = null;
          included.attributes.ctaLink = null;
          delete included.attributes.cta;
        }
        data.push(included);
      });
      json.included = isArray ? data : data[0];
      return json;
    }
  }
  _exports.default = EmailCampaign;
});