define("additive-mc/templates/instance/campaigns/referral/detail/landingpage", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "r5oYl2tD",
    "block": "[[[8,[39,0],null,[[\"@campaignId\",\"@resource\",\"@landingpageModelType\",\"@isReadOnly\"],[[30,0,[\"_campaignId\"]],[30,0,[\"model\"]],\"referral-landingpage\",[30,0,[\"isReadOnly\"]]]],null]],[],false,[\"amc-campaign/landingpage-dialog\"]]",
    "moduleName": "additive-mc/templates/instance/campaigns/referral/detail/landingpage.hbs",
    "isStrictMode": false
  });
});