define("additive-mc/templates/components/amc-campaign/configuration-item/select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "dSMN87hS",
    "block": "[[[8,[39,0],null,[[\"@onSave\",\"@onClose\",\"@item\",\"@isReadOnly\"],[[28,[37,1],[[30,0,[\"_save\"]]],null],[30,0,[\"onClose\"]],[30,0,[\"item\"]],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@classNames\",\"@label\",\"@initialValue\",\"@selected\",\"@options\",\"@onChange\",\"@isReadOnly\"],[\"w-100\",[30,0,[\"item\",\"label\"]],[30,1,[\"changeset\",\"value\"]],[30,1,[\"changeset\",\"value\",\"label\"]],[30,1,[\"changeset\",\"allowedValues\"]],[28,[37,3],[[30,0],[28,[37,4],[[30,1,[\"changeset\",\"value\"]]],null]],null],[28,[37,5],[[30,1,[\"isLoading\"]],[30,0,[\"isReadOnly\"]]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"item\"]],null,[[\"@value\"],[[30,3]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"label\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2,3]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\",\"optionComponent\",\"option\"],false,[\"amc-campaign/configuration-item/with-modal\",\"perform\",\"ui-select\",\"action\",\"mut\",\"amc-or\"]]",
    "moduleName": "additive-mc/templates/components/amc-campaign/configuration-item/select.hbs",
    "isStrictMode": false
  });
});