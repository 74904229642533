define("additive-mc/utils/facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fbApiRequest = void 0;
  /**
   *  Wrapper function for calling a facebook api endpoint
   *
   * @function fbApiRequest
   * @param {String} path
   * @param {String} [method='GET']
   * @returns {Promise<Array>}
   */
  const fbApiRequest = _exports.fbApiRequest = function fbApiRequest(path, userToken) {
    let stack = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let method = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'GET';
    const params = {
      access_token: userToken,
      limit: 5000
    };
    return new Promise((resolve, reject) => {
      window.FB.api(path, method, params, response => {
        if (response !== null && response !== void 0 && response.error) {
          reject(response.error);
        }
        if (response !== null && response !== void 0 && response.data.length) {
          stack = stack.concat(response === null || response === void 0 ? void 0 : response.data);
        }
        return resolve(stack);
      });
    });
  };
});