define("additive-mc/mirage/routes/dashboard/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DashboardOverview = void 0;
  const DashboardOverview = self => {
    self.get('/dashboard/filters', () => {
      return [{
        name: 'Letzte 12 Monate',
        value: 'last_12_months',
        start: '2023-09-01',
        end: '2024-08-27'
      }, {
        name: 'Letzte 30 Tage',
        value: 'last_30_days',
        start: '2024-07-29',
        end: '2024-08-27'
      }, {
        name: '2024',
        value: '2024',
        start: '2024-01-01',
        end: '2024-08-27'
      }, {
        name: '2023',
        value: '2023',
        start: '2023-01-01',
        end: '2023-12-31'
      }, {
        name: '2022',
        value: '2022',
        start: '2022-01-01',
        end: '2022-12-31'
      }, {
        name: 'Laufzeit',
        value: 'runtime',
        start: null,
        end: '2024-08-27'
      }];
    });
    self.get('/dashboard/overview', () => {
      return [{
        header: 'Gäste-Lifecycle E-Mails',
        link: 'https://additive-mc-mocked.firebaseapp.com/testhotel-post-com/campaigns/email',
        totalCount: 4,
        activeCount: 1,
        text: '1 von 4 Kampagnen aktiv',
        campaigns: [{
          type: 'usecase1',
          name: 'OTA zu Direktbucher umwandeln',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/email/1198',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'usecase2a',
          name: 'Reaktivierung Anfrager ohne Buchung',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/email/1199',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'usecase2b',
          name: 'Reaktivierung verlorene Gäste',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/email/1200',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'usecase4',
          name: 'Upselling Gäste/Stammgäste für Zweitaufenthalt',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/email/1202',
          state: {
            code: 'incomplete',
            message: 'Nicht alle aktiven Serien Schritte haben aktivierte E-Mails'
          }
        }]
      }, {
        header: 'Anzeigen',
        link: 'https://www.additive-apps.eu/apps/marketing-cloud.html#form-overlay',
        totalCount: 0,
        activeCount: 0,
        text: 'Upgrade notwendig um Anzeigen verwenden zu können.',
        campaigns: []
      }, {
        header: 'Soziale Netzwerke',
        link: 'https://additive-mc-mocked.firebaseapp.com/testhotel-post-com/campaigns/social',
        totalCount: 3,
        activeCount: 0,
        text: 'Noch keine Kampagne eingerichtet',
        campaigns: []
      }, {
        header: 'E-Mail Automation',
        link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email',
        totalCount: 2,
        activeCount: 2,
        text: '2 von 2 Kampagnen aktiv.',
        campaigns: [{
          type: 'trigger-campaign',
          name: 'test2',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1350',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'trigger-campaign',
          name: 'Trigger Kampagne',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1323',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'lead-email',
          name: 'Neue Lead Kampagne',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1347',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'trigger-campaign',
          name: 'test',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1344',
          state: {
            code: 'active',
            message: null
          }
        }, {
          type: 'lead-email',
          name: 'Lead E-Mail Kampagne',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1230',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'lead-email',
          name: 'test',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1326',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'lead-email',
          name: 'Lead Campaign',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1349',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'trigger-campaign',
          name: 'Hello World',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1328',
          state: {
            code: 'active',
            message: null
          }
        }, {
          type: 'trigger-campaign',
          name: 'test',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1346',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'trigger-campaign',
          name: 'Qifsha ropt',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1327',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'trigger-campaign',
          name: 'Hello',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1324',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'trigger-campaign',
          name: 'Neue Trigger Kampagne',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1348',
          state: {
            code: 'incomplete',
            message: 'Nicht alle Serien Schritte aktiver E-Mails sind aktiviert'
          }
        }, {
          type: 'trigger-campaign',
          name: 'Test',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1325',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'lead-email',
          name: 'Simons State Test',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/trigger-email/1352',
          state: {
            code: 'active',
            message: null
          }
        }]
      }, {
        header: 'On-Page',
        link: 'https://additive-mc-mocked.firebaseapp.com/testhotel-post-com/campaigns/on-page',
        totalCount: 2,
        activeCount: 2,
        text: '2 von 2 Kampagnen-Typen aktiv',
        campaigns: [{
          type: 'on-page-lead',
          name: 'asdasd',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/on-page/1245',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'on-page-incentive',
          name: 'gutscheini',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/on-page/1247',
          state: {
            code: 'active',
            message: null
          }
        }, {
          type: 'on-page-lead',
          name: 'On-Page Lead Campaign',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/on-page/1242',
          state: {
            code: 'active',
            message: null
          }
        }, {
          type: 'on-page-lead',
          name: 'test',
          link: 'https://staging.marketing-cloud.additive-apps.eu/belvedere-hotel-it/campaigns/on-page/1319',
          state: {
            code: 'deactivated',
            message: null
          }
        }]
      }, {
        header: 'Weiterempfehlung',
        link: 'https://additive-mc-mocked.firebaseapp.com/testhotel-post-com/campaigns/referral',
        totalCount: 3,
        activeCount: 1,
        text: '1 von 3 Kampagnen-Typen aktiv',
        campaigns: [{
          type: 'referral-lead',
          name: 'testtest',
          link: 'https://staging.marketing-cloud.additive-apps.eu/testhotel-post-com/campaigns/referral/1247',
          state: {
            code: 'deactivated',
            message: null
          }
        }, {
          type: 'referral-lead',
          name: 'test1',
          link: 'https://staging.marketing-cloud.additive-apps.eu/testhotel-post-com/campaigns/referral/1247',
          state: {
            code: 'active',
            message: null
          }
        }, {
          type: 'referral-lead',
          name: 'test2',
          link: 'https://staging.marketing-cloud.additive-apps.eu/testhotel-post-com/campaigns/referral/1247',
          state: {
            code: 'deactivated',
            message: null
          }
        }]
      }];
    });
  };
  _exports.DashboardOverview = DashboardOverview;
});