define("additive-mc/mirage/models/email-series", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Model.extend({
    campaign: (0, _emberCliMirage.belongsTo)('email-campaign'),
    emails: (0, _emberCliMirage.hasMany)('email')
  });
});