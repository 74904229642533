define("additive-mc/templates/instance/preview/email", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qiQ6wNpE",
    "block": "[[[8,[39,0],null,[[\"@key\",\"@modelName\"],[\"subscribers\",\"subscriber\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@title\",\"@filter\",\"@isPlainView\"],[[30,0,[\"model\",\"name\"]],\"subscribers\",true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"navbar\"]],null,[[\"@breadcrumbs\",\"@hasBreadcrumbs\"],[[28,[37,2],null,[[\"currentRouteName\",\"rootName\",\"currentRouteNameText\"],[\"email\",\"preview\",[30,0,[\"model\",\"name\"]]]]],true]],null],[1,\"\\n    \"],[8,[30,2,[\"contentHeader\"]],null,[[\"@classNames\"],[\"w-100 ma-auto ph2 ph3-sm ph4-md mw8\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@classNames\",\"@currentRouteName\",\"@currentRouteNameText\",\"@rootName\"],[\"font-xl\",\"email\",[30,0,[\"model\",\"name\"]],\"preview\"]],null],[1,\"\\n      \"],[10,0],[14,0,\"font-md pv1 mb4\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"preview.upcomingEmails.description\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,2,[\"content\"]],null,[[\"@class\"],[\"pv4 ph1\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],null,[[\"@model\",\"@filter\",\"@isReadOnly\"],[[30,0,[\"model\"]],[30,1],[30,0,[\"isReadOnly\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]]],[\"filter\",\"view\"],false,[\"ui-filterable\",\"ui-content-views\",\"hash\",\"ui-breadcrumbs\",\"t\",\"amc-preview/upcoming-emails-subscriber\"]]",
    "moduleName": "additive-mc/templates/instance/preview/email.hbs",
    "isStrictMode": false
  });
});