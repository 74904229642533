define("additive-mc/components/amc-campaign/configuration-item/datetime", ["exports", "@ember/component", "@ember/object", "rsvp", "ember-concurrency", "date-fns"], function (_exports, _component, _object, _rsvp, _emberConcurrency, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Component which handles campaigns configuration-item for `datetime`.
   *
   * @class amc-campaigns/configuration-item/datetime
   * @module amc-campaigns
   */
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    model: null,
    /**
     * The configuration-item
     *
     * @property item
     * @default null
     */
    item: null,
    /**
     * Whether its read only
     *
     * @property isReadOnly
     * @default false
     */
    isReadOnly: false,
    /**
     * Whether the content is valid
     *
     * @property _contentIsValid
     * @private
     */
    _contentIsValid: false,
    /**
     * The initial value
     *
     * @property _initialValue
     * @private
     */
    _initialValue: null,
    minDate: null,
    maxDate: null,
    init() {
      this._super(...arguments);

      //TODO: Use validation to check the date
      if (this.item.key === 'start-date') {
        this.set('minDate', new Date());
        const endDate = this.model.configurations.findBy('key', 'end-date').value;
        this.set('maxDate', endDate);
      } else if (this.item.key === 'end-date') {
        const startDate = this.model.configurations.findBy('key', 'start-date').value;
        this.set('minDate', (0, _dateFns.addDays)(new Date(startDate || new Date()), 1));
      }
      this._initialValue = this.item && this.item.value;
    },
    /**
     * Save event
     *
     * @function onSave
     * @return {Promise} Returns a promise
     * @public
     */
    onSave() {},
    /**
     * Close event
     *
     * @function onClose
     * @return {Promise} Returns a promise
     * @public
     */
    onClose() {},
    /**
     * Save wrapper to reset item.value on error
     */
    _save: (0, _emberConcurrency.task)(function* () {
      const tasks = [];
      try {
        tasks.push(this.onSave());
        tasks.push((0, _emberConcurrency.timeout)(400));
        yield (0, _emberConcurrency.all)(tasks);
      } catch (error) {
        // reset
        (0, _object.set)(this, 'item.value', this._initialValue);
      }
    }),
    actions: {
      beforeSave() {
        return (0, _rsvp.resolve)(this._contentIsValid);
      }
    }
  });
});