define("additive-mc/components/amc-campaign/configuration-item/number", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@glimmer/tracking", "additive-mc/validations/campaign-configurations", "ember-concurrency"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _tracking, _campaignConfigurations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <AmcCampaign::ConfigurationItem::WithModal
    @onSave={{perform this._save}}
    @onClose={{this.onClose}}
    @item={{this.item}}
    @validation={{this._validation}}
    @isReadOnly={{this.isReadOnly}} as |modal|
  >
    <modal.content>
      <UiInput
        @label={{this.item.label}}
        @value={{modal.changeset.value}}
        @isDisabled={{amc-or modal.isLoading this.isReadOnly}}
        @errorMessage={{get modal.changeset.error.value "validation"}}
      />
    </modal.content>
  </AmcCampaign::ConfigurationItem::WithModal>
  */
  {
    "id": "Cvc/7nQv",
    "block": "[[[8,[39,0],null,[[\"@onSave\",\"@onClose\",\"@item\",\"@validation\",\"@isReadOnly\"],[[28,[37,1],[[30,0,[\"_save\"]]],null],[30,0,[\"onClose\"]],[30,0,[\"item\"]],[30,0,[\"_validation\"]],[30,0,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@label\",\"@value\",\"@isDisabled\",\"@errorMessage\"],[[30,0,[\"item\",\"label\"]],[30,1,[\"changeset\",\"value\"]],[28,[37,3],[[30,1,[\"isLoading\"]],[30,0,[\"isReadOnly\"]]],null],[28,[37,4],[[30,1,[\"changeset\",\"error\",\"value\"]],\"validation\"],null]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"modal\"],false,[\"amc-campaign/configuration-item/with-modal\",\"perform\",\"ui-input\",\"amc-or\",\"get\"]]",
    "moduleName": "additive-mc/components/amc-campaign/configuration-item/number.hbs",
    "isStrictMode": false
  });
  /**
   * Component which handles campaigns configuration-item for `number`.
   *
   * `number` is a positive integer
   *
   * @class amc-campaigns/configuration-item/number
   * @module amc-campaigns
   */
  let AmcCampaignConfigurationItemComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.func), _dec4 = (0, _emberArgTypes.arg)(_propTypes.func), _dec5 = (0, _emberConcurrency.task)(function* () {
    const tasks = [];
    try {
      tasks.push(this.onSave());
      tasks.push((0, _emberConcurrency.timeout)(400));
      yield (0, _emberConcurrency.all)(tasks);
    } catch (error) {
      // reset
      this.item.value = this._initialValue;
    }
  }), _class = class AmcCampaignConfigurationItemComponent extends _component2.default {
    constructor() {
      var _this$item;
      super(...arguments);
      /**
       * The configuration-item
       *
       * @argument item
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "item", _descriptor, this);
      /**
       * Whether its read only
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor2, this);
      /**
       * The initial value
       *
       * @property _initialValue
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_initialValue", _descriptor3, this);
      /**
       * The validation
       *
       * @property _validation
       * @type {any}
       * @default null
       */
      _initializerDefineProperty(this, "_validation", _descriptor4, this);
      /**
       * Save event
       *
       * @function onSave
       * @return {Promise} Returns a promise
       * @public
       */
      _initializerDefineProperty(this, "onSave", _descriptor5, this);
      /**
       * On Close Event
       *
       * @argument onClose
       * @type {Function}
       */
      _initializerDefineProperty(this, "onClose", _descriptor6, this);
      /**
       * Save wrapper to reset item.value on error
       */
      _initializerDefineProperty(this, "_save", _descriptor7, this);
      this._initialValue = (_this$item = this.item) === null || _this$item === void 0 ? void 0 : _this$item.value;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "item", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_initialValue", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_validation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return _campaignConfigurations.numberValidation;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onSave", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_save", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignConfigurationItemComponent);
});