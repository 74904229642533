define("additive-mc/mirage/serializers/legal", ["exports", "additive-mc/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    keyForModel(modelName) {
      return 'legals';
    },
    serialize(object, request) {
      // super call
      let json = _application.default.prototype.serialize.apply(this, arguments);

      // Some multilingual-fake behavior
      const languageBehavior = (entity, newLanguage) => {
        if (!newLanguage) {
          return entity;
        }

        // Update language property depending on `Accept-Langauge` header
        if (entity.language !== newLanguage) {
          entity.language = newLanguage;
        }

        // Add language in front of multilingual-property. to make language change visible.
        entity.privacyUrl.value = `${entity.privacyUrl.value}-${newLanguage}.com`;
        entity.privacyUrl.syncedValue = `${entity.privacyUrl.syncedValue}-${newLanguage}.com`;
        entity.disclaimerUrl.value = `${entity.disclaimerUrl.value}-${newLanguage}.com`;
        entity.disclaimerUrl.syncedValue = `${entity.disclaimerUrl.syncedValue}-${newLanguage}.com`;
        entity.requestConditions = `${newLanguage}-${entity.requestConditions}`;
        entity.bookingConditions = `${newLanguage}-${entity.bookingConditions}`;
        return entity;
      };
      json.legals = languageBehavior(json.legals, request.requestHeaders['accept-language']);
      return json;
    }
  });
});