define("additive-mc/components/amc-delete-model", ["exports", "@ember/component", "@ember/service", "ember-concurrency", "@ember/object"], function (_exports, _component, _service, _emberConcurrency, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * HOC which deletes a given model.
   */
  var _default = _exports.default = _component.default.extend({
    store: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiFilter: (0, _service.inject)(),
    tagName: '',
    confirm: true,
    resourceId: null,
    modelName: null,
    modelOptions: null,
    hasFilter: true,
    /**
     * Send delete
     *
     * @type {Task}
     * @function _send
     * @private
     */
    _send: (0, _emberConcurrency.task)(function* () {
      try {
        const model = this.store.peekRecord(this.modelName, this.resourceId);
        if (!model) {
          throw new Error();
        }
        yield model.destroyRecord(this.modelOptions);
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
        this.doAfterDelete();
      } catch (error) {
        try {
          const errorDetail = JSON.parse(error.message);
          this.uiToast.showToast({
            title: errorDetail.message,
            type: 'error'
          });
        } catch (e) {
          this.uiToast.showToast({
            title: this.intl.t('toast.unexpectedError'),
            type: 'error'
          });
        }
      }
    }),
    _delete() {
      if (!this.confirm) {
        this._send.perform();
        return;
      }
      this.uiDialog.showConfirm(this.intl.t('components.amc-delete-model.confirm.title'), this.intl.t('components.amc-delete-model.confirm.description', {
        htmlSafe: true
      }), () => this._send.perform(), this.intl.t('global.actions.delete'), true, true);
    },
    didInsertElement() {
      this._super(...arguments);
      (0, _object.set)(this, 'modelOptions', this.modelOptions || {});
    },
    doAfterDelete() {
      if (this.hasFilter) {
        const filterName = this.modelName.split('-campaign')[0].replace(/([-][a-z])/g, group => group.toUpperCase().replace('-', ''));
        this.uiFilter.reset(filterName);
      }
      this.afterDelete();
    },
    afterDelete() {}
  });
});