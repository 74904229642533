define("additive-mc/components/amc-url-preview", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.url}}
    <div class="amc-url-preview mw-100 flex flex-column items-start flex-row-sm font-sm">
      <div
        class="url-wrapper dif mw-100 font-sm secondary pv1 pr2 pl1 bg-secondary--10 br1
  
  
          {{if this.hasClipboard "cpointer"}}"
        role="button"
        {{on "click" this.copyUrl}}
      >
        {{#if this.hasClipboard}}
          {{svg-jar "clipboard" class="clipboard-icon mr1"}}
        {{/if}}
  
        <div class="ellipsis">
          {{#if this.showFullUrl}}
            {{this.url}}
          {{else if this.showPlaceholder}}
            {{t "global.actions.copyShareableURL"}}
          {{else}}
            {{this._prettifiedUrl}}
          {{/if}}
        </div>
      </div>
      {{#unless this.hideLink}}
        <a
          class="link-wrapper dib secondary bg-secondary--10 br1 mt1 ml1-sm mt0-sm"
          href="{{this.url}}"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span class="flex items-center justify-center">
            {{svg-jar "external-link" width="20" height="20"}}
          </span>
        </a>
      {{/unless}}
    </div>
  {{/if}}
  */
  {
    "id": "T6twiv+h",
    "block": "[[[41,[30,0,[\"url\"]],[[[1,\"  \"],[10,0],[14,0,\"amc-url-preview mw-100 flex flex-column items-start flex-row-sm font-sm\"],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[\"url-wrapper dif mw-100 font-sm secondary pv1 pr2 pl1 bg-secondary--10 br1\\n\\n\\n        \",[52,[30,0,[\"hasClipboard\"]],\"cpointer\"]]]],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[30,0,[\"copyUrl\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"hasClipboard\"]],[[[1,\"        \"],[1,[28,[35,2],[\"clipboard\"],[[\"class\"],[\"clipboard-icon mr1\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"ellipsis\"],[12],[1,\"\\n\"],[41,[30,0,[\"showFullUrl\"]],[[[1,\"          \"],[1,[30,0,[\"url\"]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"showPlaceholder\"]],[[[1,\"          \"],[1,[28,[35,3],[\"global.actions.copyShareableURL\"],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,0,[\"_prettifiedUrl\"]]],[1,\"\\n        \"]],[]]]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"hideLink\"]]],[[[1,\"      \"],[10,3],[14,0,\"link-wrapper dib secondary bg-secondary--10 br1 mt1 ml1-sm mt0-sm\"],[15,6,[29,[[30,0,[\"url\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"flex items-center justify-center\"],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"external-link\"],[[\"width\",\"height\"],[\"20\",\"20\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"on\",\"svg-jar\",\"t\",\"unless\"]]",
    "moduleName": "additive-mc/components/amc-url-preview.hbs",
    "isStrictMode": false
  });
  let AmcUrlPreviewComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.bool), _dec2 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec3 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec4 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class AmcUrlPreviewComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiToast", _descriptor2, this);
      _initializerDefineProperty(this, "hasClipboard", _descriptor3, this);
      _initializerDefineProperty(this, "showFullUrl", _descriptor4, this);
      _initializerDefineProperty(this, "showPlaceholder", _descriptor5, this);
      _initializerDefineProperty(this, "hideLink", _descriptor6, this);
      _initializerDefineProperty(this, "url", _descriptor7, this);
    }
    get _prettifiedUrl() {
      var _this$url;
      let url = (_this$url = this.url) === null || _this$url === void 0 ? void 0 : _this$url.replace(/^https?:\/\//, '');
      url = url.slice(0, url.indexOf('?'));
      return url;
    }
    copyUrl() {
      var _navigator;
      if (!this.hasClipboard) {
        return;
      }
      (_navigator = navigator) === null || _navigator === void 0 || (_navigator = _navigator.clipboard) === null || _navigator === void 0 || _navigator.writeText(this.url);
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('toast.copyUrlSuccess')
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasClipboard", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showFullUrl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showPlaceholder", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hideLink", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "url", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "copyUrl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copyUrl"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcUrlPreviewComponent);
});