define("additive-mc/mirage/factories/setting", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    promoteFreeRooms: _faker.default.random.boolean(),
    useCustomEmailTemplate: _faker.default.random.boolean(),
    sendAt: _faker.default.random.arrayElement(['04:00', '12:30', '12:00']),
    excludedIpAddresses: '8.8.8.8,8.8.4.4',
    statisticsRecipients: [_faker.default.internet.email(), _faker.default.internet.email()].join(','),
    landingPage() {
      return {
        allowedValues: [{
          label: 'Landingpage 1',
          value: '1'
        }, {
          label: 'Landingpage 2',
          value: '2'
        }],
        value: '1'
      };
    },
    landingPageUrl: _faker.default.internet.url(),
    demoMode: _faker.default.random.boolean(),
    voucherValidityPeriod() {
      return {
        value: 'end_of_next_year',
        allowedValues: [{
          label: 'Ende nächstes Jahr',
          value: 'end_of_next_year'
        }, {
          label: '1 Jahr',
          value: 'one_year'
        }, {
          label: '6 Monate',
          value: 'six_months'
        }, {
          label: '3 Monate',
          value: 'three_months'
        }, {
          label: '1 Monat',
          value: 'one_month'
        }]
      };
    },
    email() {
      return {
        sync: _faker.default.random.boolean(),
        syncedValue: _faker.default.internet.email(),
        value: _faker.default.internet.email()
      };
    },
    languages() {
      return {
        de: _faker.default.random.boolean(),
        it: _faker.default.random.boolean(),
        en: _faker.default.random.boolean(),
        nl: _faker.default.random.boolean(),
        fr: _faker.default.random.boolean()
      };
    },
    standardEmailHeader() {
      return {
        url: 'https://multimedia.additive-apps.tech/media/439f7eb2521f8cad8ce87f90afd9cbbe',
        name: 'Bild Gutschein - Feuerstein 2500px.jpg',
        type: 'image',
        mediumId: 'c26551c9-cdb1-45c7-9103-a5b1d15a89a8',
        provider: 'imbo',
        remoteId: '439f7eb2521f8cad8ce87f90afd9cbbe'
      };
    },
    trackingCode() {
      return `<script>
        (function (i, s, o, g, r, a, m) {
            i['AdditiveTRObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://tr.additive-apps.cloud/main.js', 'ddtv');
        ddtv('init', 'xxxxx', 'xxxxxx');
        ddtv('send', 'pageview');
      </script>`;
    },
    socialNetworks() {
      const allowedValues = [{
        label: 'option1',
        value: 'option1-value'
      }, {
        label: 'option2',
        value: 'option2-value'
      }, {
        label: 'option3',
        value: 'option3-value'
      }];
      return {
        adAccount: {
          value: _faker.default.random.arrayElement(allowedValues)
        },
        businessManager: {
          value: _faker.default.random.arrayElement(allowedValues)
        },
        facebookPage: {
          value: _faker.default.random.arrayElement(allowedValues)
        },
        facebookPixel: {
          value: _faker.default.random.boolean() ? _faker.default.random.arrayElement(allowedValues) : null
        },
        instagram: {
          value: _faker.default.random.boolean() ? _faker.default.random.arrayElement(allowedValues) : null
        },
        tiktok: {
          value: _faker.default.random.arrayElement(allowedValues)
        }
      };
    },
    facebookOAuth() {
      const isConnectedUser = _faker.default.random.boolean();
      return {
        scope: ['ads_read', 'business_management', 'catalog_management', 'ads_management'],
        appId: '2802084849896799',
        hasUserToken: isConnectedUser,
        userId: isConnectedUser ? '1122112211221122' : null,
        userName: isConnectedUser ? 'Facebook User' : null
      };
    },
    tiktokOAuth() {
      return {
        appId: '6953978208546979842',
        hasUserToken: _faker.default.random.boolean()
      };
    },
    afterCreate(setting, server) {
      let nameValues = {};
      let voucherGreetingText = {};
      Object.keys(setting.languages).forEach(langcode => {
        nameValues[langcode] = _faker.default.company.companyName();
        voucherGreetingText[langcode] = _faker.default.lorem.sentence();
      });
      setting.update({
        name: {
          sync: _faker.default.random.boolean(),
          syncedValue: _faker.default.company.companyName(),
          value: nameValues
        },
        voucherGreetingText
      });
    }
  });
});