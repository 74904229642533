define("additive-mc/templates/components/amc-dashboard/campaign-placeholder", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qfFf1k65",
    "block": "[[[41,[30,0,[\"icon\"]],[[[1,\"  \"],[10,0],[14,0,\"pv4 dn db-md\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,0,[\"icon\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[10,0],[14,0,\"pv4 white\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex font-xl pb2 items-center\"],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n    \"],[8,[39,2],null,[[\"@state\",\"@size\",\"@class\"],[\"passive\",\"xs\",\"ml2\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"isUpgradable\"]],[[[1,\"        \"],[1,[28,[35,3],[\"global.warnings.upgradeNeeded\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,3],[\"dashboard.campaigns.notSetUp\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"font-md pb2\"],[12],[1,\"\\n    \"],[1,[30,0,[\"description\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"pt2 flex\"],[12],[1,\"\\n\"],[41,[30,0,[\"isUpgradable\"]],[[[1,\"      \"],[8,[39,4],null,[[\"@label\",\"@class\",\"@href\",\"@target\"],[[28,[37,3],[\"dashboard.campaigns.upgrade\"],null],\"bg-white blue flex\",\"https://www.additive-apps.eu/apps/marketing-cloud.html#form-overlay\",\"_blank\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],null,[[\"@label\",\"@class\",\"@isSecondary\",\"@onClick\"],[[28,[37,3],[\"dashboard.campaigns.setUp\"],null],\"bg-white blue\",true,[28,[37,5],[[30,0],\"transitionToCampaignList\"],null]]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"svg-jar\",\"ui-badge\",\"t\",\"ui-button\",\"action\"]]",
    "moduleName": "additive-mc/templates/components/amc-dashboard/campaign-placeholder.hbs",
    "isStrictMode": false
  });
});