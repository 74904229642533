define("additive-mc/components/ui-menu", ["exports", "@additive-apps/ui/components/ui-menu"], function (_exports, _uiMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiMenu.default;
    }
  });
});