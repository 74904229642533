define("additive-mc/components/amc-reports/conversions", ["exports", "additive-mc/config/environment", "@additive-apps/ui/components/ui-property-watcher", "@ember/object", "ember-concurrency", "@ember/service", "@additive-apps/ui/utils/query-param-util"], function (_exports, _environment, _uiPropertyWatcher, _object, _emberConcurrency, _service, _queryParamUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module amc-reports
   */
  var _default = _exports.default = _uiPropertyWatcher.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    /**
     * AbortController for the current fetch
     *
     * @property abortController
     * @type {AbortController}
     * @default null
     */
    abortController: null,
    /**
     * the filters object
     *
     * @property filters
     * @type {Object}
     */
    filters: null,
    /**
     * the current organization slug
     *
     * @property organizationSlug
     * @type {String}
     * @default null
     */
    organizationSlug: null,
    /**
     * the type used for the path
     *
     * @property type
     * @type {String}
     * @default null
     */
    type: null,
    /**
     * The description of the ui-intro
     *
     * @computed _introDescription
     * @type {String}
     * @private
     */
    _introDescription: (0, _object.computed)('type', {
      get() {
        const type = this.type;
        if (!type) {
          return this.intl.t('reports.conversions.description');
        }
        return this.intl.t(`reports.${type}.conversions.description`);
      }
    }),
    /**
     * whether it should be displayed Leads or Leads / Incentive as description
     * for the ui-metric
     *
     * @computed _leads
     * @type {String}
     * @private
     */
    _leads: (0, _object.computed)('uiAppSettings.planName', {
      get() {
        return this.uiAppSettings.planName === 'enterprise' ? 'leadsIncentive' : 'leads';
      }
    }),
    init() {
      this._super(...arguments);
      (0, _object.setProperties)(this, {
        // property watcher properties
        _watchedProperties: ['filters', 'organizationSlug'],
        _onPropertyChange: () => this.conversionsData.perform()
      });
    },
    /**
     * fetches the datasets for the reservations
     *
     * @type {Task}
     * @function conversionsData
     */
    conversionsData: (0, _emberConcurrency.task)(function* () {
      const tasks = [];
      const baseUrl = _environment.default.APP.apiBaseHost;
      const type = this.type;
      const typePath = type ? `${type}/` : '';
      const {
        filters
      } = this;
      const qps = filters ? (0, _queryParamUtil.getQueryParams)(filters) : '';
      const chartsUrl = `${baseUrl}/${this.organizationSlug}/reports/${typePath}conversions${qps}`;
      try {
        tasks.push(this.authenticatedFetch.fetch(chartsUrl, {
          signal: this.abortController && this.abortController.signal
        }));
        tasks.push((0, _emberConcurrency.timeout)(250));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        const {
          conversions,
          enquiries,
          newsletterSubscriptions,
          leadsIncentives,
          vouchers,
          bookings,
          other
        } = yield response.json();
        (0, _object.setProperties)(this, {
          conversions,
          enquiries,
          newsletterSubscriptions,
          leadsIncentives,
          vouchers,
          bookings,
          other
        });
      } catch (error) {
        /* When AbortControllers signal gets aborted, this is intended, so do not show a toast */
        if (error && error.name === 'AbortError') {
          return;
        }
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }).restartable().on('init')
  });
});