define("additive-mc/utils/iso-duration/parse", ["exports", "additive-mc/utils/iso-duration"], function (_exports, _isoDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pattern = _exports.default = void 0;
  // https://github.com/tolu/ISO8601-duration/blob/c39ca366611a4b8db61b6292c4955499b16a4d2b/src/index.js

  // PnYnMnDTnHnMnS
  const numbers = '\\d+(?:[\\.,]\\d+)?';
  const datePattern = `(${numbers}Y)?(${numbers}M)?(${numbers}D)?`;
  const timePattern = `T(${numbers}H)?(${numbers}M)?(${numbers}S)?`;
  const iso8601 = `P(?:${datePattern}(?:${timePattern})?)`;

  /**
   * The ISO8601 regex for matching / testing durations
   */
  const pattern = _exports.pattern = new RegExp(iso8601);

  /**
   * Parse 'PnYnMnDTnHnMnS' format to object
   * @param {string} input - PnYnMnDTnHnMnS formatted string
   * @return {Object} - With a property for each part of the pattern
   */
  const parse = input => {
    if (!input || typeof input !== 'string') {
      throw new Error('[iso-duration] parse error');
    }

    // Slice away first entry in match-array
    return input.match(pattern).slice(1).reduce((prev, next, idx) => {
      prev[_isoDuration.objectMap[idx]] = parseFloat(next) || 0;
      return prev;
    }, {});
  };
  var _default = _exports.default = parse;
});