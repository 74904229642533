define("additive-mc/mirage/routes/widgets/widgets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WidgetRoutes = void 0;
  const WidgetRoutes = self => {
    self.get('/widgets', _ref => {
      let {
        widgets
      } = _ref;
      const widgetModels = widgets.all();
      return {
        widgets: widgetModels.models,
        meta: {
          total: widgetModels.models.length
        }
      };
    });
    self.get('/widgets/:id', (_ref2, request) => {
      let {
        widgets
      } = _ref2;
      const {
        id
      } = request.params;
      return widgets.find(id);
    });
    self.del('/widgets/:id', (_ref3, request) => {
      let {
        widgets
      } = _ref3;
      const {
        id
      } = request.params;
      widgets.find(id).destroy();
    });
    self.post('/widgets', (_ref4, request) => {
      let {
        widgets
      } = _ref4;
      let body = JSON.parse(request.requestBody);
      return widgets.create(body);
    });
    self.put('/widgets/:id', (_ref5, request) => {
      let {
        widgets
      } = _ref5;
      const {
        id
      } = request.params;
      let body = JSON.parse(request.requestBody);
      return widgets.find(id).update(body);
    });
  };
  _exports.WidgetRoutes = WidgetRoutes;
});