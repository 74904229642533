define("additive-mc/mirage/routes/settings/index", ["exports", "additive-mc/mirage/routes/settings/settings", "additive-mc/mirage/routes/settings/legals"], function (_exports, _settings, _legals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FacebookRoutes = void 0;
  Object.defineProperty(_exports, "LegalsRoutes", {
    enumerable: true,
    get: function get() {
      return _legals.LegalsRoutes;
    }
  });
  Object.defineProperty(_exports, "SettingRoutes", {
    enumerable: true,
    get: function get() {
      return _settings.SettingRoutes;
    }
  });
  const FacebookRoutes = self => {
    self.get('https://graph.facebook.com/v14.0/me/businesses', (schema, request) => {
      var _request$queryParams2;
      const data = [];
      for (let i = 0; i < ((_request$queryParams = request.queryParams) !== null && _request$queryParams !== void 0 && _request$queryParams.after ? 5 : 50); i++) {
        var _request$queryParams;
        data.push({
          id: `${i}`,
          name: `Business ${i}`
        });
      }
      return {
        data,
        paging: {
          cursors: {
            before: (_request$queryParams2 = request.queryParams) !== null && _request$queryParams2 !== void 0 && _request$queryParams2.after ? '2' : '1',
            after: '2'
          }
        }
      };
    });
    self.get('https://graph.facebook.com/v14.0/:id/owned_ad_accounts', (schema, request) => {
      var _request$queryParams4;
      const data = [];
      for (let i = 0; i < ((_request$queryParams3 = request.queryParams) !== null && _request$queryParams3 !== void 0 && _request$queryParams3.after ? 5 : 50); i++) {
        var _request$queryParams3;
        data.push({
          id: `${i}`,
          name: `Ad Account ${i}`
        });
      }
      return {
        data,
        paging: {
          cursors: {
            before: (_request$queryParams4 = request.queryParams) !== null && _request$queryParams4 !== void 0 && _request$queryParams4.after ? '2' : '1',
            after: '2'
          }
        }
      };
    });
    self.get('https://graph.facebook.com/v14.0/:id/owned_pages', (schema, request) => {
      var _request$queryParams6, _request$queryParams7, _request$queryParams8, _request$queryParams9;
      const data = [];
      for (let i = 0; i < (((_request$queryParams5 = request.queryParams) === null || _request$queryParams5 === void 0 ? void 0 : _request$queryParams5.after) === '7' ? 5 : 50); i++) {
        var _request$queryParams5;
        data.push({
          id: `${i}`,
          name: `Owned Pages ${i}`
        });
      }
      return {
        data,
        paging: {
          cursors: {
            before: ((_request$queryParams6 = request.queryParams) === null || _request$queryParams6 === void 0 ? void 0 : _request$queryParams6.after) || '1',
            after: ((_request$queryParams7 = request.queryParams) === null || _request$queryParams7 === void 0 ? void 0 : _request$queryParams7.after) === '7' ? '7' : (_request$queryParams8 = request.queryParams) !== null && _request$queryParams8 !== void 0 && _request$queryParams8.after ? `${parseInt((_request$queryParams9 = request.queryParams) === null || _request$queryParams9 === void 0 ? void 0 : _request$queryParams9.after) + 1}` : '2'
          }
        }
      };
    });
    self.get('https://graph.facebook.com/v14.0/:id/client_pages', (schema, request) => {
      var _request$queryParams11;
      const data = [];
      for (let i = 0; i < ((_request$queryParams10 = request.queryParams) !== null && _request$queryParams10 !== void 0 && _request$queryParams10.after ? 5 : 50); i++) {
        var _request$queryParams10;
        data.push({
          id: `${i}`,
          name: `Client Pages ${i}`
        });
      }
      return {
        data,
        paging: {
          cursors: {
            before: (_request$queryParams11 = request.queryParams) !== null && _request$queryParams11 !== void 0 && _request$queryParams11.after ? '2' : '1',
            after: '2'
          }
        }
      };
    });
    self.get('https://graph.facebook.com/v14.0/:id/instagram_accounts', (schema, request) => {
      var _request$queryParams13;
      const data = [];
      for (let i = 0; i < ((_request$queryParams12 = request.queryParams) !== null && _request$queryParams12 !== void 0 && _request$queryParams12.after ? 5 : 50); i++) {
        var _request$queryParams12;
        data.push({
          id: `${i}`,
          username: `Instagram Account ${i}`
        });
      }
      return {
        data,
        paging: {
          cursors: {
            before: (_request$queryParams13 = request.queryParams) !== null && _request$queryParams13 !== void 0 && _request$queryParams13.after ? '2' : '1',
            after: '2'
          }
        }
      };
    });
    self.get('https://graph.facebook.com/v14.0/:id/adspixels', (schema, request) => {
      var _request$queryParams15;
      const data = [];
      for (let i = 0; i < ((_request$queryParams14 = request.queryParams) !== null && _request$queryParams14 !== void 0 && _request$queryParams14.after ? 5 : 50); i++) {
        var _request$queryParams14;
        data.push({
          id: `${i}`,
          name: `Ads Pixel ${i}`
        });
      }
      return {
        data,
        paging: {
          cursors: {
            before: (_request$queryParams15 = request.queryParams) !== null && _request$queryParams15 !== void 0 && _request$queryParams15.after ? '2' : '1',
            after: '2'
          }
        }
      };
    });
  };
  _exports.FacebookRoutes = FacebookRoutes;
});