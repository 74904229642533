define("additive-mc/components/amc-campaign/ads/lead-preview", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mt05">
    <Grid @gap="24" @rowGap="24" @columns="1,2">
      {{#each this.content.multimediaVariants as |variant|}}
        <AmcCampaign::Ads::Preview
          @cta={{this.cta}}
          @headline={{this.content.headline}}
          @text={{this.content.text}}
          @title={{this.content.title}}
          @media={{variant}}
          @type={{or (get variant.labels 0) this.type}}
        />
      {{/each}}
    </Grid>
  </div>
  */
  {
    "id": "ungSE/Zc",
    "block": "[[[10,0],[14,0,\"mt05\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@gap\",\"@rowGap\",\"@columns\"],[\"24\",\"24\",\"1,2\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"content\",\"multimediaVariants\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@cta\",\"@headline\",\"@text\",\"@title\",\"@media\",\"@type\"],[[30,0,[\"cta\"]],[30,0,[\"content\",\"headline\"]],[30,0,[\"content\",\"text\"]],[30,0,[\"content\",\"title\"]],[30,1],[28,[37,4],[[28,[37,5],[[30,1,[\"labels\"]],0],null],[30,0,[\"type\"]]],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13]],[\"variant\"],false,[\"grid\",\"each\",\"-track-array\",\"amc-campaign/ads/preview\",\"or\",\"get\"]]",
    "moduleName": "additive-mc/components/amc-campaign/ads/lead-preview.hbs",
    "isStrictMode": false
  });
  /**
   * Component which generates a preview of all possible ad variations
   *
   * @class AmcCampaignAdsLeadPreviewComponent
   */
  let AmcCampaignAdsLeadPreviewComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class AmcCampaignAdsLeadPreviewComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * The adCreative property of the lead ad
       *
       * @argument content
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "content", _descriptor, this);
      /**
       * The cta text
       *
       * @argument cta
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "cta", _descriptor2, this);
      /**
       * Preview type
       *
       * @argument type
       * @type {String}
       * @default 'landscape'
       */
      _initializerDefineProperty(this, "type", _descriptor3, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "content", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cta", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'landscape';
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcCampaignAdsLeadPreviewComponent);
});