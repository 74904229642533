define("additive-mc/components/amc-campaign/ads/tik-tok-target-groups-modal/setting-block", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "prop-types", "ember-arg-types"], function (_exports, _component, _templateFactory, _component2, _object, _service, _tracking, _propTypes, _emberArgTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{this._classNames}} flex flex-column pa2 br1 w-100">
    <div
      class={{concat "flex cpointer " (if this.isExpanded "pb3" "pb1")}}
      {{did-insert this._onInsertElement}}
      {{will-destroy this._onWillDestroy}}
    >
      {{#if this._isEmpty}}
        {{svg-jar "plus" height="20" width="20"}}
      {{else}}
        {{svg-jar
          "drop-down"
          class=(if this.isExpanded "dropdown--open" "dropdown")
          height="20"
          width="20"
        }}
      {{/if}}
      <div class="flex flex-column ml1">
        <div class="font-sm primary">
          {{t (concat this.intlBaseKey ".add")}}
        </div>
  
        <div class="font-sm secondary">
          {{this._descriptionText}}
        </div>
      </div>
    </div>
    {{#liquid-if this.isExpanded}}
      {{yield}}
    {{/liquid-if}}
  </div>
  */
  {
    "id": "RaF3oLGC",
    "block": "[[[10,0],[15,0,[29,[[30,0,[\"_classNames\"]],\" flex flex-column pa2 br1 w-100\"]]],[12],[1,\"\\n  \"],[11,0],[16,0,[28,[37,0],[\"flex cpointer \",[52,[30,0,[\"isExpanded\"]],\"pb3\",\"pb1\"]],null]],[4,[38,2],[[30,0,[\"_onInsertElement\"]]],null],[4,[38,3],[[30,0,[\"_onWillDestroy\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"_isEmpty\"]],[[[1,\"      \"],[1,[28,[35,4],[\"plus\"],[[\"height\",\"width\"],[\"20\",\"20\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,4],[\"drop-down\"],[[\"class\",\"height\",\"width\"],[[52,[30,0,[\"isExpanded\"]],\"dropdown--open\",\"dropdown\"],\"20\",\"20\"]]]],[1,\"\\n\"]],[]]],[1,\"    \"],[10,0],[14,0,\"flex flex-column ml1\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"font-sm primary\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[[28,[37,0],[[30,0,[\"intlBaseKey\"]],\".add\"],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"font-sm secondary\"],[12],[1,\"\\n        \"],[1,[30,0,[\"_descriptionText\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[6,[39,6],[[30,0,[\"isExpanded\"]]],null,[[\"default\"],[[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]]]]],[13]],[\"&default\"],false,[\"concat\",\"if\",\"did-insert\",\"will-destroy\",\"svg-jar\",\"t\",\"liquid-if\",\"yield\"]]",
    "moduleName": "additive-mc/components/amc-campaign/ads/tik-tok-target-groups-modal/setting-block.hbs",
    "isStrictMode": false
  });
  let AmcTikTokTargetGroupsSettingBlockComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.array), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _class = class AmcTikTokTargetGroupsSettingBlockComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      /**
       * the class names
       *
       * @argument classNames
       * @type {String}
       */
      _initializerDefineProperty(this, "classNames", _descriptor2, this);
      /**
       * the selected values
       *
       * @argument selected
       * @type {Array}
       */
      _initializerDefineProperty(this, "selected", _descriptor3, this);
      /**
       * the base key for the translations
       *
       * @argument intlBaseKey
       * @type {String}
       */
      _initializerDefineProperty(this, "intlBaseKey", _descriptor4, this);
      /**
       * whether the component is expanded
       *
       * @argument isExpanded
       * @type {Boolean}
       */
      _initializerDefineProperty(this, "isExpanded", _descriptor5, this);
    }
    get _classNames() {
      const styles = this.isExpanded ? 'bg-white ba b--secondary-10' : 'bg-secondary--10';
      return `amc-tik-tok-target-groups-modal-setting-block ${this.classNames} ${styles}`;
    }
    get _isEmpty() {
      if (this.selected.length === 0) {
        return true;
      }
      return false;
    }
    get _descriptionText() {
      if (this._isEmpty) {
        return this.intl.t(`${this.intlBaseKey}.text`);
      }
      const selected = this.selected.filter(item => item.subData ? item.subData.length === 0 : true);
      return this.intl.t(`${this.intlBaseKey}.selectedCount`, {
        count: selected.length
      });
    }
    _onInsertElement(element) {
      if (!element) {
        return;
      }
      element.addEventListener('click', this._expandSettings, false);
    }
    _onWillDestroy(element) {
      if (!element) {
        return;
      }
      element.removeEventListener('click', this._expandSettings, false);
    }
    _expandSettings() {
      const value = this.isExpanded;
      this.isExpanded = !value;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "classNames", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intlBaseKey", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return 'adCampaigns.adGeneralSettings.settings.tikTokTargetGroups.editDialog.interests';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isExpanded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_onInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onInsertElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_expandSettings", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_expandSettings"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcTikTokTargetGroupsSettingBlockComponent);
});