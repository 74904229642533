define("additive-mc/mirage/factories/service", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    name: () => _faker.default.random.word(),
    type: () => _faker.default.random.arrayElement(['facebookCatalogService']),
    status: () => {
      return {
        lastPublishedAt: _faker.default.date.between('2021-12-01', '2021-12-20'),
        state: _faker.default.random.arrayElement(['success', 'warning', 'error', 'pending']),
        message: _faker.default.lorem.words()
      };
    },
    selectedContentTypes: () => {
      return [{
        id: 'offers',
        name: 'Angebote',
        excludedContents: [{
          id: 'excludable_categories',
          name: 'Auszuschließende Kategorien',
          excluded: ['1', 'not_existing']
        }, {
          id: 'excludable_offers',
          name: 'Auszuschließende Angebote',
          excluded: []
        }]
      }];
    },
    externalServiceURL: () => {
      return _faker.default.random.boolean() ? 'https://www.facebook.com' : '';
    }
  });
});