define("additive-mc/mirage/routes/reports/ad", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AdReportsTiktokLeadAdMetrics = _exports.AdReportsReservations = _exports.AdReportsReservationTable = _exports.AdReportsPastMarketingActions = _exports.AdReportsOverall = _exports.AdReportsMetrics = _exports.AdReportsLeadAdMetrics = _exports.AdReportsKeyIndicators = _exports.AdReportsFacebookAdMetrics = _exports.AdReportsConversions = void 0;
  const AdReportsOverall = self => {
    self.get('/reports/ad', schema => {
      return {
        metrics: {
          impressions: {
            type: 'compare',
            change: -1,
            current: {
              value: 354249.0,
              type: 'yearly',
              date: 2024
            },
            compare: {
              value: 360519.0,
              type: 'yearly',
              date: 2023
            },
            format: null,
            currency: null
          },
          clicks: {
            type: 'compare',
            change: 93,
            current: {
              value: 13652.0,
              type: 'yearly',
              date: 2024
            },
            compare: {
              value: 7053.0,
              type: 'yearly',
              date: 2023
            },
            format: null,
            currency: null
          },
          adSpend: {
            type: 'compare',
            change: 41,
            current: {
              value: 409770.0,
              type: 'yearly',
              date: 2024
            },
            compare: {
              value: 289948.0,
              type: 'yearly',
              date: 2023
            },
            format: 'currency_cent',
            currency: 'EUR'
          }
        }
      };
    });
  };
  _exports.AdReportsOverall = AdReportsOverall;
  const AdReportsKeyIndicators = self => {
    self.get('/reports/ad/key-indicators', () => {
      return {
        valueAddedSum: {
          type: 'compare',
          change: 32,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        },
        valueAdded: {
          type: 'compare',
          change: 59.35,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        },
        turnover: {
          type: 'compare',
          change: 25,
          current: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.finance.amount(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.AdReportsKeyIndicators = AdReportsKeyIndicators;
  const AdReportsReservations = self => {
    self.get('/reports/ad/reservations', () => {
      return {
        reservations: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        newGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        reactivatedGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        returningGuests: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.AdReportsReservations = AdReportsReservations;
  const AdReportsConversions = self => {
    self.get('/reports/ad/conversions', () => {
      return {
        conversions: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        enquiries: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        newsletterSubscriptions: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        vouchers: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        bookings: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        },
        other: {
          type: 'compare',
          change: _faker.default.random.number({
            min: -100,
            max: 100
          }),
          current: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2019
          },
          compare: {
            value: _faker.default.random.number(),
            type: 'yearly',
            date: 2018
          }
        }
      };
    });
  };
  _exports.AdReportsConversions = AdReportsConversions;
  const AdReportsFacebookAdMetrics = self => {
    self.get('/reports/ad/content-ad-metrics', schema => {
      return {
        metrics: {
          ads: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          impressions: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          clicks: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          conversionPrice: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          }
        },
        campaigns: {
          modelName: 'reports-ad',
          models: []
        }
      };
    });
  };
  _exports.AdReportsFacebookAdMetrics = AdReportsFacebookAdMetrics;
  const AdReportsMetrics = self => {
    self.get('/reports/ad/metrics', schema => {
      return {
        metrics: {
          ads: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          impressions: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          clicks: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          },
          conversionPrice: {
            type: 'compare',
            change: _faker.default.random.number({
              min: -100,
              max: 100
            }),
            current: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2019
            },
            compare: {
              value: _faker.default.random.number(),
              type: 'yearly',
              date: 2018
            }
          }
        },
        campaigns: schema.reportsAds.all()
      };
    });
  };
  _exports.AdReportsMetrics = AdReportsMetrics;
  const AdReportsPastMarketingActions = self => {
    self.get('/reports/ad/past-marketing-actions', schema => {
      return schema.adPastMarketingActions.all();
    });
  };
  _exports.AdReportsPastMarketingActions = AdReportsPastMarketingActions;
  const AdReportsLeadAdMetrics = self => {
    self.get('/reports/ad/lead-ad-metrics', schema => {
      return {
        metrics: {
          ads: {
            type: 'compare',
            change: 0,
            current: {
              value: 1,
              type: 'yearly',
              date: 2024
            },
            compare: {
              value: 1,
              type: 'yearly',
              date: 2023
            },
            format: null,
            currency: null
          },
          clicks: {
            type: 'compare',
            change: 387,
            current: {
              value: 2738,
              type: 'yearly',
              date: 2024
            },
            compare: {
              value: 562,
              type: 'yearly',
              date: 2023
            },
            format: null,
            currency: null
          },
          impressions: {
            type: 'compare',
            change: 190,
            current: {
              value: 77641,
              type: 'yearly',
              date: 2024
            },
            compare: {
              value: 26739,
              type: 'yearly',
              date: 2023
            },
            format: null,
            currency: null
          },
          leadPrice: {
            type: 'compare',
            change: 46,
            current: {
              value: 448.68932038834953,
              type: 'yearly',
              date: 2024
            },
            compare: {
              value: 307.03960396039605,
              type: 'yearly',
              date: 2023
            },
            format: null,
            currency: null
          }
        },
        campaigns: {
          modelName: 'reports-ad',
          models: [{
            id: '23850730614680720',
            clicks: 2738,
            spend: {
              value: 92430.0,
              format: 'currency_cent',
              currency: 'EUR'
            },
            impressions: 77641,
            conversions: 0,
            publishDate: '2022-07-01T04:00:00.000Z',
            stopDate: '2023-12-31T21:00:00.000Z',
            reservations: 0,
            type: 'FacebookLeadAd',
            conversionPrice: 0,
            campaign: 'MA_FEUERSTEIN-INFO_LEAD_LEAD-ADS',
            ads: 6,
            leads: 206
          }]
        }
      };
    });
  };
  _exports.AdReportsLeadAdMetrics = AdReportsLeadAdMetrics;
  const AdReportsTiktokLeadAdMetrics = self => {
    self.get('/reports/ad/tik-tok-lead-ad-metrics', schema => {
      return {
        metrics: {
          ads: {
            type: 'compare',
            change: 20,
            current: {
              value: 1,
              type: 'yearly',
              date: 2024
            },
            compare: {
              value: 1,
              type: 'yearly',
              date: 2023
            },
            format: null,
            currency: null
          },
          clicks: {
            type: 'compare',
            change: -100,
            current: {
              value: 0.0,
              type: 'yearly',
              date: 2024
            },
            compare: {
              value: 670.0,
              type: 'yearly',
              date: 2023
            },
            format: null,
            currency: null
          },
          impressions: {
            type: 'compare',
            change: -100,
            current: {
              value: 0.0,
              type: 'yearly',
              date: 2024
            },
            compare: {
              value: 122391.0,
              type: 'yearly',
              date: 2023
            },
            format: null,
            currency: null
          },
          leadPrice: {
            type: 'compare',
            change: -100,
            current: {
              value: 0,
              type: 'yearly',
              date: 2024
            },
            compare: {
              value: 911.9696969696969,
              type: 'yearly',
              date: 2023
            },
            format: null,
            currency: null
          }
        },
        campaigns: {
          modelName: 'reports-ad',
          models: [{
            id: '1764862080802865',
            clicks: 32.47,
            spend: {
              value: 67.89,
              format: 'currency_cent',
              currency: 'EUR'
            },
            impressions: 58.24,
            conversions: 12.34,
            publishDate: '2023-05-03T15:00:00.000Z',
            stopDate: '2024-12-31T21:00:00.000Z',
            reservations: 84,
            type: 'TikTokLeadAd',
            conversionPrice: 73,
            campaign: 'MA_FEUERSTEIN-INFO_LEAD_TIK-TOK-LEAD-ADS',
            ads: 5,
            leads: 61
          }]
        }
      };
    });
  };
  _exports.AdReportsTiktokLeadAdMetrics = AdReportsTiktokLeadAdMetrics;
  const AdReportsReservationTable = self => {
    self.get('/reports/ad/reservations-table', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      const data = [];
      for (let i = 0; i < 20; i++) {
        data.push([{
          value: _faker.default.name.findName(),
          link: 'https://google.com',
          format: 'custom',
          customKey: 'name'
        }, 'Neue Reservierung', {
          value: _faker.default.date.past(),
          format: 'date'
        }, {
          value: _faker.default.random.number(),
          format: 'currency_cent'
        }, {
          value: {
            start: _faker.default.date.between(start, end),
            end: _faker.default.date.between(start, end)
          },
          format: 'custom',
          customKey: 'range'
        }, '3 Erw. 2 Kinder', _faker.default.name.findName()]);
      }
      return {
        labels: ['Reservierende Person', 'Typ', 'Reservierungsdatum', 'Res.-Umsatz', 'Aufenthalt', 'Reisegruppe', 'Kampagne'],
        data
      };
    });
  };
  _exports.AdReportsReservationTable = AdReportsReservationTable;
});