define("additive-mc/mirage/routes/dashboard/key-indicators", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DashboardKeyIndicators = void 0;
  /*
   * Gesamtwertschöpfung mit % vergl. zum Vorjahr
   *
   * Reservierungen mit % vergl. zum Vorjahr
   *
   * Online Konversionen mit % vergl. zum Vorjahr
   *
   * Sitzungen mit % vergl. zum Vorjahr
   *
   * |||||||||||||||||||||||||
   * ||                     ||
   * ||   110               ||
   * ||                     ||
   * ||   Reservierungen    ||
   * ||                     ||
   * ||   ^36% zum Vorjahr  ||
   * ||                     ||
   * |||||||||||||||||||||||||
   */
  const DashboardKeyIndicators = self => {
    self.get('/dashboard/key-indicators', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      return {
        valueAdded: {
          type: 'compare',
          change: 59.35,
          current: {
            value: start ? Math.random() * 30000 : 335074.68,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 210271.76,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        reservations: {
          type: 'compare',
          change: 25,
          current: {
            value: start ? Math.floor(Math.random() * 200) : 25,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 100,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        conversions: {
          type: 'compare',
          change: 4.19,
          current: {
            value: start ? Math.floor(Math.random() * 2000) : 25,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 1274,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        sessions: {
          type: 'compare',
          change: 32.63,
          current: {
            value: start ? Math.floor(Math.random() * 6000) : 25,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 6985,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        }
      };
    });
  };
  _exports.DashboardKeyIndicators = DashboardKeyIndicators;
});