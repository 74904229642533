define("additive-mc/utils/widget-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nestChangesetChanges = void 0;
  /**
   * The keys of the changeset changes may be concatenated strings delimited by `.`.
   * Build a nested object with the splitted keys
   *
   * @param {Object} changes
   */
  const nestChangesetChanges = changes => {
    const changesObject = {};
    changes.forEach(changedProperty => {
      const splittedPropertyKey = changedProperty.key.split('.');
      const lastKey = splittedPropertyKey.pop();
      let lastObjectLeaf = splittedPropertyKey.reduce((obj, key) => obj[key] = obj[key] || {}, changesObject);
      lastObjectLeaf[lastKey] = changedProperty.value;
    });
    return changesObject;
  };
  _exports.nestChangesetChanges = nestChangesetChanges;
});