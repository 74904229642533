define("additive-mc/templates/instance/settings/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "l3y8qnlL",
    "block": "[[[8,[39,0],null,[[\"@organizationSlug\",\"@isReadOnly\",\"@authCode\",\"@redirect\"],[[30,0,[\"_organizationSlug\"]],[30,0,[\"isReadOnly\"]],[30,0,[\"auth_code\"]],[30,0,[\"redirect\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"amc-settings\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-mc/templates/instance/settings/index.hbs",
    "isStrictMode": false
  });
});