define("additive-mc/controllers/instance/campaigns/ad/catalog-ads/index/catalog-ad/edit", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "date-fns", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "@additive-apps/utils/validators/numeral", "additive-mc/validations/advertisement"], function (_exports, _controller, _object, _tracking, _service, _dateFns, _emberChangeset, _emberChangesetValidations, _validators, _numeral, _advertisement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsAdCatalogAdsIndexCatalogAdEditController = _exports.default = (_class = class InstanceCampaignsAdCatalogAdsIndexCatalogAdEditController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor4, this);
      _initializerDefineProperty(this, "uiToast", _descriptor5, this);
      _initializerDefineProperty(this, "meta", _descriptor6, this);
      _initializerDefineProperty(this, "changeset", _descriptor7, this);
      _initializerDefineProperty(this, "budgetChangeset", _descriptor8, this);
      _initializerDefineProperty(this, "model", _descriptor9, this);
      _initializerDefineProperty(this, "promotedProductsChangesets", _descriptor10, this);
      _initializerDefineProperty(this, "isDateTouched", _descriptor11, this);
      _initializerDefineProperty(this, "isTouched", _descriptor12, this);
      _initializerDefineProperty(this, "isDiscardChangesDialog", _descriptor13, this);
      _initializerDefineProperty(this, "campaignId", _descriptor14, this);
      _initializerDefineProperty(this, "catalogAdResource", _descriptor15, this);
      /**
       * the earliest date that can be selected
       *
       * @property minDate
       * @type {Date}
       */
      _defineProperty(this, "minDate", new Date());
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    get dailySpendingAmount() {
      if (new Date(this.changeset.get('endDate')) < new Date() || parseFloat(this.budgetChangeset.get('amount')) <= parseFloat(this.changeset.get('budgetSpend.value'))) {
        return '';
      }
      const startDate = new Date() < new Date(this.changeset.get('startDate')) ? new Date(this.changeset.get('startDate')) : new Date();
      const datesDayDiff = (0, _dateFns.differenceInDays)(new Date(this.changeset.get('endDate')), startDate);
      const remainingBudget = this.budgetChangeset.get('amount') - (this.changeset.get('budgetSpend.value') || 0);
      return remainingBudget / datesDayDiff;
    }

    /**
     * the earliest date that can be selected for the end date
     *
     * @computed minEndDate
     * @type {Date}
     */
    get minEndDate() {
      var _this$changeset;
      return ((_this$changeset = this.changeset) === null || _this$changeset === void 0 ? void 0 : _this$changeset.get('startDate')) > this.minDate ? this.changeset.get('startDate') : this.minDate;
    }
    setupChangeset() {
      var _catalogAdResource$bu, _this$budgetChangeset, _catalogAdResource$bu2;
      const {
        intl
      } = this;
      let changesetChanges = null;
      if (this.changeset) {
        changesetChanges = this.changeset.get('changes');
      }
      const catalogAdResource = Object.assign({}, this.model.catalogAd.toJSON());
      catalogAdResource.startDate = new Date(catalogAdResource.startDate);
      catalogAdResource.endDate = new Date(catalogAdResource.endDate);
      catalogAdResource.promotedProducts = this.meta.productTypes.map(productType => {
        var _catalogAdResource$pr;
        return {
          value: productType.value,
          specifiedSelection: ((_catalogAdResource$pr = catalogAdResource.promotedProducts.find(promotedProduct => promotedProduct.value === productType.value)) === null || _catalogAdResource$pr === void 0 ? void 0 : _catalogAdResource$pr.specifiedSelection.map(selection => productType.subTypes.find(subType => subType.value === selection))) || [],
          selected: catalogAdResource.promotedProducts.findIndex(promotedProduct => promotedProduct.value === productType.value) !== -1
        };
      });
      let promotedProductsChangesetChanges = [];
      if (this.promotedProductsChangesets) {
        this.promotedProductsChangesets.forEach(promotedProductChangeset => {
          promotedProductsChangesetChanges.push(promotedProductChangeset.get('changes'));
        });
      }
      this.promotedProductsChangesets = [];
      catalogAdResource.promotedProducts.forEach((promotedProduct, index) => {
        var _promotedProductsChan;
        const promotedProductChangeset = new _emberChangeset.default(promotedProduct);
        promotedProductsChangesetChanges === null || promotedProductsChangesetChanges === void 0 || (_promotedProductsChan = promotedProductsChangesetChanges[index]) === null || _promotedProductsChan === void 0 || _promotedProductsChan.forEach(change => {
          promotedProductChangeset.set(change.key, change.value);
        });
        this.promotedProductsChangesets.push(promotedProductChangeset);
      });
      const validation = (0, _advertisement.catalogAdValidation)(this.intl);
      this.changeset = new _emberChangeset.default(catalogAdResource, (0, _emberChangesetValidations.default)(validation), validation);
      if (changesetChanges) {
        changesetChanges.forEach(change => {
          this.changeset.set(change.key, change.value);
        });
      }
      let budgetChangesetChanges = null;
      if (this.budgetChangeset) {
        budgetChangesetChanges = this.budgetChangeset.get('changes');
      }

      /*
        Create a separate changeset for the budget to avoid problems with
        nested elements in the changeset.
       */
      if (!catalogAdResource.budget) {
        catalogAdResource.budget = {
          amount: null,
          type: null
        };
      }
      let currentBudgetType = catalogAdResource === null || catalogAdResource === void 0 || (_catalogAdResource$bu = catalogAdResource.budget) === null || _catalogAdResource$bu === void 0 ? void 0 : _catalogAdResource$bu.type;
      if ((_this$budgetChangeset = this.budgetChangeset) !== null && _this$budgetChangeset !== void 0 && _this$budgetChangeset.get('type')) {
        currentBudgetType = this.budgetChangeset.get('type');
      }
      const budgetValidation = (0, _advertisement.adBudgetValidation)(intl);
      if ((_catalogAdResource$bu2 = catalogAdResource.budgetSpend) !== null && _catalogAdResource$bu2 !== void 0 && _catalogAdResource$bu2.value && currentBudgetType === 'lifetime') {
        budgetValidation['type'] = (0, _validators.validatePresence)({
          presence: true,
          message: intl.t('errors.required')
        });
        budgetValidation['amount'] = (0, _numeral.validateNumeral)({
          gte: catalogAdResource.budgetSpend.value,
          allowBlank: false,
          message: intl.t('adCampaigns.catalogAds.edit.errors.minSpend')
        });
      }
      this.budgetChangeset = new _emberChangeset.default(catalogAdResource.budget, (0, _emberChangesetValidations.default)(budgetValidation), budgetValidation);
      if (budgetChangesetChanges) {
        budgetChangesetChanges.forEach(change => {
          this.budgetChangeset.set(change.key, change.value);
        });
      }
      this.catalogAdResource = catalogAdResource;
    }
    selectProperty(property, _ref) {
      let {
        value
      } = _ref;
      this.changeset.set(property, value);
    }
    onChangeBudgetType(_ref2) {
      let {
        value
      } = _ref2;
      this.budgetChangeset.set('type', value);
      if (value === 'lifetime') {
        this.changeset.set('unlimitedDuration', false);
      }

      // create new changeset to update the validation
      this.setupChangeset();
      this.budgetChangeset.validate('amount');
    }
    onChangeBudget(value) {
      this.budgetChangeset.set('amount', value);
      this.budgetChangeset.validate('amount');
    }
    onBack() {
      let dirty = this.changeset.get('isDirty');
      if (!dirty) {
        this.promotedProductsChangesets.forEach(promotedProductsChangeset => {
          dirty = dirty || promotedProductsChangeset.get('isDirty');
        });
      }
      if (dirty) {
        this.isDiscardChangesDialog = true;
        return;
      }
      this.back();
    }
    discardChanges() {
      this.isDiscardChangesDialog = false;
      this.changeset.rollback();
      this.promotedProductsChangesets.forEach(changeset => {
        changeset.rollback();
      });
      this.back();
    }
    back() {
      this.router.transitionTo('instance.campaigns.ad.catalog-ads.index.catalog-ad');
    }
    onChangeDateTime(propertyName, date) {
      this.changeset.set(propertyName, date);
      this.changeset.validate('startDate');
      this.changeset.validate('endDate');
    }
    onChangeProductType(index, selected) {
      this.promotedProductsChangesets[index].set('selected', selected);
    }
    selectPromotedProductOption(index, item, checked) {
      let selected = this.promotedProductsChangesets[index].get('specifiedSelection');
      const itemIndex = selected.indexOf(item);
      if (checked) {
        /* Only push if not already inside */
        itemIndex === -1 && selected.push(item);
      } else {
        if (itemIndex >= 0) {
          const preElements = selected.slice(0, itemIndex);
          const postElements = selected.slice(itemIndex + 1);
          selected = [...preElements, ...postElements];
        }
      }
      this.promotedProductsChangesets[index].set('specifiedSelection', [...selected]);
    }
    async save() {
      this.isTouched = true;
      this.isDateTouched = true;
      try {
        await this.changeset.validate();
        await this.budgetChangeset.validate();
        if (this.changeset.get('isInvalid') || this.budgetChangeset.get('isInvalid')) {
          return;
        }
        this.changeset.execute();
        await this.budgetChangeset.execute();
        this.promotedProductsChangesets.forEach(changeset => {
          changeset.execute();
        });
        Object.keys(this.catalogAdResource).forEach(key => {
          this.model.catalogAd[key] = this.catalogAdResource[key];
        });
        await this.model.catalogAd.save({
          adapterOptions: {
            campaignId: this.campaignId,
            type: 'catalog-ads'
          }
        });
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
        this.back();
      } catch (error) {
        this.model.catalogAd.rollbackAttributes();
        this.setupChangeset();
        try {
          const errorDetail = JSON.parse(error.message);
          this.uiToast.showToast({
            title: errorDetail.message,
            type: 'error'
          });
        } catch (e) {
          this.uiToast.showToast({
            title: this.intl.t('toast.unexpectedError'),
            type: 'error'
          });
        }
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "meta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "budgetChangeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "promotedProductsChangesets", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isDateTouched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isTouched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isDiscardChangesDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "campaignId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "catalogAdResource", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectProperty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectProperty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeBudgetType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeBudgetType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeBudget", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeBudget"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onBack", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onBack"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "discardChanges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "discardChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "back", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "back"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeDateTime", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeDateTime"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeProductType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeProductType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectPromotedProductOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectPromotedProductOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _class);
});