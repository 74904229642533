define("additive-mc/routes/instance/campaigns/on-page/detail/content/content", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    store: (0, _service.inject)(),
    model(params) {
      const model = this.store.peekRecord('on-page-content', params.content_id);
      if (model) {
        return model;
      }
      const {
        campaign_id: campaignId
      } = this.paramsFor('instance.campaigns.on-page.detail');
      return this.store.findRecord('on-page-content', params.content_id, {
        adapterOptions: {
          campaignId
        }
      });
    }
  });
});