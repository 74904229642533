define("additive-mc/components/amc-reports/value-added", ["exports", "@additive-apps/ui/components/ui-property-watcher", "@ember/object", "@ember/service", "@ember/object/computed", "ember-concurrency", "@additive-apps/ui/utils/query-param-util"], function (_exports, _uiPropertyWatcher, _object, _service, _computed, _emberConcurrency, _queryParamUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module amc-reports
   */
  var _default = _exports.default = _uiPropertyWatcher.default.extend({
    authenticatedFetch: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    router: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    uiPaths: (0, _service.inject)(),
    uiToast: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    /**
     * AbortController for the current fetch
     *
     * @property abortController
     * @type {AbortController}
     * @default null
     */
    abortController: null,
    /**
     * the filters object
     *
     * @property filters
     * @type {Object}
     */
    filters: null,
    /**
     * the current organization slug
     *
     * @property organizationSlug
     * @type {String}
     * @default null
     */
    organizationSlug: null,
    /**
     * whether the table is empty
     *
     * @computed isEmpty
     * @type {Boolean}
     */
    isEmpty: (0, _computed.equal)('marketingActions.length', 0),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'tableHeaderOptions', [{
        i18n: 'reports.valueAdded.columns.action',
        minWidth: '80px'
      }, {
        i18n: 'reports.valueAdded.columns.count',
        minWidth: '80px'
      }, {
        i18n: 'reports.valueAdded.columns.valuePerAction',
        minWidth: '80px'
      }, {
        i18n: 'reports.valueAdded.columns.turnover',
        minWidth: '80px'
      }]);
      (0, _object.setProperties)(this, {
        // property watcher properties
        _watchedProperties: ['filters', 'organizationSlug'],
        _onPropertyChange: () => this.valueAddedData.perform(),
        marketingActions: []
      });
    },
    /**
     * <slug>/reports/value-added
     *
     * @throws {Error}
     */
    valueAddedData: (0, _emberConcurrency.task)(function* () {
      try {
        let tasks = [];
        const baseUrl = this.uiPaths.pathsByRouteName('instance.dashboard', this.organizationSlug).api().url;
        const {
          filters
        } = this;
        const qps = filters ? (0, _queryParamUtil.getQueryParams)(filters) : '';
        let url = `${baseUrl}/reports/value-added${qps}`;

        // load last orders
        tasks.push(this.authenticatedFetch.fetch(url, {
          signal: this.abortController && this.abortController.signal
        }));
        tasks.push((0, _emberConcurrency.timeout)(400));
        const [response] = yield (0, _emberConcurrency.all)(tasks);
        if (!response || !response.ok) {
          throw new Error('[DASHBOARD] Error in response');
        }
        const {
          valueAddeds
        } = yield response.json();
        (0, _object.set)(this, 'marketingActions', valueAddeds);
      } catch (error) {
        /* When AbortControllers signal gets aborted, this is intended, so do not show a toast */
        if (error && error.name === 'AbortError') {
          return;
        }
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }).on('init')
  });
});