define("additive-mc/mirage/factories/extension", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    name(i) {
      return `Extension ${i}`;
    },
    type: () => _faker.default.random.arrayElement(['Google::SearchAds::Extension::SiteLink', 'Google::SearchAds::Extension::AdditionalInformation']),
    language: () => _faker.default.random.arrayElement(['de', 'en', 'it']),
    link: _faker.default.internet.url()
  });
});