define("additive-mc/mirage/routes/settings/legals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LegalsRoutes = void 0;
  const LegalsRoutes = self => {
    self.get('/legals', schema => {
      return schema.legals.first();
    });
    self.put('/legals', (schema, request) => {
      const attrs = JSON.parse(request.requestBody);
      const model = schema.legals.first();
      return model.update(attrs);
    });
  };
  _exports.LegalsRoutes = LegalsRoutes;
});