define("additive-mc/controllers/instance/campaigns/search-ad/extensions", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "ember-concurrency", "@ember/service", "additive-mc/utils/constants"], function (_exports, _controller, _tracking, _object, _emberConcurrency, _service, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceCampaignsSearchAdExtensionsController = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {}), _class = class InstanceCampaignsSearchAdExtensionsController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor5, this);
      _initializerDefineProperty(this, "uiFilter", _descriptor6, this);
      _initializerDefineProperty(this, "uiToast", _descriptor7, this);
      /**
       * the model that is currently active
       *
       * @property activeModel
       * @type {Model}
       * @default null
       */
      _initializerDefineProperty(this, "activeModel", _descriptor8, this);
      /**
       * the extensions meta data
       *
       * @property meta
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "meta", _descriptor9, this);
      /**
       * key for extension type "site link"
       *
       * @property siteLinkExtension
       * @type {String}
       * @default GOOGLE_SEARCH_ADS_SITE_LINK
       */
      _initializerDefineProperty(this, "siteLinkExtension", _descriptor10, this);
      /**
       * whether the edit dialog is opened
       *
       * @property isEditDialog
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isEditDialog", _descriptor11, this);
      /**
       * Fetches and returns the extensions meta
       * Is replaced in setupController by task of parent controller
       *
       * @function fetchMeta
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchMeta", _descriptor12, this);
    }
    get isReadOnly() {
      return this.currentUser.isReadOnly;
    }
    async onDelete(model) {
      try {
        await model.destroyRecord();
        this.uiFilter.reset('extension');
        this.uiToast.showToast({
          title: this.intl.t('toast.success'),
          type: 'success'
        });
      } catch (e) {
        if (e && e.code === 'in_use') {
          this.uiDialog.showError(this.intl.t('searchAdCampaigns.extensions.errorMessages.inUse.title'), this.intl.t('searchAdCampaigns.extensions.errorMessages.inUse.description'));
        } else {
          this.uiToast.showToast({
            title: this.intl.t('toast.unexpectedError'),
            type: 'error'
          });
        }
      }
    }
    openEditDialog(model) {
      this.activeModel = model;
      this.isEditDialog = true;
    }
    delete(model) {
      this.uiDialog.showConfirm(this.intl.t('searchAdCampaigns.extensions.deleteDialog.title'), this.intl.t('searchAdCampaigns.extensions.deleteDialog.description', {
        htmlSafe: true
      }), () => this.onDelete(model), this.intl.t('global.actions.delete'), false, true);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiFilter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "activeModel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "meta", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "siteLinkExtension", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return _constants.GOOGLE_SEARCH_ADS_SITE_LINK;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isEditDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "fetchMeta", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openEditDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openEditDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _class);
});