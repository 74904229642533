define("additive-mc/mirage/factories/email-campaign", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const allowedValues = [{
    label: 'option1',
    value: 'option1-value'
  }, {
    label: 'option2',
    value: 'option2-value'
  }, {
    label: 'option3',
    value: 'option3-value'
  }];
  const codeTypeMapping = {
    usecase1: 'convert-ota-to-direct-booker-campaign',
    usecase2a: 'reactivate-enquirer-without-booking-campaign',
    usecase2b: 'reactivate-lost-guest-campaign',
    usecase3a: 'come-back-campaign',
    usecase3b: 'come-back-multiple-campaign',
    usecase4: 'upselling-for-second-stay-campaign',
    usecase5: 'early-booker-benefits-campaign',
    usecase6: 'follow-up-on-voucher-campaign',
    usecase7a: 'birthday-voucher-from-friends-campaign',
    usecase7b: 'birthday-wishes-campaign',
    usecase7c: 'birthday-gift-campaign',
    usecase9a: 'last-minute-deal-campaign',
    usecase9b: 'deals-campaign',
    usecase11: 'pre-stay-campaign',
    usecase12: 'post-stay-campaign'
  };
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    title: () => _faker.default.name.findName(),
    name() {
      return this.title;
    },
    active: () => _faker.default.random.boolean(),
    isStartsAtEnabled() {
      if (this.active) {
        return true;
      }
      return false;
    },
    startsAt() {
      return _faker.default.date.past();
      emailCampaigns;
    },
    configuration() {
      return [{
        label: 'Booking Channels',
        description: 'Booking Channels description',
        type: 'array',
        key: 'bookingChannels',
        order: 2,
        value: _faker.default.random.arrayElement([null, ['Helles', 'Märzen'], ['Kellerbier'], ['Weizenbier', 'Bockbier']])
      }, {
        type: 'boolean',
        key: 'include-guests-with-future-booking',
        label: 'Include guests with future reservations',
        description: 'Description..',
        order: 0,
        value: true,
        allowedValues: null
      }, {
        type: 'number',
        key: 'min-stays',
        label: 'Minimum stays',
        description: 'Description..',
        order: 0,
        value: 2,
        allowedValues: null
      }];
    },
    availableLanguages: ['de', 'en', 'it'],
    publishedLanguages: ['de', 'en'],
    descriptions: {
      trigger: {
        event: 'Trigger Text (from API) ...',
        details: 'Trigger description (from API) ...'
      },
      summary: 'Campaign Summary Text (from API) ...',
      details: 'Campaign Details'
    },
    isNewStructure: false,
    afterCreate(campaign, server) {
      if (!campaign.code) {
        let unusedCodes = Object.keys(codeTypeMapping);
        server.schema.emailCampaigns.all().models.forEach(campaign => {
          unusedCodes = unusedCodes.filter(code => code !== campaign.code);
        });
        campaign.update({
          code: _faker.default.random.arrayElement(unusedCodes)
        });
      }
      let voucher = null;
      const hasVoucher = _faker.default.random.boolean();
      campaign.update({
        type: codeTypeMapping[campaign.code]
      });
      if (campaign.type === 'pre-stay-campaign') {
        campaign.update({
          isNewStructure: true
        });
      }
      if (hasVoucher) {
        voucher = server.create('email-campaign-voucher');
        campaign.isNewStructure ? campaign.update({
          contribution: voucher.toJSON()
        }) : campaign.update({
          voucher: voucher.toJSON()
        });
      }
      const emailSeries = server.createList('email-series', 3, {
        campaign
      });
      if (campaign.isNewStructure) {
        const landingPage = server.create('email-campaign-landingpage');
        campaign.update({
          landingPage: landingPage.toJSON(),
          emailSeries
        });
        return;
      }
      const emails = server.createList('email', 3, Object.assign({
        campaign
      }, hasVoucher && voucher.active ? {
        ctaType: 'voucher',
        ctaText: null
      } : {}));
      campaign.update({
        emails,
        emailSeries
      });
    }
  });
});